import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  HStack,
  VStack,
  Text,
  Modal,
  FormControl,
  Stack,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import imgs from "@assets/images/loader.gif";
import { ROUTES } from "@views/routes/my_routes";

import Wrapper from "@views/components/ui/wrapper";
import {
  createServiceRequestSelector,
  create_one_service_clear,
  create_service_request_question,
  getOneServiceRequestSelector,
  getOneServiceSelector,
  getServicesQuestionListSelector,
  get_one_service,
  get_one_service_request,
  get_service_question,
  update_service_request_question,
  createServiceRequestProcessSelector,
  create_service_request_process,
  create_one_service_process_clear,
  updateServiceRequestSelector,
  update_one_service_clear,
  create_service_request_return,
  createServiceRequestReturnSelector,
  create_one_service_return_clear,
  service_request_order,
  createServiceRequestOrderSelector,
  create_one_service_order_clear,
  service_request_sent_to_workshop,
  createServiceRequestSentToWorkshopSelector,
  create_one_service_sent_to_workshop_clear,
  getServiceRequestVehiclesListSelector,
  get_service_request_vehicles,
  getone_Vehicles,
  getOneVehiclesListSelector,
} from "@services/redux";
import { Form as AntdForm, Button, Card } from "antd";
import { retrieveItem } from "@helpers/storage";
import { colorScheme } from "@helpers/constants";

const ServiceView = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = AntdForm.useForm();

  const { items } = useSelector(getServicesQuestionListSelector);
  const {
    loading: sent_to_workshop_loading,
    error: sent_to_workshop_error,
    status: sent_to_workshop_status,
  } = useSelector(createServiceRequestSentToWorkshopSelector);
  const { items: vehicles } = useSelector(
    getServiceRequestVehiclesListSelector
  );

  const { id, service_id } = useParams();
  const [lang, setLang] = useState([]);
  const [url, setURL] = useState("");
  const [actionItem, setActionItem] = useState({});
  const [question, setQuestion] = useState(items);
  const [valueField, setValuesField] = useState({});
  const [VehicleList, setVehicleList] = useState([]);
  const [VehicleId, setVehicleId] = useState("");
  const [currentService, setCurrentService] = useState("");

  const [manageModalVisible, setManageModalVisible] = useState(false);
  const roles = JSON.parse(retrieveItem("roles"));
  useEffect(() => {
    let tempFeed = items.map((services) =>
      actionItem?.services?.find((x) => {
        return x === services?.id ? true : false;
      })
        ? { ...services, isChecked: true }
        : services
    );
    setQuestion(tempFeed);
  }, [items, actionItem]);
  const {
    loading: createLoading,
    status: serviceRequestCreateStatus,
    error: serviceRequestCreateError,
  } = useSelector(createServiceRequestSelector);
  const {
    loading: updateLoading,
    status: updateStatus,
    error: updateError,
  } = useSelector(updateServiceRequestSelector);
  const {
    loading: return_loading,
    status: returnStatus,
    error: returnError,
  } = useSelector(createServiceRequestReturnSelector);
  const history = useHistory();
  const { item: getOneServicesItems, loading: loading } = useSelector(
    getOneServiceSelector
  );
  const {
    loading: process_loading,
    status: processStatus,
    error: processError,
  } = useSelector(createServiceRequestProcessSelector);
  const {
    loading: order_loading,
    status: orderStatus,
    error: orderError,
  } = useSelector(createServiceRequestOrderSelector);

  const { item: vehicle_get_one } = useSelector(getOneVehiclesListSelector);
  const [defaultValues, setDefaultValue] = useState(false);
  const handleChange = (e) => {
    const { value, checked } = e.target;

    let tempFeed = question.map((services) =>
      services.name === value
        ? { ...services, isChecked: checked }
        : actionItem?.services?.find((x) => {
            return x === services?.id ? true : false;
          })
        ? { ...services, isChecked: true }
        : services
    );
    let tempFeed1 = question.map((services) =>
      services.name === value ? { ...services, isChecked: checked } : services
    );
    setQuestion(service_id ? tempFeed1 : tempFeed);
  };

  const handleBack = () => {
    history.goBack();
  };
  const handleProcessRequest = () => {
    dispatch(create_service_request_process({ id: getOneServicesItems?.id }));
  };
  const handleOrderRequest = (value) => {
    let service_array = [];

    question?.map((x) => {
      if (x.isChecked) {
        service_array.push(x.id);
      }
    });
    dispatch(
      service_request_order({
        id: getOneServicesItems?.id,
        data: {
          order_url: valueField?.order_url?.[0]?.url,
          services: service_array,
          // vehicle_id: id,
          department_remarks: valueField.department_remarks,
          // other_service: valueField.other_service,
          // ta_remarks: valueField.ta_remarks,
        },
      })
    );
  };
  const handleReturnRequest = () => {
    dispatch(create_service_request_return({ id: getOneServicesItems?.id }));
  };

  const handleSentToWorkshop = () => {
    dispatch(service_request_sent_to_workshop({ id: getOneServicesItems?.id }));
  };

  const handleSubmit = (values) => {
    let service_array = [];

    question?.map((x) => {
      if (x.isChecked) {
        service_array.push(x.id);
      }
    });
    if (service_id) {
      // getOneServicesItems?.services?.map((x) => {
      //   service_array.push(x.id);
      // });
      getOneServicesItems?.entity_state?.from_state_transitions?.[0]
        ?.transition_name === "Department Confirmation"
        ? handleOrderRequest()
        : dispatch(
            update_service_request_question({
              id: service_id,
              data: {
                services: service_array,
                vehicle_id: id,
                remarks: valueField.remarks,
                other_service: valueField.other_service,
                ta_remarks: valueField.ta_remarks,
                // other_service: valueField.other_service,
              },
            })
          );
    } else {
      dispatch(
        create_service_request_question({
          data: {
            services: service_array,
            vehicle_id: valueField.vehicle_id,
            remarks: valueField.remarks,
            other_service: valueField.other_service,
          },
        })
      );
    }
  };
  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  useEffect(() => {
    if (id) dispatch(getone_Vehicles({ id: id }));
  }, [id]);
  useEffect(() => {
    if (orderError) {
      showToast({ type: "error", message: "Something went wrong!" });
      dispatch(create_one_service_order_clear);
      history.goBack();
    } else if (orderStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Service Request")} ${t("confirmed by department ")}`,
      });
      dispatch(create_one_service_order_clear);
      history.goBack();
    }
  }, [orderStatus, orderError]);
  useEffect(() => {
    if (sent_to_workshop_error) {
      showToast({ type: "error", message: "Something went wrong!" });
      dispatch(create_one_service_sent_to_workshop_clear);
      history.goBack();
    } else if (sent_to_workshop_status === "Success") {
      showToast({
        type: "success",
        message: `${t("Vehicle sent to workshop successfully")}`,
      });
      dispatch(create_one_service_sent_to_workshop_clear);
      history.goBack();
    }
  }, [sent_to_workshop_status, sent_to_workshop_error]);
  useEffect(() => {
    if (processError) {
      showToast({ type: "error", message: "Something went wrong!" });
      dispatch(create_one_service_process_clear);
      history.goBack();
    } else if (processStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Service Request")} ${t(
          "Inspection started Successfully"
        )}`,
      });
      dispatch(create_one_service_process_clear);
      history.goBack();
    }
  }, [processStatus, processError]);
  useEffect(() => {
    if (returnError) {
      showToast({ type: "error", message: "Something went wrong!" });
      dispatch(create_one_service_return_clear());
      history.goBack();
    } else if (returnStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Vehicle returned successfully ")}`,
      });
      dispatch(create_one_service_return_clear());
      history.goBack();
    }
  }, [returnStatus, returnError]);

  useEffect(() => {
    if (serviceRequestCreateError) {
      showToast({ type: "error", message: serviceRequestCreateError?.message });
      dispatch(create_one_service_clear);
      history.push(ROUTES.SERVICE_REQUEST_LIST);
    } else if (serviceRequestCreateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Service Request")} ${t("create_successfully")}`,
      });
      dispatch(create_one_service_clear);
      history.push(ROUTES.SERVICE_REQUEST_LIST);
    } else if (updateError === "Failure") {
      showToast({ type: "error", message: "Something went wrong!" });
      dispatch(update_one_service_clear());
      handleModalClose();

      history.goBack();
    } else if (updateStatus === "Success") {
      history.goBack();

      showToast({
        type: "success",
        message: `${
          getOneServicesItems?.entity_state.from_state_transitions?.map(
            (x) => x.transition_name === "Send to workshop"
          )
            ? "Confirmation sent to Department successfully"
            : "Service request updated successfully"
        }`,
      });
      handleModalClose();

      dispatch(update_one_service_clear());
    }
  }, [
    serviceRequestCreateStatus,
    updateStatus,
    updateError,
    serviceRequestCreateError,
  ]);
  const dateDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };
  const handleService = (values) => {
    setValuesField(values);
    setManageModalVisible(true);
  };

  useEffect(() => {
    dispatch(get_service_question());
  }, []);
  useEffect(() => {
    if (service_id) dispatch(get_one_service({ id: service_id }));
  }, [service_id]);
  useEffect(() => {
    let temp = [];
    if (vehicles) {
      temp = vehicles.map((x) => {
        return {
          label: `${x?.car_model?.car_make?.name} - ${x.vehicle_number}`,
          value: x.id,
        };
      });
    }
    setVehicleList(temp);
  }, [vehicles]);

  useEffect(() => {
    if (service_id && getOneServicesItems) {
      setActionItem({
        ...getOneServicesItems,
        vehicle_id: getOneServicesItems?.vehicle_id,
        services: getOneServicesItems?.services?.map((x) => x.id),
        order_url: getOneServicesItems?.order_url
          ? [
              {
                uid: "-1",
                name: "Order Copy",
                status: "done",
                url: getOneServicesItems?.order_url,
              },
            ]
          : [],
      });
    }

    getOneServicesItems?.services?.filter((p) => {
      return question?.some((x) => {
        if (x.id === p.id) {
          setDefaultValue(true);
        }
      });
    });
  }, [getOneServicesItems]);

  useEffect(() => {
    dispatch(get_service_request_vehicles());
  }, []);
  useEffect(() => {
    let temp;
    if (VehicleId) {
      temp = vehicles?.find((x) => x.id === VehicleId)?.current_service
        ?.created_date_time;
      setCurrentService(temp);
    } else {
      setCurrentService("");
    }
  }, [VehicleId]);
  useEffect(() => {
    if (currentService && !service_id) {
      showToast({
        type: "error",
        message: "Already service request available for this vehicle",
      });
    }
  }, [currentService, service_id]);
  if (loading)
    return (
      <Box alignSelf="center" marginTop="25%">
        <img src={imgs} />
      </Box>
    );
  const handleValueChange = (all, val) => {
    if (val.vehicle_id) {
      setVehicleId(val.vehicle_id);
    }
  };
  return (
    <Box flex="1" w="100%">
      {/* <Form
      initialValues={actionItem}
      loading={loading}
      > */}
      <Box flex={1}>
        <VStack space={4} mt={7} flex={1}>
          <Box backgroundColor={colorScheme.transparent}>
            <Text bold fontSize={"lg"}>
              Service Request
            </Text>
          </Box>
          <VStack space={5}>
            {service_id && (
              <Card mb="5">
                <VStack>
                  <HStack
                    flexDirection={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <Box flex={1}>
                      <HStack>
                        <Box flex={1}>
                          <Text bold>Vehicle Number:</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>{vehicle_get_one?.vehicle_number}</Text>
                        </Box>
                      </HStack>
                    </Box>

                    <Box flex={1}>
                      <HStack>
                        <Box flex={1}>
                          <Text bold>Class Vehicle:</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>
                            {vehicle_get_one?.class_vehicle
                              ? vehicle_get_one?.class_vehicle
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                  </HStack>
                  <HStack
                    flexDirection={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <Box flex={1}>
                      <HStack>
                        <Box flex={1}>
                          <Text bold>Vehicle Make:</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>
                            {vehicle_get_one?.car_model?.car_make?.name}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box flex={1}>
                      <HStack>
                        <Box flex={1}>
                          <Text bold>Vehicle Model:</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>{vehicle_get_one?.car_model?.name}</Text>
                        </Box>
                      </HStack>
                    </Box>
                  </HStack>
                  <HStack
                    flexDirection={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <Box flex={1}>
                      <HStack
                        flexDirection={{
                          base: "column",
                          md: "row",
                        }}
                      >
                        <Box flex={1}>
                          <HStack>
                            <Box flex={1}>
                              <Text bold>Colour:</Text>
                            </Box>
                            <Box flex={1}>
                              <Text>
                                {vehicle_get_one?.colour
                                  ? vehicle_get_one?.colour
                                  : "-"}
                              </Text>
                            </Box>
                          </HStack>
                        </Box>
                        <Box flex={1}>
                          <HStack>
                            <Box flex={1}>
                              <Text bold>Fuel Type:</Text>
                            </Box>
                            <Box flex={1}>
                              <Text>
                                {vehicle_get_one?.fuel_used
                                  ? vehicle_get_one?.fuel_used
                                  : "-"}
                              </Text>
                            </Box>
                          </HStack>
                        </Box>
                      </HStack>
                    </Box>
                  </HStack>
                  <Stack
                    flexDirection={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <Box flex={1}>
                      <HStack
                        flexDirection={{
                          base: "column",
                          md: "row",
                        }}
                      >
                        <Box flex={1}>
                          <HStack>
                            <Box flex={1}>
                              <Text bold>Engine Number:</Text>
                            </Box>
                            <Box flex={1}>
                              <Text>{vehicle_get_one?.engine_number}</Text>
                            </Box>
                          </HStack>
                        </Box>
                        <Box flex={1}>
                          <HStack>
                            <Box flex={1}>
                              <Text bold>Chassis Number:</Text>
                            </Box>
                            <Box flex={1}>
                              <Text>
                                {vehicle_get_one?.chasis_number
                                  ? vehicle_get_one?.chasis_number
                                  : "-"}
                              </Text>
                            </Box>
                          </HStack>
                        </Box>
                      </HStack>
                    </Box>
                  </Stack>
                </VStack>
              </Card>
            )}

            <Wrapper>
              <Form
                onSubmit={handleService}
                onValueChange={handleValueChange}
                initialValues={actionItem}
                form={form}
              >
                <Box width="50%" mb="4">
                  <Form.Select
                    // disabled={roles?.[0]?.name === "Technician"}
                    field={"vehicle_id"}
                    label={t("form:vehicle")}
                    options={VehicleList}
                    labelField="label"
                    valueField="value"
                    hidden={service_id ? true : false}
                  />
                </Box>

                <Text fontSize="md" fontWeight="bold" py={4} p={5}>
                  {`${t("Services need to be done:")}`}
                </Text>
                {items.map((i, id) => {
                  return (
                    <>
                      <label>
                        <HStack ml={5} py={2} style={{ cursor: "pointer" }}>
                          <input
                            type="checkbox"
                            color="Primary"
                            name={i.name}
                            checked={i?.isChecked}
                            value={i.name}
                            defaultChecked={actionItem?.services?.find((x) => {
                              return x === i?.id ? true : false;
                            })}
                            onChange={handleChange}
                            style={{ marginTop: "5px" }}
                          />
                          <Box ml={2}>
                            <Text
                              fontSize="md"
                              name={i.name}
                              onClick={() => {
                                handleChange({
                                  target: {
                                    name: i.name,
                                    checked: !i?.isChecked,
                                  },
                                });
                              }}
                            >
                              {i.name}
                            </Text>
                          </Box>
                        </HStack>
                      </label>
                    </>
                  );
                })}
                {service_id &&
                  getOneServicesItems?.entity_state?.name ===
                    "Confirmed by Technician" && (
                    <HStack mt="4">
                      <Box flex={1}>
                        <Form.File
                          field={"order_url"}
                          label={"Order Copy"}
                          setURL={setURL}
                          rules={[
                            {
                              required: false,
                              message: "Order Copy is required",
                            },
                          ]}
                        />
                      </Box>
                      <Box flex={1}></Box>
                    </HStack>
                  )}
                {getOneServicesItems?.order_url &&
                  roles?.[0]?.name === "Technician" && (
                    <Box mt="12">
                      <a
                        href={getOneServicesItems?.order_url}
                        target={"_blank"}
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        Download Order Copy
                      </a>
                    </Box>
                  )}
                <Box mt="4">
                  <Form.TextArea
                    field={"other_service"}
                    label={"Please Specify other complaints"}
                    // disabled={service_id ? true : false}
                  />
                </Box>

                {getOneServicesItems?.entity_state?.name !==
                  "Department Requested" &&
                  service_id && (
                    <Box mt="4">
                      <Form.TextArea
                        field={"ta_remarks"}
                        label={"Technician Remarks"}
                        disabled={
                          service_id &&
                          getOneServicesItems?.entity_state?.name ===
                            "Inspection"
                            ? false
                            : true
                        }
                      />
                    </Box>
                  )}
                <Box mt="4">
                  <Form.TextArea
                    field={"remarks"}
                    label={"Remarks"}
                    hidden={
                      (roles?.[0]?.name === "Public Department" ||
                        roles?.[0]?.name === "Department") &&
                      service_id
                    }
                    disabled={service_id}
                  />
                </Box>
                {service_id &&
                  (roles?.[0]?.name === "Public Department" ||
                    roles?.[0]?.name === "Department") && (
                    <Box mt="4">
                      <Form.TextArea
                        field={"department_remarks"}
                        label={"Remarks"}
                      />
                    </Box>
                  )}
                <HStack space={2} justifyContent="flex-end" padding={5}>
                  {!service_id && (
                    <Form.Button
                      size="md"
                      height="45px"
                      colorScheme="success"
                      label={"Submit"}
                      formErrorMessage={t("error:error_message")}
                    >
                      {t("submit")}
                    </Form.Button>
                  )}

                  {service_id && (
                    <Form.Button
                      size="md"
                      height="45px"
                      colorScheme="success"
                      label={
                        getOneServicesItems?.entity_state
                          ?.from_state_transitions?.[0]?.transition_name
                      }
                      formErrorMessage={t("error:error_message")}
                    >
                      {t("submit")}
                    </Form.Button>
                  )}
                  {getOneServicesItems?.entity_state?.from_state_transitions?.map(
                    (x) => {
                      if (
                        x.transition_name !== "TA Confirmation" &&
                        x.transition_name !== "Department Confirmation"
                      )
                        return (
                          <Button
                            isLoading={
                              x?.transition_name === "Inspection"
                                ? process_loading
                                : x?.transition_name === "completed"
                                ? return_loading
                                : x?.transition_name === "Sent to workshop"
                                ? updateLoading
                                : x?.transition_name === "TA Confirmation"
                                ? updateLoading
                                : x?.transition_name ===
                                  "Department Confirmation"
                                ? order_loading
                                : x?.transition_name === "In Service"
                                ? sent_to_workshop_loading
                                : false
                            }
                            size="md"
                            height="45px"
                            colorScheme="success"
                            formErrorMessage={t("error:error_message")}
                            // onPress={() => {
                            //   handleProcessRequest();
                            // }}
                            onClick={
                              x?.transition_name === "Inspection"
                                ? () => {
                                    handleProcessRequest();
                                  }
                                : x?.transition_name === "completed"
                                ? () => {
                                    handleReturnRequest();
                                  }
                                : x?.transition_name === "Send to workshop"
                                ? () => {
                                    handleSubmit();
                                  }
                                : x.transition_name === "TA Confirmation"
                                ? () => {
                                    handleSubmit();
                                  }
                                : x.transition_name ===
                                  "Department Confirmation"
                                ? () => {
                                    handleOrderRequest();
                                  }
                                : x.transition_name === "In Service"
                                ? () => {
                                    handleSentToWorkshop();
                                  }
                                : () => {
                                    console.log("hiii");
                                  }
                            }
                          >
                            {x?.transition_name}
                          </Button>
                        );
                    }
                  )}

                  <Button
                    isLoading={false}
                    // width="100px"
                    size="md"
                    colorScheme="warning"
                    variant="outline"
                    formErrorMessage={t("error:error_message")}
                    onClick={handleBack}
                  >
                    {t("back")}
                  </Button>
                </HStack>
              </Form>
            </Wrapper>
          </VStack>
        </VStack>
      </Box>
      <Modal isOpen={manageModalVisible} onClose={handleModalClose}>
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton />
          <Modal.Header>Service Request</Modal.Header>
          <Modal.Body>
            <FormControl>
              <FormControl.Label>
                Are you sure you want to give following service request?
              </FormControl.Label>
            </FormControl>
            <FormControl>
              <FormControl.Label color="black">
                {t("Service Request:")}
              </FormControl.Label>
              {question?.map((x) => {
                if (x.isChecked) {
                  return (
                    <FormControl.Label color="black">
                      {x.name}
                    </FormControl.Label>
                  );
                }
              })}
            </FormControl>
          </Modal.Body>
          <Modal.Footer>
            <HStack space={6}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onClick={handleModalClose}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                loading={
                  service_id
                    ? getOneServicesItems?.entity_state
                        ?.from_state_transitions?.[0]?.transition_name ===
                      "Department Confirmation"
                      ? order_loading
                      : updateLoading
                    : createLoading
                }
              >
                Yes
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      {/* </Form> */}
    </Box>
  );
};
export default ServiceView;
