import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Text, Button } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import { ROUTES } from "@views/routes/my_routes";
import { useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";

import {
  createCarModelSelector,
  create_one_carmodel_clear,
  deleteCarModelSelector,
  delete_carmodel,
  delete_one_carmodel_modal,
  getCar_Models,
  getModelListSelector,
  get_one_carmake,
  get_one_carmodel,
  get_one_carmodel_clear,
  updateCarMakeSelector,
  updateCarModelSelector,
  update_one_carmake_clear,
  update_one_carmodel_clear,
  getOneCarMakeSelector,
} from "@services/redux";
import CarModelDetails from "./car_model_details";

import ActionButton from "../../components/ui/table/action_button";

import CarMakeDetails from "./car_make_details";

const CarModelList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [manageMakeVisible, setManageMakeVisible] = useState(false);

  const { t } = useTranslation();
  const { id } = useParams();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [carMake, setCarMake] = useState({});

  const { loading: deleteLoading, status: deleteStatus } = useSelector(
    deleteCarModelSelector
  );
  const { loading: updateLoading, status: updateStatus } = useSelector(
    updateCarModelSelector
  );
  const { loading: createLoading, status: createStatus } = useSelector(
    createCarModelSelector
  );

  const { loading, items, total_items } = useSelector(getModelListSelector);
  const { item: car_make } = useSelector(getOneCarMakeSelector);

  const { status: carMake_updateStatus } = useSelector(updateCarMakeSelector);

  useEffect(() => {
    if (carMake_updateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Car Make")} ${t("update_successfully")}`,
      });

      dispatch(get_one_carmake({ id: id }));
      dispatch(update_one_carmake_clear);
      handleMakeClose();
    }
  }, [carMake_updateStatus]);

  useEffect(() => {
    if (car_make) {
      setCarMake(car_make);
    }
  }, [car_make]);

  useEffect(() => {
    dispatch(getCar_Models({ car_make_id: id }));
    dispatch(get_one_carmake({ id }));
  }, []);

  const handleAdd = () => {
    dispatch(get_one_carmodel_clear);
    setActionType("add");
    setActionItem({});
    setManageModalVisible(true);
  };

  const handleEdit = (pro) => {
    setActionType("edit");
    dispatch(get_one_carmodel({ id: pro.id }));
    setActionItem({
      ...pro,

      id: pro.id,
    });
    setManageModalVisible(true);
  };

  useEffect(() => {
    if (deleteStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Car Model")} ${t("Deleted_successfully")}`,
      });
      setDialogVisible(false);
      dispatch(getCar_Models({ car_make_id: id }));
      handleModalClose();
      dispatch(delete_one_carmodel_modal());
    } else if (updateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Car Model")} ${t("update_successfully")}`,
      });
      setManageModalVisible(false);
      dispatch(update_one_carmodel_clear);
      dispatch(getCar_Models({ car_make_id: id }));

      handleModalClose();
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Car Model")} ${t("create_successfully")}`,
      });
      setManageModalVisible(false);
      dispatch(getCar_Models({ car_make_id: id }));
      dispatch(create_one_carmodel_clear());
      handleModalClose();
    }
  }, [deleteStatus, updateStatus, createStatus]);

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };
  const handleMakeClose = () => {
    setManageMakeVisible(false);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const handleReturn = (pro) => {
    // history.push({
    //   pathname: `${ROUTES.CAR_MODEL_LIST}/${pro.id}`,
    // });
    setActionType("edit");
    // setActionItem({
    //   ...pro,
    //   id: pro.id,
    // });

    setManageMakeVisible(true);
  };

  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "left",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 30,
          iconColor: "primary.700",
          title: t("table:edit_carmodel"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 30,
          iconColor: "red.700",
          title: t("table:delete_carmodel"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_carmodel"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <Box flex={1} borderColor="grey">
          <VStack flex={1} space={10}>
            <HStack flex={1} space={10}>
              <HStack flex={1} space={2}>
                <Box>
                  <Text fontSize="md" fontWeight="bold">
                    {carMake?.name}
                  </Text>
                </Box>
              </HStack>
              <HStack flex={1} space={2}>
                <Box>
                  <ActionButton
                    // height="50px"
                    label={t("Update")}
                    colorScheme="primary"
                    // variant="outline"
                    onPress={handleReturn}
                  />
                </Box>
                <Box>
                  <Button
                    height="40px"
                    colorScheme="danger"
                    variant="outline"
                    onPress={() => {
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                </Box>
              </HStack>
            </HStack>
          </VStack>
        </Box>
      </Box>
      <Box width="100%" mt="10px" marginBottom="20px">
        <Box position="sticky" top="0px" zIndex="2" bg="white">
          <Text fontSize="md" fontWeight="bold" py={4}>
            {t("table:carmodel_name")}
          </Text>
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="ProgrammeSessions"
              loading={loading}
              data={items}
              columns={columns}
              hasPagination
              totalItems={total_items}
              // fetch={getCar_Models}
              query={null}
              headerActions={header_actions}
              emptyMessage={t("table:empty_carmodel")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Modal
        isOpen={manageModalVisible}
        onClose={handleModalClose}
        header={
          actionType === "add"
            ? t("table:add_carmodel")
            : t("table:edit_carmodel")
        }
        component={
          <CarModelDetails
            id={id}
            handleModalClose={handleModalClose}
            initialValue={actionItem}
          />
        }
        source={actionItem}
        group={"User"}
        size={"lg"}
      />
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("table:delete_carmodel")}
        content={t("Are you sure you want to delete this car model?")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            // isLoading: loading,
            formErrorMessage: t("error:error_message"),
            submit: delete_carmodel,
          },
        ]}
      />

      <Modal
        isOpen={manageMakeVisible}
        onClose={handleMakeClose}
        header={
          actionType === "add"
            ? t("table:add_carmake")
            : t("table:edit_carmake")
        }
        component={
          <CarMakeDetails
            id={actionItem.programme_master_id}
            handleMakeClose={handleMakeClose}
            initialValue={car_make}
          />
        }
        source={actionItem}
        group={"User"}
        size={"lg"}
      />
    </>
  );
};
export default CarModelList;
