import React, { useEffect, useState } from "react";
import { Box, Card, HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import { get_one_fuel, getOnefuelSelector } from "@services/redux";
import { Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { retrieveItem } from "@helpers/storage";

const FuelView = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();

  const { item: fuel } = useSelector(getOnefuelSelector);

  useEffect(() => {
    dispatch(get_one_fuel({ id: id }));
  }, [id]);

  const formatDisplay = (date) => {
    if (date) {
      date = new Date(date);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };

  const formatStatus = (status) => {
    switch (status) {
      case "active":
        return "Active";
      case "expired":
        return "Expired";
    }
  };

  const roles = JSON.parse(retrieveItem("roles"));

  return (
    <Box flex="1">
      <Box position="sticky" flex={1} mb="10" mt="4">
        <Text fontWeight={"bold"} fontSize="md">
          {"Fuel Entry Details"}
        </Text>
      </Box>
      <Box>
        <Card mb="5">
          <VStack space="5">
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>Vehicle Number:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      {fuel?.vehicle?.vehicle_number}{" "}
                      {fuel?.vehicle?.car_model?.name} -{" "}
                      {fuel?.vehicle?.car_model?.car_make?.name}
                    </Text>
                  </Box>
                </HStack>
              </Box>

              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>Officer Name:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{fuel?.officer_name ? fuel?.officer_name : "-"}</Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>Quantity of Fuel:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{fuel?.quantity}</Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>Fuel Refilled Date:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{formatDisplay(fuel?.fuel_refilled_date)}</Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Meter Reading:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{fuel?.meter_reading}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Fuel Type:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{fuel?.fuel_type ? fuel?.fuel_type : "-"}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Driver Details:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {fuel?.driver?.name}, {fuel?.driver?.mobile}{" "}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}></Box>
                </HStack>
              </Box>
            </HStack>
          </VStack>
        </Card>
      </Box>
      <HStack justifyContent={"flex-end"}>
        <Button
          onClick={() => {
            history.goBack();
          }}
          type="default"
          danger
        >
          Back
        </Button>
      </HStack>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default FuelView;
