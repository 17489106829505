import _form from "./form";
import _input from "./input";
import _rich_text from "./rich_text";
import _file_input from "./file_input";
import _date_picker from "./date_picker";
import _image from "./image";
import _button from "./button";

const Form = (props) => {
  return <_form {...props} />;
};

const TextBox = (props) => {
  return <_input {...props} type="text" />;
};
const Number = (props) => {
  return <_input {...props} type="number" />;
};

const TextArea = (props) => {
  return <_input {...props} type="textarea" />;
};

const Password = (props) => {
  return <_input {...props} type="password" />;
};

const IntlPhone = (props) => {
  return <_input {...props} type="intl-phone" />;
};

const Phone = (props) => {
  return <_input {...props} type="phone" />;
};

const Select = (props) => {
  return <_input {...props} type="select" />;
};

const RichText = (props) => {
  return <_rich_text {...props} />;
};

const File = (props) => {
  return <_file_input {...props} />;
};
const Date = (props) => {
  return <_date_picker {...props} />;
};
const Image = (props) => {
  return <_image {...props} />;
};

const Button = (props) => {
  return <_button {...props} />;
};

Form.TextBox = TextBox;
Form.Number = Number;
Form.TextArea = TextArea;
Form.Password = Password;
Form.IntlPhone = IntlPhone;
Form.Phone = Phone;
Form.Select = Select;
Form.RichText = RichText;
Form.File = File;
Form.Date = Date;
Form.Image = Image;
Form.Button = Button;

export default Form;
