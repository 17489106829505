import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";

const GenerateTransitPass = (props) => {
  const { pass_item } = props;

  const valid_till = moment(Number(pass_item?.valid_till)).format("DD/MM/YYYY");

  return (
    <Document>
      <Page size="A4" orientation="portrait" style={styles.page}>
        {/* V.P. No. Section */}
        <View style={styles.headerSection}>
          <Text style={styles.vpNo}>V.P. No. {pass_item?.pass_number}</Text>
        </View>

        {/* Title Section */}
        <View style={styles.titleSection}>
          <Text style={styles.title}>SECRETARIAT CAR PASS</Text>
          <Text style={styles.subTitle}>TRANSIT ONLY</Text>
        </View>

        {/* Main Content */}
        <View style={styles.bodySection}>
          <Text style={styles.contentText}>
            Permit:{" "}
            <Text style={styles.text_details}>
              {pass_item?.pass_type?.parent_pass_type?.name}
            </Text>{" "}
            Vehicle No:
            <Text style={styles.text_details}>{pass_item?.vehicle_number}</Text>
          </Text>
          <Text style={styles.contentText}>
            of Thiru/Tmt/M/s:{" "}
            <Text style={styles.text_details}>{pass_item?.name}</Text> for
            Transit entry into the
          </Text>
          <Text style={styles.contentText}>
            Secretariat Campus. Parking of the vehicle will be
          </Text>
          <Text style={styles.contentText}>
            only at "Parking Area" in Marina Garden
          </Text>
          <Text style={styles.contentText}>
            (opposite to Secretariat). This Transit entry pass is valid up to{" "}
            <Text style={styles.text_details}>{valid_till}</Text> .
          </Text>
        </View>

        {/* Footer Section */}
        <View style={styles.footerSection}>
          <Text style={styles.footerText}>Under Secretary to Government,</Text>
          <Text style={styles.footerText}>Public(MV) Department,</Text>
          <Text style={styles.footerText}>Secretariat, Chennai-9</Text>
          <Text style={styles.footerText}>
            Parking inside Secretariat campus is strictly prohibited
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: "Times-Roman",
    flexDirection: "column",
    justifyContent: "space-between",
    border: 1,
    borderColor: "#000000",
    borderWidth: "5px",
  },
  headerSection: {
    textAlign: "left",
    marginBottom: 10,
  },
  vpNo: {
    fontSize: 26,
    fontWeight: "extrabold",
  },
  titleSection: {
    textAlign: "center",
    marginBottom: 20,
    lineHeight: 1.5,
  },
  title: {
    fontSize: 34,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  subTitle: {
    fontSize: 36,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  bodySection: {
    marginBottom: 30,
    lineHeight: 1.5,
    width: "100%",
  },
  contentText: {
    fontSize: "24px",
    textAlign: "justify",
    marginBottom: 10,
  },
  text_details: {
    fontSize: "24px",
    textAlign: "justify",
    textDecoration: "underline",
  },
  footerSection: {
    textAlign: "center",
    marginTop: 20,
  },
  footerText: {
    fontSize: 18,
    marginBottom: 5,
  },
});

export default GenerateTransitPass;
