import { gql } from "@apollo/client";

export const getOTPMutation = gql`
  query getOTP($mobile: String!) {
    getOTP(mobile: $mobile) {
      id
      otp
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const verifyOTPQuery = gql`
 query verifyOTP($mobile: String, $otp: String) {
  verifyOTP(mobile: $mobile, otp: $otp) {
    data
    status
    error {
      status_code
      message
    }
  }
}
`;
