import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Text } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { AiOutlineEye } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import { ROUTES } from "@views/routes/my_routes";
import { useParams } from "react-router-dom";
import {
  delete_services,
  getServicesListSelector,
  get_service,
  getEntityStateListSelector,
  get_all_entity_state,
  getServiceRequestReportSelector,
  getServiceRequestReportClear,
  getServiceRequestReport,
  getserviceRequestWithoutPaginationSelector,
  get_serviceRequestWithoutPagination,
} from "@services/redux";
import ReactSelect from "react-select";

import { Button, Card, DatePicker, Pagination } from "antd";
import imgs from "@assets/images/loader.gif";
import { retrieveItem } from "@helpers/storage";
import { excelDownload } from "@helpers/constants";

const ServiceList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [entityState, setEntityState] = useState([]);
  const [entityStateId, setEntityStateId] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [search_string, setSearchString] = useState("");

  const [fromReturnedDate, setFromReturnedDate] = useState("");
  const [toReturnedDate, setToReturnedDate] = useState("");

  const [current, setCurrent] = useState(1);
  const [current_limit, setCurrentLimit] = useState(10);
  const roles = JSON.parse(retrieveItem("roles"));

  const { t } = useTranslation();
  const { id } = useParams();
  const [dialogVisible, setDialogVisible] = useState(false);

  const [object, setObject] = useState(null);

  const { items: entity_state } = useSelector(getEntityStateListSelector);
  const { url: report, loading: report_loading } = useSelector(
    getServiceRequestReportSelector
  );

  const { items: service_request_items } = useSelector(
    getserviceRequestWithoutPaginationSelector
  );
  console.log("service_request_items", service_request_items);

  useEffect(() => {
    dispatch(get_serviceRequestWithoutPagination({ ...object }));
  }, [object]);

  const XLSX_download = () => {
    const labels = [
      "Vehicle Number",
      "Service Request Date",
      "Service Sent Date",
      "Service Returned Date",
      "Service Request",
      // "Service Done",
      "Status",
    ];

    const excel_items = service_request_items?.map((service_request) => {
      let service_request_data = {
        ["Vehicle Number"]: service_request?.vehicle?.vehicle_number ?? "-",
        ["Service Request Date"]:
          formatDisplay(service_request.created_date_time) ?? "-",
        ["Service Sent Date"]:
          formatDisplay(service_request.sent_to_workshop_date_time) ?? "-",
        ["Service Returned Date"]:
          formatDisplay(service_request.returned_date_time) ?? "-",
        ["Service Request"]:
          service_request?.services
            ?.map((services) => services.name)
            .join(", ") ?? "-",
        // ["Service Done"]: service_request?.engine_number ??"-",
        ["Status"]: service_request?.entity_state?.name ?? "-",
      };
      return service_request_data;
    });

    excelDownload(excel_items, labels, "Service Request Report");
  };

  useEffect(() => {
    if (report) {
      window.open(report);
      dispatch(getServiceRequestReportClear());
    }
  }, [report]);

  const handleView = (item) => {
    if (roles?.[0]?.name === "Admin") {
      history.push({
        pathname: `${ROUTES.SERVICE_PAGE}/${item.id}`,
      });
    } else if (
      item?.entity_state?.name !== "Inspection" &&
      roles?.[0]?.name === "Technician"
    ) {
      history.push({
        pathname: `${ROUTES.SERVICE_PAGE}/${item.id}`,
      });
    } else if (
      item?.entity_state?.name === "Inspection" &&
      roles?.[0]?.name === "Technician"
    ) {
      history.push({
        pathname: `${ROUTES.SERVICE_REQUEST_DETAILS}/${item?.vehicle_id}/${item?.id}`,
      });
    } else if (
      item?.entity_state?.name === "Confirmed by Technician" &&
      (roles?.[0]?.name === "Department" ||
        roles?.[0]?.name === "Public Department")
    ) {
      history.push({
        pathname: `${ROUTES.SERVICE_REQUEST_DETAILS}/${item?.vehicle_id}/${item?.id}`,
      });
    } else {
      history.push({
        pathname: `${ROUTES.SERVICE_PAGE}/${item.id}`,
      });
    }
  };

  const formatDisplay = (date) => {
    if (typeof date === "string") {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    } else return "-";
  };

  useEffect(() => {
    dispatch(
      get_all_entity_state({
        entity_id: "34f8d558-4b67-466b-a783-b08d9f83c4ce",
      })
    );
  }, []);

  const {
    items: getServicesItems,
    total_items,
    loading,
  } = useSelector(getServicesListSelector);

  const fetchData = (page_number, page_limit) => {
    dispatch(
      get_service({
        page_limit: page_limit,
        page_number: page_number,
      })
    );
    setCurrent(page_number);
    setCurrentLimit(page_limit);
  };
  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  // const handleReport = () => {
  //   dispatch(
  //     getServiceRequestReport({
  //       status_id: entityStateId,
  //       from_requested_date_time: fromDate,
  //       to_requested_date_time: toDate,
  //       search_string: search_string,
  //       from_returned_date_time: fromReturnedDate,
  //       to_returned_date_time: toReturnedDate,
  //     })
  //   );
  // };
  useEffect(() => {
    let temp = entity_state.map((x) => {
      return { label: x.name, value: x.id };
    });
    setEntityState(temp);
  }, [entity_state]);

  useEffect(() => {
    setObject({
      status_id: entityStateId,
      from_requested_date_time: fromDate,
      to_requested_date_time: toDate,
      from_returned_date_time: fromReturnedDate,
      to_returned_date_time: toReturnedDate,
    });
  }, [entityStateId, fromDate, fromReturnedDate, toDate, toReturnedDate]);

  useEffect(() => {
    dispatch(
      get_service({
        status_id: entityStateId,
        from_requested_date_time: fromDate,
        to_requested_date_time: toDate,
        from_returned_date_time: fromReturnedDate,
        to_returned_date_time: toReturnedDate,
        page_number: current,
        page_limit: current_limit,
      })
    );
  }, [entityStateId, fromDate, fromReturnedDate, toDate, toReturnedDate]);
  const handleAdd = (item) => {
    history.push({
      pathname: `${ROUTES.SERVICE_REQUEST_DETAILS}`,
    });
  };
  let header_actions = [
    {
      height: "50px",
      label: t("table:add_new_service_request"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  let columns = [
    {
      header: t("table:reg_no"),
      dataIndex: "vehicle.vehicle_number",
      key: "vehicle_number",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: "Service Request Date",
      dataIndex: "created_date_time",
      formatDisplay: formatDisplay,
      key: "date_of_Service",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: "Service Sent Date",
      dataIndex: "sent_to_workshop_date_time",
      key: "created_date_time",
      sortable: false,
      formatDisplay: formatDisplay,
      align: "left",
      flex: 1,
    },
    {
      header: "Service Return Date",
      dataIndex: "returned_date_time",
      formatDisplay: formatDisplay,
      key: "date_of_delivery",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: "Status",
      dataIndex: "entity_state.name",
      key: "entity_state.name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    // {
    //   header: t("table:level"),
    //   dataIndex: "level.name",
    //   key: "level_id",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },

    {
      header: t("View"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "left",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEye />,
          iconSize: 30,
          iconColor: "primary.700",
          title: t("table:edit_position_view"),
          onPress: handleView,
        },
      ],
    },
  ];
  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <Box top="0px" zIndex="2">
          <Text fontSize="md" fontWeight="bold" py={4}>
            {t("table:vehicle_request")}
          </Text>
        </Box>
        <VStack space={4}>
          <HStack
            space={4}
            zIndex={1}
            mt={3}
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box flex={1} mt={3} zIndex={6}>
              <Text>{"Status"}</Text>
              <ReactSelect
                // value={camp?.find((r) => r.value === entityStateId)}
                options={entityState}
                onChange={(entity, Object) => {
                  if (entity) setEntityStateId(entity.value);
                  else setEntityStateId(null);
                }}
                isClearable
              />
            </Box>

            <Box flex={1} mt={3} zIndex={5}>
              <Text>{"Service Requested From Date"}</Text>
              <DatePicker
                format="DD/MM/YYYY"
                onChange={(value) => {
                  setFromDate(value?.$d);
                }}
                allowClear
              />
            </Box>
            <Box flex={1} mt={3} zIndex={4}>
              <Text>{"Service Requested To Date"}</Text>
              <DatePicker
                format="DD/MM/YYYY"
                onChange={(value) => {
                  setToDate(value?.$d);
                }}
                allowClear
              />
            </Box>
          </HStack>
          <HStack
            space={4}
            mt={3}
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box flex={1} mt={3} zIndex={3}>
              <Text>{"Service Returned From Date"}</Text>
              <DatePicker
                format="DD/MM/YYYY"
                onChange={(value) => {
                  setFromReturnedDate(value?.$d);
                }}
                allowClear
              />
            </Box>
            <Box flex={1} mt={3} zIndex={2}>
              <Text>{"Service Returned To Date"}</Text>
              <DatePicker
                format="DD/MM/YYYY"
                onChange={(value) => {
                  setToReturnedDate(value?.$d);
                }}
                allowClear
              />
            </Box>

            <Box mt={3} zIndex={1} flex={1} alignSelf="end">
              <Button
                onClick={XLSX_download}
                type="primary"
                style={{
                  width: "228px",
                  background: "#683fe9",
                  borderColor: "#683fe9",
                }}
                loading={report_loading}
              >
                <Text bold fontSize="15px" color="white">
                  Excel Export
                </Text>
              </Button>
            </Box>
          </HStack>

          <Wrapper>
            {roles?.[0]?.name !== "Technician" && (
              <Table
                width="100%"
                rowKey="id"
                // group="ProgrammeSessions"
                loading={loading}
                columns={columns}
                data={getServicesItems}
                setSearchString={setSearchString}
                hasSearch
                hasPagination
                headerActions={
                  roles?.[0]?.name === "Department" ||
                  roles?.[0]?.name === "Public Department"
                    ? header_actions
                    : []
                }
                totalItems={total_items}
                fetch={get_service}
                query={object}
                emptyMessage={t("No Request Details Found")}
                headerColor={"white"}
              />
            )}
          </Wrapper>
          <>
            {loading && roles?.[0]?.name === "Technician" && (
              <Box alignSelf="center" marginTop="25%">
                <img src={imgs} />
              </Box>
            )}
          </>
          {roles?.[0]?.name === "Technician" && (
            <>
              {getServicesItems?.length ? (
                <>
                  {getServicesItems?.map((x, i) => {
                    return (
                      <Card
                      // style={{
                      //   backgroundColor: `${i % 2 === 0 ? "#80808012" : ""}`,
                      // }}
                      >
                        <VStack>
                          <Box>
                            <HStack justifyContent={"flex-end"}>
                              <AiOutlineEye
                                size={"30"}
                                color="#683fe9"
                                onClick={() => {
                                  handleView(x);
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </HStack>
                          </Box>
                          <Box>
                            <HStack>
                              <Box flex={1}>
                                <Text bold>{"Reg No:"}</Text>
                              </Box>
                              <Box flex={1}>
                                <Text>{x.vehicle?.vehicle_number}</Text>
                              </Box>
                            </HStack>
                          </Box>
                          <Box>
                            <HStack>
                              <Box flex={1}>
                                <Text bold>{"Service Request Date:"}</Text>
                              </Box>
                              <Box flex={1}>
                                <Text>
                                  {formatDisplay(x.created_date_time)}
                                </Text>
                              </Box>
                            </HStack>
                          </Box>
                          <Box>
                            <HStack>
                              <Box flex={1}>
                                <Text bold>{"Service Sent Date:"}</Text>
                              </Box>
                              <Box flex={1}>
                                <Text>
                                  {formatDisplay(x.sent_to_workshop_date_time)}
                                </Text>
                              </Box>
                            </HStack>
                          </Box>
                          <Box>
                            <HStack>
                              <Box flex={1}>
                                <Text bold>{"Service Return Date:"}</Text>
                              </Box>
                              <Box flex={1}>
                                <Text>
                                  {formatDisplay(x.returned_date_time)}
                                </Text>
                              </Box>
                            </HStack>
                          </Box>
                          <Box>
                            <HStack>
                              <Box flex={1}>
                                <Text bold>{"Status:"}</Text>
                              </Box>
                              <Box flex={1}>
                                <Text color="green.600" bold>
                                  {x.entity_state.name}
                                </Text>
                              </Box>
                            </HStack>
                          </Box>
                        </VStack>
                      </Card>
                    );
                  })}
                </>
              ) : (
                ""
              )}

              {!getServicesItems?.length && !loading ? (
                <Text bold textAlign={"center"}>
                  No Service Request Found
                </Text>
              ) : (
                ""
              )}
              {getServicesItems?.length ? (
                <Pagination
                  total={total_items}
                  showSizeChanger={true}
                  current={current}
                  hideOnSinglePage={false}
                  onChange={fetchData}
                />
              ) : (
                ""
              )}
            </>
          )}
        </VStack>
      </Box>
      <Modal
        isOpen={manageModalVisible}
        onClose={handleModalClose}
        header={
          actionType === "add"
            ? t("form:add_position")
            : t("form:edit_position")
        }
        // component={
        //   <PositionDetails
        //     id={actionItem.programme_master_id}
        //     handleModalClose={handleModalClose}
        //     initialValue={actionItem}
        //   />
        // }
        source={actionItem}
        group={"User"}
        size={"lg"}
      />
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("table:delete_position")}
        content={t("table:delete_position_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            // isLoading: loading,
            formErrorMessage: t("error:error_message"),
            submit: delete_services,
          },
        ]}
      />
    </>
  );
};
export default ServiceList;
