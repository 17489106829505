import React from "react";
import { Card, Tabs } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { Box, HStack } from "native-base";
import { MdPerson } from "react-icons/md";
import Homebanner from "@assets/images/Home_banner.jpg";
import VehiclePassLogin from "./vehicle_pass_login";
import PassHistoryList from "./pass_history_list";

const   VehiclePass = () => {
  const history = useHistory();
  const handleCardClick = (type) => {
    history.push({
      pathname: `${ROUTES?.PASS_FORM}/${type}`,
    });
  };

  return (
    <div style={{ padding: "30px" }}>
      <Tabs
        defaultActiveKey="apply_pass"
        className="search_results_tab_contents"
      >
        <Tabs.TabPane tab="Apply Pass" key="apply_pass">
          <div
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HStack justifyContent="center">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "20px",
                  padding: "20px",
                  marginRight: "24px",
                }}
              >
                <Card
                  style={{
                    width: 340,
                    height: 160,
                    background:
                      "linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114))",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "24px",
                  }}
                  onClick={() => handleCardClick("vip")}
                >
                  <MdPerson
                    style={{
                      fontSize: "46px",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  />
                  <h2
                    style={{
                      fontWeight: "bold",
                      fontSize: "24px",
                      color: "#ffffff",
                    }}
                  >
                    VIP
                  </h2>
                </Card>
                <Card
                  style={{
                    width: 340,
                    height: 160,
                    background:
                      "linear-gradient( 83.2deg,  rgba(150,93,233,1) 10.8%, rgba(99,88,238,1) 94.3% )",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "24px",
                  }}
                  onClick={() => handleCardClick("secretariat_staff")}
                >
                  <MdPerson
                    style={{
                      fontSize: "46px",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  />
                  <h2
                    style={{
                      fontWeight: "bold",
                      fontSize: "24px",
                      color: "#ffffff",
                    }}
                  >
                    Secretariat Staff
                  </h2>
                </Card>
                <Card
                  style={{
                    width: 340,
                    height: 160,
                    background:
                      "radial-gradient( circle 1292px at -13.6% 51.7%,  rgba(0,56,68,1) 0%, rgba(163,217,185,1) 51.5%, rgba(255,252,247,1) 88.6% )",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "24px",
                  }}
                  onClick={() => handleCardClick("hod")}
                >
                  <MdPerson
                    style={{
                      fontSize: "46px",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  />
                  <h2
                    style={{
                      fontWeight: "bold",
                      fontSize: "24px",
                      color: "#ffffff",
                    }}
                  >
                    HOD
                  </h2>
                </Card>
                <Card
                  style={{
                    width: 340,
                    height: 160,
                    background:
                      "linear-gradient( 112.5deg,  rgba(95,10,135,1) 11.4%, rgba(164,80,139,1) 60.2% )",
                    backgroundColor: "#4F66A3",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "24px",
                    flexDirection: "column",
                  }}
                  onClick={() => handleCardClick("public")}
                >
                  <MdPerson
                    style={{
                      fontSize: "46px",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  />
                  <h2
                    style={{
                      fontWeight: "bold",
                      fontSize: "24px",
                      color: "#ffffff",
                    }}
                  >
                    Public
                  </h2>
                </Card>
              </div>
            </HStack>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pass History" key="pass">
          <PassHistoryList />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default VehiclePass;
