import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getAllServicesHistory } from "./graphql";
const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "serviceList",
  initialState,
  reducers: {
    _get_service: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_service_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_service_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_service, _get_service_success, _get_service_failure } =
  slice.actions;

export const getServicesListSelector = (state) => state.serviceList;

export const getServiceListReducer = slice.reducer;

export function get_service(variables) {
  return async (dispatch) => {
    dispatch(_get_service());
    try {
      const response = await QueryRequest(getAllServicesHistory, variables, dispatch);

      if (response?.data?.getServiceRequests && !response?.data?.getServiceRequests.error) {
        dispatch(_get_service_success(response?.data?.getServiceRequests));
      } else if (response?.data?.getServiceRequests?.error) {
        dispatch(_get_service_failure(response?.data?.getServiceRequests.error));
      }
    } catch (error) {
      dispatch(_get_service_failure(error));
    }
  };
}
