import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { service_request_return_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "serviceRequestReturn",
  initialState,
  reducers: {
    _create_service_request_return: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_service_request_return_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_service_request_return_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_service_request_return_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _create_service_request_return,
  _create_service_request_return_reset,
  _create_service_request_return_success,
  _create_service_request_return_failure,
} = slice.actions;

export const createServiceRequestReturnSelector = (state) =>
  state.serviceRequestReturn;

export const createServiceRequestReturnReducer = slice.reducer;

export function create_service_request_return(variables) {
  return async (dispatch) => {
    dispatch(_create_service_request_return());
    try {
      const response = await MutateRequest(
        service_request_return_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.serviceRequestReturned &&
        !response?.data?.serviceRequestReturned.error
      ) {
        dispatch(
          _create_service_request_return_success(
            response?.data?.serviceRequestReturned
          )
        );
      } else if (response?.data?.serviceRequestReturned?.error) {
        dispatch(
          _create_service_request_return_failure(
            response?.data?.serviceRequestReturned.error
          )
        );
      }
    } catch (error) {
      dispatch(_create_service_request_return_failure(error));
    }
  };
}
// export function create_one_position_clear(dispatch) {
//   return async (dispatch) => {
//     dispatch(_create_position_reset());
//   };
// }
export function create_one_service_return_clear() {
  return async (dispatch) => {
    dispatch(_create_service_request_return_reset());
  };
}
