import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, VStack, HStack, Text } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import { ROUTES } from "@views/routes/my_routes";
import { useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";

import { getDepartmentListSelector, get_Departments } from "@services/redux";
import {
  get_one_department,
  get_one_department_clear,
} from "@services/redux/slices/department/department_one";
import DepartmentDetails from "./department_details";
import {
  deleteDepartmentSelector,
  delete_department,
  delete_one_department_modal,
} from "@services/redux/slices/department/department_delete";
import {
  updateDepartmentSelector,
  update_one_department_clear,
} from "@services/redux/slices/department/department_update";
import {
  createDepartmentSelector,
  create_one_department_clear,
} from "@services/redux/slices/department/department_create";
const DEPARTMENT_LIST = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading: deleteLoading, status: deleteStatus } = useSelector(
    deleteDepartmentSelector
  );
  const { loading: updateLoading, status: updateStatus } = useSelector(
    updateDepartmentSelector
  );
  const { loading: createLoading, status: createStatus } = useSelector(
    createDepartmentSelector
  );

  const handleAdd = () => {
    // dispatch(reset_programme_session_item());
    dispatch(get_one_department_clear);
    setActionType("add");
    setActionItem({});
    setManageModalVisible(true);
  };

  const handleEdit = (pro) => {
    setActionType("edit");
    dispatch(get_one_department({ id: pro.id }));
    setActionItem({
      ...pro,

      id: pro.id,
    });
    setManageModalVisible(true);
  };

  useEffect(() => {
    if (deleteStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Department")} ${t("Deleted_successfully")}`,
      });
      setDialogVisible(false);
      dispatch(get_Departments());
      handleModalClose();
      dispatch(delete_one_department_modal());
    } else if (updateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Department")} ${t("update_successfully")}`,
      });
      setManageModalVisible(false);
      dispatch(update_one_department_clear);
      dispatch(get_Departments());

      handleModalClose();
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Department")} ${t("create_successfully")}`,
      });
      setManageModalVisible(false);
      dispatch(get_Departments());
      dispatch(create_one_department_clear());
      handleModalClose();
    }
  }, [deleteStatus, updateStatus, createStatus]);

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const { loading, items, total_items } = useSelector(
    getDepartmentListSelector
  );

  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "left",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 30,
          iconColor: "primary.700",
          title: t("table:edit_department"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 30,
          iconColor: "red.700",
          title: t("table:delete_department"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_department"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <Text fontSize="md" fontWeight="bold" py={4}>
          {t("table:department_name")}
        </Text>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="ProgrammeSessions"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_Departments}
              query={null}
              headerActions={header_actions}
              emptyMessage={t("No Department details Found")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Modal
        isOpen={manageModalVisible}
        onClose={handleModalClose}
        header={
          actionType === "add"
            ? t("form:add_department")
            : t("form:edit_department")
        }
        component={
          <DepartmentDetails
            id={actionItem.programme_master_id}
            handleModalClose={handleModalClose}
            initialValue={actionItem}
          />
        }
        source={actionItem}
        group={"User"}
        size={"lg"}
      />
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("table:delete_department")}
        content={t("table:delete_department_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            // isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_department,
          },
        ]}
      />
    </>
  );
};
export default DEPARTMENT_LIST;
