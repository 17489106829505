import { getVehiclesListReducer } from "./vechile";
import { createVehicleReducer } from "./vechile_create";
import { getUpdateVehiclesListReducer } from "./vechile_update";
import { getOneVehiclesListReducer } from "./vechile_getone";
import { getDeleteVehiclesListReducer } from "./vechile_delete";
import { getVehicles_without_paginationListReducer } from "./vachile_without_pagination";
import { getServiceRequestVehiclesListReducer } from "./get_all_vehicles_for_service_request";
import { checkVehicleReducer } from "./check_vehicles_already_exist";

export const getVehiclesListReducers = {
  vehicles_List: getVehiclesListReducer,
  createVehicle: createVehicleReducer,
  vehicles_update: getUpdateVehiclesListReducer,
  vehicles_getone: getOneVehiclesListReducer,
  vehicles_delete: getDeleteVehiclesListReducer,
  serviceRequestVehicles: getServiceRequestVehiclesListReducer,
  vehicles_without_pagination_List: getVehicles_without_paginationListReducer,
  checkVehicle: checkVehicleReducer,
};
export * from "./vechile";
export * from "./vechile_create";
export * from "./vechile_update";
export * from "./vechile_getone";
export * from "./vechile_delete";
export * from "./vachile_without_pagination";
export * from "./get_all_vehicles_for_service_request";
export * from "./check_vehicles_already_exist";
