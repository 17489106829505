import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { updateAccessoriesMuatation, updateAgencyMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "additional_accessoryUpdate",
  initialState,
  reducers: {
    _update_additional_accessory: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_additional_accessory_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _update_additional_accessory_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_additional_accessory_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _update_additional_accessory,
  _update_additional_accessory_reset,
  _update_additional_accessory_success,
  _update_additional_accessory_failure,
} = slice.actions;

export const updateAdditionalAccessorySelector = (state) =>
  state.additional_accessoryUpdate;

export const updateAdditionalAccessoryReducer = slice.reducer;

export function update_additional_accessory(variables) {
  return async (dispatch) => {
    dispatch(_update_additional_accessory());
    try {
      const response = await MutateRequest(
        updateAccessoriesMuatation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateAdditionalAccessoryMaster &&
        !response?.data?.updateAdditionalAccessoryMaster.error
      ) {
        dispatch(
          _update_additional_accessory_success(
            response?.data?.updateAdditionalAccessoryMaster
          )
        );
      } else if (response?.data?.updateAdditionalAccessoryMaster?.error) {
        dispatch(
          _update_additional_accessory_failure(
            response?.data?.updateAdditionalAccessoryMaster.error
          )
        );
      }
    } catch (error) {
      dispatch(_update_additional_accessory_failure(error));
    }
  };
}
export async function update_one_additional_accessory_clear(dispatch) {
  dispatch(_update_additional_accessory_reset());
}
