import { createCondemnationReducer } from "./condemnation_create";
import { deleteCondemnationReducer } from "./condemnation_delete";
import { getCondemnationListReducer } from "./condemnation_list";
import { getOneCondemnationReducer } from "./condemnation_one";
import { updateCondemnationReducer } from "./condemnation_update";
import { getCondemnationListReducerWithoutPagination } from "./condemnation_list_without_Pagination";
export const getCondemnationListReducers = {
  condemnationList: getCondemnationListReducer,
  condemnationCreate: createCondemnationReducer,
  condemnationGetOne: getOneCondemnationReducer,
  condemnationUpdate: updateCondemnationReducer,
  condemnationDelete: deleteCondemnationReducer,
  condemnationListWithoutPagination:getCondemnationListReducerWithoutPagination
};
export * from "./condemnation_list";
export * from "./condemnation_create";
export * from "./condemnation_delete";
export * from "./condemnation_one";
export * from "./condemnation_update";
export * from "./condemnation_list_without_Pagination"