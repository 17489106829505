import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getCarModels } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "modelList",
  initialState,
  reducers: {
    _get_model: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_model_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_model_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_model, _get_model_success, _get_model_failure } = slice.actions;

export const getModelListSelector = (state) => state.modelList;
export const getModelListReducer = slice.reducer;
export function getCar_Models(variables) {
  return async (dispatch) => {
    dispatch(_get_model());
    try {
      const response = await QueryRequest(
        getCarModels,
        variables,
        dispatch
      );
      if (response?.data?.getCarModels && !response?.data?.getCarModels.error) {
        dispatch(_get_model_success(response?.data?.getCarModels));
      } else if (response?.data?.getCarModels?.error) {
        dispatch(_get_model_failure(response?.data?.getCarModels.error));
      }
    } catch (error) {
      dispatch(_get_model_failure(error));
    }
  };
}
