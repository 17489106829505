import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getDrivers } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "driverList",
  initialState,
  reducers: {
    _get_driver: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_driver_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_driver_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_driver, _get_driver_success, _get_driver_failure } = slice.actions;

export const getDriverListSelector = (state) => state.driverList;

export const getDriverListReducer = slice.reducer;

export function get_DriverList(variables) {
  return async (dispatch) => {
    dispatch(_get_driver());
    try {
      // let data = {
      //   items: [
      //     {
      //       name: "Saran",
      //       mobile_number: "9003317813",
      //       aadhar_number: "786567678767",
      //     },
      //   ],
      // };
      // dispatch(_get_driver_success(data));
      const response = await QueryRequest(getDrivers, variables, dispatch);
      if (response?.data?.getDrivers && !response?.data?.getDrivers.error) {
        dispatch(_get_driver_success(response?.data?.getDrivers));
      } else if (response?.data?.getDrivers?.error) {
        dispatch(_get_driver_failure(response?.data?.getDrivers.error));
      }
    } catch (error) {
      dispatch(_get_driver_failure(error));
    }
  };
}
