import { gql } from "@apollo/client";

export const getGatePasss = gql`
  query getPasses(
    $status: String
    $page_number: Float
    $page_limit: Float
    $pass_type_id: String
    $from_date: String
    $to_date: String
    $search_string: String
    $pass_sub_type_id: String
    $pass_child_type_id: String
  ) {
    getPasses(
      status: $status
      page_number: $page_number
      page_limit: $page_limit
      pass_type_id: $pass_type_id
      to_date: $to_date
      from_date: $from_date
      search_string: $search_string
      pass_sub_type_id: $pass_sub_type_id
      pass_child_type_id: $pass_child_type_id
    ) {
      items {
        id
        remarks
        status
        pass_number
        name
        pass_type_id
        department_id
        designation_id
        issued_date
        valid_till
        vehicle_number
        vehicle_make
        address
        recommendation_letter_url
        rc_book_url
        purpose_of_visit
        ownership_detail
        department_string
        designation_string
        pass_type {
          id
          name
          parent_pass_type {
            id
            name
          }
        }
        department {
          id
          name
        }
        designation {
          id
          name
        }
        entity_state {
          id
          name
        }
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const createGatePassMutation = gql`
  mutation createPass($data: pass_input) {
    createPass(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updateGatePassMutation = gql`
  mutation updatePass($id: String!, $data: pass_input) {
    updatePass(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const cancelGatePassMutation = gql`
  mutation cancelPass($id: String!, $data: pass_input) {
    cancelPass(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const getOneGatePassQuery = gql`
  query getPass($id: String!) {
    getPass(id: $id) {
      id
      previous_pass_number
      previous_issued_date
      pass_type_id
      status
      name
      rc_book_url
      address
      vehicle_make
      vehicle_number
      designation_string
      department_string
      pass_number
      remarks
      department_id
      designation_id
      purpose_of_visit
      ownership_detail
      recommendation_letter_url
      department {
        name
      }
      designation {
        name
      }
      valid_till
      issued_date
      entity_state {
        id
        name
        entity {
          id
          name
        }
      }
      created_by {
        mobile
      }
      pass_type {
        id
        name
        parent_pass_type {
          id
          name
          parent_pass_type {
            id
            name
          }
        }
      }
    }
  }
`;

export const deleteGatePassMutation = gql`
  mutation deletePass($id: String!) {
    deletePass(id: $id) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;

export const getPassTypeQuery = gql`
  query getPassTypes {
    getPassTypes {
      items {
        id
        name

        child_pass_types {
          id
          name
          child_pass_types {
            id
            name
          }
        }
      }
    }
  }
`;

export const getPassSubTypesQuery = gql`
  query getPassSubTypes(
    $page_number: Float
    $page_limit: Float
    $pass_type_id: String
  ) {
    getPassSubTypes(
      page_number: $page_number
      page_limit: $page_limit
      pass_type_id: $pass_type_id
    ) {
      items {
        id
        name
        pass_type_id
      }
    }
  }
`;

export const getGatePasssWithoutpagination = gql`
  query getPasses(
    $status: String
    $page_number: Float
    $page_limit: Float
    $pass_type_id: String
    $from_date: String
    $to_date: String
    $search_string: String
    $pass_sub_type_id: String
    $pass_child_type_id: String
  ) {
    getPasses(
      status: $status
      page_number: $page_number
      page_limit: $page_limit
      pass_type_id: $pass_type_id
      to_date: $to_date
      from_date: $from_date
      search_string: $search_string
      pass_sub_type_id: $pass_sub_type_id
      pass_child_type_id: $pass_child_type_id
    ) {
      items {
        id
        department {
          name
        }
        designation {
          name
        }
        designation_string
        department_string
        previous_pass_number
        previous_issued_date
        pass_type_id
        status
        name
        pass_number
        remarks
        valid_till
        issued_date
        status
        vehicle_number
        vehicle_make
        pass_type {
          id
          name
          parent_pass_type {
            id
            name
            parent_pass_type {
              id
              name
              parent_pass_type {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const getPassEntityStateTransitions = gql`
  query getPassEntityStateTransitions($pass_id: String!) {
    getPassEntityStateTransitions(pass_id: $pass_id) {
      items {
        id
        transition_name
        from_entity_state_id
        from_entity_state {
          id
          name
        }
        to_entity_state_id
        to_entity_state {
          id
          name
        }
        mandatory_fields
        show_fields
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const getAllEntity = gql`
  query getAllEntitys(
    $search_string: String
    $page_number: Int
    $page_limit: Int
  ) {
    getAllEntitys(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
      }
    }
  }
`;
