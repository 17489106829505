import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_all_service_request_without_pagination } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "service_request",
  initialState,
  reducers: {
    _get_service_request_without_pagination: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_service_request_without_pagination_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_service_request_without_pagination_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_service_request_without_pagination,
  _get_service_request_without_pagination_success,
  _get_service_request_without_pagination_failure,
} = slice.actions;

export const getserviceRequestWithoutPaginationSelector = (state) =>
  state.service_request;

export const getserviceRequestWithoutPaginationReducer = slice.reducer;

export function get_serviceRequestWithoutPagination(variables) {
  return async (dispatch) => {
    dispatch(_get_service_request_without_pagination());
    try {
      const response = await QueryRequest(
        get_all_service_request_without_pagination,
        variables,
        dispatch
      );
      if (
        response?.data?.getServiceRequests &&
        !response?.data?.getServiceRequests.error
      ) {
        dispatch(
          _get_service_request_without_pagination_success(
            response?.data?.getServiceRequests
          )
        );
      } else if (response?.data?.getServiceRequests?.error) {
        dispatch(
          _get_service_request_without_pagination_failure(
            response?.data?.getServiceRequests.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_service_request_without_pagination_failure(error));
    }
  };
}
