import { dynamic_form_fields } from "@helpers/constants";
import { useTranslation } from "react-i18next";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
// import "antd/dist/antd.min.css";
import _grid from "./grid";

import { QueryRequest } from "@services/apollo/api_service";
// import { fileQuery } from "@services/redux/slices/";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Upload,
  Space,
  Card,
  TimePicker,
} from "antd";
import JoditEditor from "jodit-react";
import { Divider, Text } from "native-base";
import { useEffect, useState } from "react";
import { showToast } from "@helpers/toast";
import axios from "axios";
import moment from "moment";
import { AiOutlineAlert } from "react-icons/ai";
import { getUUID } from "@helpers/uuid";
import { fileQuery } from "@services/redux/slices/login/graphql";

const { TextArea } = Input;
const { Option } = Select;
const validateInput = (rule, value, callback) => {
  // Define the regular expression pattern for allowed characters
  const allowedPattern = /^[^<>]+$/;

  if (!allowedPattern.test(value)) {
    callback("Invalid characters entered.");
  } else {
    callback(); // Call the callback without any argument for a successful validation
  }
};
const validateFile = (file) => {
  const fileName = file.name;
  const hasRepeatedExtension = /\.[^.]+(\.[^.]+)$/.test(fileName);
  if (hasRepeatedExtension) {
    return Promise.reject(
      new Error("File with repeated extension not allowed.")
    );
  }
  return Promise.resolve();
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
const TextBox = (props) => {
  const updatedProps = { ...props };
  if (updatedProps?.rules && updatedProps.rules.length > 0) {
    updatedProps.rules.push({ validator: validateInput });
  } else {
    updatedProps.rules = [{ validator: validateInput }];
  }

  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      style={props.style}
      hidden={props.hidden}
    >
      <Input disabled={props.disabled} />
    </Form.Item>
  );
};
const Number = (props) => {
  if (props?.rules && props.rules.length > 0) {
    const updatedRules = [...props.rules, { validator: validateInput }];
    props = { ...props, rules: updatedRules };
  } else {
    props = { ...props, rules: [{ validator: validateInput }] };
  }

  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      hidden={props.hidden}
    >
      <InputNumber
        style={{ width: "100%" }}
        controls={false}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
      />
    </Form.Item>
  );
};

const FormTextArea = (props) => {
  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      hidden={props.hidden}
    >
      <TextArea disabled={props.disabled} />
    </Form.Item>
  );
};
const FormSelect = (props) => {
  let options = props.options;
  let max_count = props.maxcount;
  const tagSizeValidator = async (rule, value, callback) => {
    if (value) {
      if (value.length > max_count) {
        return Promise.reject(
          `நீங்கள் ${max_count} மேல் தேர்வு செய்ய முடியாது.`
        );
      } else if (value.length <= props.maxcount) {
        return Promise.resolve();
      }
    }
    return;
  };
  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={
        props?.rules && [
          {
            required: props?.rules?.[0]?.required || false,
            message: props?.rules?.[0]?.message || undefined,
          },
          // {
          //   validator: props.maxcount && tagSizeValidator,
          // },
        ]
      }
      style={props.style}
      hidden={props.hidden}
    >
      <Select
        mode={props.isMulti ? "multiple" : "single"}
        disabled={props.disabled}
        allowClear
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
          0 ||
          option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options?.map((item) => {
          return (
            <Option
              value={item[props?.valueField]}
              defaultValue={props.defaultValue}
            >
              {item[props?.labelField]}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};
const Password = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <Input.Password />
    </Form.Item>
  );
};
let fileUploadresponse;
const File = (props) => {
  const [fileList, setFileList] = useState({});
  const [headers, setHeaders] = useState();
  const handleBeforeUpload = async ({ name, type }) => {
    console.log("name", name);
    // const hasRepeatedExtension = /\.[^.]+(\.[^.]+)$/.test(name);
    const hasRepeatedExtension = /^[^.]+(\.[^.]+)$/.test(name);

    const filetype = name.split(".")[1];
    const isAllowedFormat = [
      "png",
      "jpg",
      "pdf",
      "xlsx",
      "docx",
      "jpeg",
    ].includes(filetype);

    if (hasRepeatedExtension && isAllowedFormat) {
      let fileupload = {
        type: "photo",
        fileName: getUUID(),
      };
      fileUploadresponse = await QueryRequest(fileQuery, fileupload);
    } else if (!isAllowedFormat) {
      showToast({ type: "error", message: "Invalid file format" });
    } else {
      showToast({ type: "error", message: "Invalid file format" });
    }
  };
  const handleFileChanged = ({ file }) => {
    if (file.status === "removed") {
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
    } else if (file.status === "done") {
      const newFile = {
        ...file,
        name: getUUID(),
        url: fileUploadresponse?.data?.getUploadDetails?.url,
      };
      setFileList([newFile]);
      showToast({
        type: "success",
        message: "Document Uploaded Successfully",
      });
    }
  };
  const handleUpload = async ({ onSuccess, onError, file }) => {
    axios
      .put(fileUploadresponse?.data?.getUploadDetails?.url, file, {
        headers: { "Content-Type": file?.type },
      })
      .then(async (res) => {
        if (res.status.toString() === "200") {
          file.url =
            fileUploadresponse?.data?.getUploadDetails?.url.split("?")[0];
          onSuccess(null, file);
          if (props.setURL)
            props.setURL(
              fileUploadresponse?.data?.getUploadDetails?.url.split("?")[0]
            );
        } else {
          onError(err, err, file);
        }
      })
      .catch((err) => {
        onError(err, err, file);
      });
  };
  const action = fileUploadresponse?.data?.getUploadDetails;

  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      valuePropName="fileList"
      getValueFromEvent={normFile}
      extra=""
    >
      <Upload
        maxCount={1}
        action={action}
        headers={headers}
        fileList={fileList}
        showUploadList={{
          showPreviewIcon: true,
          downloadIcon: "Download",
          // showRemoveIcon: true,
          removeIcon: props?.disabled && true,
        }}
        // showUploadList={{ showDownloadIcon: false, showPreviewIcon: true }}
        customRequest={(e) => handleUpload(e)}
        beforeUpload={(args) => handleBeforeUpload(args)}
        onChange={(e) => handleFileChanged(e)}
      // accept=".pdf,.doc,.docx"
      >
        <Button
          style={{
            border: "none",
          }}
          disabled={props.disabled}
          icon={<UploadOutlined />}
        >
          Click to upload
        </Button>
      </Upload>
    </Form.Item>
  );
};

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

const Date = (props) => {
  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      disabled={props.disabled}
    >
      <DatePicker
        style={{ width: "100%" }}
        // defaultValue={moment("01/01/2010", dateFormatList[0])}
        format={props.format}
        disabledDate={props.disabledDate}
        showToday={false}
        picker={props.picker}
        disabled={props.disabled}
      />
      {/* <DatePicker
        disabledDate={props.disabledDate}
        defaultValue={props.defaultValue}
        defaultPickerValue={props.defaultPickerValue}
        disabledTime={props.disabledTime}
        showTime={props.showTime}
      /> */}
    </Form.Item>
  );
};
const FormRadio = (props) => {
  {
    let options = props.options.map((item) => {
      return (
        <Radio
          value={item["id"]}
          disabled={props.disabled}
          defaultChecked={item.defaultChecked}
        >
          {item["name"]}
        </Radio>
      );
    });
    return props.type === "button" ? (
      <Form.Item label={props.label} name={props.field} rules={props.rules}>
        <Radio.Group>
          {props.options.map((item) => {
            return (
              <Radio.Button value={item["id"]}>{item["name"]}</Radio.Button>
            );
          })}
        </Radio.Group>
      </Form.Item>
    ) : (
      <Form.Item label={props.label} name={props.field} rules={props.rules}>
        <Radio.Group>
          {props.direction === "row"
            ? props.options.map((item) => {
              return (
                <Row justify="space-between">
                  <Radio value={item["id"]} disabled={true}>
                    {item["name"]}
                  </Radio>
                </Row>
              );
            })
            : options}
        </Radio.Group>
      </Form.Item>
    );
  }
};
const CheckBox = (props) => {
  // let options = props.options.map((item) => {
  //   return (
  //     <Checkbox
  //       value={item["id"]}
  //       style={{
  //         lineHeight: "32px",
  //       }}
  //     >
  //       {item["name"]}
  //     </Checkbox>
  //   );
  // });
  let options = props?.options?.map((item) => {
    return <Checkbox value={item["id"]} disabled={props.disabled}>{props.label}</Checkbox>;
  });
  return (
    <Form.Item name={props.field} valuePropName="checked">
      {/* <Checkbox.Group>
        {props.direction === "row"
          ? props.options.map((item) => {
            return (
              <Row justify="space-between">
                <Checkbox
                  value={item["id"]}
                  style={{
                    lineHeight: "32px",
                  }}
                ></Checkbox>
              </Row>
            );
          })
          : options}
      </Checkbox.Group> */}
      <Checkbox disabled={props.disabled} >{props.label}</Checkbox>
    </Form.Item>
  );
};
const RichText = (props) => {
  return (
    <Form.Item label={props.label} name={props.field}>
      <JoditEditor />
    </Form.Item>
  );
};
const FormButton = (props) => {
  return (
    <Form.Item
    // wrapperCol={{
    //   span: 12,
    //   offset: 6,
    // }}
    >
      <Button
        type={"primary"}
        htmlType="submit"
        loading={props.isLoading}
        // borderRadius="5px"
        disabled={props.isDisabled}

      // style={{
      //   minWidth: "280px",
      //   color: "white",
      // }}
      >
        <Text color={props.isDisabled ? "black" : "white"}>{props.label}</Text>
      </Button>
    </Form.Item>
  );
};
const Time = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <TimePicker format={props?.format} minuteStep={props?.minuteStep} />
    </Form.Item>
  );
};
const Grid = (props) => {
  return <_grid {...props} />;
};
const List = (props) => {
  let currentData = props.currentData;
  let listItemData = currentData[props.field_name];

  return (
    <Form.List name={props.field_name}>
      {(fields, { add, remove }) => {
        return (
          <div style={{ width: "80%", marginLeft: "10%" }}>
            {fields?.map((field, index) => {
              return (
                <div
                  key={field.key}
                  style={{ padding: "8px 0", marginLeft: "20px" }}
                >
                  <Card border={1} mt={4}>
                    <Row gutter={16}>
                      {props?.children?.map((item, fieldIndex) => {
                        let listItem = props.children[fieldIndex];
                        let shouldShow = true;
                        if (listItem.props.showBasedField) {
                          shouldShow = false;
                        }
                        if (
                          listItemData &&
                          listItemData[index] &&
                          listItem.props.showBasedField
                        ) {
                          shouldShow = false;
                          let showBasedFieldValue =
                            listItemData[index][listItem.props.showBasedField];
                          if (
                            showBasedFieldValue &&
                            listItem.props.showBasedValues &&
                            listItem.props.showBasedValues.indexOf(
                              showBasedFieldValue
                            ) > -1
                          ) {
                            shouldShow = true;
                          }
                        }
                        let on_item = {
                          ...item,
                          props: {
                            ...item.props,
                            field: [index, `${item?.props?.field}`],
                          },
                        };
                        if (!shouldShow) {
                          return;
                        } else {
                          return <Col span={8}>{on_item}</Col>;
                        }
                      })}
                      {/* {fields.length > 1 ? ( */}
                      <Button
                        type="danger"
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                        style={{ marginLeft: "20px" }}
                        icon={<MinusCircleOutlined />}
                      >
                        நீக்குக
                      </Button>
                      {/* ) : null} */}
                    </Row>
                  </Card>
                </div>
              );
            })}

            {/* <Divider /> */}
            <Form.Item>
              <Button
                type="solid"
                onClick={() => add()}
                style={{ width: "32%", float: "right" }}
              >
                <PlusOutlined /> {props?.add_label}
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};
const AntdDynamicForm = (props) => {
  const { children, form } = props;
  const [initialValues, setInitialValues] = useState(props.initialValues);

  useEffect(() => {
    form?.setFieldsValue(props.initialValues);
    setInitialValues(props.initialValues);
  }, [form, props.initialValues]);
  return (
    <Form
      form={form}
      name={props.id}
      layout={"vertical"}
      onFinish={props.onSubmit}
      onValuesChange={props.onValueChange}
      initialValues={initialValues}
      disabled={props.disabled || false}
    >
      {children}
    </Form>
  );
};
AntdDynamicForm.Grid = Grid;
AntdDynamicForm.List = List;
AntdDynamicForm.TextBox = TextBox;
// AntdDynamicForm.Hidden = Hidden;
AntdDynamicForm.Number = Number;
AntdDynamicForm.TextArea = FormTextArea;
AntdDynamicForm.Password = Password;
// AntdDynamicForm.IntlPhone = IntlPhone;
AntdDynamicForm.Phone = Number;
AntdDynamicForm.Select = FormSelect;
// AntdDynamicForm.Cascade = Cascade;
AntdDynamicForm.RichText = RichText;
AntdDynamicForm.File = File;
AntdDynamicForm.Date = Date;
AntdDynamicForm.Radio = FormRadio;
AntdDynamicForm.CheckBox = CheckBox;
AntdDynamicForm.Button = FormButton;
AntdDynamicForm.Time = Time;

export default AntdDynamicForm;
