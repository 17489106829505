import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Text } from "native-base";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom";

import { getDriverHistorySelector, getDriverHistory } from "@services/redux";
import data from "@assets/images/loader.gif";

import { Button, Card } from "antd";
const DriverHistory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  const { loading, items, total_items } = useSelector(getDriverHistorySelector);
  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };
  useEffect(() => {
    if (id) {
      dispatch(getDriverHistory({ driver_id: id }));
    }
  }, [id]);
  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <Box>
          <Text fontSize="md" fontWeight="bold" py={4}>
            {t("table:driver_history")}
          </Text>
        </Box>
        {items?.length ? (
          <Box>
            {items?.map((x) => {
              return (
                <VStack mt="5">
                  <Card>
                    <VStack space="3">
                      <HStack space="10">
                        <Box width="250px">
                          <Text bold>{"Alloted Date"}</Text>
                        </Box>
                        <Box>
                          <Text>
                            {x.allotment_date
                              ? formatDisplay(x.allotment_date)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="10">
                        <Box width="250px">
                          <Text bold>{"GO File"}</Text>
                        </Box>
                        <Box>
                          {x?.go_copy ? (
                            <a
                              href={x?.go_copy}
                              target={"_blank"}
                              style={{
                                textDecoration: "underline",
                              }}
                            >
                              GO File
                            </a>
                          ) : (
                            "-"
                          )}
                        </Box>
                      </HStack>
                      <HStack space="10">
                        <Box width="250px">
                          <Text bold>{"Alloted Department"}</Text>
                        </Box>
                        <Box>
                          <Text>
                            {x?.department?.name ? x?.department?.name : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack space="10">
                        <Box width="250px">
                          <Text bold>{"Alloted Designation"}</Text>
                        </Box>
                        <Box>
                          <Text>
                            {x.designation?.name ? x.designation?.name : "-"}
                          </Text>
                        </Box>
                      </HStack>
                    </VStack>
                  </Card>
                </VStack>
              );
            })}
          </Box>
        ) : (
          ""
        )}

        {!items?.length && !loading ? (
          <Box alignSelf="center" marginTop="25%">
            <Text bold textAlign={"center"}>
              {"No History available for this driver"}
            </Text>
          </Box>
        ) : (
          ""
        )}

        {!items?.length && loading && (
          <Box alignSelf="center" marginTop="25%">
            <img src={data} />
          </Box>
        )}
        <HStack justifyContent={"flex-end"} mt="8">
          <Button
            onClick={() => {
              history.goBack();
            }}
            type="default"
            danger
          >
            Back
          </Button>
        </HStack>
      </Box>
    </>
  );
};
export default DriverHistory;
