import { update } from "lodash";
import { createTyreReducer } from "./tyre_create";
import { deleteTyreReducer, deletetyreReducer } from "./tyre_delete";
import { getTyreListReducer } from "./tyre_list";
import { getTyreMakerListReducer } from "./tyre_make_list";
import { getOnetyreReducer } from "./tyre_one";
import { updatetyreReducer } from "./tyre_update";
export const getTyreListReducers = {
  tyreList: getTyreListReducer,
  tyreCreate: createTyreReducer,
  tyreDelete: deleteTyreReducer,
  tyreGetOne: getOnetyreReducer,
  tyreUpdate: updatetyreReducer,
  tyreMakerList: getTyreMakerListReducer,
};
export * from "./tyre_make_list";
export * from "./tyre_create";
export * from "./tyre_delete";
export * from "./tyre_list";
export * from "./tyre_one";
export * from "./tyre_update";
