import { createServiceQuestionReducer } from "./serviceQuestion_create";
import { deleteServiceQuestionsReducer } from "./serviceQuestion_delete";
import { getOneServiceQuestionReducer } from "./serviceQuestion_getOne";
import { getServiceQuestionListReducer } from "./serviceQuestion_list";
import { updateServiceQuestionReducer } from "./serviceQuestion_update";
export const getServicesReducers = {
  question_List: getServiceQuestionListReducer,
  question_Create: createServiceQuestionReducer,
  question_GetOne: getOneServiceQuestionReducer,
  question_Update: updateServiceQuestionReducer,
  question_delete: deleteServiceQuestionsReducer,
};
export * from "./serviceQuestion_list";
export * from "./serviceQuestion_create";
export * from "./serviceQuestion_getOne";
export * from "./serviceQuestion_update";
export * from "./serviceQuestion_delete";
