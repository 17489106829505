import { gql } from "@apollo/client";

export const getCarMakers = gql`
  query getCarMakes(
    $page_number: Float
    $page_limit: Float
    $search_string: String
  ) {
    getCarMakes(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        name
        car_models {
          id
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const createCarMakeMutation = gql`
  mutation createCarMake($data: car_make_input) {
    createCarMake(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const deleteCarMakeMutation = gql`
  mutation deleteCarMake($id: String!) {
    deleteCarMake(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const updateCarMakeMutation = gql`
  mutation updateCarMake($id: String!, $data: car_make_input) {
    updateCarMake(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const getOneCarMakeQuery = gql`
  query getCarMake($id: String) {
    getCarMake(id: $id) {
      id
      name
      error {
        status_code
        message
      }
    }
  }
`;
