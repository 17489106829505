import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { deleteAgencyMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "agencyDelete",
  initialState,
  reducers: {
    _delete_agency: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_agency_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_agency_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const { _delete_agency, _delete_agency_success, _delete_agency_failure } =
  slice.actions;

export const deleteAgencySelector = (state) => state.agencyDelete;

export const deleteAgencyReducer = slice.reducer;

export function delete_agency(variables) {
  return async (dispatch) => {
    dispatch(_delete_agency());
    try {
      const response = await MutateRequest(
        deleteAgencyMutation,
        variables,
        dispatch
      );
      if (response?.data?.deleteAgency && !response?.data?.deleteAgency.error) {
        dispatch(_delete_agency_success(response?.data?.deleteAgency));
      } else if (response?.data?.deleteAgency?.error) {
        dispatch(_delete_agency_failure(response?.data?.deleteAgency.error));
      }
    } catch (error) {
      dispatch(_delete_agency_failure(error));
    }
  };
}
export function delete_one_agency_modal(dispatch) {
  return async (dispatch) => {
    dispatch(_delete_agency());
  };
}
