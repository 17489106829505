import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { login_query } from "./graphql";
import { storeItem } from "@helpers/storage";
import { decryptResponse } from "@helpers/crypto";

const initialState = {
  loading: false,
  error: null,
  item: null,
  authorize: false,
  roles: [],
};

const slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    _login: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
      state.authorize = false;
      state.roles = [];
    },
    _login_success: (state, { payload }) => {
      payload = decryptResponse(payload.data);
      state.loading = false;
      state.authorize = true;
      state.item = payload;
      state.roles = payload?.roles;
      storeItem("roles", JSON.stringify(payload?.roles));
      storeItem("user", JSON.stringify(payload));
      storeItem("token", payload?.token);
      state.error = null;
    },
    _login_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.authorize = false;
      state.item = null;
      state.roles = [];
    },
    _setAuthorize: (state, { payload }) => {
      state.authorize = payload;
      state.roles = [];
      state.item = null;
    },
  },
});

const { _login, _login_success, _login_failure, _setAuthorize } = slice.actions;

export const loginSelector = (state) => state.login;

export const loginReducer = slice.reducer;

export function login(variables) {
  return async (dispatch) => {
    dispatch(_login());
    try {
      const response = await QueryRequest(login_query, variables, dispatch);
      if (response?.data?.loginUser && !response?.data?.loginUser.error) {
        dispatch(_login_success(response?.data?.loginUser));
      } else if (response?.data?.loginUser?.error) {
        dispatch(_login_failure(response?.data?.loginUser.error));
      }
    } catch (error) {
      dispatch(_login_failure(error));
    }
  };
}

export function setAuthorize(dispatch) {
  return async (dispatch) => {
    dispatch(_setAuthorize());
  };
}
