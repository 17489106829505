import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getfuels } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "fuelList",
  initialState,
  reducers: {
    _get_fuel: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_fuel_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_fuel_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_fuel, _get_fuel_success, _get_fuel_failure } = slice.actions;

export const getfuelListSelector = (state) => state.fuelList;

export const getfuelListReducer = slice.reducer;

export function get_fuelList(variables) {
  return async (dispatch) => {
    dispatch(_get_fuel());
    try {
      // let data = {
      //   items: [
      //     {
      //       meter_reading: "300",
      //       driver_name: "Sathish",
      //       vechicle_number: "TN 61 0001",
      //       amount_of_fuel: "20",
      //       amount: 3000,
      //     },
      //   ],
      // };
      // dispatch(_get_fuel_success(data));
      const response = await QueryRequest(getfuels, variables, dispatch);
      if (response?.data?.getFuels && !response?.data?.getFuels.error) {
        dispatch(_get_fuel_success(response?.data?.getFuels));
      } else if (response?.data?.getFuels?.error) {
        dispatch(_get_fuel_failure(response?.data?.getFuels.error));
      }
    } catch (error) {
      dispatch(_get_fuel_failure(error));
    }
  };
}
