import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest, MutateRequest } from "@services/apollo/api_service";
import { createAllotment } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  status: null,

  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "allotment_create",
  initialState,
  reducers: {
    _get_allotment_create: (state) => {
      state.loading = true;
      state.id = null;
      state.createStatus = null;
    },
    _get_allotment_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.createStatus = null;
    },
    _get_allotment_create_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.createStatus = payload.status;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_allotment_create_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.createStatus = payload.status;

      state.total_items = [];
    },
  },
});

const { _get_allotment_create, _get_allotment_reset, _get_allotment_create_success, _get_allotment_create_failure } = slice.actions;

export const getCreateAllotmentsListSelector = (state) => state.allotment_create;

export const getCreateAllotmentsListReducer = slice.reducer;



export function create_Allotments(variables) {
  return async (dispatch) => {
    dispatch(_get_allotment_create());
    try {
      const response = await MutateRequest(
        createAllotment,
        variables,
        dispatch
      );
      if (response?.data?.createAllotment && !response?.data?.createAllotment.error) {
        dispatch(_get_allotment_create_success(response?.data?.createAllotment));
      } else if (response?.data?.createAllotment?.error) {
        dispatch(_get_allotment_create_failure(response?.data?.createAllotment.error));
      }
    } catch (error) {
      dispatch(_get_allotment_create_failure(error));
    }
  };
}

export async function create_allotment_reset(dispatch) {
  dispatch(_get_allotment_reset());
}