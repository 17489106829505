import { createGatePassReducer } from "./gate_pass_create";
import { deleteGatePassReducer } from "./gate_pass_delete";
import { getGatePassListReducer } from "./gate_pass_list";
import { getOnegatepassReducer } from "./gate_pass_one";
import { getPassSubListReducer } from "./gate_pass_sub_types";
import { getpass_typeListReducer } from "./gate_pass_type";
import { updateGatePassReducer } from "./gate_pass_update";
import { getGatePassListWithoutPaginationReducer } from "./gate_pass_without_pagination";
import { getAllEntityReducer } from "./get_all_entity";
import { cancelGatePassReducer } from "./pass_cancel";
import { getPassEntityStateReducer } from "./pass_entity_state_transitions";
export const getGatePassListReducers = {
  gatePassList: getGatePassListReducer,
  gatePassCreate: createGatePassReducer,
  gatepassGetOne: getOnegatepassReducer,
  gatePassUpdate: updateGatePassReducer,
  gatePassDelete: deleteGatePassReducer,
  passTypeList: getpass_typeListReducer,
  passSubList: getPassSubListReducer,
  gatePassListWithoutPagination: getGatePassListWithoutPaginationReducer,
  gatePasscancel: cancelGatePassReducer,
  getPassEntityState: getPassEntityStateReducer,
  getAllEntity: getAllEntityReducer,
};
export * from "./gate_pass_list";
export * from "./gate_pass_create";
export * from "./gate_pass_delete";
export * from "./gate_pass_one";
export * from "./gate_pass_update";
export * from "./gate_pass_type";
export * from "./gate_pass_sub_types";
export * from "./gate_pass_without_pagination";
export * from "./pass_cancel";
export * from "./pass_entity_state_transitions";
export * from "./get_all_entity";
