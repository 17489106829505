import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Text } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import { showToast } from "@helpers/toast";

import DesignationDetails from "./designation_details";
import {
  deleteDesignationSelector,
  updateDesignationSelector,
  createDesignationSelector,
  create_one_designation_clear,
  get_one_designation,
  update_one_designation_clear,
  getDesignationListSelector,
  delete_designation,
  get_designation,
  delete_one_designation_modal,
} from "@services/redux/slices/designation";
const DesignationList = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading: deleteLoading, status: deleteStatus } = useSelector(
    deleteDesignationSelector
  );
  const { status: updateStatus } = useSelector(updateDesignationSelector);
  const { status: createStatus } = useSelector(createDesignationSelector);

  const handleAdd = () => {
    setManageModalVisible(true);

    setActionType("add");
  };

  const handleEdit = (pro) => {
    setActionType("edit");
    dispatch(get_one_designation({ id: pro.id }));
    setActionItem(pro);
    setManageModalVisible(true);
  };

  useEffect(() => {
    if (deleteStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Designation")} ${t("Deleted_successfully")}`,
      });
      setDialogVisible(false);
      dispatch(get_designation());
      handleModalClose();
      dispatch(delete_one_designation_modal());
    } else if (updateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Designation")} ${t("update_successfully")}`,
      });
      dispatch(update_one_designation_clear);
      dispatch(get_designation());

      handleModalClose();
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Designation")} ${t("create_successfully")}`,
      });
      dispatch(get_designation());
      dispatch(create_one_designation_clear());
      handleModalClose();
    }
  }, [deleteStatus, updateStatus, createStatus]);

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };

  const {
    loading,
    total_items,
    items: designation,
  } = useSelector(getDesignationListSelector);

  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "left",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 30,
          iconColor: "primary.700",
          title: t("table:edit_designation"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 30,
          iconColor: "red.700",
          title: t("table:delete_designation"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_designation"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <Text fontSize="md" fontWeight="bold" py={4}>
          {t("table:designation_name")}
        </Text>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="ProgrammeSessions"
              loading={loading}
              data={designation}
              columns={columns}
              // hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_designation}
              query={null}
              headerActions={header_actions}
              emptyMessage={t("No Designation details Found")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Modal
        isOpen={manageModalVisible}
        onClose={handleModalClose}
        header={
          actionType === "add"
            ? t("table:add_designation")
            : t("table:edit_designation")
        }
        component={
          <DesignationDetails
            handleModalClose={handleModalClose}
            initialValue={actionItem}
          />
        }
        source={actionItem}
        group={"User"}
        size={"lg"}
      />
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("table:delete_designation")}
        content={t("table:delete_designation_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_designation,
          },
        ]}
      />
    </>
  );
};
export default DesignationList;
