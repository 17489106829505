import React, { useEffect, useState } from "react";
import { Link as ReactLink, useHistory } from "react-router-dom";

import {
  Box,
  Hidden,
  Text,
  VStack,
  HStack,
  Center,
  Link,
  IconButton,
  Icon,
  Checkbox,
  Button,
  Divider,
  Stack,
  useTheme,
  useColorModeValue,
} from "native-base";
import { useTranslation } from "react-i18next";
import AntDesign from "react-native-vector-icons/dist/AntDesign";
import Entypo from "react-native-vector-icons/dist/Entypo";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import FloatingLabelInput from "@views/components/ui/floating_label_input";
import KeyboardAwareScrollView from "@views/components/ui/keyboard_aware_scrollview/keyboard_aware_scrollview";
// import {
//   change_password,
//   change_password_Selector,
//   sign_clear,
// } from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "@helpers/toast";
import { ROUTES } from "@views/routes/my_routes";
import login_pic from "@assets/images/Assembly_1.jpg";

const ChangePassword = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const [text, setText] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = React.useState(false);
  // const {
  //   loading: passwordLoading,
  //   error,
  //   status: statusCreate,
  // } = useSelector(change_password_Selector);

  const handleChangePassword = () => {
    // setLoading(true);
    if (text === pass) {
      showToast({
        type: "error",
        message: "Current password and new password doesn't match",
      });
      setLoading(false);
    } else {
      // dispatch(
      //   change_password({
      //     password: text,
      //     new_password: pass,
      //   })
      // );
    }
  };
  // useEffect(() => {
  //   if (error) {
  //     setLoading(false);
  //     showToast({ type: "error", message: error });
  //     dispatch(sign_clear);
  //   } else if (statusCreate === "Success") {
  //     showToast({
  //       type: "success",
  //       message: `${`Password Changed Successfully`}`,
  //     });
  //     setLoading(false);

  //     dispatch(sign_clear);
  //     history.push(ROUTES.LOGIN);
  //   }
  // }, [statusCreate, error]);
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleChangePassword();
    }
  };
  return (
    <Box
      pt="60px"
      _dark={{
        bgImage: `url(${login_pic})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      _light={{
        bgImage: `url(${login_pic})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      bg={useColorModeValue("success.900", "success.900")}
      flex="1"
    >
      <Stack
        mt={{
          md: "0px",
          sm: "60px",
        }}
      >
        <Center>
          <Stack
            my={10}
            flexDirection={{
              base: "column",
              md: "row",
            }}
            w={{
              md: "100%",
              sm: "80%",
            }}
            maxW={{
              md: "340px",
              sm: "300px",
            }}
            flex={{
              base: "1",
              md: "none",
            }}
          >
            <KeyboardAwareScrollView
              contentContainerStyle={{
                flexGrow: 1,
              }}
              style={{
                flex: 1,
              }}
            >
              <VStack
                flex="1"
                px="6"
                py="9"
                _light={{
                  bg: "white",
                }}
                _dark={{
                  bg: "coolGray.800",
                }}
                space="3"
                justifyContent="space-between"
                borderRadius={{
                  base: "0",
                  md: "xl",
                }}
              >
                <VStack space="7">
                  <Text
                    fontSize="md"
                    fontWeight="bold"
                    textTransform="uppercase"
                    textAlign={"center"}
                  >
                    {t("Change Password")}
                  </Text>
                  <VStack>
                    <VStack space="3">
                      <VStack
                        space={{
                          base: "7",
                          md: "4",
                        }}
                      >
                        <FloatingLabelInput
                          isRequired
                          label="current Password"
                          labelColor="#6e6e6e"
                          labelBGColor={useColorModeValue("#fff", "#1f2937")}
                          onKeyPress={handleKeypress}
                          borderRadius="4"
                          defaultValue={text}
                          onChangeText={(txt) => setText(txt)}
                          _text={{
                            fontSize: "sm",
                            fontWeight: "medium",
                          }}
                          _dark={{
                            borderColor: "coolGray.700",
                          }}
                          _light={{
                            borderColor: "coolGray.300",
                          }}
                        />
                        <FloatingLabelInput
                          isRequired
                          type={showPass ? "" : "password"}
                          label="New Password"
                          onKeyPress={handleKeypress}
                          borderRadius="4"
                          labelColor="#6e6e6e"
                          labelBGColor={useColorModeValue("#fff", "#1f2937")}
                          defaultValue={pass}
                          onChangeText={(txt) => setPass(txt)}
                          InputRightElement={
                            <IconButton
                              variant="unstyled"
                              icon={
                                showPass ? (
                                  <AiFillEyeInvisible
                                    size={18}
                                    color={useColorModeValue(
                                      "success.900",
                                      "success.900"
                                    )}
                                  />
                                ) : (
                                  <AiFillEye size={18} color="red" />
                                )
                              }
                              onPress={() => {
                                setShowPass(showPass ? false : true);
                              }}
                            />
                          }
                          _text={{
                            fontSize: "sm",
                            fontWeight: "medium",
                          }}
                          _dark={{
                            borderColor: "coolGray.700",
                          }}
                          _light={{
                            borderColor: "coolGray.300",
                          }}
                        />
                      </VStack>
                      <Button
                        isLoading={loading}
                        mt="5"
                        size="md"
                        borderRadius="4"
                        _text={{
                          fontWeight: "medium",
                          textTransform: "uppercase",
                        }}
                        bg={useColorModeValue("success.900", "success.700")}
                        _loading={{
                          bg: useColorModeValue("success.900", "success.700"),
                        }}
                        onPress={handleChangePassword}
                        /* onPress function naviagteTo:"OTP" (next/Router) */
                      >
                        {t("Change Password")}
                      </Button>
                      {/* <Box margin={"auto"}>
                      <Text color="blue.900">
                        <Link href="/">Home</Link>
                      </Text>
                    </Box>      */}
                    </VStack>
                  </VStack>
                </VStack>
              </VStack>
            </KeyboardAwareScrollView>
          </Stack>
        </Center>
      </Stack>
    </Box>
  );
};
export default ChangePassword;
