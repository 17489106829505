import { showToast } from "@helpers/toast";
import {
  getOTPRequest,
  vehiclePassLoginSelector,
  verify_otp_clear,
  verifyOTPRequest,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const VehiclePassLoginForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [otp_sent, set_otp_sent] = useState(false);
  const [otp, set_otp] = useState("");
  const [mobile, set_mobile] = useState("");

  const [timer, set_timer] = useState(30);
  const [actionItem, setActionItem] = useState({ mobile: "" });

  const history = useHistory();

  const { status, verifyStatus, error, loading, data } = useSelector(
    vehiclePassLoginSelector
  );

  //   const send_otp = () => {
  //     if (mobile) {
  //       set_otp_sent(true);
  //       set_timer(30);
  //       message.success("OTP sent to your mobile number!");
  //       const count_down = setInterval(() => {
  //         set_timer((prev) => {
  //           if (prev === 1) {
  //             clearInterval(count_down);
  //             return 0;
  //           }
  //           return prev - 1;
  //         });
  //       }, 1000);
  //     }
  //   };

  useEffect(() => {
    let count_down;
    if (otp_sent && timer > 0) {
      count_down = setInterval(() => {
        set_timer((prev) => {
          if (prev === 1) {
            clearInterval(count_down);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(count_down);
  }, [otp_sent, timer]);

  const send_otp = () => {
    if (mobile) {
      set_otp_sent(true);
      setActionItem({ mobile: mobile });
      dispatch(getOTPRequest({ mobile: mobile }));
      message.success("OTP sent to your mobile number!");
      dispatch(verify_otp_clear());
    }
  };

  const on_finish = (values) => {
    console.log("values", values);
    setActionItem({ mobile: values.mobile, otp: values.otp });
    dispatch(verifyOTPRequest({ mobile: values.mobile, otp: values.otp }));
  };

  useEffect(() => {
    if (status === "Success") {
      set_timer(30);
      dispatch(verify_otp_clear());
    } else if (error)
      showToast({
        type: "error",
        message: error,
      });
    dispatch(verify_otp_clear());
    if (verifyStatus === "Success") {
      history.push(ROUTES.VEHICLE_PASS);
      dispatch(verify_otp_clear());
    }
  }, [status, error, verifyStatus]);

  const resend_otp = () => {
    if (mobile) {
      set_timer(30);
      dispatch(getOTPRequest(actionItem));
      message.success("OTP resent to your mobile number!");
      dispatch(verify_otp_clear());
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handle_key_press = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Form
        form={form}
        name="login"
        onFinish={on_finish}
        onFinishFailed={onFinishFailed}
        style={{ width: "80%" }}
      >
        <Form.Item
          name="mobile"
          rules={[
            {
              required: true,
              message: "Please input your mobile number!",
            },
            {
              pattern: /^[0-9]{10}$/,
              message: "Please enter a valid 10-digit mobile number!",
            },
          ]}
        >
          <Input
            placeholder="Mobile Number"
            value={mobile}
            onChange={(e) => set_mobile(e.target.value)}
            onKeyPress={handle_key_press}
          />
        </Form.Item>

        {otp_sent && (
          <Form.Item
            name="otp"
            rules={[{ required: true, message: "Please enter the OTP!" }]}
          >
            <Input
              placeholder="OTP"
              value={otp}
              onChange={(e) => set_otp(e.target.value)}
            />
          </Form.Item>
        )}

        {!otp_sent && (
          <Form.Item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              onClick={send_otp}
              style={{ borderRadius: "5px" }}
            >
              Send OTP
            </Button>
          </Form.Item>
        )}

        {otp_sent && (
          <>
            {/* <Form.Item
              name="otp"
              rules={[{ required: true, message: "Please enter the OTP!" }]}
            >
              <Input
                placeholder="OTP"
                value={otp}
                onChange={(e) => set_otp(e.target.value)}
              />
            </Form.Item> */}
            <Form.Item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                // loading={loading}
                type="primary"
                htmlType="submit"
                style={{ borderRadius: "5px" }}
              >
                Login
              </Button>
            </Form.Item>
            <div
              style={{
                marginTop: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {timer > 0 ? (
                <div>
                  Resend OTP in {`00:${timer.toString().padStart(2, "0")}`}
                </div>
              ) : (
                <div style={{ alignItems: "center" }}>
                  <div>
                    {"Didn't receive OTP?"}

                    <span
                      onClick={resend_otp}
                      style={{
                        textDecoration: "underline",
                        marginLeft: "7px",
                        cursor: "pointer",
                      }}
                    >
                      {"Resend OTP"}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default VehiclePassLoginForm;
