import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getCondemnationReportQuery } from "./graphql";
const initialState = {
  loading: false,
  error: null,
  url: null,
};
const slice = createSlice({
  name: "getCondemnationReport",
  initialState,
  reducers: {
    getCondemnationReportInitialize: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    getCondemnationReportReset: (state) => {
      state.loading = false;
      state.url = null;
      state.error = null;
    },
    getCondemnationReportSuccess: (state, { payload }) => {
      state.loading = false;
      state.url = payload.url;
      state.error = null;
    },
    getCondemnationReportFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  getCondemnationReportInitialize,
  getCondemnationReportSuccess,
  getCondemnationReportFailure,
  getCondemnationReportReset,
} = slice.actions;
export const getCondemnationReportSelector = (state) =>
  state.getCondemnationReport;

export const getCondemnationReportReducer = slice.reducer;

export function getCondemnationReport(variables) {
  return async (dispatch) => {
    dispatch(getCondemnationReportInitialize());
    try {
      const response = await QueryRequest(
        getCondemnationReportQuery,
        variables,
        dispatch
      );
      if (
        response?.data?.getCondemnationReport &&
        !response?.data?.getCondemnationReport.error
      ) {
        dispatch(
          getCondemnationReportSuccess(response?.data?.getCondemnationReport)
        );
      } else if (response?.data?.getCondemnationReport?.error) {
        dispatch(
          getCondemnationReportFailure(
            response?.data?.getCondemnationReport.error
          )
        );
      }
    } catch (error) {
      dispatch(getCondemnationReportFailure(error));
    }
  };
}

export function getCondemnationReportClear() {
  return (dispatch) => {
    dispatch(getCondemnationReportReset());
  };
}
