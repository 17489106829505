import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, HStack, Tooltip, IconButton, Center, Button } from "native-base";
import { Controller, useFormContext } from "react-hook-form";
import fileUpload from "@helpers/fileupload";
import Loader from "react-js-loader";

let defaultStyles = {
  height: "40px",
  labelColor: "#6e6e6e",
  labelBGColor: "#fff",
  iconColor: "#ff0000",
  iconSize: 20,
  boxBorderRadius: 6,
  fontSize: "14px",
};

const FileInput = (props) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState({});
  const [isFocused, setIsFocused] = useState(false);
  let inputFileRef = useRef("");
  let height = props.height || props.h || defaultStyles.height;
  let labelColor = props.labelColor || defaultStyles.labelColor;
  let labelBGColor = props.labelBGColor || defaultStyles.labelBGColor;
  let boxBorderRadius = props.boxBorderRadius || defaultStyles.boxBorderRadius;

  let boxColor = "inputBorder.300";

  return (
    <Box minHeight={height} {...props}>
      <Controller
        control={control}
        name={`${props.field}`}
        render={({
          field: { onChange, value, name, ref },
          fieldState: { error: _error },
        }) => {
          let isValid = _error?.message === undefined;
          let error = _error?.message;

          if (!isValid && error) {
            boxColor = "error.400";
          } else if (isFocused) {
            boxColor = "primary.400";
          }

          return (
            <>
              <HStack space={0} justifyContent="flex-end">
                {!props.hideLeftbar && (
                  <Box
                    width="15px"
                    minHeight={height}
                    bg={boxColor}
                    borderLeftRadius={boxBorderRadius}
                    borderWidth="1px"
                    borderColor={boxColor}
                    style={{
                      transition: "background .5s ease",
                      WebkitTransition: "background .5s ease",
                      MozTransition: "background .5s ease",
                    }}
                  ></Box>
                )}
                <Box
                  flex={1}
                  minHeight={height}
                  bg="#ffffff"
                  borderRightRadius="6"
                  borderLeftRadius={props.hideLeftbar ? "6" : "0"}
                  borderWidth="1px"
                  borderLeftWidth={!props.hideLeftbar ? "0px" : "1px"}
                  borderColor={isFocused ? "primary.400" : boxColor}
                  style={{
                    transition: "border .5s ease",
                    WebkitTransition: "border .5s ease",
                    MozTransition: "border .5s ease",
                  }}
                >
                  <Box
                    height="10px"
                    position="absolute"
                    top="-10px"
                    left="10px"
                  >
                    <Box
                      zIndex="2"
                      _text={{ color: labelColor, textTransform: "uppercase" }}
                    >
                      {props.label}
                    </Box>
                    <Box
                      zIndex="1"
                      position="absolute"
                      top="9px"
                      left="0px"
                      bg="#ffffff"
                      height="1px"
                      width="100%"
                    >
                      <input
                        type="file"
                        ref={inputFileRef}
                        onChangeCapture={async (e) => {
                          let temp = { ...loading };
                          temp[props.label] = true;
                          props.disableSubmit ? props.disableSubmit(true) : "";
                          setLoading(temp);
                          let url = await fileUpload(
                            e.target.files[0],
                            "photo"
                          );
                          if (props.setURL) props.setURL(url);
                          setFile({ name: props.label });
                          onChange(url);
                          temp = { ...loading };
                          temp[props.label] = false;
                          setLoading(temp);
                          props.disableSubmit ? props.disableSubmit(false) : "";
                        }}
                        style={{ visibility: "hidden" }}
                      />
                    </Box>
                  </Box>
                  <Tooltip
                    label={error}
                    isDisabled={!isValid && error ? false : true}
                    display={!isValid && error ? "block" : "none"}
                    //hasArrow={!isValid && error ? true : false}
                    bg="error.400"
                    _text={{
                      color: "#fff",
                    }}
                  >
                    <Box>
                      <HStack>
                        <Box flex={1}>
                          <HStack>
                            {loading[props.label] ? (
                              <Box h="20px" mt="-25px">
                                <Loader
                                  type="spinner-default"
                                  bgColor={"#349481"}
                                  color={"#349481"}
                                  size={15}
                                />
                              </Box>
                            ) : (
                              <Box
                                ml="15px"
                                justifyContent="center"
                                height={height}
                                _text={{
                                  fontSize: "12px",
                                }}
                              >
                                {file ? file.name : t("table:NO_FILE_CHOSEN")}
                              </Box>
                            )}
                          </HStack>
                        </Box>
                        <Box width="150px">
                          <HStack justifyContent="flex-end">
                            <Button
                              height={height}
                              colorScheme="blueGray"
                              variant="ghost"
                              _text={{
                                fontSize: "12px",
                                textTransform: "uppercase",
                              }}
                              onPress={() => {
                                setFile(null);
                                onChange(null);
                              }}
                            >
                              {t("form:clear")}
                            </Button>
                            <Button
                              height={height}
                              colorScheme="primary"
                              variant="outline"
                              _text={{
                                fontSize: "12px",
                                textTransform: "uppercase",
                              }}
                              onPress={() => inputFileRef.current.click()}
                            >
                              {t("form:browse")}
                            </Button>
                          </HStack>
                        </Box>
                      </HStack>
                    </Box>
                  </Tooltip>
                </Box>
                {props.button && (
                  <Center width="50px" minHeight={height}>
                    <IconButton
                      variant="unstyled"
                      icon={<ButtonIcon />}
                      onPress={() => {
                        props.button.onPress();
                      }}
                    />
                  </Center>
                )}
              </HStack>
            </>
          );
        }}
      />
    </Box>
  );
};

export default FileInput;
