import { getDeleteServiceRequestListReducer } from "./service_delete";
import { getServiceListReducer } from "./service_list";
import { getOneServiceReducer } from "./service_one";
import { getServiceQuestionListReducer } from "./service_question_list";
import { createServiceRequestReducer } from "./service_request_create";
import { getOneServiceRequestReducer } from "./service_request_one";
import { createServiceRequestOrderReducer } from "./service_request_order";
import { createServiceRequestProcessReducer } from "./service_request_process";
import { createServiceRequestReturnReducer } from "./service_request_returned";
import { updateServiceRequestReducer } from "./service_request_update";
import { getserviceRequestWithoutPaginationReducer } from "./service_request_without_pagination";
import { createServiceRequestSentToWorkshopReducer } from "./service_sent_to_workshop";

export const serviceReducers = {
  serviceList: getServiceListReducer,
  serviceOne: getOneServiceReducer,
  serviceQuestionList: getServiceQuestionListReducer,
  serviceRequestCreate: createServiceRequestReducer,
  serviceRequestOne: getOneServiceRequestReducer,
  service_delete: getDeleteServiceRequestListReducer,
  serviceRequestUpdate: updateServiceRequestReducer,
  serviceRequestProcess: createServiceRequestProcessReducer,
  serviceRequestReturn: createServiceRequestReturnReducer,
  serviceRequestOrder: createServiceRequestOrderReducer,
  serviceRequestSentToWorkshop: createServiceRequestSentToWorkshopReducer,
  service_request: getserviceRequestWithoutPaginationReducer,
};

export * from "./service_list";
export * from "./service_one";
export * from "./service_question_list";
export * from "./service_request_create";
export * from "./service_request_one";
export * from "./service_delete";
export * from "./service_request_update";
export * from "./service_request_process";
export * from "./service_request_returned";
export * from "./service_request_order";
export * from "./service_sent_to_workshop";
export * from "./service_request_without_pagination";
