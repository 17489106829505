import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
//import { createDepartmentMutation } from "./graphql";
import { createTyreMakeMutation} from "./graphql"
const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "tyreCreate",
  initialState,
  reducers: {
   _create_tyre: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
   _create_tyre_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
   _create_tyre_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
   _create_tyre_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
 _create_tyre,
 _create_tyre_reset,
 _create_tyre_success,
 _create_tyre_failure,
} = slice.actions;

export const createTyreSelector = (state) => state.tyreCreate;

export const createTyreReducer = slice.reducer;

export function create_Tyre(variables) {
  return async (dispatch) => {
    dispatch(_create_tyre());
    try {
      const response = await MutateRequest(
        createTyreMakeMutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createTyreMake &&
        !response?.data?.createTyreMake.error
      ) {
        dispatch(_create_tyre_success(response?.data?.createTyreMake));
      } else if (response?.data?.createTyreMake?.error) {
        dispatch(
         _create_tyre_failure(response?.data?.createTyreMake.error)
        );
      }
    } catch (error) {
      dispatch(_create_tyre_failure(error));
    }
  };
}
export function create_one_tyre_clear(dispatch) {
  return async (dispatch) => {
    dispatch(_create_tyre_reset());
  };
}
