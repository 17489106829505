import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getOneServiceHistory } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "serviceOne",
  initialState,
  reducers: {
    _get_one_servicehistory: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },

    _get_one_servicehistory_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_servicehistory_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const {
  _get_one_servicehistory,
  _get_one_servicehistory_success,
  _get_one_servicehistory_failure,
} = slice.actions;

export const getOneServiceSelector = (state) => state.serviceOne;

export const getOneServiceReducer = slice.reducer;

export function get_one_service(variables) {
  return async (dispatch) => {
    dispatch(_get_one_servicehistory());
    try {
      const response = await QueryRequest(
        getOneServiceHistory,
        variables,
        dispatch
      );
      if (
        response?.data?.getServiceRequest &&
        !response?.data?.getServiceRequest.error
      ) {
        dispatch(
          _get_one_servicehistory_success(response?.data?.getServiceRequest)
        );
      } else if (response?.data?.getServiceRequest?.error) {
        dispatch(
          _get_one_servicehistory_failure(
            response?.data?.getServiceRequest.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_one_servicehistory_failure(error));
    }
  };
}
export async function get_one_servicehistory_clear(dispatch) {
  dispatch(_get_one_reason());
}
