import { gql } from "@apollo/client";

export const get_validity_expired_vehicles = gql`
  query getValidityExpiredVehicles($page_number: Float, $page_limit: Float) {
    getValidityExpiredVehicles(
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        vehicle_number
        vehicle_price
        car_model {
          name
          car_make {
            name
          }
        }
        date_of_delivery
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_meter_reading_reached_vehicles = gql`
  query getCondemnationReadyVehicles($page_number: Float, $page_limit: Float) {
    getCondemnationReadyVehicles(
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        vehicle_id
        meter_reading
        vehicle {
          id
          vehicle_number
          car_model {
            name
            car_make {
              name
            }
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const get_service_not_given = gql`
  query getNotFuelAndServiceVehicle($page_number: Float, $page_limit: Float) {
    getNotFuelAndServiceVehicle(
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        vehicle_id
        meter_reading
        vehicle {
          vehicle_number
          car_model {
            name
            car_make {
              name
            }
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
