import React, { useEffect, useState } from "react";
import { Box, Card, HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import data from "@assets/images/loader.gif";

import {
  get_one_condemnation,
  getOneCondemnationSelector,
} from "@services/redux";
import { Button, Row } from "antd";
import { useHistory, useParams } from "react-router-dom";

const CondemnationView = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [actionItem, setActionItem] = useState({});
  const { item: condemnation, loading } = useSelector(
    getOneCondemnationSelector
  );

  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    dispatch(get_one_condemnation({ id: id }));
  }, [id]);

  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };

  const formatStatus = (status) => {
    switch (status) {
      case "active":
        return "Active";
    }
  };
  if (loading)
    return (
      <Box alignSelf="center" marginTop="25%">
        <img src={data} />
      </Box>
    );
  return (
    <Box flex="1">
      <Box position="sticky" flex={1} mb="10" mt="4">
        <Text fontWeight={"bold"} fontSize="md">
          Condemnation Details
        </Text>
      </Box>

      <Box>
        <Card mb="5">
          <VStack space={7}>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <Text bold>Vehicle Number:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{condemnation?.vehicle?.vehicle_number}</Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <Text bold>Vehicle:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      {condemnation?.vehicle?.car_model?.name} -{" "}
                      {condemnation?.vehicle?.car_model?.car_make?.name}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <Text bold>GO Number:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      {condemnation?.go_number ? condemnation?.go_number : "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <Text bold>GO Date:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      {condemnation?.date
                        ? formatDisplay(condemnation?.date)
                        : "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <Text bold>GO File:</Text>
                  </Box>
                  <Box flex={1}>
                    {condemnation?.go_file ? (
                      <Text>
                        <a
                          href={condemnation?.go_file}
                          target={"_blank"}
                          style={{ textDecoration: "underline" }}
                        >
                          GO File
                        </a>
                      </Text>
                    ) : (
                      "-"
                    )}
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}></Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <Text bold>Reason</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      {condemnation?.reason ? condemnation?.reason : "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}></Box>
            </HStack>
          </VStack>
        </Card>
        <HStack space={5} justifyContent="flex-end">
          <Button
            isLoading={false}
            width="100px"
            danger
            onClick={() => {
              history.goBack();
            }}
            formErrorMessage={t("error:error_message")}
          >
            {t("common:BACK")}
          </Button>
        </HStack>
      </Box>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default CondemnationView;
