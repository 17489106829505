import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getPassTypeQuery } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "passTypeList",
  initialState,
  reducers: {
    _get_pass_type: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_pass_type_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_pass_type_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_pass_type, _get_pass_type_success, _get_pass_type_failure } =
  slice.actions;

export const getpass_typeListSelector = (state) => state.passTypeList;

export const getpass_typeListReducer = slice.reducer;

export function get_pass_typeList(variables) {
  return async (dispatch) => {
    dispatch(_get_pass_type());
    try {
      // let data = {
      //   items: [
      //     {
      //       meter_reading: "300",
      //       driver_name: "Sathish",
      //       vechicle_number: "TN 61 0001",
      //       amount_of_pass_type: "20",
      //       amount: 3000,
      //     },
      //   ],
      // };
      // dispatch(_get_pass_type_success(data));
      const response = await QueryRequest(
        getPassTypeQuery,
        variables,
        dispatch
      );
      if (response?.data?.getPassTypes && !response?.data?.getPassTypes.error) {
        dispatch(_get_pass_type_success(response?.data?.getPassTypes));
      } else if (response?.data?.getPassTypes?.error) {
        dispatch(_get_pass_type_failure(response?.data?.getPassTypes.error));
      }
    } catch (error) {
      dispatch(_get_pass_type_failure(error));
    }
  };
}
