import ScrollView from "@views/components/ui/scroll_view";
import { Card } from "antd";
import { Box } from "native-base";
import React, { Component, Fragment } from "react";
import { Row, Col, Table, CardBody } from "reactstrap";
import Footer from "../footer/footer";
import Header from "../Header/header";

const ScreenReaderAccess = (props) => {
  return (
    <ScrollView>
      <Header />
      <Box flex="1" minH="700px">
        <Card>
          <Box
            style={{ width: "90%", justifyContent: "center" }}
            overflowX="auto"
          >
            <Row>
              <Col lg="12">
                <h2>Screen Reader Access</h2>
                <p>
                  Following table lists the information about different screen
                  readers:
                </p>
                <table
                  id="u_content_text_1"
                  style={{
                    fontFamily: "arial,helvetica,sans-serif",
                  }}
                  role="presentation"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  border="0"
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "6px",

                          // width:90px;
                        }}
                      >
                        Screen Reader
                      </th>
                      <th
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "6px",

                          // width:90px;
                        }}
                      >
                        Website
                      </th>
                      <th
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "6px",

                          // width:90px;
                        }}
                      >
                        Free/Commercial
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                        }}
                      >
                        Screen Access For All (SAFA)
                      </td>
                      <td
                        className="screenReader"
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                        }}
                      >
                        <a
                          className="LinkStyle"
                          href="http://www.nabdelhi.in/it-services/technology-training-left/downloads/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window"
                        >
                          http://www.nabdelhi.in
                        </a>
                      </td>
                      <td
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                        }}
                      >
                        Free
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                          backgroundColor: "rgb(0 0 0 / 5%)",
                        }}
                      >
                        Non Visual Desktop Access (NVDA)
                      </td>
                      <td
                        className="screenReader"
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                          backgroundColor: "rgb(0 0 0 / 5%)",
                        }}
                      >
                        <a
                          className="LinkStyle"
                          href="http://www.nvda-project.org/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window "
                        >
                          http://www.nvda-project.org
                        </a>
                      </td>
                      <td
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                          backgroundColor: "rgb(0 0 0 / 5%)",
                        }}
                      >
                        Free
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                        }}
                      >
                        System Access To Go
                      </td>
                      <td
                        className="screenReader"
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                        }}
                      >
                        <a
                          className="LinkStyle"
                          href="http://www.satogo.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window "
                        >
                          http://www.satogo.com/
                        </a>
                      </td>
                      <td
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                        }}
                      >
                        Free
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                          backgroundColor: "rgb(0 0 0 / 5%)",
                        }}
                      >
                        Thunder
                      </td>
                      <td
                        className="screenReader"
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                          backgroundColor: "rgb(0 0 0 / 5%)",
                        }}
                      >
                        <a
                          className="LinkStyle"
                          href="http://www.screenreader.net/index.php?pageid=2"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window"
                        >
                          http://www.screenreader.net/index.php?pageid=2
                        </a>
                      </td>
                      <td
                        className="screenReader"
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                          backgroundColor: "rgb(0 0 0 / 5%)",
                        }}
                      >
                        Free
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="screenReader"
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                        }}
                      >
                        WebAnywhere
                      </td>
                      <td
                        className="screenReader"
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                        }}
                      >
                        <a
                          className="LinkStyle"
                          href="http://webanywhere.cs.washington.edu/wa.php"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window"
                        >
                          http://webanywhere.cs.washington.edu/wa.php
                        </a>
                      </td>
                      <td
                        className="screenReader"
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                        }}
                      >
                        Free
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="screenReader"
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                          backgroundColor: "rgb(0 0 0 / 5%)",
                        }}
                      >
                        Hal
                      </td>
                      <td
                        className="screenReader"
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                          backgroundColor: "rgb(0 0 0 / 5%)",
                        }}
                      >
                        <a
                          className="LinkStyle"
                          href="http://www.yourdolphin.co.uk/productdetail.asp?id=5"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window"
                        >
                          http://www.yourdolphin.co.uk/productdetail.asp?id=5
                        </a>
                      </td>
                      <td
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                          backgroundColor: "rgb(0 0 0 / 5%)",
                        }}
                      >
                        Commercial
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                        }}
                      >
                        JAWS
                      </td>
                      <td
                        className="screenReader"
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                        }}
                      >
                        <a
                          className="LinkStyle"
                          href="http://www.freedomscientific.com/jaws-hq.asp"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window"
                        >
                          http://www.freedomscientific.com/jaws-hq.asp
                        </a>
                      </td>
                      <td
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                        }}
                      >
                        Commercial
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                          backgroundColor: "rgb(0 0 0 / 5%)",
                        }}
                      >
                        Supernova
                      </td>
                      <td
                        className="screenReader"
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                          backgroundColor: "rgb(0 0 0 / 5%)",
                        }}
                      >
                        <a
                          className="LinkStyle"
                          href="http://www.yourdolphin.co.uk/productdetail.asp?id=1"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window"
                        >
                          http://www.yourdolphin.co.uk/productdetail.asp?id=1
                        </a>
                      </td>
                      <td
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                          backgroundColor: "rgb(0 0 0 / 5%)",
                        }}
                      >
                        Commercial
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                        }}
                      >
                        Window-Eyes
                      </td>
                      <td
                        className="screenReader"
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                        }}
                      >
                        <a
                          className="LinkStyle"
                          href="http://www.gwmicro.com/Window-Eyes/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="External website that opens in a new window"
                        >
                          http://www.gwmicro.com/Window-Eyes/
                        </a>
                      </td>
                      <td
                        class="v-container-padding-padding"
                        style={{
                          border: "1px solid #dddddd",
                          padding: "6px",
                          textAlign: "left",
                        }}
                      >
                        Commercial
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </Box>
        </Card>
        <Box>{/* <Box mt="30px" width="200px" zIndex={1}></Box> */}</Box>
      </Box>
      <Footer />
    </ScrollView>
  );
};

export default ScreenReaderAccess;
