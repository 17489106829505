import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getAllEntityState } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "entityStateList",
  initialState,
  reducers: {
    _get_entity_state: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_entity_state_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_entity_state_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_entity_state,
  _get_entity_state_success,
  _get_entity_state_failure,
} = slice.actions;

export const getEntityStateListSelector = (state) => state.entityStateList;

export const getEntityStateListReducer = slice.reducer;

export function get_all_entity_state(variables) {
  return async (dispatch) => {
    dispatch(_get_entity_state());
    try {
      const response = await QueryRequest(
        getAllEntityState,
        variables,
        dispatch
      );
      if (
        response?.data?.getAllEntityStates &&
        !response?.data?.getAllEntityStates.error
      ) {
        dispatch(_get_entity_state_success(response?.data?.getAllEntityStates));
      } else if (response?.data?.getAllEntityStates?.error) {
        dispatch(
          _get_entity_state_failure(response?.data?.getAllEntityStates.error)
        );
      }
    } catch (error) {
      dispatch(_get_entity_state_failure(error));
    }
  };
}
