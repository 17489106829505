import { decryptResponse } from "@helpers/crypto";
import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_captcha_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  captcha: null,
  item: null,
};

const slice = createSlice({
  name: "getCaptcha",
  initialState,
  reducers: {
    _get_captcha: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _get_captcha_success: (state, { payload }) => {
      state.loading = false;
      state.captcha = decryptResponse(payload.captcha);
      state.error = null;
    },
    _get_captcha_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const { _get_captcha, _get_captcha_success, _get_captcha_failure } =
  slice.actions;

export const getCaptchaSelector = (state) => state.getCaptcha;

export const getCaptchaReducer = slice.reducer;

export function get_captcha(variables) {
  return async (dispatch) => {
    dispatch(_get_captcha());
    try {
      const response = await QueryRequest(
        get_captcha_query,
        variables,
        dispatch
      );
      if (response?.data?.getCaptcha && !response?.data?.getCaptcha.error) {
        dispatch(_get_captcha_success(response?.data?.getCaptcha));
      } else if (response?.data?.getCaptcha?.error) {
        dispatch(_get_captcha_failure(response?.data?.getCaptcha.error));
      }
    } catch (error) {
      dispatch(_get_captcha_failure(error));
    }
  };
}

export async function get_captcha_clear(dispatch) {
  dispatch(_get_captcha());
}
