import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getDrivers, get_drivers_without_pagination } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "driverListWithoutPagination",
  initialState,
  reducers: {
    _get_driver_without_pagination: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_driver_without_pagination_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_driver_without_pagination_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_driver_without_pagination,
  _get_driver_without_pagination_success,
  _get_driver_without_pagination_failure,
} = slice.actions;

export const getdriverListWithOutPaginationSelector = (state) =>
  state.driverListWithoutPagination;

export const getdriverListWithOutPaginationReducer = slice.reducer;

export function get_driver_without_pagination(variables) {
  return async (dispatch) => {
    dispatch(_get_driver_without_pagination());
    try {
      // let data = {
      //   items: [
      //     {
      //       meter_reading: "300",
      //       driver_name: "Sathish",
      //       vechicle_number: "TN 61 0001",
      //       amount_of_fuel: "20",
      //       amount: 3000,
      //     },
      //   ],
      // };
      // dispatch(_get_fuel_without_pagination_success(data));
      const response = await QueryRequest(
        // get_drivers_without_pagination,
        getDrivers,
        variables,
        dispatch
      );
      if (response?.data?.getDrivers && !response?.data?.getDrivers.error) {
        dispatch(
          _get_driver_without_pagination_success(response?.data?.getDrivers)
        );
      } else if (response?.data?.getDrivers?.error) {
        dispatch(
          _get_driver_without_pagination_failure(
            response?.data?.getDrivers.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_driver_without_pagination_failure(error));
    }
  };
}
