import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  VStack,
  HStack,
  Text,
  Card,
  SimpleGrid,
  Pressable,
  Image,
} from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import { ROUTES } from "@views/routes/my_routes";
import { useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";
import * as XLSX from "xlsx";

import {
  getfuelListSelector,
  getfuelListWithOutPaginationSelector,
  get_fuelList,
  get_fuel_without_pagination,
  getAll_Vehicles,
  getVehiclesListSelector,
  getFuelReport,
  getFuelReportSelector,
  getFuelReportClear,
} from "@services/redux";
import { get_one_condemnation_clear } from "@services/redux";
import {
  deletefuelSelector,
  delete_fuel,
  delete_one_fuel_modal,
} from "@services/redux";
import { updatefuelSelector, update_one_fuel_clear } from "@services/redux";
import { createfuelSelector, create_one_fuel_clear } from "@services/redux";
import FuelDetails from "./fuel_details";
import { Button, DatePicker } from "antd";
import ReactSelect from "react-select";
import FuelCoupon from "./fuel_coupon";
import { BiDownload } from "react-icons/bi";
import { retrieveItem } from "@helpers/storage";
import { colorScheme, excelDownload } from "@helpers/constants";
import { RiOilLine } from "react-icons/ri";
import Gauge from "@assets/icons/gauge.png";
import Speedometer from "@assets/icons/speedometer.png";
import moment from "moment";
const FuelList = (props) => {
  const history = useHistory();
  // const { vehicle_id } = props;
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const [object, setObject] = useState({});
  console.log("object", object);
  const [refilledFromDate, setRefilledFromDate] = useState("");
  const [refilledToDate, setRefilledToDate] = useState("");
  const [search_string, setSearchString] = useState("");
  const [couponModal, setCouponModal] = useState(false);

  const [vehicle_id, setVehicleId] = useState("");
  const [vehicleList, setVehicleList] = useState([]);

  const [manageModalVisible, setManageModalVisible] = useState(false);
  const { t } = useTranslation();
  const { id, paramtype } = useParams();
  const [dialogVisible, setDialogVisible] = useState(false);

  const { url: report, loading: report_loading } = useSelector(
    getFuelReportSelector
  );
  const { items: vehicles } = useSelector(getVehiclesListSelector);

  const { loading: deleteLoading, status: deleteStatus } =
    useSelector(deletefuelSelector);
  const { loading, items, total_items } = useSelector(getfuelListSelector);

  const { items: fuel_items } = useSelector(
    getfuelListWithOutPaginationSelector
  );

  useEffect(() => {
    console.log("searchstring", search_string, object);
    dispatch(get_fuel_without_pagination({ search_string, ...object }));
  }, [object, search_string]);

  const XLSX_download = () => {
    const labels = [
      "Date",
      "Vehicle Number",
      "Vehicle Name",
      "Officer/Minister",
      "Driver Name",
      "Kms Covered",
      "Fuel Type",
      "Quantity of fuel",
      "Amount in Rs",
    ];

    const excel_items = fuel_items?.map((fuel) => {
      console.log("fuel_items", fuel_items);

      let fuel_data = {
        ["Date"]: moment(fuel?.fuel_refilled_date).format(`DD/MM/YYYY`),
        ["Vehicle Number"]: fuel?.vehicle?.vehicle_number ?? "-",
        ["Vehicle Name"]: fuel?.vehicle?.car_model?.car_make?.name ?? "-",
        ["Officer/Minister"]: fuel?.officer_name ?? "-",
        ["Driver Name"]: fuel?.driver?.name ?? "-",
        ["Kms Covered"]: fuel?.meter_reading ?? "-",
        ["Fuel Type"]: fuel?.fuel_type ?? "-",
        ["Quantity of fuel"]: fuel?.quantity ?? "-",
        ["Amount in Rs"]: fuel?.amount,
      };
      return fuel_data;
    });

    excelDownload(excel_items, labels, "Fuel Report");
  };

  useEffect(() => {
    let temp = [];
    if (vehicles) {
      temp = vehicles?.map((x) => {
        return {
          label: `${x.vehicle_number} ${x.car_model?.name} - ${x.car_model?.car_make?.name}`,
          value: x.id,
        };
      });
    }
    setVehicleList(temp);
  }, [vehicles]);
  useEffect(() => {
    if (report) {
      window.open(report);
      dispatch(getFuelReportClear());
    }
  }, [report]);
  useEffect(() => {
    dispatch(getAll_Vehicles());
  }, []);
  useEffect(() => {
    setObject({
      vehicle_id: vehicle_id,
      search_string: search_string,
      fuel_refilled_start_date: refilledFromDate,
      fuel_refilled_end_date: refilledToDate,
    });
  }, [vehicle_id, refilledFromDate, refilledToDate]);
  useEffect(() => {
    if (deleteStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Fuel entry")} ${t("Deleted_successfully")}`,
      });
      setDialogVisible(false);
      dispatch(get_fuelList());
      handleModalClose();
      dispatch(delete_one_fuel_modal());
    }
  }, [deleteStatus]);
  const handleAdd = () => {
    history.push({
      pathname: `${ROUTES.FUEL_DETAILS}`,
    });
    dispatch(get_one_condemnation_clear);
    setActionType("add");
    setActionItem({});
  };
  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.FUEL_DETAILS}/${item.id}`,
    });
    dispatch(get_one_condemnation_clear);
    setActionType("add");
    setActionItem({});
  };

  const handleView = (item) => {
    history.push({
      pathname: `${ROUTES.FUEL_VIEW}/${item.id}`,
    });
  };
  const handleAddModel = () => {
    setManageModalVisible(true);
  };

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };
  const roles = JSON.parse(retrieveItem("roles"));

  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const formatAmount = (value) => {
    return `₹${value.toLocaleString("en-IN")}`;
  };

  const formatDisplay = (date) => {
    date = new Date(date);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const handleCouponOpen = (item) => {
    setActionItem(item);
    setCouponModal(true);
  };

  const handleCouponClose = () => {
    setCouponModal(false);
  };
  let action1 = [
    {
      icon: <AiOutlineEdit />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("table:edit_fuel"),
      onPress: handleEdit,
    },
    {
      icon: <BiDownload />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("table:coupon"),
      onPress: handleCouponOpen,
    },
    {
      icon: <AiOutlineDelete />,
      iconSize: 30,
      iconColor: "red.700",
      title: t("table:delete_fuel"),
      onPress: handleDelete,
    },
  ];

  const action = [
    {
      icon: <AiOutlineEye />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("View Fuel Entry"),
      onPress: handleView,
    },
  ];
  const columns = [
    {
      header: t("table:vechicle_number"),
      dataIndex: "vehicle.vehicle_number",
      key: "vechicle_number",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:driver_name"),
      dataIndex: "driver.name",
      key: "driver_name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("Fuel refilled date"),
      dataIndex: "fuel_refilled_date",
      key: "date",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatDisplay,
    },
    {
      header: t("table:meter_reading"),
      dataIndex: "meter_reading",
      key: "meter_reading",
      sortable: false,
      align: "left",
      width: 220,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "left",
      type: "actions",
      width: 150,
      actions: roles?.[0]?.name === "Public Department" ? action1 : action,
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_fuel"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];
  var kilometer = items.reduce((accum, item) => accum + item.meter_reading, 0);
  var fuel_consumed = items.reduce((accum, item) => accum + item.quantity, 0);
  // const handleReport = () => {
  //   dispatch(
  //     getFuelReport({
  //       vehicle_id: vehicle_id,
  //       fuel_refilled_start_date: refilledFromDate,
  //       fuel_refilled_end_date: refilledToDate,
  //       search_string: search_string,
  //     })
  //   );
  // };
  let details = [
    {
      name: "Kms Covered",
      value: kilometer || 0,
      color: "#d5ac90",
      sub_color: "#b49178",
      icon: (
        <Image
          source={Speedometer}
          size={"50px"}
          style={{ tintColor: "#b49178" }}
        />
      ),
      // onPress: handleAllotments(false),
    },
    {
      name: "Fuel Consumed",
      value: fuel_consumed || 0,
      color: "#C88EA7",
      sub_color: "#ab8495",
      icon: (
        <Image source={Gauge} size={"50px"} style={{ tintColor: "#ab8495" }} />
      ),
    },
  ];
  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <Text fontSize="md" fontWeight="bold" py={4}>
          {t("table:fuel")}
        </Text>
        <VStack space={5}>
          <HStack space="5" zIndex="6" mb="3">
            <Box flex={1}>
              <Text>{"Vehicle"}</Text>
              <ReactSelect
                value={vehicleList?.find((r) => r.value === vehicle_id)}
                options={vehicleList}
                onChange={(entity, Object) => {
                  if (entity) setVehicleId(entity.value);
                  else setVehicleId(null);
                }}
                isClearable={true}
              />
            </Box>

            <Box flex={1}>
              <Text>{"Fuel Refilled From Date"}</Text>
              <DatePicker
                onChange={(value, dateString) => {
                  setRefilledFromDate(dateString);
                }}
                allowClear
              />
            </Box>
            <Box flex={1}>
              <Text>{"Fuel Refilled To Date"}</Text>
              <DatePicker
                onChange={(value, dateString) => {
                  setRefilledToDate(dateString);
                }}
                allowClear
              />
            </Box>
          </HStack>
          <HStack>
            <Button
              onClick={XLSX_download}
              type="primary"
              style={{
                width: "228px",
                background: "#683fe9",
                borderColor: "#683fe9",
              }}
              loading={report_loading}
            >
              <Text bold fontSize="15px" color="white">
                Excel Export
              </Text>
            </Button>
          </HStack>

          {items?.length && !loading ? (
            // <HStack mt="5" space="5" justifyContent={"center"}>
            //   <Box>
            //     <Card
            //       width="200"
            //       h="100"
            //       backgroundColor={
            //         "linear-gradient(234deg, #058b48 0%, rgb(61, 178, 183) 82%);"
            //       }
            //     >
            //       <VStack>
            //         <Box>
            //           <Text
            //             bold
            //             textAlign={"center"}
            //             color="white"
            //             fontSize={"20"}
            //           >
            //             {kilometer}
            //           </Text>
            //         </Box>
            //         <Box>
            //           <Text bold textAlign={"center"} color="white">
            //             Kms Covered
            //           </Text>
            //         </Box>
            //       </VStack>
            //     </Card>
            //   </Box>
            //   <Box>
            //     <Card
            //       width="200"
            //       h="100"
            //       backgroundColor={
            //         "linear-gradient(315deg, rgba(204, 131, 217, 0.84) 0%, rgb(149, 38, 203) 74%);"
            //       }
            //     >
            //       <VStack>
            //         <Box>
            //           <Text
            //             bold
            //             textAlign={"center"}
            //             color="white"
            //             fontSize={"20"}
            //           >
            //             {fuel_consumed}
            //           </Text>
            //         </Box>
            //         <Box>
            //           <Text bold textAlign={"center"} color="white">
            //             Fuel Consumed
            //           </Text>
            //         </Box>
            //       </VStack>
            //     </Card>
            //   </Box>
            // </HStack>
            <SimpleGrid
              columns={{
                base: 1,
                xs: 1,
                md: 3,
                sm: 2,
                lg: 4,
                xl: 4,
                "2xl": 5,
              }}
              spacingY={"27px"}
              spacingX={21}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {details?.map((option) => {
                return (
                  <Box
                    size="100px"
                    backgroundColor={option.color}
                    style={{
                      cursor: "pointer",
                      width: "220px",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <Pressable onPress={option.onPress}>
                      <VStack
                        justifyContent={"space-between"}
                        alignItems={"space-around"}
                      >
                        <HStack
                          space={"10px"}
                          justifyContent={"space-around"}
                          // padding={"10px"}
                        >
                          <Box h={"80px"} justifyContent={"center"}>
                            {option.icon}
                          </Box>
                          <Box h={"80px"} justifyContent={"center"}>
                            <Text
                              color={"white"}
                              fontSize={"26px"}
                              // fontStyle={"italic"}
                              fontWeight={600}
                            >
                              {option?.value}
                            </Text>
                          </Box>
                        </HStack>
                        <Box
                          backgroundColor={option?.sub_color}
                          justifyContent={"center"}
                          width={"100%"}
                          height={"30px"}
                          style={{
                            borderBottomLeftRadius: "10px",
                            borderBottomRightRadius: "10px",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          <Text
                            textAlign={"center"}
                            alignItems={"center"}
                            color={"white"}
                            fontSize={"14px"}
                            fontWeight={500}
                          >
                            {option?.name}
                          </Text>
                        </Box>
                      </VStack>
                    </Pressable>
                  </Box>
                );
              })}
            </SimpleGrid>
          ) : (
            ""
          )}

          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="Fuel"
              loading={loading}
              data={items}
              columns={columns}
              setSearchString={setSearchString}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_fuelList}
              query={object}
              headerActions={
                roles?.[0]?.name === "Public Department" ||
                roles?.[0]?.name === "Department"
                  ? header_actions
                  : []
              }
              emptyMessage={t("No Fuel Entry details Found")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Modal
        isOpen={manageModalVisible}
        onClose={handleModalClose}
        header={
          actionType === "add"
            ? t("form:add_fuel_entry")
            : t("form:edit_position")
        }
        component={
          <FuelDetails
            handleModalClose={handleModalClose}
            initialValue={actionItem}
          />
        }
      />
      <Modal
        size="xxl"
        isOpen={couponModal}
        onClose={handleCouponClose}
        header={actionItem?.vehicle?.name}
        component={
          <FuelCoupon handleModalClose={handleCouponClose} data={actionItem} />
        }
        // size={900}
      />
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("table:delete_fuel")}
        content={t("table:delete_fuel_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            // isLoading: loading,
            formErrorMessage: t("error:error_message"),
            submit: delete_fuel,
          },
        ]}
      />
    </>
  );
};
export default FuelList;
