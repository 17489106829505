import React from "react";
import { Box, Image } from "native-base";
import logo from "@assets/images/logo.png";
const SidebarHeader = (props) => {
  return (
    <Box w={"100%"} h={"100%"} justifyContent="center" alignItems="center">
      <Image h="10" size="20" alt="logo" resizeMode={"contain"} source={logo} />
    </Box>
  );
};

export default SidebarHeader;
