import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getDriverHistoryQuery } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "driverHistoryList",
  initialState,
  reducers: {
    _get_driver_history: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_driver_history_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_driver_history_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_driver_history,
  _get_driver_history_success,
  _get_driver_history_failure,
} = slice.actions;

export const getDriverHistorySelector = (state) => state.driverHistoryList;

export const getDriverHistoryReducer = slice.reducer;

export function getDriverHistory(variables) {
  return async (dispatch) => {
    dispatch(_get_driver_history());
    try {
      const response = await QueryRequest(
        getDriverHistoryQuery,
        variables,
        dispatch
      );
      if (
        response?.data?.getDriverAllotmentLogs &&
        !response?.data?.getDriverAllotmentLogs.error
      ) {
        dispatch(
          _get_driver_history_success(response?.data?.getDriverAllotmentLogs)
        );
      } else if (response?.data?.getDriverAllotmentLogs?.error) {
        dispatch(
          _get_driver_history_failure(
            response?.data?.getDriverAllotmentLogs.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_driver_history_failure(error));
    }
  };
}
