import { deleteAdditionalAccessoryReducer } from "./additional_accessories_delete";
import { getOneAdditionalAccessoryReducer } from "./additional_accessories_getone";
import { getAccessoriesListReducer } from "./additional_accessories_list";
import { updateAdditionalAccessoryReducer } from "./additional_accessories_update";
import { createAdditionalAccessoryReducer } from "./additional_accessory_create";
export const getAccessoriesListReducers = {
  accessories_List: getAccessoriesListReducer,
  additional_accessory_create: createAdditionalAccessoryReducer,
  additional_accessoryUpdate: updateAdditionalAccessoryReducer,
  additional_accessoryDelete: deleteAdditionalAccessoryReducer,
  additional_accessoryGetOne: getOneAdditionalAccessoryReducer,
};
export * from "./additional_accessories_list";
export * from "./additional_accessories_getone";
export * from "./additional_accessories_update";
export * from "./additional_accessory_create";
export * from "./additional_accessories_delete";
