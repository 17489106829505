import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { deleteAccessoriesMuatation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "additional_accessoryDelete",
  initialState,
  reducers: {
    _delete_additional_accessory: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_additional_accessory_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_additional_accessory_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _delete_additional_accessory,
  _delete_additional_accessory_success,
  _delete_additional_accessory_failure,
} = slice.actions;

export const deleteAdditionalAccessorySelector = (state) =>
  state.additional_accessoryDelete;

export const deleteAdditionalAccessoryReducer = slice.reducer;

export function delete_additional_accessory(variables) {
  return async (dispatch) => {
    dispatch(_delete_additional_accessory());
    try {
      const response = await MutateRequest(
        deleteAccessoriesMuatation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteAdditionalAccessoryMaster &&
        !response?.data?.deleteAdditionalAccessoryMaster.error
      ) {
        dispatch(
          _delete_additional_accessory_success(
            response?.data?.deleteAdditionalAccessoryMaster
          )
        );
      } else if (response?.data?.deleteAdditionalAccessoryMaster?.error) {
        dispatch(
          _delete_additional_accessory_failure(
            response?.data?.deleteAdditionalAccessoryMaster.error
          )
        );
      }
    } catch (error) {
      dispatch(_delete_additional_accessory_failure(error));
    }
  };
}
export function delete_one_additional_accessory_modal(dispatch) {
  return async (dispatch) => {
    dispatch(_delete_additional_accessory());
  };
}
