import React, { Fragment, useEffect, useState } from "react";
import { Box, Divider, HStack, VStack } from "native-base";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button as NativeButton } from "native-base";
import Modal from "@views/components/ui/modal";
import {
  getEntityListSelector,
  get_all_entity,
} from "@services/redux/slices/entity";
import { retrieveItem } from "@helpers/storage";

const Entity = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data } = props;
  const [toggleModal, setToggleModal] = useState(false);
  const [issueCertificateDialog, setIssueCertificateDialog] = useState(false);
  const { t } = useTranslation();
  const [actionItem, setActionItem] = useState({ service_request_id: id });
  const [iterationItem, setIterationItem] = useState({});
  const [allowIterationCreate, setAllowIterationCreate] = useState(false);
  const [iterationType, setIterationType] = useState();
  const [modalHeaderName, setModalHeaderName] = useState();
  const { items: service_request_iteration_responses } = useSelector(
    getEntityListSelector
  );

  const handleRootAdd = (
    type,
    id,
    entity_state_id,
    entity_state_transition_id,
    modal_header_name
  ) => {
    debugger;
    setIterationItem({
      ...iterationItem,
      id,
      entity_state_id,
      entity_state_transition_id,
    });
    setModalHeaderName(modal_header_name);
    setIterationType(modal_header_name);
    setToggleModal(true);
  };
  const handleIssueCertificate = (
    type,
    id,
    entity_state_id,
    entity_state_transition_id
  ) => {
    setIterationItem({
      ...iterationItem,
      id,
      entity_state_id,
      entity_state_transition_id,
    });
    setIterationType(type);
    setIssueCertificateDialog(true);
  };
  const handleIssueCertificateDialogClose = () => {
    setIssueCertificateDialog(false);
  };
  const [authorizeVendor, setAuthorizeVendor] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [authorizeSP, setAuthorizeSP] = useState(false);
  const roles = JSON.parse(retrieveItem("roles"));

  useEffect(() => {
    roles?.map((x) => {
      if (x.name === "Vendor") {
        setAuthorizeVendor(true);
      }
      if (x.name === "Auditor") {
        setAuthorizeSP(true);
      }
    });
  }, [roles]);

  const handleModalClose = () => {
    setToggleModal(false);
  };

  //   const handleSubmit = (values) => {
  //     dispatch(create_service_request_iteration({ data: values }));
  //   };
  const onDownload = (values) => {
    debugger;
    const link = document.createElement("a");
    link.download = values;
    link.href = values;
    link.click();
  };
  useEffect(() => {
    dispatch(get_all_entity());
  });
  return (
    <Box w="100%">
      {service_request_iteration_responses?.map((x, i) => {
        return (
          <VStack
            rounded="lg"
            key={i}
            shadow={6}
            borderWidth={1}
            borderColor="#c8c7c7"
            m="20px"
            p="20px"
            bg="white.300"
            space={1}
          >
            <Box key={`header-${i}`}>
              <HStack>
                {" "}
                <Box key={`iteration-name-${i}`} _text={{ fontWeight: "bold" }}>
                  {/* {x.name} */}
                </Box>{" "}
                <Box
                  ml="70%"
                  key={`iteration-status-actions-${i}`}
                  _text={{ fontWeight: "bold", fontSize: "17px" }}
                >
                  {x?.entity_state?.name}
                </Box>
              </HStack>
            </Box>
            <HStack flex={1} space={2} justifyContent="flex-end">
              {x.entity_state?.from_state_transitions?.map((y) => {
                return (
                  y?.transition_name &&
                  (y?.transition_name === "Issue Certificate" ? (
                    <Box>
                      <NativeButton
                        style={{
                          marginTop: "3%",
                          width: "30vh",
                        }}
                        onPress={() => {
                          handleIssueCertificate(
                            "STS",
                            x.id,
                            y?.to_entity_state_id,
                            y?.id
                          );
                        }}
                      >
                        {y?.transition_name}
                      </NativeButton>
                    </Box>
                  ) : (
                    <Box>
                      <NativeButton
                        style={{
                          marginTop: "3%",
                          width: "30vh",
                        }}
                        onPress={() => {
                          handleRootAdd(
                            "STS",
                            x.id,
                            y?.to_entity_state_id,
                            y?.id,
                            y?.transition_name
                          );
                        }}
                      >
                        {y?.transition_name}
                      </NativeButton>
                    </Box>
                  ))
                );
              })}
            </HStack>
          </VStack>
        );
      })}
    </Box>
  );
};

export default Entity;
