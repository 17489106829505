import { gql } from "@apollo/client";

export const getServicesQuery = gql`
  query getServices($page_number: Float, $page_limit: Float) {
    getServices(page_number: $page_number, page_limit: $page_limit) {
      items {
        id
        name
        description
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const createServiceMutation = gql`
  mutation createService($data: service_input) {
    createService(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updateServiceMutation = gql`
  mutation updateService($id: String!, $data: service_input) {
    updateService(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const deleteServiceMutation = gql`
  mutation deleteServiceMutation($id: String!) {
    deleteService(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;
export const getOneServiceQuery = gql`
  query getService($id: String!) {
    getService(id: $id) {
      id
      name
      description
    }
  }
`;
