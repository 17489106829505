import React, { useEffect } from "react";
import { Box, useColorModeValue, Pressable, Center } from "native-base";
import { Animated } from "react-native";
import { TabView as RNTabView, SceneMap } from "react-native-tab-view";

const TabView = (props) => {
  const [index, setIndex] = React.useState(0);
  let routes = [];
  let _renderScene = {};
  for (let i = 0; i < props.tabs.length; i++) {
    let _route = {
      key: props.tabs[i].key,
      title: props.tabs[i].title,
      isEnabled:
        props.tabs[i].isEnabled === undefined ? true : props.tabs[i].isEnabled,
    };
    routes.push(_route);

    const Component = () => {
      const element = React.cloneElement(props.tabs[i].component);
      return <>{element}</>;
    };

    _renderScene[props.tabs[i].key] = Component;
  }

  const renderScene = SceneMap(_renderScene);

  const renderTabBar = (props) => {
    const inputRange = props.navigationState.routes.map((x, i) => i);
    return (
      <Box flexDirection="row">
        {props.navigationState.routes.map((route, i) => {
          const opacity = props.position.interpolate({
            inputRange,
            outputRange: inputRange.map((inputIndex) =>
              inputIndex === i ? 1 : 0.5
            ),
          });
          const color =
            index === i
              ? useColorModeValue("#000", "#e5e5e5")
              : useColorModeValue("#1f2937", "#a1a1aa");
          const borderColor =
            index === i
              ? "cyan.500"
              : useColorModeValue("coolGray.200", "gray.400");
          return (
            <Pressable
              key={i}
              borderBottomWidth="3"
              borderColor={borderColor}
              flex={1}
              alignItems="center"
              h="40px"
              cursor="pointer"
              onPress={() => {
                if (route.isEnabled) {
                  setIndex(i);
                } else {
                  alert("disabled");
                }
              }}
            >
              <Animated.Text
                style={{
                  color,
                }}
              >
                {route.title}
              </Animated.Text>
            </Pressable>
          );
        })}
      </Box>
    );
  };

  return (
    <RNTabView
      navigationState={{
        index,
        routes,
      }}
      renderScene={renderScene}
      renderTabBar={renderTabBar}
      onIndexChange={setIndex}
      style={{
        marginTop: "20px",
      }}
    />
  );
};
export default TabView;
