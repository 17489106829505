import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getGatePassReportQuery } from "./graphql";
const initialState = {
  loading: false,
  error: null,
  url: null,
};
const slice = createSlice({
  name: "getGatePassReport",
  initialState,
  reducers: {
    getGatePassReportInitialize: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    getGatePassReportReset: (state) => {
      state.loading = false;
      state.url = null;
      state.error = null;
    },
    getGatePassReportSuccess: (state, { payload }) => {
      state.loading = false;
      state.url = payload.url;
      state.error = null;
    },
    getGatePassReportFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  getGatePassReportInitialize,
  getGatePassReportSuccess,
  getGatePassReportFailure,
  getGatePassReportReset,
} = slice.actions;
export const getGatePassReportSelector = (state) => state.getGatePassReport;

export const getGatePassReportReducer = slice.reducer;

export function getGatePassReport(variables) {
  return async (dispatch) => {
    dispatch(getGatePassReportInitialize());
    try {
      const response = await QueryRequest(
        getGatePassReportQuery,
        variables,
        dispatch
      );
      if (
        response?.data?.getGatePassReport &&
        !response?.data?.getGatePassReport.error
      ) {
        dispatch(getGatePassReportSuccess(response?.data?.getGatePassReport));
      } else if (response?.data?.getGatePassReport?.error) {
        dispatch(
          getGatePassReportFailure(response?.data?.getGatePassReport.error)
        );
      }
    } catch (error) {
      dispatch(getGatePassReportFailure(error));
    }
  };
}

export function getGatePassReportClear() {
  return (dispatch) => {
    dispatch(getGatePassReportReset());
  };
}
