import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { createfuelMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "fuelCreate",
  initialState,
  reducers: {
    _create_fuel: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_fuel_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_fuel_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_fuel_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _create_fuel,
  _create_fuel_reset,
  _create_fuel_success,
  _create_fuel_failure,
} = slice.actions;

export const createfuelSelector = (state) => state.fuelCreate;

export const createfuelReducer = slice.reducer;

export function create_fuel(variables) {
  return async (dispatch) => {
    dispatch(_create_fuel());
    try {
      const response = await MutateRequest(
        createfuelMutation,
        variables,
        dispatch
      );
      if (response?.data?.createFuel && !response?.data?.createFuel.error) {
        dispatch(_create_fuel_success(response?.data?.createFuel));
      } else if (response?.data?.createFuel?.error) {
        dispatch(_create_fuel_failure(response?.data?.createFuel.error));
      }
    } catch (error) {
      dispatch(_create_fuel_failure(error));
    }
  };
}
export function create_one_fuel_clear(dispatch) {
  return async (dispatch) => {
    dispatch(_create_fuel_reset());
  };
}
