import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { createCarMakeMutation, createDepartmentMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "carmake_create",
  initialState,
  reducers: {
    _create_carmake: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_carmake_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_carmake_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_carmake_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _create_carmake,
  _create_carmake_reset,
  _create_carmake_success,
  _create_carmake_failure,
} = slice.actions;

export const createCarMakeSelector = (state) => state.carmake_create;

export const createCarMakeReducer = slice.reducer;

export function create_carmake(variables) {
  return async (dispatch) => {
    dispatch(_create_carmake());
    try {
      const response = await MutateRequest(
        createCarMakeMutation,
        variables,
        dispatch
      );
      if (
        response?.data?.createCarMake &&
        !response?.data?.createCarMake.error
      ) {
        dispatch(_create_carmake_success(response?.data?.createCarMake));
      } else if (response?.data?.createCarMake?.error) {
        dispatch(_create_carmake_failure(response?.data?.createCarMake.error));
      }
    } catch (error) {
      dispatch(_create_carmake_failure(error));
    }
  };
}
export function create_one_carmake_clear(dispatch) {
  return async (dispatch) => {
    dispatch(_create_carmake_reset());
  };
}
