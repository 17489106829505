import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { deletedesignationMakeMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "designationDelete",
  initialState,
  reducers: {
    _delete_designation: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_designation_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_designation_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _delete_designation,
  _delete_designation_success,
  _delete_designation_failure,
} = slice.actions;

export const deleteDesignationSelector = (state) => state.designationDelete;

export const deleteDesignationReducer = slice.reducer;

export function delete_designation(variables) {
  return async (dispatch) => {
    dispatch(_delete_designation());
    try {
      const response = await MutateRequest(
        deletedesignationMakeMutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteDesignation &&
        !response?.data?.deleteDesignation.error
      ) {
        dispatch(
          _delete_designation_success(response?.data?.deleteDesignation)
        );
      } else if (response?.data?.deleteDesignation?.error) {
        dispatch(
          _delete_designation_failure(response?.data?.deleteDesignation.error)
        );
      }
    } catch (error) {
      dispatch(_delete_designation_failure(error));
    }
  };
}
export function delete_one_designation_modal(dispatch) {
  return async (dispatch) => {
    dispatch(_delete_designation());
  };
}
