import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { pass_dashboard_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: {},
};

const slice = createSlice({
  name: "pass_dashboard_count",
  initialState,
  reducers: {
    _pass_dashboard: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _pass_dashboard_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _pass_dashboard_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const {
    _pass_dashboard,
    _pass_dashboard_success,
    _pass_dashboard_failure,
} = slice.actions;

export const pass_dashboard_Selector = (state) =>
  state.pass_dashboard_count;

export const pass_dashboard_Reducer = slice.reducer;

export function pass_dashboard(variables) {
  return async (dispatch) => {
    dispatch(_pass_dashboard());
    try {
      const response = await QueryRequest(
        pass_dashboard_query,
        variables,
        dispatch
      );
      if (
        response?.data?.passDashboardCount &&
        !response?.data?.passDashboardCount.error
      ) {
        dispatch(
            _pass_dashboard_success(
            response?.data?.passDashboardCount
          )
        );
      } else if (response?.data?.passDashboardCount?.error) {
        dispatch(
            _pass_dashboard_failure(
            response?.data?.passDashboardCount.error
          )
        );
      }
    } catch (error) {
      dispatch(_pass_year_list_failure(error));
    }
  };
}
