import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Button, Text } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import {
  ClassVechile,
  VehicleColor,
  FuelUsed,
  BatteryType,
  SeatingCapacity,
} from "@views/components/source/constant";

import {
  getMakerListSelector,
  getCar_Makers,
  getTyre_Makers,
  getTyreMakerListSelector,
  getAll_Agency,
  getAgencyListSelector,
  getAll_Accessories,
  getAccessoriesListSelector,
  create_Vehicles,
  update_Vehicles,
  getone_Vehicles,
  getOneVehiclesListSelector,
  getDepartmentListSelector,
  get_Departments,
} from "@services/redux";
import ReactSelect from "react-select";

const VehicleUpdate = (props) => {
  const dispatch = useDispatch();

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [makerChoose, setMakerChoose] = useState("");
  const [currentMakerModels, setCurrentMakerModels] = useState("");

  useEffect(() => {
    dispatch(getCar_Makers({}));
    dispatch(getTyre_Makers({}));
    dispatch(getAll_Agency({}));
    dispatch(getAll_Accessories({}));
    dispatch(get_Departments({}));
  }, []);
  useEffect(() => {
    if (id) dispatch(getone_Vehicles({ id: id }));
  }, [id]);

  const handleBack = () => {
    history.push(ROUTES.VEHICLE_LIST);
  };

  const { items: maker_items } = useSelector(getMakerListSelector);
  const { items: tyremaker_items } = useSelector(getTyreMakerListSelector);
  const { items: agency_items } = useSelector(getAgencyListSelector);
  const { items: accessories_items } = useSelector(getAccessoriesListSelector);
  const { item: getOne_items } = useSelector(getOneVehiclesListSelector);
  const { items: department_items } = useSelector(getDepartmentListSelector);

  const onValueChange = (values) => {
    if (values.vechile_make) {
      setMakerChoose(values.vechile_make);
    }

    // if (values.requested_taluk) {
    //   setSelectedTaluk(values.requested_taluk);
    // }
  };

  useEffect(() => {
    let currentmakerChoose = [];

    if (makerChoose) {
      currentmakerChoose = maker_items.find(
        (x) => x.id === makerChoose
      )?.car_models;
    }

    setCurrentMakerModels(currentmakerChoose);
  }, [makerChoose]);

  const handleSubmit = (values) => {
    values.fuel_tank_capacity = parseInt(values.fuel_tank_capacity);
    values.vehicle_price = parseInt(values.vehicle_price);

    // if (values.id) {
    //   let update_id = values?.id;
    //   delete values?.id;
    //   delete values?.error;
    //   dispatch(
    //     update_Vehicles({
    //       id: update_id,
    //       data: values,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     create_Vehicles({
    //       data:
    //         values

    //     })
    //   );
    // }

    // if (values.id) {
    //   let update_id = values?.id;
    //   delete values?.id;
    //   delete values?.error;
    //   dispatch(
    //     update_department({
    //       id: update_id,
    //       data: {
    //         name: values.name,
    //         email: values.email,
    //         address: values.address,
    //         contact_person_name: values.contact_person_name,
    //         contact_person_email: values.contact_person_email,
    //         contact_person_mobile: values.contact_person_mobile,
    //         contact_person_designation: values.contact_person_designation,
    //         hod_name: values.hod_name,
    //         hod_designation: values.hod_designation,
    //         // is_district_department: values.is_district_department,
    //         hod_email: values.hod_email,
    //         hod_mobile: values.hod_mobile,
    //         district_id: values.district_id,
    //       },
    //     })
    //   );
    // } else {
    //   dispatch(
    //     create_department({
    //       data: {
    //         name: values.name,
    //         email: values.email,
    //         address: values.address,
    //         contact_person_name: values.contact_person_name,
    //         contact_person_email: values.contact_person_email,
    //         contact_person_mobile: values.contact_person_mobile,
    //         contact_person_designation: values.contact_person_designation,
    //         hod_name: values.hod_name,
    //         hod_designation: values.hod_designation,
    //         // is_district_department: values.is_district_department,
    //         hod_email: values.hod_email,
    //         hod_mobile: values.hod_mobile,
    //         district_id: values.district_id,
    //       },
    //     })
    //   );
    // }
  };
  // useEffect(() => {
  //   dispatch(get_districts());
  // }, []);
  //   useEffect(() => {
  //     if (departmentCreateError) {
  //       showToast({ type: "error", message: departmentCreateError?.message });
  //       dispatch(create_one_department_clear);
  //     } else if (departmentCreateStatus === "Success") {
  //       showToast({
  //         type: "success",
  //         message: `${t("department")} ${t("create_successfully")}`,
  //       });
  //       dispatch(create_one_department_clear);
  //     } else if (departmentUpdateError) {
  //       showToast({ type: "error", message: departmentUpdateError?.message });
  //       dispatch(update_one_department_clear);
  //     } else if (departmentUpdateStatus === "Success") {
  //       showToast({
  //         type: "success",
  //         message: `${t("department")} ${t("update_successfully")}`,
  //       });
  //       dispatch(update_one_department_clear);
  //     }
  //   }, [departmentCreateStatus, departmentUpdateStatus]);
  //   useEffect(() => {
  //     if (id) {
  //       if (props.location.state) {
  //         setActionItem(props.location.state);
  //       } else {
  //         dispatch(get_one_department({ id }));
  //       }
  //     } else {
  //     }
  //   }, [id]);

  //   useEffect(() => {
  //     if (department) {
  //       let district_ids = department?.districts?.map((x) => {
  //         return x.id;
  //       });
  //       department = { ...department, district_ids };
  //       setActionItem(department);
  //     }
  //   }, [department]);
  return (
    <Box flex="1" w="100%">
      <Box top="10px" zIndex="2" bg="white">
        <Text fontSize="md" fontWeight="bold" py={4}>
          {id ? t("update_vechicle") : t("add_vechicle")}
        </Text>
      </Box>
      <Box position="sticky" top="0px" zIndex="2" bg="white">
        {/* <Text fontSize="md" fontWeight="bold">
          {actionItem.name}
        </Text> */}
      </Box>
      <br />
      <Form
        // validationSchema={getDepartmentValidationSchema(t)}
        initialValues={getOne_items}
        onSubmit={handleSubmit}
        onValueChange={onValueChange}
      >
        <VStack space={10} mt={5}>
          <HStack flex={1} space={10}>
            <Box flex={1}>
              <Form.Select
                field={"class_vehicle"}
                label={t("form:class_vehicle")}
                options={ClassVechile}
                labelField={"label"}
                valueField={"value"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>

            <Box flex={1}>
              <Form.Select
                field={"vechile_make"}
                label={t("form:vechile_make")}
                options={maker_items}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>

            <Box flex={1}>
              <Form.Select
                field={"car_model_id"}
                label={t("form:vechile_model")}
                options={currentMakerModels}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>

            {/* <Box flex={1}>
              <Form.TextBox field={"car_model_id"} label={t("form:car_model_id")} />
            </Box> */}
          </HStack>
          <HStack flex={1} space={10}>
            <Box flex={1}>
              <Form.TextBox
                field={"vehicle_number"}
                label={t("form:vehicle_number")}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={"engine_number"}
                label={t("form:engine_number")}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={"chasis_number"}
                label={t("form:chasis_number")}
              />
            </Box>
            <Box flex={1}>
              <Form.Select
                field={"colour"}
                label={t("form:colour")}
                options={VehicleColor}
                labelField={"label"}
                valueField={"value"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
          </HStack>

          <HStack flex={4} space={10}>
            <Box flex={1}>
              <Form.Select
                field={"fuel_used"}
                label={t("form:fuel_used")}
                options={FuelUsed}
                labelField={"label"}
                valueField={"value"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
            <Box flex={1}>
              <Form.Select
                field={"battery_type"}
                label={t("form:battery_type")}
                options={BatteryType}
                labelField={"label"}
                valueField={"value"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={"battery_number"}
                label={t("form:battery_number")}
                // options={districts}
              />
            </Box>
          </HStack>
          <HStack flex={1} space={10}>
            <Box flex={1}>
              <Form.TextBox
                field={"fuel_tank_capacity"}
                label={t("form:fuel_tank_capacity")}
              />
            </Box>
            <Box flex={1}>
              <Form.Select
                field={"seating_capacity"}
                label={t("form:seating_capacity")}
                options={SeatingCapacity}
                labelField={"label"}
                valueField={"value"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
            <Box flex={1}>
              <Form.Select
                field={"tyre_maker"}
                label={t("form:tyre_maker")}
                options={tyremaker_items}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox
                field={"tyre_number"}
                label={t("form:tyre_number")}
              />
            </Box>
          </HStack>

          <HStack flex={1} space={10}>
            <Box flex={1}>
              <Form.Select
                field={"wheel_drive"}
                label={t("form:wheel_drive")}
                options={SeatingCapacity}
                labelField={"label"}
                valueField={"value"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
            <Box flex={1}>
              <Form.Select
                field={"agency_id"}
                label={t("form:agency_id")}
                options={agency_items}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>

            <Box flex={1}>
              <Form.Date
                field={`date_of_delivery`}
                label={t("form:date_of_delivery")}
              />
            </Box>
          </HStack>
          <HStack flex={1} space={10}>
            <Box flex={1}>
              <Form.TextBox
                field={"vehicle_price"}
                label={t("form:vehicle_price")}
              />
            </Box>
            <Box flex={1}>
              <Form.TextBox field={"go_number"} label={t("form:go_number")} />
            </Box>
            <Box flex={1}>
              <Form.Select
                field={"additional_accessories"}
                label={t("form:additional_accessories")}
                options={accessories_items}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={true}
              />
            </Box>
          </HStack>

          <HStack flex={1} space={10}>
            <Box flex={1}>
              <Form.Select
                field={"allotments[0].department_id"}
                label={t("form:allotment_name")}
                options={department_items}
                labelField={"name"}
                valueField={"id"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
              />
            </Box>
          </HStack>

          <HStack space={2} justifyContent="flex-end">
            <Form.Button
              isLoading={false}
              width="100px"
              colorScheme="primary"
              // variant="outline"
              height="45px"
              formErrorMessage={t("error:error_message")}
            >
              {id ? t("update") : t("submit")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              colorScheme="danger"
              variant="outline"
              onPress={handleBack}
              formErrorMessage={t("error:error_message")}
            >
              {t("Back")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default VehicleUpdate;
