import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "../../../apollo/api_service";
import { getOTPMutation, verifyOTPQuery } from "./graphql";
import { decryptData, decryptResponse } from "@helpers/crypto";
import { storeItem } from "@helpers/storage";

const initialState = {
  loading: false,
  has_errors: false,
  error: null,
  status: null,
  verifyStatus: null,
};

const slice = createSlice({
  name: "vehiclePassLogin",
  initialState,
  reducers: {
    _vehiclePassLogin: (state) => {
      state.loading = true;
    },
    _vehiclePassLoginSuccess: (state, { payload }) => {
      state.loading = false;
      state.has_errors = false;
      state.error = null;
      state.status = payload.status;
    },
    _vehiclePassLoginFailure: (state, { payload }) => {
      state.loading = false;
      state.has_errors = true;
      state.error = payload.message;
      state.status = payload.status;
    },
    _verifyOTPSuccess: (state, { payload }) => {
      let decrypt_payload = decryptResponse(payload?.data);
      storeItem("token", decrypt_payload?.token);
      storeItem("user", JSON.stringify(decrypt_payload));
      storeItem("name", payload?.name);
      localStorage.setItem("currentLanguage", "en");
      state.authorize = true;
      state.loading = false;
      state.has_errors = false;
      state.error = null;
      state.data = payload.data;
      state.verifyStatus = payload.data ? "Success" : "Failure";
    },
    _verifyOTPFailure: (state, { payload }) => {
      state.loading = false;
      state.has_errors = true;
      state.error = payload.message;
    },
    _verifyOTPreset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
      state.data = null;
      state.verifyStatus=null;
    },
  },
});

const {
  _vehiclePassLogin,
  _vehiclePassLoginSuccess,
  _vehiclePasseLoginFailure,
  _verifyOTPSuccess,
  _verifyOTPFailure,
  _verifyOTPreset,
} = slice.actions;

export const vehiclePassLoginSelector = (state) => state.vehiclePassLogin;

export const vehiclePassLoginReducer = slice.reducer;

export function getOTPRequest(variables) {
  return async (dispatch) => {
    dispatch(_vehiclePassLogin());
    try {
      const response = await MutateRequest(getOTPMutation, variables, dispatch);
      if (response?.data?.getOTP && !response?.data?.getOTP.error) {
        dispatch(_vehiclePassLoginSuccess(response?.data?.getOTP));
      } else if (response?.data?.getOTP?.error) {
        dispatch(_vehiclePasseLoginFailure(response?.data?.getOTP.error));
      }
    } catch (error) {
      dispatch(_vehiclePasseLoginFailure(error));
    }
  };
}

export function verifyOTPRequest(variables) {
  return async (dispatch) => {
    dispatch(_vehiclePassLogin());
    try {
      const response = await MutateRequest(verifyOTPQuery, variables, dispatch);
      if (response?.data?.verifyOTP && !response?.data?.verifyOTP.error) {
        dispatch(_verifyOTPSuccess(response?.data?.verifyOTP));
      } else if (response?.data?.verifyOTP?.error) {
        dispatch(_verifyOTPFailure(response?.data?.verifyOTP.error));
      }
    } catch (error) {
      dispatch(_verifyOTPFailure(error));
    }
  };
}

export function verify_otp_clear(dispatch) {
  return async (dispatch) => {
    dispatch(_verifyOTPreset());
  };
}

