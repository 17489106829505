import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_service_not_given } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "serviceNotGivenVehicles",
  initialState,
  reducers: {
    _get_service_not_given_vehicles: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_service_not_given_vehicles_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_service_not_given_vehicles_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_service_not_given_vehicles,
  _get_service_not_given_vehicles_success,
  _get_service_not_given_vehicles_failure,
} = slice.actions;

export const get_service_not_given_vehicles_list_selector = (state) =>
  state.serviceNotGivenVehicles;

export const getServiceNotGivenVehiclesListReducer = slice.reducer;

export function get_service_not_given_vehicles_list(variables) {
  return async (dispatch) => {
    dispatch(_get_service_not_given_vehicles());
    try {
      const response = await QueryRequest(
        get_service_not_given,
        variables,
        dispatch
      );
      if (
        response?.data?.getNotFuelAndServiceVehicle &&
        !response?.data?.getNotFuelAndServiceVehicle.error
      ) {
        dispatch(
          _get_service_not_given_vehicles_success(
            response?.data?.getNotFuelAndServiceVehicle
          )
        );
      } else if (response?.data?.getNotFuelAndServiceVehicle?.error) {
        dispatch(
          _get_service_not_given_vehicles_failure(
            response?.data?.getNotFuelAndServiceVehicle.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_service_not_given_vehicles_failure(error));
    }
  };
}
