import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Text, Pressable } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineEye,
  AiOutlineRollback,
} from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import { ROUTES } from "@views/routes/my_routes";
import { useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";
import * as XLSX from "xlsx";
import {
  getCondemnationListSelector,
  get_CondemnationList,
  getCondemnationReportClear,
  getCondemnationReportSelector,
  getCondemnationReport,
  getCondemnationListWithoutPaginationSelector,
  get_CondemnationListWithoutPagination,
} from "@services/redux";
import {
  getOneCondemnationSelector,
  get_one_condemnation_clear,
} from "@services/redux";
import {
  deleteCondemnationSelector,
  delete_condemnation,
  delete_one_condemnation_modal,
} from "@services/redux";
import {
  updateCondemnationSelector,
  update_one_condemnation_clear,
} from "@services/redux";
import {
  createCondemnationSelector,
  create_one_condemnation_clear,
} from "@services/redux";
import CondemnationDetails from "./condemnation_details";
import { Button } from "antd";
import { retrieveItem } from "@helpers/storage";
import { colorScheme, excelDownload } from "@helpers/constants";
const CondemnationList = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { vehicle_id } = props;
  const roles = JSON.parse(retrieveItem("roles"));

  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [search_string, setSearchString] = useState("");
  const { t } = useTranslation();
  const { id } = useParams();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading: deleteLoading, status: deleteStatus } = useSelector(
    deleteCondemnationSelector
  );
  const { url: report, loading: report_loading } = useSelector(
    getCondemnationReportSelector
  );
  const { loading: updateLoading, status: updateStatus } = useSelector(
    updateCondemnationSelector
  );
  const { loading: createLoading, status: createStatus } = useSelector(
    createCondemnationSelector
  );

  const { items: condemnation_without_pagination_items } = useSelector(
    getCondemnationListWithoutPaginationSelector
  );

  useEffect(() => {
    if (report) {
      window.open(report);
      dispatch(getCondemnationReportClear());
    }
  }, [report]);

  const handleAdd = () => {
    history.push({
      pathname: `${ROUTES.CONDEMNATION_DETAILS}`,
      state: `${vehicle_id}`,
    });
    dispatch(get_one_condemnation_clear);
    setActionType("add");
    setActionItem({});
    setManageModalVisible(true);
  };

  const handleEdit = (pro) => {
    setActionType("edit");
    history.push({
      pathname: `${ROUTES.CONDEMNATION_DETAILS}/${pro.id}`,
    });
    dispatch(getOneCondemnationSelector({ id: pro.id }));
    setActionItem({
      ...pro,

      id: pro.id,
    });
    setManageModalVisible(true);
  };
  const handleVehicleView = (item) => {
    history.push({
      pathname: `${ROUTES.VEHICLE_TAB_VIEW}/${item.vehicle_id}`,
      state: [item],
    });
  };
  const vehicleDetails = (value) => {
    return (
      <Pressable
        // style={{
        //   textDecoration: "none",
        // }}
        width={"100px"}
        onPress={() => {
          handleVehicleView(value);
        }}
      >
        <Text
          color={"blue.700"}
        >{`${value?.vehicle?.vehicle_number} - ${value?.vehicle?.car_model?.car_make?.name}`}</Text>
      </Pressable>
    );
  };
  useEffect(() => {
    if (deleteStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Vehicle removed from condemnation")}`,
      });
      setDialogVisible(false);
      dispatch(get_CondemnationList());
      handleModalClose();
      dispatch(delete_one_condemnation_modal());
    } else if (updateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Condemnation")} ${t("update_successfully")}`,
      });
      setManageModalVisible(false);
      dispatch(update_one_condemnation_clear);
      dispatch(get_CondemnationList());

      handleModalClose();
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Condemnation")} ${t("create_successfully")}`,
      });
      setManageModalVisible(false);
      dispatch(get_CondemnationList());
      dispatch(create_one_condemnation_clear());
      handleModalClose();
    }
  }, [deleteStatus, updateStatus, createStatus]);

  const handleView = (item) => {
    history.push(`${ROUTES.CONDEMNATION_VIEW}/${item.id}`);
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };
  const handleDialogOpen = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };
  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const { loading, items, total_items } = useSelector(
    getCondemnationListSelector
  );

  const formatDisplay = (date) => {
    if (date && typeof date === "string") {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    } else return "-";
  };
  const formatVehicle = (value) => {
    return (
      <Text>{`${value?.vehicle?.vehicle_number} - ${value?.vehicle?.car_model?.car_make?.name}`}</Text>
    );
  };
  const formatVehicleName = (value) => {
    return <>{` ${value?.vehicle?.car_model?.car_make?.name}`}</>;
  };
  console.log("search_string", search_string);
  const handleReport = () => {
    dispatch(
      getCondemnationReport({
        search_string: search_string,
      })
    );
  };
  let actions = [
    {
      icon: <AiOutlineRollback />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("table:move_back"),
      onPress: handleDialogOpen,
    },
    {
      icon: <AiOutlineEdit />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("table:edit"),
      onPress: handleEdit,
    },
  ];

  let action_1 = [
    {
      icon: <AiOutlineEye />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("View Condemnation"),
      onPress: handleView,
    },
  ];

  let columns = [
    {
      header: t("table:Vehicle"),
      dataIndex: "",
      key: "reason",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: vehicleDetails,
    },

    {
      header: t("table:go_number"),
      dataIndex: "go_number",
      key: "go_number",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:go_date"),
      dataIndex: "date",
      key: "go_date",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatDisplay,
    },
    {
      header: t("table:reason"),
      dataIndex: "reason",
      key: "reason",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "left",
      type: "actions",
      width: 200,
      actions:
        roles?.[0]?.name === "Department" ||
        roles?.[0]?.name === "Public Department"
          ? actions
          : action_1,
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_condemnation"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  useEffect(() => {
    dispatch(
      get_CondemnationListWithoutPagination({ search_string: search_string })
    );
  }, [search_string]);

  const handleXLSXExport = () => {
    const labels = ["Vehicle Number", "Vehicle Name", "Date of Condemnation"];

    const excel_items = condemnation_without_pagination_items?.map(
      (condemnation) => {
        let condemnation_data = {
          [labels[0]]: condemnation?.vehicle?.vehicle_number ?? "-",
          [labels[1]]: condemnation?.vehicle?.car_model?.name ?? "-",
          [labels[2]]: condemnation?.reason ?? "-",
        };
        return condemnation_data;
      }
    );

    excelDownload(excel_items, labels, "Condemnation Report");
  };

  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <Text fontSize="md" fontWeight="bold" py={4}>
          {t("table:condemnation")}
        </Text>

        <VStack space={5}>
          <Wrapper>
            <Button
              // onClick={handleReport}
              onClick={handleXLSXExport}
              type="primary"
              style={{
                width: "228px",
                background: "#683fe9",
                borderColor: "#683fe9",
              }}
              loading={report_loading}
            >
              <Text bold fontSize="15px" color="white">
                Excel Export
              </Text>
            </Button>
            <Table
              width="100%"
              rowKey="id"
              group="condemnation"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              setSearchString={setSearchString}
              fetch={get_CondemnationList}
              query={null}
              headerActions={
                roles?.[0]?.name === "Department" ||
                roles?.[0]?.name === "Public Department"
                  ? header_actions
                  : []
              }
              emptyMessage={t("No Condemnation details Found")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Modal
        isOpen={manageModalVisible}
        onClose={handleModalClose}
        header={
          actionType === "add"
            ? t("table:add_condemnation")
            : t("table:edit_condemnation")
        }
        component={
          <CondemnationDetails
            id={actionItem.id}
            handleModalClose={handleModalClose}
            initialValue={actionItem}
          />
        }
        source={actionItem}
        group={"User"}
      />
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("table:move_back")}
        content={t("table:condemnation_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("move"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_condemnation,
          },
        ]}
      />
    </>
  );
};
export default CondemnationList;
