import * as Yup from "yup";
export function getReturnValidationSchema(t) {
  const validationSchema = Yup.object().shape({
    meter_reading: Yup.string().required(t("error:meter_reading")),
    // additional_accessory_allotments: Yup.string().required(
    //   t("error: additional_accessory_allotments")
    // ),
    // allotment_number: Yup.string().required(t("error:allotment_number")),
    // department_id: Yup.string().required(t("error:department_id")),
    // designation_id: Yup.string().required(t("error:designation_id")),
  });
  return validationSchema;
}
