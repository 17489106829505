import { gql } from "@apollo/client";

export const getNotificationsQuery = gql`
  query getNotifications($page_number: Float, $page_limit: Float) {
    getNotifications(page_number: $page_number, page_limit: $page_limit) {
      items {
        id
        content
        created_at
        service_request_id
        created_by {
          name
        }
        is_read
        service_request {
          id
          created_date_time
          returned_date_time
          sent_to_workshop_date_time
          return_to_department_date_time
          vehicle {
            id
            vehicle_number
            car_model {
              name
              car_make {
                name
              }
            }
          }
        }
        entity_state {
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_notifications_unread_query = gql`
  query getUnreadNotificationsCount {
    getUnreadNotificationsCount {
      count
    }
  }
`;
