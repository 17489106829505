import React, { useEffect, useState } from "react";
import { Box, HStack, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import {
  update_carmake,
  get_one_carmodel,
  getOneCarModelSelector,
  updateCarMakeSelector,
  get_one_carmake,
} from "@services/redux";
import { Form as AntdForm, Button } from "antd";

const CarMakeDetails = (props) => {
  const dispatch = useDispatch();
  const { loading: updateLoading } = useSelector(updateCarMakeSelector);

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id, handleMakeClose, initialValue } = props;
  const { item: getOneCarModel } = useSelector(getOneCarModelSelector);
  useEffect(() => {
    dispatch(get_one_carmodel({ id }));
  }, []);

  const handleSubmit = (values) => {
    if (initialValue.id) {
      dispatch(
        update_carmake({
          id: initialValue?.id,
          data: {
            name: values.name,
          },
        })
      );
    }
  };

  useEffect(() => {
    if (initialValue) {
      setActionItem(initialValue);
    }
  }, [initialValue]);
  const [form] = AntdForm.useForm();

  return (
    <Box flex="1">
      <Form form={form} initialValues={actionItem} onSubmit={handleSubmit}>
        <VStack space={10} mt={2}>
          <HStack space={10}>
            <Box flex={1}>
              <Form.TextBox
                field={"name"}
                label={t("form:name")}
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              />
            </Box>
          </HStack>

          <HStack space={2} justifyContent="flex-end">
            <Form.Button
              isLoading={updateLoading}
              width="100px"
              colorScheme="primary"
              height="45px"
              formErrorMessage={t("error:error_message")}
              label={initialValue.id ? t("update") : t("add")}
            >
              {t("update")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              colorScheme="danger"
              variant="outline"
              onClick={handleMakeClose}
              formErrorMessage={t("error:error_message")}
            >
              {t("common:cancel")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default CarMakeDetails;
