import { Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import { PhoneOutlined } from "@ant-design/icons";
// import Homebanner from "@assets/images/Home_banner.jpg";
import login_pic from "@assets/images/Assembly_1.jpg";
import { Box, Center, Hidden, Image, Stack, Text } from "native-base";
import logo from "@assets/images/logo.png";
import { useTranslation } from "react-i18next";
import VehiclePassLoginForm from "./vehicle_pass_login_form";

const VehiclePassLogin = () => {
  const { t } = useTranslation();
  return (
    <Box
      minH={{
        md: "700px",
      }}
    >
      {/* <Header /> */}
      <Box
        safeAreaTop
        _light={{
          bg: "coolGray.700",
        }}
        _dark={{
          bg: "coolGray.700",
        }}
      />
      <Center
        my="auto"
        padding={"40px"}
        minH={{
          base: "0",
          md: "500px",
        }}
        _dark={{
          bg: "coolGray.900",
        }}
        _light={{
          bgImage: `url(${login_pic})`,

          backgroundRepeat: "no-repeat",

          backgroundSize: "cover",
          opacity: "1",
        }}
        flex="1"
      >
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="85%"
          maxW={{
            md: "900px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <Hidden till="md">
            <Center
              flex="1"
              bg="#141414"
              opacity={"0.8"}
              borderTopLeftRadius={{
                base: "0",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "0",
                md: "xl",
              }}
            >
              <Image
                h="18"
                size="40"
                alt="NativeBase Startup+ "
                resizeMode={"contain"}
                source={logo}
              />
              <Text color="white" fontSize="lg" fontWeight={"600"}>
                {t("PD")}
              </Text>
            </Center>
          </Hidden>
          <Box
            minH={{
              md: "300px",
            }}
            flex={1}
            px="6"
            py="9"
            _light={{
              bg: "white",
            }}
            _dark={{
              bg: "coolGray.800",
            }}
            space="3"
            justifyContent="center"
            borderTopRightRadius={{
              base: "2xl",
              md: "xl",
            }}
            borderTopLeftRadius={{
              base: "2xl",
              md: "0",
            }}
            borderBottomRightRadius={{
              base: "xl",
              md: "xl",
            }}
            borderBottomLeftRadius={{
              base: "xl",
              md: "0",
            }}
          >
            <VehiclePassLoginForm />
          </Box>
        </Stack>
      </Center>
    </Box>
  );
};

export default VehiclePassLogin;
