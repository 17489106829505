import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  HStack,
  Text,
  VStack,
  Modal as NativeModal,
  FormControl,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import { showToast } from "@helpers/toast";
import * as XLSX from "xlsx";
import {
  update_one_allotment_clear,
  getAccessoriesListSelector,
  create_Allotments,
  getCreateAllotmentsListSelector,
  getOneVehiclesListSelector,
  updateAllotmentSelector,
  update_allotment,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { getReturnValidationSchema } from "./returnValidation";
import { Button } from "antd";

const ReturnDetails = (props) => {
  const dispatch = useDispatch();

  const [inputList, setInputList] = useState([{ doc: "" }]);
  const [actionItem, setActionItem] = useState({});
  const [excelItem, setExcelItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);

  const { t } = useTranslation();
  const { id, alloted_id } = useParams();
  const history = useHistory();

  const { initialValue, allotmentsLast, vehicle_id } = props;

  const { items: accessories_items } = useSelector(getAccessoriesListSelector);
  const {
    status: updateStatus,
    loading,
    error: updateError,
  } = useSelector(updateAllotmentSelector);

  const handleSubmit = (values) => {
    setActionItem(values);
    setManageModalVisible(true);
  };
  const handleBack = () => {
    history.goBack();
  };
  React.useEffect(() => {
    if (updateStatus === "Success") {
      showToast({
        type: "success",
        message: `Vehicle is Returned Successfully`,
      });
      handleBack();
      dispatch(update_one_allotment_clear);
    } else if (updateError) {
      showToast({
        type: "warn",
        message: `Something Went wrong`,
      });
      dispatch(update_one_allotment_clear);
    }
  }, [updateStatus, updateError]);
  const { item: getOne_items } = useSelector(getOneVehiclesListSelector);

  useEffect(() => {
    if (initialValue) {
      setActionItem(initialValue);
    }
  }, [initialValue]);

  const handleAddClick = () => {
    setInputList([...inputList], { doc: "" });
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };
  const handleAlot = () => {
    actionItem.vehicle_id = id;

    dispatch(
      update_allotment({
        id: alloted_id,
        data: actionItem,
      })
    );
  };
  return (
    <Box flex="1">
      <Box top="10px" zIndex="2" bg="white">
        <Text fontSize="md" fontWeight="bold" py={4}>
          {t("form:return_Page")}
        </Text>
      </Box>
      <Form
        validationSchema={getReturnValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={5}>
          <HStack flex={1} space={10}>
            <Box flex={1}>
              <Form.TextBox
                field={"meter_reading"}
                label={t("form:meter_reading")}
              />
            </Box>
            <Box flex={1}>
              <Form.Date
                field={"returned_date_time"}
                label={t("Returned Date")}
                options={accessories_items}
              />
            </Box>
          </HStack>

          <HStack space={2} justifyContent="flex-end">
            <Form.Button
              width="100px"
              colorScheme="primary"
              height="45px"
              label={t("submit")}
              formErrorMessage={t("error:error_message")}
            >
              {t("submit")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              colorScheme="primary"
              variant="outline"
              onClick={() => {
                handleBack();
              }}
              formErrorMessage={t("error:error_message")}
            >
              {t("Back")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <NativeModal isOpen={manageModalVisible} onClose={handleModalClose}>
        <NativeModal.Content maxWidth="400px">
          <NativeModal.CloseButton />
          <NativeModal.Header>Return Allotment</NativeModal.Header>
          <NativeModal.Body>
            <FormControl>
              <FormControl.Label>
                {`${t("Are you sure you want return this")} ${""}${
                  getOne_items.vehicle_number
                } ${"Vehicle"} ${t("to the Public Department")}${"?"} `}
              </FormControl.Label>
            </FormControl>
          </NativeModal.Body>
          <NativeModal.Footer>
            <HStack space={5}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onClick={handleModalClose}
              >
                Cancel
              </Button>
              <Button onClick={handleAlot} loading={loading}>
                Yes
              </Button>
            </HStack>
          </NativeModal.Footer>
        </NativeModal.Content>
      </NativeModal>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default ReturnDetails;
