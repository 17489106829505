import React, { useEffect, useState } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import {
  createfuelSelector,
  create_fuel,
  getAll_Vehicles,
  getVehiclesListSelector,
  updatefuelSelector,
  update_fuel,
  getDriverListSelector,
  get_DriverList,
  update_one_fuel_clear,
  get_fuelList,
  create_one_fuel_clear,
  getOnefuelSelector,
  get_one_fuel,
} from "@services/redux";
import { useHistory, useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { FuelUsed } from "@views/components/source/constant";
import { Button } from "antd";
import { Form as AntdForm } from "antd";
import moment from "moment";
import { retrieveItem } from "@helpers/storage";
const FuelDetails = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { match, paramtype, vehicle_id } = props;
  // const id = match?.params?.id;
  const history = useHistory();
  const [form] = AntdForm.useForm();

  const [actionItem, setActionItem] = useState({});
  const [transitShow, setTransitShow] = useState(false);
  const [visitors, setVisitors] = useState(false);
  const [VehicleList, setVehicleList] = useState([]);
  const roles = JSON.parse(retrieveItem("roles"));

  const { t } = useTranslation();
  const { handleModalClose, initialValue } = props;
  const { items: vehicles } = useSelector(getVehiclesListSelector);
  const { items: drivers } = useSelector(getDriverListSelector);

  const {
    loading: createLoading,
    error: createError,
    status: createStatus,
  } = useSelector(createfuelSelector);
  const {
    loading: updateLoading,
    error: updateError,
    status: updateStatus,
  } = useSelector(updatefuelSelector);

  const { item: fuel_details } = useSelector(getOnefuelSelector);

  const handleSubmit = (values) => {
    values.photo = values.photo?.[0]?.url;
    values.vehicle_id = vehicle_id ? vehicle_id : values.vehicle_id;
    if (id) {
      let update_id = id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_fuel({
          id: update_id,
          data: values,
        })
      );
    } else {
      dispatch(
        create_fuel({
          data: values,
        })
      );
    }
  };

  useEffect(() => {
    if (id) dispatch(get_one_fuel({ id }));
  }, [id]);

  useEffect(() => {
    if (fuel_details?.id) {
      let date = new Date(fuel_details?.fuel_refilled_date);
      setActionItem({
        ...fuel_details,

        fuel_refilled_date: moment(date),

        // fuel_refilled_date: moment(
        //   parseInt(fuel_details?.fuel_refilled_date, 10)
        // ),
      });
    } else form.resetFields();
  }, [fuel_details]);
  useEffect(() => {
    let temp = [];
    if (vehicles) {
      temp = vehicles.map((x) => {
        return {
          label: `${x?.car_model?.car_make?.name} - ${x.vehicle_number}`,
          value: x.id,
        };
      });
    }
    setVehicleList(temp);
  }, [vehicles]);

  useEffect(() => {
    if (createError === "Failure") {
      showToast({
        type: "success",
        message: `${t("Something went wrong!")}`,
      });

      dispatch(
        get_fuelList({
          vehicle_id: vehicle_id ? vehicle_id : null,
        })
      );

      dispatch(create_one_fuel_clear());
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Fuel")} ${t("create_successfully")}`,
      });
      history?.goBack();
      dispatch(
        get_fuelList({
          vehicle_id: vehicle_id ? vehicle_id : null,
        })
      );
      dispatch(create_one_fuel_clear());
    } else if (updateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Fuel")} ${t("updated successfully")}`,
      });
      history?.goBack();
      dispatch(
        get_fuelList({
          vehicle_id: vehicle_id ? vehicle_id : null,
        })
      );
      dispatch(update_one_fuel_clear);
    } else if (updateError === "Failure") {
      showToast({
        type: "error",
        message: `Something went wrong!`,
      });
      history?.goBack();
      dispatch(
        get_fuelList({
          vehicle_id: vehicle_id ? vehicle_id : null,
        })
      );
      dispatch(update_one_fuel_clear);
    }
  }, [createError, createStatus, updateError, updateStatus]);

  useEffect(() => {
    dispatch(getAll_Vehicles());
    dispatch(get_DriverList());
  }, []);
  // useEffect(() => {
  //   if (initialValue) {
  //     setActionItem(initialValue);
  //   }
  // }, [initialValue]);

  const handleInputChange = (value) => {
    if (value.types === "transit_pass") {
      setTransitShow(true);
    } else setTransitShow(false);
    if (value.pass_type === "visitors") {
      setVisitors(true);
    } else setVisitors(false);
  };

  return (
    <Box flex="1">
      <Box position="sticky" flex={1} mb="10" mt="4">
        <Text fontWeight={"bold"} fontSize="md">
          {id ? "Edit Fuel" : "Add Fuel"}
        </Text>
      </Box>
      <Form
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={handleInputChange}
      >
        <VStack>
          <HStack space={5}>
            {paramtype !== "vehicle" && (
              <Box flex={1}>
                <Form.Select
                  field={"vehicle_id"}
                  label={t("form:Vehicle")}
                  options={VehicleList}
                  labelField="label"
                  valueField="value"
                  rules={[
                    {
                      required: true,
                      message: "Vehicle is required",
                    },
                  ]}
                />
              </Box>
            )}

            <Box flex={1}>
              <Form.Select
                field={"driver_id"}
                label={t("form:driver_name")}
                options={drivers}
                labelField="name"
                valueField="id"
                isClearable={true}
                rules={[
                  {
                    required: true,
                    message: "Driver Name is required",
                  },
                ]}
              />
            </Box>
          </HStack>
          <HStack space={5}>
            <Box flex={1}>
              <Form.TextBox
                field={"officer_name"}
                label={t("form:officer_minister_name")}
                rules={[
                  {
                    required: false,
                    message: "Meter Reading is required",
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.Number
                field={"meter_reading"}
                label={t("form:kms_covered")}
                rules={[
                  {
                    required: true,
                    message: "Meter Reading is required",
                  },
                ]}
              />
            </Box>
          </HStack>

          <HStack space={5}>
            <Box flex={1}>
              <Form.Number
                field={"quantity"}
                label={t("Quantity of Fuel")}
                rules={[
                  {
                    required: true,
                    message: "Quantity is required",
                  },
                ]}
              />
            </Box>
            <Box flex={1}>
              <Form.Date field={"fuel_refilled_date"} label={t("form:date")} />
            </Box>
          </HStack>
          <HStack space={5}>
            <Box flex={1}>
              <Form.Select
                field={"fuel_type"}
                label={t("form:fuel_type")}
                options={FuelUsed}
                labelField={"label"}
                valueField={"value"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                rules={[
                  {
                    required: true,
                    message: "Fuel Type is required",
                  },
                ]}
              />
            </Box>
            <Box flex={1}></Box>
          </HStack>
          <HStack space={2} justifyContent="flex-end">
            {roles?.[0]?.name === "Admin" ? (
              ""
            ) : (
              <Form.Button
                isLoading={id ? updateLoading : createLoading}
                width="100px"
                BoxorScheme="primary"
                height="45px"
                formErrorMessage={t("error:error_message")}
                label={id ? t("update") : t("add")}
              >
                {actionItem.id ? t("update") : t("add")}
              </Form.Button>
            )}
            <Button
              isLoading={false}
              width="100px"
              BoxorScheme="danger"
              variant="outline"
              onClick={
                paramtype === "vehicle"
                  ? () => {
                      handleModalClose();
                    }
                  : () => {
                      history.goBack();
                    }
              }
              formErrorMessage={t("error:error_message")}
              label={id ? t("update") : t("add")}
            >
              {paramtype === "vehicle" ? t("common:close") : t("common:back")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default FuelDetails;
