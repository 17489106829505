import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Text } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { CgFileRemove } from "react-icons/cg";
import Modal from "@views/components/ui/modal";
import { ROUTES } from "@views/routes/my_routes";
import { useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";

import {
  getDriverListSelector,
  getDriverReport,
  getDriverReportClear,
  getDriverReportSelector,
  get_DriverList,
  update_driver,
} from "@services/redux";
import { getOneDriverSelector, get_one_driver_clear } from "@services/redux";
import {
  deleteDriverSelector,
  delete_driver,
  delete_one_driver_modal,
} from "@services/redux";
import { updateDriverSelector, update_one_driver_clear } from "@services/redux";
import { createDriverSelector, create_one_driver_clear } from "@services/redux";
import { Button, Form, Select } from "antd";
import { BiHistory } from "react-icons/bi";
import { retrieveItem } from "@helpers/storage";
import { startCase } from "lodash";
import {
  get_driver_without_pagination,
  getdriverListWithOutPaginationSelector,
} from "@services/redux/slices/drivers/driver_list_without_pagination";
import { excelDownload } from "@helpers/constants";
const DriverList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { type, department_id } = useParams();
  const [object, set_object] = useState({ status: "active" });
  const [actionItem, setActionItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [retriedVisible, setRetriedVisible] = useState(false);
  const [searchString, setSearchString] = useState(null);
  const [form_data, set_form_data] = useState(null);

  const [statusType, setStatusType] = useState("");
  const [statusTypeValue, setStatusTypeValue] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [statusValue, setStatusValue] = useState("");

  useEffect(() => {
    if (type) {
      const [status, retried] = type.split("-");
      setStatusType(status);
      setStatusTypeValue(retried);
    }
  }, [type]);
  useEffect(() => {
    if (statusType === "type") {
      setTypeValue(statusTypeValue);
    } else if (statusType === "status") {
      setStatusValue(statusTypeValue);
    }
  }, [statusType, statusTypeValue]);

  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useSelector(deleteDriverSelector);
  const {
    loading: update_loading,
    status: update_status,
    error: update_error,
  } = useSelector(updateDriverSelector);
  const { loading, items, total_items } = useSelector(getDriverListSelector);
  const { items: driver_details } = useSelector(
    getdriverListWithOutPaginationSelector
  );

  const handleAdd = () => {
    history.push({
      pathname: `${ROUTES.DRIVER_DETAILS}`,
    });
    dispatch(get_one_driver_clear);
    setActionItem({});
    setManageModalVisible(true);
  };

  const handleEdit = (pro) => {
    history.push({
      pathname: `${ROUTES.DRIVER_DETAILS}/${pro.id}`,
    });
    dispatch(getOneDriverSelector({ id: pro.id }));
    setActionItem({
      ...pro,

      id: pro.id,
    });
    setManageModalVisible(true);
  };
  const handleView = (item) => {
    history.push({
      pathname: `${ROUTES.DRIVER_VIEW}/${item.id}`,
    });
  };
  const handleHistory = (item) => {
    history.push({
      pathname: `${ROUTES.DRIVER_HISTORY}/${item.id}`,
    });
  };
  useEffect(() => {
    if (deleteStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Driver")} ${t("Deleted_successfully")}`,
      });
      setDialogVisible(false);
      dispatch(get_DriverList());

      dispatch(delete_one_driver_modal());
    } else if (deleteError === "Failure") {
      showToast({
        type: "success",
        message: `${t("Driver")} ${t("Deleted_successfully")}`,
      });
      setDialogVisible(false);
      dispatch(get_DriverList());

      dispatch(delete_one_driver_modal());
    }
  }, [deleteStatus, deleteError]);

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
    setRetriedVisible(false);
  };
  const handle_retried_driver = () => {
    dispatch(
      update_driver({ id: actionItem?.id, data: { status: "retried" } })
    );
  };

  const { url: report, loading: report_loading } = useSelector(
    getDriverReportSelector
  );
  useEffect(() => {
    if (report) {
      window.open(report);
      dispatch(getDriverReportClear());
    }
  }, [report]);

  const roles = JSON.parse(retrieveItem("roles"));
  let rejected_status = "";
  const handle_reject = (get_one) => {
    setActionItem(get_one);
    setRetriedVisible(true);
    console.log("get_one", get_one);
    rejected_status = "success";
  };

  useEffect(() => {
    if (update_status === "Success") {
      showToast({
        type: "success",
        message: `${t("Driver")} ${t("retried successfully")}`,
      });
      setRetriedVisible(false);
      dispatch(update_one_driver_clear);
      dispatch(get_DriverList({ page_number: 1, page_limit: 10 }));
    } else if (update_error) {
      showToast({
        type: "error",
        message: `Something want wrong`,
      });
      dispatch(update_one_driver_clear());
    }
  }, [update_status, update_error]);

  let actions = [
    {
      icon: <AiOutlineEdit />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("table:edit_driver"),
      onPress: handleEdit,
    },
    {
      icon: <BiHistory />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("table:history"),
      onPress: handleHistory,
    },
    {
      icon: <AiOutlineDelete />,
      iconSize: 30,
      iconColor: "red.700",
      title: t("table:delete_driver"),
      onPress: handleDelete,
    },
  ];
  let action_public_department = [
    {
      icon: <AiOutlineEye />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("View Driver Details"),
      onPress: handleView,
    },
    {
      icon: <BiHistory />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("table:history"),
      onPress: handleHistory,
    },
  ];

  if (object.status === "active")
    actions.push({
      icon: <CgFileRemove />,
      iconSize: 30,
      iconColor: "red.700",
      title: t("table:retried_driver"),
      onPress: handle_reject,
    });

  const formatAddress = (value) => {
    return (
      <VStack>
        <Box>{value?.address?.line1}</Box>
        <Box>{value?.address?.line2}</Box>
        <Box>{value?.address?.district?.name}</Box>
        <Box>{value?.address?.pincode}</Box>
      </VStack>
    );
  };

  const handle_status = (record) => {
    console.log("record", record);
    return (
      <Box
        background={record?.status === "active" ? "green.400" : "red.500"}
        borderRadius={"20px"}
        width={"100px"}
        textAlign={"center"}
      >
        <Text color={"white"}>{startCase(record?.status)}</Text>
      </Box>
    );
  };

  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      width: 220,
    },
    {
      header: t("table:mobile_number"),
      dataIndex: "mobile",
      key: "mobile",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:address"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatAddress,
    },
    {
      header: t("table:allocated_department"),
      dataIndex: "alloted_department.name",
      key: "alloted_department.name",
      sortable: false,
      align: "left",
      width: 220,
    },
    {
      header: t("table:allocated_designation"),
      dataIndex: "alloted_designation.name",
      key: "alloted_designation.name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:status"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: handle_status,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "left",
      type: "actions",
      width: 150,
      actions:
        roles?.[0]?.name === "Admin" ? actions : action_public_department,
    },
  ];
  const XLSX_download = () => {
    const labels = [
      "Name",
      "Mobile Number",
      "Address",
      "Alloted Department",
      "Alloted Designation",
    ];

    const excel_items = driver_details?.map((driver) => {
      let drivers_data = {
        ["Name"]: driver?.name ?? "-",
        ["Mobile Number"]: driver?.mobile ?? "-",
        ["Address"]:
          `${driver?.address?.line1 ?? ""}  ${driver?.address?.line2 ?? ""},${
            driver?.address?.district?.name ?? ""
          }, ${driver?.address?.pincode ?? ""} ` ?? "-",
        ["Alloted Department"]: driver?.alloted_department?.name ?? "-",
        ["Alloted Designation"]: driver?.alloted_designation?.name ?? "-",
      };
      return drivers_data;
    });

    excelDownload(excel_items, labels, "Driver Report");
  };

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_driver"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];
  let driver_status_option = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Retried",
      value: "retried",
    },
  ];

  let driver_type = [
    {
      label: "Motor cycle messengers",
      value: "motor_cycle_massagers",
    },
    {
      label: "Car Drivers",
      value: "car_drivers",
    },
  ];

  let drivers_type = [
    {
      label: "Regular",
      value: "regular",
    },
    {
      label: "Political",
      value: "political",
    },
    {
      label: "TEXCO",
      value: "TEXCO",
    },
    {
      label: "Contract",
      value: "contract",
    },
  ];

  let handle_form_change = (value, values) => {
    set_form_data(values);
  };

  // useEffect(() => {
  //   form.setFieldsValue({
  //     status: "active",
  //   });
  // }, []);

  useEffect(() => {
    set_object({
      status: form_data?.status || statusValue,
      driver_type: form_data?.type || typeValue,
      search_string: searchString,
    });
  }, [form_data, searchString, statusValue, typeValue]);
  useEffect(() => {
    dispatch(
      get_driver_without_pagination({
        ...form_data,
        search_string: searchString,
      })
    );
  }, [form_data, searchString]);

  useEffect(() => {
    form.setFieldsValue({
      driver_type: typeValue,
      status: statusValue,
    });
  }, [typeValue, statusValue, form]);

  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <Box>
          <Text fontSize="md" fontWeight="bold" py={4}>
            {t("table:driver")}
          </Text>
        </Box>
        <HStack alignItems={"center"} space={"10px"}>
          {roles?.[0]?.name === "Admin" ? (
            <Button
              onClick={XLSX_download}
              type="primary"
              style={{
                width: "228px",
                background: "#683fe9",
                borderColor: "#683fe9",
                marginTop: "3px",
              }}
              loading={report_loading}
            >
              <Text bold fontSize="15px" color="white">
                Excel Export
              </Text>
            </Button>
          ) : (
            ""
          )}
          <Form
            layout="vertical"
            onValuesChange={handle_form_change}
            form={form}
          >
            <HStack space={"20px"}>
              <Box width={"250px"}>
                <Form.Item name={"status"} label="Status">
                  <Select
                    options={driver_status_option}
                    allowClear
                    disabled={statusValue}
                  />
                </Form.Item>
              </Box>
              <Box width={"250px"}>
                <Form.Item name={"type"} label="Type">
                  <Select options={driver_type} />
                </Form.Item>
              </Box>
              <Box width={"250px"}>
                <Form.Item name={"driver_type"} label="Driver Type">
                  <Select
                    options={drivers_type}
                    allowClear
                    disabled={typeValue}
                  />
                </Form.Item>
              </Box>
            </HStack>
          </Form>
        </HStack>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="DriverList"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              setSearchString={setSearchString}
              totalItems={total_items}
              fetch={get_DriverList}
              query={object}
              headerActions={
                roles?.[0]?.name === "Admin" ? header_actions : null
              }
              emptyMessage={t("No Driver details Found")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>

      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("table:delete_driver")}
        content={t("table:delete_driver_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_driver,
          },
        ]}
      />
      <Dialog
        isOpen={retriedVisible}
        onClose={handleDialogClose}
        header={t("table:retried_driver")}
        content={t("table:retried_driver_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("confirm"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: update_loading,
            formErrorMessage: t("error:error_message"),
            submit: handle_retried_driver,
          },
        ]}
      />
    </>
  );
};
export default DriverList;
