export default function unicConvertes(key) {
  let unix_timestamp = key;

  // Create a new JavaScript Date object based on the timestamp

  // multiplied by 1000 so that the argument is in milliseconds, not seconds.

  var date = new Date(parseInt(unix_timestamp));

  // Hours part from the timestamp

  var hours = date.getHours();

  // Minutes part from the timestamp

  var minutes = "0" + date.getMinutes();

  // Seconds part from the timestamp

  var seconds = "0" + date.getSeconds();

  // Will display time in 10:30:23 format

  var formattedTime = date.toLocaleDateString("en-IN");

  var dat = formattedTime.split("/");

  var finaldate = dat[0] + "-" + dat[1] + "-" + dat[2];

  return finaldate;
}
