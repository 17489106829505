import { Text } from "native-base";
import { useTranslation } from "react-i18next";
import { retrieveItem } from "./storage";

export const getNotificationCustomMessage = (t, data, user_id) => {
  const roles = JSON.parse(retrieveItem("roles"));

  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };
  if (
    roles?.[0]?.name === "Admin" ||
    roles?.[0]?.name === "Secretary" ||
    roles?.[0]?.name === "Deputy Secretary" ||
    roles?.[0]?.name === "Under Secretary"
  ) {
    if (data.entity_state?.name === "Department Requested") {
      return `Service Request has been ${data.entity_state?.name} by ${
        data.created_by?.name
      } for ${data.service_request?.vehicle?.vehicle_number} - ${
        data.service_request?.vehicle?.car_model?.name
      } - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      } on ${formatDisplay(data.created_at)}`;
      // else if(data.entity_state?.name === "Requested" && data.created_by ==="Public Department"){
      //   return `Service Request has been ${data.entity_state?.name} for ${data.service_request?.vehicle?.vehicle_number} - ${data.service_request?.vehicle?.car_model?.name} - ${data.service_request?.vehicle?.car_model?.car_make?.name}`
      // }
    } else if (data.entity_state?.name === "Confirmed by Technician") {
      return `Service Request has been Confirmed by Technician for ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }  on ${formatDisplay(data.created_at)}`;
    } else if (data.entity_state?.name === "Department Confirmed") {
      return `Order Copy Uploaded by ${data.created_by?.name} ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }  on ${formatDisplay(data.created_at)}`;
    } else if (data.entity_state?.name === "Inspection") {
      return `Inspection started by Technician for    ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }  on ${formatDisplay(data.created_at)} `;
    } else if (data.entity_state?.name === "In-Service") {
      return `Vehicle sent to workshop by Technician on   on ${formatDisplay(
        data.created_at
      )} for  ${data.service_request?.vehicle?.vehicle_number} - ${
        data.service_request?.vehicle?.car_model?.name
      } - ${data.service_request?.vehicle?.car_model?.car_make?.name}`;
    } else if (data.entity_state?.name === "Service Completed") {
      return `Service completed  on ${formatDisplay(data.created_at)}  for   ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }`;
    }
  }

  if (roles?.[0]?.name === "Department") {
    if (data.entity_state?.name === "Department Requested") {
      return `Service Request has been  ${data.entity_state?.name} for ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }  on  ${formatDisplay(data.created_at)}`;
    } else if (data.entity_state?.name === "Confirmed by Technician") {
      return `Service Request has been Confirmed by Technician for ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }  on ${formatDisplay(data.created_at)}`;
    } else if (data.entity_state?.name === "Department Confirmed") {
      return `Order Copy Uploaded ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }  on ${formatDisplay(data.created_at)}`;
    } else if (data.entity_state?.name === "Inspection") {
      return `Inspection started by Technician for  ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }  on ${formatDisplay(data.created_at)}`;
    } else if (data.entity_state?.name === "In-Service") {
      return `Vehicle sent to workshop by Technician  on ${formatDisplay(
        data.created_at
      )} for ${data.service_request?.vehicle?.vehicle_number} - ${
        data.service_request?.vehicle?.car_model?.name
      } - ${data.service_request?.vehicle?.car_model?.car_make?.name}`;
    } else if (data.entity_state?.name === "Service Completed") {
      return `Service completed    on ${formatDisplay(data.created_at)} for  ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }`;
    }
  }
  if (roles?.[0]?.name === "Technician") {
    if (data.entity_state?.name === "Department Requested") {
      return `Service Request received for  ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      } on  ${formatDisplay(data.created_at)}`;
    } else if (data.entity_state?.name === "Confirmed by Technician") {
      return `Service Request has been Confirmed by you for ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }  on ${formatDisplay(data.created_at)}`;
    } else if (data.entity_state?.name === "Department Confirmed") {
      return `Order Copy Uploaded by department ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }  on ${formatDisplay(data.created_at)}`;
    } else if (data.entity_state?.name === "Inspection") {
      return `Inspection started by you for  ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }  on ${formatDisplay(data.created_at)}`;
    } else if (data.entity_state?.name === "In-Service") {
      return `Vehicle sent to workshop by you on ${formatDisplay(
        data?.service_request?.sent_to_workshop_date_time
      )}  ${data.service_request?.vehicle?.vehicle_number} - ${
        data.service_request?.vehicle?.car_model?.name
      } - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }  on ${formatDisplay(data.created_at)}`;
    } else if (data.entity_state?.name === "Service Completed") {
      return `Service completed on ${formatDisplay(data.created_at)} for  ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }`;
    }
  }
  if (roles?.[0]?.name === "Public Department") {
    if (data.entity_state?.name === "Department Requested") {
      return `Service Request has been  ${data.entity_state?.name} for ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }  on  ${formatDisplay(data.created_at)}`;
    } else if (data.entity_state?.name === "Confirmed by Technician") {
      return `Service Request has been Confirmed by Technician for ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }  on ${formatDisplay(data.created_at)}`;
    } else if (data.entity_state?.name === "Department Confirmed") {
      return `Order Copy Uploaded ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }  on ${formatDisplay(data.created_at)}`;
    } else if (data.entity_state?.name === "Inspection") {
      return `Inspection started by Technician for  ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }   on ${formatDisplay(data.created_at)}`;
    } else if (data.entity_state?.name === "In-Service") {
      return `Vehicle sent to workshop by you  o on ${formatDisplay(
        data.created_at
      )} for ${data.service_request?.vehicle?.vehicle_number} - ${
        data.service_request?.vehicle?.car_model?.name
      } - ${data.service_request?.vehicle?.car_model?.car_make?.name}`;
    } else if (data.entity_state?.name === "Service Completed") {
      return `Service completed  on ${formatDisplay(data.created_at)} for  ${
        data.service_request?.vehicle?.vehicle_number
      } - ${data.service_request?.vehicle?.car_model?.name} - ${
        data.service_request?.vehicle?.car_model?.car_make?.name
      }`;
    }
  } else {
    return `No Notifications.`;
  }
};
