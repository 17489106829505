import { gql } from "@apollo/client";

export const getDrivers = gql`
  query getDrivers(
    $type: String
    $status: String
    $page_number: Float
    $search_string: String
    $page_limit: Float
  ) {
    getDrivers(
      type: $type
      status: $status
      page_number: $page_number
      search_string: $search_string
      page_limit: $page_limit
    ) {
      items {
        id
        name
        mobile
        aadhar
        photo
        alloted_department {
          id
          name
        }
        alloted_designation {
          id
          name
        }
        address_id
        status
        address {
          id
          line1
          line2
          pincode
          district {
            name
          }
          district_id
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const createDriverMutation = gql`
  mutation createDriver($data: driver_input) {
    createDriver(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updateDriverMutation = gql`
  mutation updateDriver($id: String!, $data: driver_input) {
    updateDriver(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const getOneDriverQuery = gql`
  query getDriver($id: String!) {
    getDriver(id: $id) {
      id
      name
      mobile
      aadhar
      photo
      address_id
      go_file
      id_proof
      driver_classification
      date_of_birth
      date_of_retirement
      alloted_department_id
      alloted_designation_id
      allotment_date
      is_motor_cycle_messager
      address {
        id
        line1
        line2
        pincode
        district_id
      }
    }
  }
`;

export const deleteDriverMutation = gql`
  mutation deleteDriver($id: String!) {
    deleteDriver(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const getDriverHistoryQuery = gql`
  query getDriverAllotmentLogs($driver_id: String!) {
    getDriverAllotmentLogs(driver_id: $driver_id) {
      items {
        id
        driver_id
        alloted_department_id
        alloted_designation_id
        allotment_date
        created_at
        driver {
          name
        }
        department {
          id
          name
        }
        designation {
          id
          name
        }
      }
    }
  }
`;

export const get_drivers_without_pagination = gql`
  query getDriver {
    getDrivers {
      items {
        id
        name
        mobile
        aadhar
        photo
        alloted_department {
          id
          name
        }
        alloted_designation {
          id
          name
        }
        address_id
        status
        address {
          id
          line1
          line2
          pincode
          district {
            name
          }
          district_id
        }
      }
    }
  }
`;
