import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getServiceRequestQuery } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "serviceRequestOne",
  initialState,
  reducers: {
    _get_one_service_request: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },

    _get_one_service_request_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_service_request_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const {
  _get_one_service_request,
  _get_one_service_request_success,
  _get_one_service_request_failure,
} = slice.actions;

export const getOneServiceRequestSelector = (state) => state.serviceRequestOne;

export const getOneServiceRequestReducer = slice.reducer;

export function get_one_service_request(variables) {
  return async (dispatch) => {
    dispatch(_get_one_service_request());
    try {
      const response = await QueryRequest(
        getServiceRequestQuery,
        variables,
        dispatch
      );
      if (response?.data?.getVehicle && !response?.data?.getVehicle.error) {
        dispatch(_get_one_service_request_success(response?.data?.getVehicle));
      } else if (response?.data?.getVehicle?.error) {
        dispatch(
          _get_one_service_request_failure(response?.data?.getVehicle.error)
        );
      }
    } catch (error) {
      dispatch(_get_one_service_request_failure(error));
    }
  };
}
// export async function get_one_reason_clear(dispatch) {
//   dispatch(_get_one_reason());
// }
