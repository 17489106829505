import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { deletefuelMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "fuelDelete",
  initialState,
  reducers: {
    _delete_fuel: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_fuel_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_fuel_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const { _delete_fuel, _delete_fuel_success, _delete_fuel_failure } =
  slice.actions;

export const deletefuelSelector = (state) => state.fuelDelete;

export const deletefuelReducer = slice.reducer;

export function delete_fuel(variables) {
  return async (dispatch) => {
    dispatch(_delete_fuel());
    try {
      const response = await MutateRequest(
        deletefuelMutation,
        variables,
        dispatch
      );
      if (response?.data?.deleteFuel && !response?.data?.deleteFuel.error) {
        dispatch(_delete_fuel_success(response?.data?.deleteFuel));
      } else if (response?.data?.deleteFuel?.error) {
        dispatch(_delete_fuel_failure(response?.data?.deleteFuel.error));
      }
    } catch (error) {
      dispatch(_delete_fuel_failure(error));
    }
  };
}
export function delete_one_fuel_modal(dispatch) {
  return async (dispatch) => {
    dispatch(_delete_fuel());
  };
}
