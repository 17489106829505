import { combineReducers } from "redux";
import { formsReducer } from "./default_slices/forms";
import { listQueriesReducer } from "./default_slices/list_queries";
import { accordionsReducer } from "./default_slices/accordions";
import { dashboardReducers } from "./slices/dashboard";
import { loginReducers } from "./slices/login";
import { vehiclePassLoginReducers } from "./slices/vehicle_pass_login";
import { serviceReducers } from "./slices/service";
import { getMakerListReducers } from "./slices/car_make";
import { getModelListReducers } from "./slices/car_model";
import { getVehiclesListReducers } from "./slices/vechile";
import { getAgencyListReducers } from "./slices/agency";
import { getAccessoriesListReducers } from "./slices/additional_accessories";
import { getDepartmentListReducers } from "./slices/department";
import { getCreateAllotmentsListReducers } from "./slices/allotment";
import { getEntityListReducers } from "./slices/entity";
import { designationReducers } from "./slices/designation";
import { getServicesReducers } from "./slices/service_question";
import { getTyreListReducers } from "./slices/tyre make";
import { entityStateReducers } from "./slices/entity_state";
import { getGatePassListReducers } from "./slices/gate_pass";
import { getCondemnationListReducers } from "./slices/condemnation";
import { getFuelListReducers } from "./slices/fuel";
import { getDriverListReducers } from "./slices/drivers";
import { districtReducer } from "./slices/districts";
import { notificationReducer } from "./slices/notifications";
import { reportReducers } from "./slices/reports";
import { captchaReducers, condemnationVehicleReducer, createApplyPassReducers } from "./slices";
//INDEX

export const rootReducer = combineReducers({
  ...dashboardReducers,
  ...formsReducer,
  ...listQueriesReducer,
  ...accordionsReducer,
  ...loginReducers,
  ...vehiclePassLoginReducers,
  ...designationReducers,
  ...serviceReducers,
  ...getMakerListReducers,
  ...getEntityListReducers,
  ...getModelListReducers,
  ...getServicesReducers,
  ...getVehiclesListReducers,
  ...getAgencyListReducers,
  ...getAccessoriesListReducers,
  ...getTyreListReducers,
  ...getDepartmentListReducers,
  ...getCreateAllotmentsListReducers,
  ...entityStateReducers,
  ...getGatePassListReducers,
  ...getCondemnationListReducers,
  ...getFuelListReducers,
  ...getDriverListReducers,
  ...districtReducer,
  ...notificationReducer,
  ...reportReducers,
  ...condemnationVehicleReducer,
  ...captchaReducers,
  ...createApplyPassReducers,
  //INDEX_VALUE
});
