import { gql } from "@apollo/client";

export const getTyreMakers = gql`
  query getTyreMakes(
    $page_number: Float
    $page_limit: Float
    $search_string: String
  ) {
    getTyreMakes(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        name
        error {
          status_code
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const createTyreMakeMutation = gql`
  mutation createTyreMake($data: tyre_make_input) {
    createTyreMake(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const deletetyreMakeMutation = gql`
  mutation deleteTyreMake($id: String!) {
    deleteTyreMake(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const updatetyreMakeMutation = gql`
  mutation updateTyreMake($data: tyre_make_input, $id: String!) {
    updateTyreMake(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const gettyreMakeQuery = gql`
  query getTyreMake($id: String!) {
    getTyreMake(id: $id) {
      id
      name
      error {
        status_code
        message
      }
    }
  }
`;
