import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { driver_dashboard_count_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: {},
};

const slice = createSlice({
  name: "driver_dashboard_count",
  initialState,
  reducers: {
    _driver_dashboard_count: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _driver_dashboard_count_success: (state, { payload }) => {
      state.loading = false;
      console.log("driver_count",payload)
      state.item = payload;
      state.error = null;
    },
    _driver_dashboard_count_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const {
    _driver_dashboard_count,
    _driver_dashboard_count_success,
    _driver_dashboard_count_failure,
} = slice.actions;

export const driver_dashboard_count_Selector = (state) =>
  state.driver_dashboard_count;

export const driver_dashboard_count_Reducer = slice.reducer;

export function driver_dashboard(variables) {
  return async (dispatch) => {
    dispatch(_driver_dashboard_count());
    try {
      const response = await QueryRequest(
        driver_dashboard_count_query,
        variables,
        dispatch
      );
      if (
        response?.data?.driverDashboard &&
        !response?.data?.driverDashboard.error
      ) {
        dispatch(
            _driver_dashboard_count_success(
            response?.data?.driverDashboard
          )
        );
      } else if (response?.data?.driverDashboard?.error) {
        dispatch(
            _driver_dashboard_count_failure(
            response?.data?.driverDashboard.error
          )
        );
      }
    } catch (error) {
      dispatch( _driver_dashboard_count_failure(error));
    }
  };
}
