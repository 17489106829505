import { createDepartmentReducer } from "./department_create";
import { deleteDepartmentReducer } from "./department_delete";
import { getDepartmentListReducer } from "./department_list";
import { getOneDepartmentReducer } from "./department_one";
import { updateDepartmentReducer } from "./department_update";
export const getDepartmentListReducers = {
  DEPARTMENT_LIST: getDepartmentListReducer,
  departmentCreate: createDepartmentReducer,
  departmentGetOne: getOneDepartmentReducer,
  departmentUpdate: updateDepartmentReducer,
  departmentDelete: deleteDepartmentReducer,
};
export * from "./department_list";
