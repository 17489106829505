import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { deleteDepartmentMutation, deletetyreMakeMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "tyreDelete",
  initialState,
  reducers: {
    _delete_tyre: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_tyre_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_tyre_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const { _delete_tyre, _delete_tyre_success, _delete_tyre_failure } =
  slice.actions;

export const deletetyreSelector = (state) => state.tyreDelete;

export const deleteTyreReducer = slice.reducer;

export function delete_tyre(variables) {
  return async (dispatch) => {
    dispatch(_delete_tyre());
    try {
      const response = await MutateRequest(
        deletetyreMakeMutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteTyreMake &&
        !response?.data?.deleteTyreMake.error
      ) {
        dispatch(_delete_tyre_success(response?.data?.deleteTyreMake));
      } else if (response?.data?.deleteTyreMake?.error) {
        dispatch(_delete_tyre_failure(response?.data?.deleteTyreMake.error));
      }
    } catch (error) {
      dispatch(_delete_tyre_failure(error));
    }
  };
}
export function delete_one_tyre_modal(dispatch) {
  return async (dispatch) => {
    dispatch(_delete_tyre());
  };
}
