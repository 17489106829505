import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text, Pressable } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Dialog from "@views/components/ui/dialog";

import {
  get_validity_expired_vehicles_list,
  get_validity_expired_vehicles_list_selector,
  create_condemnation,
  create_one_condemnation_clear,
  createCondemnationSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { FcCancel } from "react-icons/fc";
import { useState } from "react";
import { retrieveItem } from "@helpers/storage";

const ValidityExpiredVehicles = () => {
  const history = useHistory();
  const roles = JSON.parse(retrieveItem("roles"));
  const [showModal, setShowModal] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, items, total_items } = useSelector(
    get_validity_expired_vehicles_list_selector
  );
  const { loading: create_condemnation_loading } = useSelector(
    createCondemnationSelector
  );

  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.VEHICLE_TAB}/${item.id}/vehicle`,
      state: [item],
    });
  };
  const handleView = (item) => {
    history.push({
      pathname: `${ROUTES.VEHICLE_TAB_VIEW}/${item.id}`,
      state: [item],
    });
  };

  const handleCondemnationModalOpen = (values) => {
    setActionItem(values);
    setShowModal(true);
  };
  const handleCondemnationClose = () => {
    setShowModal(false);
  };
  const formatVehicleDetails = (value) => {
    return (
      <Pressable
        onPress={() => {
          roles?.[0]?.name === "Department" ||
          roles?.[0]?.name === "Public Department"
            ? handleEdit(value)
            : handleView(value);
        }}
      >
        {value?.vehicle_number} -{" "}
        {value.car_model?.car_make?.name + " - " + value.car_model?.name}
      </Pressable>
    );
  };
  const handleCondemnation = (value) => {
    dispatch(
      create_condemnation({
        data: {
          vehicle_id: actionItem.id
            ? actionItem.id
            : actionItem.vehicle?.vehicle_id,
        },
      })
    );
  };
  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };
  let columns = [
    {
      header: t("table:vehicle_details"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatVehicleDetails,
    },
    {
      header: t("table:year_of_purchase"),
      dataIndex: "date_of_delivery",
      key: "date_of_delivery",
      sortable: false,
      align: "left",
      width: 250,
      formatDisplay: formatDisplay,
    },
    {
      header: t("table:Condemnation"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "left",
      type: "actions",
      width: 150,
      actions: [
        {
          icon: <FcCancel />,
          iconSize: 30,
          iconColor: "primary.700",
          title: t("Move to condemnation"),
          onPress: handleCondemnationModalOpen,
        },
      ],
    },
  ];

  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="validity_expired"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_validity_expired_vehicles_list}
              query={null}
              headerActions={[]}
              emptyMessage={t("No vehicles data found")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Dialog
        isOpen={showModal}
        onClose={handleCondemnationClose}
        header={t("Condemnation")}
        content={t(
          `Are you sure you want to move ${
            actionItem?.vehicle?.vehicle_number
              ? actionItem?.vehicle?.vehicle_number
              : actionItem?.vehicle_number
          } vehicle to condemnation?`
        )}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleCondemnationClose,
          },
          {
            height: "40px",
            width: "120px",
            label: t("Condemnation"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: create_condemnation_loading,
            formErrorMessage: t("error:error_message"),
            submit: handleCondemnation,
          },
        ]}
      />
    </>
  );
};
export default ValidityExpiredVehicles;
