import { gql } from "@apollo/client";

export const getAllEntityState = gql`
  query getAllEntityState($entity_id: String!) {
    getAllEntityStates(entity_id: $entity_id) {
      items {
        id
        name
      }
    }
  }
`;
