import { gql } from "@apollo/client";

export const createAllotment = gql`
  mutation createAllotment($data: allotment_input) {
    createAllotment(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updateAllotmentMutation = gql`
  mutation updateAllotment($id: String!, $data: allotment_input) {
    updateAllotment(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
