import { getMeterReadingReachedVehiclesListReducer } from "./reached_meter_reading";
import { getServiceNotGivenVehiclesListReducer } from "./service_not_given_vehicles";
import { getValidityExpiredVehiclesListReducer } from "./validity_expired_vehicles";

export const condemnationVehicleReducer = {
  meterReadingReachedVehiclesList: getMeterReadingReachedVehiclesListReducer,
  validityExpiredVehiclesList: getValidityExpiredVehiclesListReducer,
  serviceNotGivenVehicles: getServiceNotGivenVehiclesListReducer,
};

export * from "./reached_meter_reading";
export * from "./validity_expired_vehicles";
export * from "./service_not_given_vehicles";
