import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_notifications_unread_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "unReadCount",
  initialState,
  reducers: {
    _get_notifications_unread: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_notifications_unread_success: (state, { payload }) => {
      state.loading = false;

      state.items = payload.count;
      state.error = null;
    },
    _get_notifications_unread_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_notifications_unread,
  _get_notifications_unread_success,
  _get_notifications_unread_failure,
} = slice.actions;

export const getUnReadNotificationListSelector = (state) => state.unReadCount;

export const getUnReadNotificationListReducer = slice.reducer;

export function getUnReadNotificationList(variables) {
  return async (dispatch) => {
    dispatch(_get_notifications_unread());
    try {
      const response = await QueryRequest(
        get_notifications_unread_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getUnreadNotificationsCount &&
        !response?.data?.getUnreadNotificationsCount.error
      ) {
        dispatch(
          _get_notifications_unread_success(
            response?.data?.getUnreadNotificationsCount
          )
        );
      } else if (response?.data?.getUnreadNotificationsCount?.error) {
        dispatch(
          _get_notifications_unread_failure(
            response?.data?.getUnreadNotificationsCount.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_notifications_unread_failure(error));
    }
  };
}
