import React, { useEffect } from "react";
import TabView from "@views/components/ui/tab_view";

import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button, Card, HStack, Text, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import {
  createVehicleSelector,
  getUpdateVehiclesListSelector,
} from "@services/redux";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { ROUTES } from "@views/routes/my_routes";
import VehicleAllotment from "./vehicle_allotment";
import ReturnDetails from "./vehicle_return_details";
import VehicleUpdate from "./vehicle_edit";
import VehicleDetails from "./vehicle_details";
import CondemnationDetails from "../condemnation/condemnation_details";
import ServiceHistory from "../service/service_history";
import FuelList from "../fuel/fuel_list";
import VechilceAllotment from "./vehicle_allotment";

const VehicleTab = (props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [index, setIndex] = React.useState(0);
  const { id: vehicle_id } = useSelector(createVehicleSelector);

  const { id: update_vehicle_id } = useSelector(getUpdateVehiclesListSelector);
  useEffect(() => {
    if (vehicle_id) setIndex(1);
    else if (update_vehicle_id) setIndex(1);
  }, [vehicle_id, update_vehicle_id]);
  const changeIndex = (idx) => {
    setIndex(idx);
  };

  let tabs = [
    {
      key: "tab1",
      title: "General",
      component: <VehicleDetails vehicle_id={id ? id : vehicle_id} />,
    },

    {
      key: "tab3",
      title: "Vehicle Allotment",
      component: <VechilceAllotment vehicle_id={id ? id : vehicle_id} />,
      isEnabled: id || vehicle_id ? true : false,
    },
    {
      key: "tab2",
      title: "Service History",
      component: <ServiceHistory vehicle_id={id ? id : vehicle_id} />,
      isEnabled: id || vehicle_id ? true : false,
    },
    // {
    //   key: "tab4",
    //   title: "Fuel",
    //   component: <FuelList vehicle_id={id ? id : vehicle_id} />,
    //   isEnabled: id || vehicle_id ? true : false,
    // },
    // {
    //   key: "tab5",
    //   title: "Condemnation",
    //   component: <CondemnationDetails vehicle_id={id ? id : vehicle_id} />,
    //   isEnabled: id || vehicle_id ? true : false,
    // },
  ];
  //   const handleBack = () => {
  //     history.push(ROUTES.REFUGEE_MANAGEMENT_LIST);
  //   };
  return (
    <>
      <Box top="10px" zIndex="2">
        <Text fontSize="md" fontWeight="bold" mb="4">
          {id ? t("update_vehicle") : t("add_vehicle")}
        </Text>
      </Box>
      {(id || vehicle_id) && (
        <Card mt="5">
          <Box position="sticky" flex={1} mb="10">
            {props?.location?.state?.map((x) => {
              return (
                <VStack space={4} mt={7} flex={1} ml={10}>
                  <HStack space="5" flex={3}>
                    <>
                      <VStack>
                        <Text fontSize="md" fontWeight="bold">
                          {t("table:car_modal")}
                        </Text>
                      </VStack>

                      <VStack>
                        <Text fontSize="md">
                          {`${props?.location?.state?.[0]?.car_model?.car_make?.name} - ${props?.location?.state?.[0]?.car_model?.name}`}
                        </Text>
                      </VStack>
                      <VStack>
                        <Text fontSize="md" fontWeight="bold">
                          {t("table:req_no")}
                        </Text>
                      </VStack>
                      <VStack>
                        <Text fontSize="md">
                          {props?.location?.state?.[0]?.vehicle_number}
                        </Text>
                      </VStack>
                    </>
                  </HStack>
                </VStack>
              );
            })}
          </Box>
        </Card>
      )}

      <br />
      <TabView tabs={tabs} index={index} />
    </>
  );
};

export default VehicleTab;
