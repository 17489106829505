import React, { useEffect, useState } from "react";
import { Box, HStack, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import { getPositionValidationSchema } from "./position_validation";

import {
  createTyreSelector,
  create_Tyre,
  updatetyreSelector,
  update_tyre,
} from "@services/redux";
import { Form as AntdForm, Button } from "antd";

const TyreDetails = (props) => {
  const dispatch = useDispatch();

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { loading: updateLoading } = useSelector(updatetyreSelector);

  const { loading: createLoading } = useSelector(createTyreSelector);
  const { id, handleModalClose, initialValues, loading } = props;
  const handleSubmit = (values) => {
    if (initialValues.id) {
      let update_id = initialValues?.id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_tyre({
          id: update_id,
          data: {
            name: values.name,
          },
        })
      );
    } else {
      dispatch(
        create_Tyre({
          data: {
            name: values.name,
          },
        })
      );
    }
  };

  useEffect(() => {
    if (initialValues) {
      setActionItem(initialValues);
    }
  }, [initialValues]);

  const [form] = AntdForm.useForm();

  return (
    <Box flex="1">
      <Form
        form={form}
        validationSchema={getPositionValidationSchema(t)}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={2}>
          <HStack space={10}>
            <Box flex={1}>
              <Form.TextBox
                field={"name"}
                label={t("form:tyre_name")}
                rules={[
                  {
                    required: true,
                    message: "Tyre Name is required",
                  },
                ]}
              />
            </Box>
          </HStack>

          <HStack space={2} justifyContent="flex-end">
            <Form.Button
              isLoading={actionItem.id ? updateLoading : createLoading}
              width="100px"
              colorScheme="primary"
              height="45px"
              formErrorMessage={t("error:error_message")}
              label={actionItem.id ? t("update") : t("add")}
            >
              {actionItem.id ? t("update") : t("add")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              colorScheme="danger"
              variant="outline"
              onClick={handleModalClose}
              formErrorMessage={t("error:error_message")}
            >
              {t("common:cancel")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default TyreDetails;
