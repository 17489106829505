import { Button, Text } from "native-base";
import React from "react";

const ActionButton = (props) => {
  return (
    <Button
      {...props}
      type="primary"
      style={{
        background: "#683fe9",
        borderColor: "#683fe9",
        height: "45x",
        borderRadius: "5px",
      }}
    >
      <Text bold color={"white"}>
        {" "}
        {props.label}
      </Text>
    </Button>
  );
};
export default ActionButton;
