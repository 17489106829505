import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, Image, Link, Text } from "native-base";
import logo from "@assets/images/logo.png";

// import banner from "@assets/icons/logo.png";
const Footer = (props) => {
  return (
    <>
      <HStack
        _light={{
          bg: "rgb(23, 153, 199) ",
        }}
        pt="10"
        pb="4"
        justifyContent="space-between"
        direction={{
          base: "column",
          md: "column",
          lg: "row",
        }}
        px="7"
        // mx="auto"
      >
        <Box pb="5" pt="7">
          <img
            src={logo}
            // height="150px"
            width="100px"
          />
        </Box>

        <Box py="5">
          <Text fontSize="xl" color="white">
            Quick Links
          </Text>
          <br />
          <a href="https://www.india.gov.in/" target={"_blank"}>
            <Text fontSize="md" color="white" style={{ cursor: "pointer" }}>
              Government of India
            </Text>
          </a>
          <a href="https://www.tn.gov.in/" target={"_blank"}>
            <Text fontSize="md" color="white" style={{ cursor: "pointer" }}>
              Government of Tamil Nadu
            </Text>
          </a>
          <a href="https://publicdepartment.tn.gov.in/" target={"_blank"}>
            <Text fontSize="md" color="white" style={{ cursor: "pointer" }}>
              Public Department
            </Text>
          </a>
        </Box>
        <Box py="5">
          <Text fontSize="xl" color="white">
            Contact
          </Text>
          <br />
          <Text fontSize="md" color="white">
            Public Department,
          </Text>

          <Text fontSize="md" color="white">
            Old Building, Secretariat,
          </Text>
          <Text fontSize="md" color="white">
            Rajaji Salai, Fort St.George,
          </Text>
          <Text fontSize="md" color="white">
            Chennai 600 009.
          </Text>
          <Text fontSize="md" color="white">
            Email: pubsec@tn.gov.in
          </Text>
          <Text fontSize="md" color="white">
            Phone: +91 - 44 - 2567 1444
          </Text>
        </Box>
        <Box py="5" pr="12">
          {/* <Text fontSize="xl" color="white">
            Social Media
          </Text>
          <br />
          <Box>
            <HStack space={4}>
              <Link
                href="https://www.youtube.com/channel/UCm_qoq3N9d5VIBiN6va4OZg/videos"
                isExternal
              >
                <Image
                  source={{
                    uri: "https://cliply.co/wp-content/uploads/2019/04/371903520_SOCIAL_ICONS_YOUTUBE.png",
                  }}
                  alt="Alternate Text"
                  size="38px"
                />
              </Link>
              <Link href="https://twitter.com/tamiliansNRT" isExternal>
                <Image
                  source={{
                    uri: "https://www.freepnglogos.com/uploads/twitter-logo-png/twitter-bird-symbols-png-logo-0.png",
                  }}
                  alt="Alternate Text"
                  size="38px"
                />
              </Link>
              <Link href="https://www.facebook.com/nrtchennai1038" isExternal>
                <Image
                  source={{
                    uri: "https://seeklogo.com/images/F/facebook-icon-circle-logo-09F32F61FF-seeklogo.com.png",
                  }}
                  alt="Alternate Text"
                  size="32px"
                />
              </Link>
            </HStack>
          </Box> */}
        </Box>
      </HStack>
      <HStack
        _light={{
          bg: "rgb(23, 153, 199)",
        }}
        justifyContent="space-between"
        px="5"
        pb="3"
      >
        <Text color="white" font-size="lg">
          &#169;Public Vehicle Management
        </Text>
        <Text color="white" font-size="lg">
          Powered by {""}
          <a href="https://vertace.com/" target={"_blank"}>
            <Text fontSize="md" color="white" style={{ cursor: "pointer" }}>
              Vertace
            </Text>
          </a>
          {/* <Link
            href="https://vertace.com/"
            _text={{
              color: "white",
              textDecoration: "none",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            Vertace
          </Link> */}
        </Text>
      </HStack>
    </>
  );
};
export default Footer;
