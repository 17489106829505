import { gql } from "@apollo/client";

export const getAllAgency = gql`
  query getAgencies($search_string: String) {
    getAgencies(search_string: $search_string) {
      items {
        id
        name
        address
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const createAgencyMutation = gql`
  mutation createAgency($data: agency_input) {
    createAgency(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const updateAgencyMutation = gql`
  mutation updateAgency($data: agency_input, $id: String!) {
    updateAgency(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const deleteAgencyMutation = gql`
  mutation deleteAgency($id: String!) {
    deleteAgency(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const getOneAgencyQuery = gql`
  query getAgency($id: String) {
    getAgency(id: $id) {
      id
      name
      address
    }
  }
`;
