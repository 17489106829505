import { gql } from "@apollo/client";
import { QueryRequest } from "@services/apollo/api_service";
import { fileQuery } from "@services/redux";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export default async function fileUpload(file, storageName) {
  let url = await getURL(storageName, String(uuidv4()));
  let final_url = url?.split("?")[0];
  let success = true;
  await axios
    .put(url, file, {
      headers: { "Content-Type": file?.type },
    })
    .then(async (res) => {
      if (res.status.toString() === "200") {
        // return final_url;
      } else {
        success = false;
      }
    });
  if (success) {
    return final_url;
  }
}
async function getURL(type, fileName) {
  let query = gql`
    query getUploadDetails($type: String, $fileName: String) {
      getUploadDetails(type: $type, fileName: $fileName) {
        status
        access_token
        url

        file_upload_type
        error {
          status_code
          message
        }
      }
    }
  `;

  let { data } = await QueryRequest(query, {
    type,
    fileName,
  });
  return data.getUploadDetails.url;
}
