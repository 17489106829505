import { gql } from "@apollo/client";

export const getDesignations = gql`
  query getDesignations {
    getDesignations {
      items {
        id
        name
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const createDesignationMutation = gql`
  mutation createDesignation($data: designation_input) {
    createDesignation(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const deletedesignationMakeMutation = gql`
  mutation deleteDesignation($id: String!) {
    deleteDesignation(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const updatedesignationMakeMutation = gql`
  mutation updateDesignation($id: String!, $data: designation_input) {
    updateDesignation(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const getdesignationMakeQuery = gql`
  query getDesignation($id: String!) {
    getDesignation(id: $id) {
      id
      name
    }
  }
`;
