import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getOneDriverQuery } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "driverGetOne",
  initialState,
  reducers: {
    _get_one_driver: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },

    _get_one_driver_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_driver_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_driver, _get_one_driver_success, _get_one_driver_failure } =
  slice.actions;

export const getOneDriverSelector = (state) => state.driverGetOne;

export const getOneDriverReducer = slice.reducer;

export function get_one_driver(variables) {
  return async (dispatch) => {
    dispatch(_get_one_driver());
    try {
      const response = await QueryRequest(
        getOneDriverQuery,
        variables,
        dispatch
      );
      if (response?.data?.getDriver && !response?.data?.getDriver.error) {
        dispatch(_get_one_driver_success(response?.data?.getDriver));
      } else if (response?.data?.getDriver?.error) {
        dispatch(_get_one_driver_failure(response?.data?.getDriver.error));
      }
    } catch (error) {
      dispatch(_get_one_driver_failure(error));
    }
  };
}
export async function get_one_driver_clear(dispatch) {
  dispatch(_get_one_driver());
}
