import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { deleteServiceMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "question_delete",
  initialState,
  reducers: {
    _delete_service_question: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_service_question_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_service_question_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _delete_service_question,
  _delete_service_question_success,
  _delete_service_question_failure,
} = slice.actions;

export const deleteServiceQuestionsSelector = (state) => state.question_delete;

export const deleteServiceQuestionsReducer = slice.reducer;

export function delete_service_question(variables) {
  return async (dispatch) => {
    dispatch(_delete_service_question());
    try {
      const response = await MutateRequest(
        deleteServiceMutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteService &&
        !response?.data?.deleteService.error
      ) {
        dispatch(
          _delete_service_question_success(response?.data?.deleteService)
        );
      } else if (response?.data?.deleteService?.error) {
        dispatch(
          _delete_service_question_failure(response?.data?.deleteService.error)
        );
      }
    } catch (error) {
      dispatch(_delete_service_question_failure(error));
    }
  };
}
export function delete_one_service_question_modal(dispatch) {
  return async (dispatch) => {
    dispatch(_delete_service_question());
  };
}
