import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text, HStack, Pressable } from "native-base";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useHistory } from "react-router-dom";
import Dialog from "@views/components/ui/dialog";

import {
  create_condemnation,
  create_one_condemnation_clear,
  createCondemnationSelector,
  get_meter_reading_reached_vehicles_list,
  get_meter_reading_reached_vehicles_list_selector,
  get_validity_expired_vehicles_list,
} from "@services/redux";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@views/routes/my_routes";
import ReactSelect from "react-select";
import ValidityExpiredVehicles from "./validity_expired_vehicles";
import { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { useEffect } from "react";
import { showToast } from "@helpers/toast";
import { FcCancel } from "react-icons/fc";
import { retrieveItem } from "@helpers/storage";
import { colorScheme } from "@helpers/constants";

const ReachedMeterReading = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const roles = JSON.parse(retrieveItem("roles"));
  const [showModal, setShowModal] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const dispatch = useDispatch();
  const { loading, items, total_items } = useSelector(
    get_meter_reading_reached_vehicles_list_selector
  );

  const {
    loading: create_condemnation_loading,
    error: createError,
    status: createStatus,
  } = useSelector(createCondemnationSelector);

  const [vehicleType, setVehicleType] = useState("meter_reading_reached");
  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.VEHICLE_TAB}/${item.id}/vehicle`,
      state: [item],
    });
  };
  const handleView = (item) => {
    history.push({
      pathname: `${ROUTES.VEHICLE_TAB_VIEW}/${item.id}`,
      state: [item],
    });
  };
  const formatVehicleDetails = (value) => {
    return (
      <Pressable
        onPress={() => {
          roles?.[0]?.name === "Department" ||
          roles?.[0]?.name === "Public Department"
            ? handleEdit(value)
            : handleView(value);
        }}
      >
        {value?.vehicle_number} -{" "}
        {value.car_model?.car_make?.name + " - " + value.car_model?.name}
      </Pressable>
    );
  };
  const handleCondemnationModalOpen = (values) => {
    setActionItem(values);
    setShowModal(true);
  };
  const handleCondemnation = (value) => {
    dispatch(
      create_condemnation({
        data: {
          vehicle_id: actionItem.vehicle_id
            ? actionItem.vehicle_id
            : actionItem.vehicle?.vehicle_id,
        },
      })
    );
  };
  const handleCondemnationClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (createError === "Failure") {
      showToast({
        type: "success",
        message: `${t("Something went wrong!")}`,
      });
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `Moved to condemnation successfully`,
      });
      handleCondemnationClose();
      dispatch(create_one_condemnation_clear());
      dispatch(get_meter_reading_reached_vehicles_list());
      dispatch(get_validity_expired_vehicles_list());
    }
  }, [createStatus, createError]);

  let columns = [
    {
      header: t("table:vehicle_details"),
      dataIndex: "vehicle",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: formatVehicleDetails,
    },
    {
      header: t("table:meter_reading"),
      dataIndex: "meter_reading",
      key: "meter_reading",
      sortable: false,
      align: "left",
      width: 250,
    },
    {
      header: t("table:Condemnation"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "left",
      type: "actions",
      width: 150,
      actions: [
        {
          icon: <FcCancel />,
          iconSize: 30,
          iconColor: "primary.700",
          title: t("Move to condemnation"),
          onPress: handleCondemnationModalOpen,
        },
      ],
    },
  ];

  const vehicle_types = [
    {
      label: "Meter Reading Reached",
      value: "meter_reading_reached",
    },
    {
      label: "Validity Date Reached",
      value: "validity_date_reached",
    },
  ];
  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <Box
          position="sticky"
          top="0px"
          bg="white"
          backgroundColor={colorScheme.transparent}
        >
          <Text fontSize="md" fontWeight="bold" py={4}>
            {t("table:ready_for_condemnation")}
          </Text>
        </Box>
        <Box width="50%" zIndex="10">
          <ReactSelect
            value={vehicle_types?.find((r) => r.value === vehicleType)}
            options={vehicle_types}
            onChange={(entity, Object) => {
              if (entity) setVehicleType(entity.value);
              else setVehicleType(null);
            }}
            isClearable={true}
          />
        </Box>
        {vehicleType === "meter_reading_reached" && (
          <VStack space={5}>
            <Wrapper>
              <Table
                width="100%"
                rowKey="id"
                group="reached_meter_reading"
                loading={loading}
                data={items}
                columns={columns}
                hasSearch
                hasPagination
                totalItems={total_items}
                fetch={get_meter_reading_reached_vehicles_list}
                query={null}
                headerActions={[]}
                emptyMessage={t("No vehicles data found")}
                headerColor={"white"}
              />
            </Wrapper>
          </VStack>
        )}
      </Box>
      {vehicleType === "validity_date_reached" && <ValidityExpiredVehicles />}
      <Dialog
        isOpen={showModal}
        onClose={handleCondemnationClose}
        header={t("Condemnation")}
        content={t(
          `Are you sure you want to move ${
            actionItem?.vehicle?.vehicle_number
              ? actionItem?.vehicle?.vehicle_number
              : actionItem?.vehicle_number
          } vehicle to condemnation?`
        )}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleCondemnationClose,
          },
          {
            height: "40px",
            width: "120px",
            label: t("Condemnation"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: create_condemnation_loading,
            formErrorMessage: t("error:error_message"),
            submit: handleCondemnation,
          },
        ]}
      />
    </>
  );
};
export default ReachedMeterReading;
