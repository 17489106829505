import { getVehicleReportReducer } from "./vehicle_report";
import { getServiceRequestReportReducer } from "./service_request_report";
import { getGatePassReportReducer } from "./gate_pass_report";
import { getFuelReportReducer } from "./fuel_report";
import { getCondemnationReportReducer } from "./condemnation_report";
import { getDriverReportReducer } from "./driver_report";

export const reportReducers = {
  getVehicleReport: getVehicleReportReducer,
  getServiceRequestReport: getServiceRequestReportReducer,
  getGatePassReport: getGatePassReportReducer,
  getFuelReport: getFuelReportReducer,
  getCondemnationReport: getCondemnationReportReducer,
  getDriverReport: getDriverReportReducer,
};

export * from "./vehicle_report";
export * from "./service_request_report";
export * from "./gate_pass_report";
export * from "./fuel_report";
export * from "./condemnation_report";
export * from "./driver_report";
