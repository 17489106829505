import React, { useEffect, useState } from "react";
import { Box, HStack, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import {
  createServiceQuestionSelector,
  create_service_question,
  updateServiceQuestionSelector,
  update_service_question,
} from "@services/redux/slices/service_question";
import { Form as AntdForm, Button } from "antd";

const ServiceQuestionDetails = (props) => {
  const dispatch = useDispatch();
  const { loading: updateLoading } = useSelector(updateServiceQuestionSelector);
  const { loading: createLoading } = useSelector(createServiceQuestionSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id, handleModalClose, initialValue } = props;
  const handleSubmit = (values) => {
    if (initialValue.id) {
      let update_id = initialValue?.id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_service_question({
          id: update_id,
          data: {
            name: values.name,
          },
        })
      );
    } else {
      dispatch(
        create_service_question({
          data: {
            name: values.name,
          },
        })
      );
    }
  };

  useEffect(() => {
    if (initialValue) {
      setActionItem(initialValue);
    }
  }, [initialValue]);
  const [form] = AntdForm.useForm();

  return (
    <Box flex="1">
      <Form
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        // onValueChange={handleInputChange}
      >
        <VStack space={10} mt={2}>
          <HStack space={10}>
            <Box flex={1}>
              <Form.TextArea
                field={"name"}
                label={t("form:service_question_name")}
                rules={[
                  {
                    required: true,
                    message: "Service Question is required",
                  },
                ]}
              />
            </Box>
          </HStack>

          <HStack space={2} justifyContent="flex-end">
            <Form.Button
              isLoading={actionItem?.id ? updateLoading : createLoading}
              label={actionItem.id ? t("update") : t("add")}
              width="100px"
              colorScheme="primary"
              height="45px"
              formErrorMessage={t("error:error_message")}
            >
              {actionItem.id ? t("update") : t("add")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              colorScheme="danger"
              variant="outline"
              onClick={handleModalClose}
              formErrorMessage={t("error:error_message")}
            >
              {t("common:cancel")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default ServiceQuestionDetails;
