import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { checkVehicleQuery } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: {},
};

const slice = createSlice({
  name: "checkVehicle",
  initialState,
  reducers: {
    _check_vehicle: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _check_vehicle_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.item = {};
    },
    _check_vehicle_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = payload?.error;
    },
    _check_vehicle_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = {};
    },
  },
});

const {
  _check_vehicle,
  _check_vehicle_reset,
  _check_vehicle_success,
  _check_vehicle_failure,
} = slice.actions;

export const checkVehicleSelector = (state) => state.checkVehicle;

export const checkVehicleReducer = slice.reducer;

export function check_vehicles(variables) {
  return async (dispatch) => {
    dispatch(_check_vehicle());
    try {
      const response = await QueryRequest(
        checkVehicleQuery,
        variables,
        dispatch
      );
      if (response?.data?.checkVehicle && !response?.data?.checkVehicle.error) {
        dispatch(_check_vehicle_success(response?.data?.checkVehicle));
      } else if (response?.data?.checkVehicle?.error) {
        dispatch(_check_vehicle_failure(response?.data?.checkVehicle.error));
      }
    } catch (error) {
      dispatch(_check_vehicle_failure(error));
    }
  };
}

export function check_vehicle_clear() {
  return async (dispatch) => {
    dispatch(_check_vehicle_reset());
  };
}
