import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getFuelReportQuery } from "./graphql";
const initialState = {
  loading: false,
  error: null,
  url: null,
};
const slice = createSlice({
  name: "getFuelReport",
  initialState,
  reducers: {
    getFuelReportInitialize: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    getFuelReportReset: (state) => {
      state.loading = false;
      state.url = null;
      state.error = null;
    },
    getFuelReportSuccess: (state, { payload }) => {
      state.loading = false;
      state.url = payload.url;
      state.error = null;
    },
    getFuelReportFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  getFuelReportInitialize,
  getFuelReportSuccess,
  getFuelReportFailure,
  getFuelReportReset,
} = slice.actions;
export const getFuelReportSelector = (state) => state.getFuelReport;

export const getFuelReportReducer = slice.reducer;

export function getFuelReport(variables) {
  return async (dispatch) => {
    dispatch(getFuelReportInitialize());
    try {
      const response = await QueryRequest(
        getFuelReportQuery,
        variables,
        dispatch
      );
      if (
        response?.data?.getFuelReport &&
        !response?.data?.getFuelReport.error
      ) {
        dispatch(getFuelReportSuccess(response?.data?.getFuelReport));
      } else if (response?.data?.getFuelReport?.error) {
        dispatch(getFuelReportFailure(response?.data?.getFuelReport.error));
      }
    } catch (error) {
      dispatch(getFuelReportFailure(error));
    }
  };
}

export function getFuelReportClear() {
  return (dispatch) => {
    dispatch(getFuelReportReset());
  };
}
