import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { showToast } from "@helpers/toast";
import { getPositionValidationSchema } from "./position_validation";
import {
  updateDepartmentSelector,
  update_department,
} from "@services/redux/slices/department/department_update";
import {
  createDepartmentSelector,
  create_department,
} from "@services/redux/slices/department/department_create";
import { getOneDepartmentSelector } from "@services/redux/slices/department/department_one";
import { Button, Form as AntdForm } from "antd";

const DepartmentDetails = (props) => {
  const dispatch = useDispatch();
  let { item, loading: getOneLoading } = useSelector(getOneDepartmentSelector);
  const { loading: updateLoading } = useSelector(updateDepartmentSelector);
  const { loading: createLoading } = useSelector(createDepartmentSelector);

  const [form] = AntdForm.useForm();

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id, handleModalClose, initialValue } = props;
  const handleSubmit = (values) => {
    if (actionItem.id) {
      let update_id = actionItem.id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_department({
          id: update_id,
          data: {
            name: values.name,
            code: values.code,
          },
        })
      );
    } else {
      dispatch(
        create_department({
          data: {
            name: values.name,
            code: values.code,
          },
        })
      );
    }
  };

  useEffect(() => {
    if (initialValue) {
      setActionItem(initialValue);
    }
  }, [initialValue]);

  return (
    <Box flex="1">
      <Form
        form={form}
        validationSchema={getPositionValidationSchema(t)}
        initialValues={actionItem}
        onSubmit={handleSubmit}
      >
        <VStack space={10} mt={2}>
          <Box flex={1}>
            <Form.TextBox
              field={"name"}
              label={t("form:department_name")}
              rules={[
                {
                  required: true,
                  message: "Department Name is required",
                },
              ]}
            />
          </Box>
          <Box flex={1}>
            <Form.TextBox
              field={"code"}
              label={t("form:code")}
              rules={[
                {
                  required: true,
                  message: "Code is required",
                },
              ]}
            />
          </Box>
          <HStack space={2} justifyContent="flex-end">
            <Form.Button
              isLoading={actionItem.id ? updateLoading : createLoading}
              width="100px"
              colorScheme="primary"
              height="45px"
              formErrorMessage={t("error:error_message")}
              label={actionItem.id ? t("update") : t("add")}
            >
              {actionItem.id ? t("update") : t("add")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              colorScheme="danger"
              variant="outline"
              onClick={handleModalClose}
              formErrorMessage={t("error:error_message")}
            >
              {t("common:cancel")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default DepartmentDetails;
