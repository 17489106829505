import React, { useEffect, useState } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import {
  pass_type,
  transit_pass_type,
  two_wheeler_pass_type,
} from "@utils/constant";
import {
  createCondemnationSelector,
  create_condemnation,
  updateCondemnationSelector,
  update_condemnation,
  get_one_condemnation,
  getOneCondemnationSelector,
  getAll_Vehicles,
  getVehiclesListSelector,
  get_CondemnationList,
  update_one_condemnation_clear,
  create_one_condemnation_clear,
  get_service_request_vehicles,
  getServiceRequestVehiclesListSelector,
} from "@services/redux";
import { Button, Row, Form as AntdForm } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";
import fileUpload from "@utils/file_upload";
import moment from "moment";
import { retrieveItem } from "@helpers/storage";
import { colorScheme } from "@helpers/constants";

const CondemnationDetails = (props) => {
  const dispatch = useDispatch();
  const roles = JSON.parse(retrieveItem("roles"));
  const [form] = AntdForm.useForm();
  const { id, paramtype } = useParams();
  const history = useHistory();
  const [actionItem, setActionItem] = useState({});
  const [transitShow, setTransitShow] = useState(false);
  const [visitors, setVisitors] = useState(false);
  const [VehicleList, setVehicleList] = useState([]);
  const { t } = useTranslation();
  const { handleModalClose, initialValue } = props;
  const {
    loading: createLoading,
    error: createError,
    status: createStatus,
  } = useSelector(createCondemnationSelector);
  const {
    loading: updateLoading,
    error: updateError,
    status: updateStatus,
  } = useSelector(updateCondemnationSelector);
  const { item: condemnation } = useSelector(getOneCondemnationSelector);
  const { items: vehicles } = useSelector(
    getServiceRequestVehiclesListSelector
  );

  useEffect(() => {
    if (createError === "Failure") {
      showToast({
        type: "success",
        message: `${t("Something went wrong!")}`,
      });
    } else if (updateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Condemnation")} ${t("update_successfully")}`,
      });

      dispatch(update_one_condemnation_clear());
      dispatch(
        get_CondemnationList({
          vehicle_id: props?.location?.state?.vehicle_id
            ? props.location?.state?.vehicle_id
            : null,
        })
      );

      history.goBack();
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Condemnation")} ${t("create_successfully")}`,
      });

      dispatch(
        get_CondemnationList({
          vehicle_id: props?.location?.state?.vehicle_id
            ? props.location?.state?.vehicle_id
            : null,
        })
      );
      dispatch(create_one_condemnation_clear());
      history?.goBack();
    } else if (updateError === "Failure") {
      showToast({
        type: "success",
        message: `${t("Something went wrong!")}`,
      });
      dispatch(update_one_condemnation_clear());
    }
  }, [updateStatus, createStatus, createError, updateError]);

  useEffect(() => {
    dispatch(get_service_request_vehicles());
  }, []);

  useEffect(() => {
    if (id) dispatch(get_one_condemnation({ id }));
  }, [id]);

  useEffect(() => {
    let temp = [];
    if (vehicles) {
      temp = vehicles.map((x) => {
        return {
          label: `${x?.car_model?.car_make?.name} - ${x.vehicle_number}`,
          value: x.id,
        };
      });
    }
    setVehicleList(temp);
  }, [vehicles]);
  useEffect(() => {
    if (condemnation) {
      setActionItem({
        ...condemnation,
        date: moment(new Date(Number(condemnation.date))),
        vehicle_id: condemnation?.vehicle_id,
        vehicle: condemnation?.vehicle?.vehicle_number,
        go_file: condemnation?.go_file
          ? [
              {
                uid: "-1",
                name: condemnation?.go_file ? "GO File" : "",
                status: "done",
                url: condemnation?.go_file,
              },
            ]
          : [],
        challan: condemnation?.challan
          ? [
              {
                uid: "-2",
                name: condemnation?.challan ? "Challan" : "",
                status: "done",
                url: condemnation?.challan,
              },
            ]
          : [],
        other_document: condemnation?.other_document
          ? [
              {
                uid: "-3",
                name: condemnation?.other_document ? "Other Document" : "",
                status: "done",
                url: condemnation?.other_document,
              },
            ]
          : [],
        front_image_url: condemnation?.front_image_url
          ? [
              {
                uid: "-1",
                name: condemnation?.front_image_url ? "Front image" : "",
                status: "done",
                url: condemnation?.front_image_url,
              },
            ]
          : [],
        rear_image_url: condemnation?.rear_image_url
          ? [
              {
                uid: "-1",
                name: condemnation?.rear_image_url ? "Rear image" : "",
                status: "done",
                url: condemnation?.rear_image_url,
              },
            ]
          : [],
        left_image_url: condemnation?.left_image_url
          ? [
              {
                uid: "-1",
                name: condemnation?.left_image_url ? "Left image" : "",
                status: "done",
                url: condemnation?.left_image_url,
              },
            ]
          : [],
        right_image_url: condemnation?.right_image_url
          ? [
              {
                uid: "-1",
                name: condemnation?.right_image_url ? "Right image" : "",
                status: "done",
                url: condemnation?.right_image_url,
              },
            ]
          : [],
        dashboard_image_url: condemnation?.dashboard_image_url
          ? [
              {
                uid: "-1",
                name: condemnation?.dashboard_image_url
                  ? "Dashboard image"
                  : "",
                status: "done",
                url: condemnation?.dashboard_image_url,
              },
            ]
          : [],
      });
    }
  }, [condemnation]);

  const handleSubmit = (values) => {
    values.front_image_url = values.front_image_url?.[0]?.url;
    values.rear_image_url = values.rear_image_url?.[0]?.url;
    values.left_image_url = values.left_image_url?.[0]?.url;
    values.right_image_url = values.right_image_url?.[0]?.url;
    values.dashboard_image_url = values.dashboard_image_url?.[0]?.url;
    values.go_file = values.go_file?.[0]?.url;
    values.challan = values.challan?.[0]?.url;
    values.other_document = values.other_document?.[0]?.url;

    values.vehicle_id = values.vehicle_id || condemnation?.vehicle_id;
    delete values.vehicle;
    // values.go_file =
    //   "https://aim-assets.s3.ap-south-1.amazonaws.com/default-avatar.png";
    if (id) {
      let update_id = id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_condemnation({
          id: update_id,
          data: values,
        })
      );
    } else {
      dispatch(
        create_condemnation({
          data: values,
        })
      );
    }
  };
  const handleInputChange = (value) => {
    if (value.types === "transit_pass") {
      setTransitShow(true);
    } else setTransitShow(false);
    if (value.pass_type === "visitors") {
      setVisitors(true);
    } else setVisitors(false);
  };

  return (
    <Box flex="1">
      <Box mt="4" backgroundColor={colorScheme.transparent}>
        <Text fontWeight={"bold"} fontSize="md" mb="5">
          {id ? "Condemnation" : "Condemnation"}
        </Text>
      </Box>

      <Form
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={handleInputChange}
      >
        <VStack space={2}>
          <HStack flex={1} space={5}>
            <Box flex={1}>
              <Form.File
                field={`front_image_url`}
                label={t("Front")}
                shape="square"
                // fallbackUrl={front}
              />
              {condemnation?.front_image_url && (
                <img
                  src={condemnation?.front_image_url}
                  alt=""
                  width="65px"
                  // height={"25px"}
                />
              )}
            </Box>
            <Box flex={1}>
              <Form.File
                field={`rear_image_url`}
                label={t("Rear")}
                shape="square"
                // fallbackUrl={back}
              />
              {condemnation?.rear_image_url && (
                <img src={condemnation?.rear_image_url} alt="" width="65px" />
              )}
            </Box>
            <Box flex={1}>
              <Form.File
                field={`left_image_url`}
                label={t("Left")}
                shape="square"
                // fallbackUrl={left}
              />
              {condemnation?.left_image_url && (
                <img src={condemnation?.left_image_url} alt="" width="65px" />
              )}
            </Box>
            <Box flex={1}>
              <Form.File
                field={`right_image_url`}
                label={t("Right")}
                shape="square"
                // fallbackUrl={right}
              />
              {condemnation?.right_image_url && (
                <img src={condemnation?.right_image_url} alt="" width="65px" />
              )}
            </Box>
            <Box flex={1}>
              <Form.File
                field={`dashboard_image_url`}
                label={t("Dashboard")}
                shape="square"
                // fallbackUrl={dash}W
              />
              {condemnation?.dashboard_image_url && (
                <img
                  src={condemnation?.dashboard_image_url}
                  alt=""
                  width="65px"
                />
              )}
            </Box>
          </HStack>

          <HStack space={5}>
            {!id && (
              <Box flex={1}>
                <Form.Select
                  field={"vehicle_id"}
                  label={t("form:vehicle")}
                  options={VehicleList}
                  labelField="label"
                  valueField="value"
                  rules={[
                    {
                      required: true,
                      message: "Vehicle is required",
                    },
                  ]}
                />
              </Box>
            )}
            {id && (
              <Box flex={1}>
                <Form.TextBox
                  disabled
                  field={"vehicle"}
                  label={t("form:vehicle")}
                  rules={[
                    {
                      required: false,
                      message: "Vehicle is required",
                    },
                  ]}
                />
              </Box>
            )}
            <Box flex={1}>
              <Form.TextBox
                field={"go_number"}
                label={t("form:go_number")}
                rules={[
                  {
                    required: false,
                    message: "GO Number is required",
                  },
                ]}
              />
            </Box>
          </HStack>

          <HStack space={5}>
            <Box flex={1}>
              <Form.Date
                field={"date"}
                label={t("form:go_date")}
                rules={[
                  {
                    required: false,
                    message: "Date is required",
                  },
                ]}
              />
            </Box>

            <Box flex={1}>
              <Form.File
                field={"go_file"}
                label={t("form:go_file")}
                rules={[
                  {
                    required: false,
                    message: "GO File is required",
                  },
                ]}
              />
            </Box>
          </HStack>

          <HStack space={5}>
            <Box flex={1}>
              <Form.File
                field={"challan"}
                label={t("form:challan")}
                rules={[
                  {
                    required: false,
                    message: "Challan is required",
                  },
                ]}
              />
            </Box>

            <Box flex={1}>
              <Form.File
                field={"other_document"}
                label={t("form:other_document")}
                rules={[
                  {
                    required: false,
                    message: "Other Document is required",
                  },
                ]}
              />
            </Box>
          </HStack>
          <HStack space={5}>
            <Box flex={1}>
              <Form.TextArea field={"reason"} label={t("form:reason")} />
            </Box>
          </HStack>
          <HStack space={2} justifyContent="flex-end">
            {roles?.[0]?.name !== "Admin" && (
              <Form.Button
                isLoading={id ? updateLoading : createLoading}
                width="100px"
                BoxorScheme="primary"
                height="45px"
                formErrorMessage={t("error:error_message")}
                label={id ? t("update") : t("add")}
              >
                {actionItem.id ? t("Condemn") : t("Condemn")}
              </Form.Button>
            )}
            <Button
              isLoading={false}
              width="100px"
              BoxorScheme="danger"
              variant="outline"
              onClick={() => {
                history.goBack();
              }}
              formErrorMessage={t("error:error_message")}
            >
              {t("common:back")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default CondemnationDetails;
