import { React, useRef } from "react";

import { Button } from "antd";
import { Box, Divider, HStack, Text, VStack } from "native-base";
import { ToWords } from "to-words";
import { useReactToPrint } from "react-to-print";
const FuelCoupon = (props) => {
  const { data } = props;
  const toWords = new ToWords();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const formatDisplay = (date) => {
    if (date) {
      date = new Date(date);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };

  return (
    <Box flex={1}>
      <HStack justifyContent={"flex-end"} mt="10">
        <Button
          onClick={() => {
            handlePrint();
          }}
        >
          Print
        </Button>
      </HStack>
      <div ref={componentRef}>
        <VStack space="5" padding="40px">
          <Box alignItems={"center"}>
            <Text textAlign="center" fontSize="22px">
              Fuel Requirements
            </Text>
          </Box>

          <Box>
            <HStack>
              <Box flex={1}>
                <Text textAlign="left">{data?.running_number}</Text>
              </Box>
              <Box alignItems={"right"}>
                <Text>Date: {formatDisplay(data?.fuel_refilled_date)}</Text>
              </Box>
            </HStack>
          </Box>
          <Box>
            <HStack>
              <Box flex={1}>
                <Text textAlign="left">
                  From: Section Officer,
                  <br />
                  {"         "} Public Motor Vehicle Department,
                  <br />
                  {"         "} Secretariat,
                  <br />
                  {"         "} Chennai 600009.
                </Text>
              </Box>
              <Box alignItems={"right"} marginRight="120">
                <Text>
                  To: Technical Assistant,
                  <br />
                  {"     "} Secretariat,
                  <br />
                  {"     "} Petrol Bunk,
                  <br />
                  {"     "} Chennai 600009.
                </Text>
              </Box>
            </HStack>
          </Box>
          <Box alignItems={"center"}>
            <Text>
              I am to request you to supply fuel for my office vehicle bearing
              registration number {data?.vehicle?.vehicle_number}
            </Text>
          </Box>

          <Box>
            <table
              bordered
              id="u_content_text_1"
              style={{
                fontFamily: "arial,helvetica,sans-serif",
              }}
              role="presentation"
              cellpadding="0"
              cellspacing="0"
              width="90%"
              border="0"
            >
              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "center",
                      padding: "6px",

                      // width:90px;
                    }}
                  >
                    Sl.No
                  </th>
                  <th
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "center",
                      padding: "6px",

                      // width:90px;
                    }}
                  >
                    Fuel
                  </th>

                  <th
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "center",
                      padding: "6px",

                      // width:90px;
                    }}
                  >
                    Quantity in numbers
                  </th>
                  <th
                    style={{
                      border: "1px solid #dddddd",
                      textAlign: "center",
                      padding: "6px",

                      // width:90px;
                    }}
                  >
                    Quantity in words
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      padding: "6px",
                      textAlign: "center",
                    }}
                  >
                    (1)
                  </td>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      padding: "6px",
                      textAlign: "center",
                    }}
                  >
                    {data.fuel_type}
                  </td>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      padding: "6px",
                      textAlign: "center",
                    }}
                  >
                    {data.quantity}
                  </td>
                  <td
                    style={{
                      border: "1px solid #dddddd",
                      padding: "6px",
                      textAlign: "center",
                    }}
                  >
                    {toWords.convert(data.quantity)}
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
          <Box></Box>
          <Box alignItems={"end"} marginRight="70">
            <Text>Approved Quantity</Text>
          </Box>
          <Box></Box>
          <Box></Box>
          <Box alignItems={"end"} marginRight="50">
            <Text>Signature of the Officer</Text>
          </Box>
          {/* <Box>
            <Text >
              Note: Officer’s signature is required for any corrections made in
              the fuel quantity
            </Text>
          </Box> */}
          <Divider />
          <Box>
            <Text>
              Note: To be retained by Motor Vehicle Technical Officer.
            </Text>
          </Box>
          <Box alignItems={"center"}>
            <Text>To be filled by Motor Vehicle Maintenance</Text>
          </Box>
          <Box>
            <Text>Approved Fuel :</Text>
          </Box>
          <Box marginRight="70">
            <Text>Quantity (in words):</Text>
          </Box>
          <Box>
            <Text>Price: </Text>
          </Box>
          <Box></Box>
          <Box></Box>

          <Box alignItems={"end"} marginRight="50">
            <Text>Signature of the Officer</Text>
          </Box>
          <Box alignItems={"right"}>
            <Text>…………………….. litres (in words)</Text>
          </Box>
          <Box alignItems={"center"}>
            <Text>Petrol/Diesel/Oil Received.</Text>
          </Box>
          <Box>
            <Box></Box>
            <Box></Box>
            <Text>Receiver’s Signature</Text>
          </Box>
          <Box>
            <Text>Designation</Text>
          </Box>
          <Box alignItems={"center"}>
            <Text>Given back to Section Officer by driver.</Text>
          </Box>
        </VStack>
      </div>
    </Box>
  );
};

export default FuelCoupon;
