import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getAllVehiclesWithOutpagination} from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "vehicles_without_pagination_List",
  initialState,
  reducers: {
    _get_vehicles_without_pagination: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_vehicles_without_pagination_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_vehicles_without_pagination_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
    _get_vehicles_without_pagination_reset:(state,{payload})=>{
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    }
  },
});

const { _get_vehicles_without_pagination, _get_vehicles_without_pagination_success, _get_vehicles_without_pagination_failure,_get_vehicles_without_pagination_reset } =
  slice.actions;

export const getVehicles_without_paginationListSelector = (state) => state.vehicles_without_pagination_List;

export const getVehicles_without_paginationListReducer = slice.reducer;

export function getAll_Vehicles_without_pagination(variables) {
  return async (dispatch) => {
    dispatch(_get_vehicles_without_pagination());
    try {
      const response = await QueryRequest(getAllVehiclesWithOutpagination, variables, dispatch);
      if (response?.data?.getVehicles && !response?.data?.getVehicles.error) {
        dispatch(_get_vehicles_without_pagination_success(response?.data?.getVehicles));
      } else if (response?.data?.getVehicles?.error) {
        dispatch(_get_vehicles_without_pagination_failure(response?.data?.getVehicles.error));
      }
    } catch (error) {
      dispatch(_get_vehicles_without_pagination_failure(error));
    }
  };
}

export function get_vehicles_without_pagination_clear(dispatch) {
  return async (dispatch) => {
    dispatch(_get_vehicles_without_pagination_reset());
  };
}
