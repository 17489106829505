import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Upload,
  Row,
  Col,
  InputNumber,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Box, HStack, Text } from "native-base";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "@views/routes/my_routes";
import {
  apply_pass_create,
  create_one_apply_pass_clear,
  createApplyPassSelector,
  get_All_Entity,
  get_Departments,
  get_designation,
  get_pass_typeList,
  getAllEntitySelector,
  getDepartmentListSelector,
  getDesignationListSelector,
  getpass_typeListSelector,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "@helpers/toast";
import { useTranslation } from "react-i18next";
import { retrieveItem } from "@helpers/storage";
import { upperCase } from "lodash";
import { fileQuery } from "@services/redux/slices/login/graphql";
import { getUUID } from "@helpers/uuid";
import { QueryRequest } from "@services/apollo/api_service";
import axios from "axios";

const PassForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { type } = useParams();
  console.log("type", type);

  const history = useHistory();
  const dispatch = useDispatch();
  const [passId, setPassId] = useState("");
  const [passType, setpassType] = useState([]);
  const [child_pass_id, setChildPassId] = useState();
  const [passSubTypes, setpassSubTypes] = useState([]);
  const { mobile } = JSON.parse(retrieveItem("user"));
  const [mobileNumber, setMobileNumber] = useState(mobile);
  const [fileList, setFileList] = useState([]);

  console.log("fileList", fileList);

  const { TextArea } = Input;
  const { Option } = Select;

  const { items: pass_types } = useSelector(getpass_typeListSelector);
  const { items: designation } = useSelector(getDesignationListSelector);
  const { items: department } = useSelector(getDepartmentListSelector);
  const { items: entity_item } = useSelector(getAllEntitySelector);
  console.log("entity_item", entity_item);

  const {
    loading: createPassLoading,
    status: createStatus,
    error: createError,
  } = useSelector(createApplyPassSelector);

  console.log("department", department, pass_types);

  const onFinish = (values) => {
    console.log("values", values);
    delete values?.parent_pass_id;
    delete values?.mobile;
    dispatch(
      apply_pass_create({
        data: {
          ...values,
          entity_id:
            ((type === "vip" || type === "hod") && entity_item?.[2]?.id) ||
            ((type === "secretariat_staff" || type === "public") &&
              entity_item?.[0]?.id),
          rc_book_url: values?.rc_book_url?.[0]?.url,
          recommendation_letter_url:
            values?.recommendation_letter_url?.[0]?.url,
        },
      })
    );
    form.resetFields();
  };
  // const beforeUpload = (file) => {
  //   console.log("file", file);
  //   // dispatch(fileQuery({ type: "photo", fileName: getUUID() }));
  //   return false;
  // };

  let file_upload_response = "";

  const beforeUpload = async ({ name, type }) => {
    let splitString = name.split(".");
    let extension = splitString[1];
    // let file_upload = {
    //   custom: {
    //     extension: extension,
    //   },
    // };
    let fileupload = {
      type: "photo",
      fileName: getUUID(),
    };
     file_upload_response = await QueryRequest(
      fileQuery,
      fileupload
    );
  };

  const handleUpload = ({ onSuccess, onError, file }) => {
    axios
      .put(fileList?.[0]?.url, file, {
        headers: { "Content-Type": file.type },
      })
      .then((response) => {
        file.url = fileList?.[0]?.url.split("?")[0];

        onSuccess(null, file);
      })
      .catch((error) => {});
  };

  const action = file_upload_response?.data?.getUploadDetails;

  const handleFileChanged = ({ file }) => {
    console.log("file",file);
    
    if (file.status === "removed") {
      setFileList([]);
    } else if (file.status === "uploading") {
      file = {
        ...file,
        url: file_upload_response?.data?.getUploadDetails?.url,
      };
      setFileList([file]);
      return file;
    } else if (file.status === "done") {
      toast.success(t("document_updated_successfully"));

      // showToast({
      //   type: "success",
      //   message: "Image updated successfully",
      // });
      setFileList([...fileList]);
      return file;
    }
  };

  let departments = [
    {
      label: "Chief Minister's Office",
      value: "chief_minister_officer",
    },
    {
      label: "Public Department",
      value: "public_department",
    },
  ];

  const handleGoBack = () => {
    history.push(ROUTES.VEHICLE_PASS);
  };

  useEffect(() => {
    let sub_types = [];
    if (passId) {
      sub_types = pass_types?.find((x) => x.id === passId)?.child_pass_types;
    }
    console.log("sub_types", sub_types);

    setpassType(sub_types);
  }, [passId]);

  const handleInputChange = (val, value) => {
    console.log("value", value);
    if (value.parent_pass_id) {
      setPassId(value.parent_pass_id);
      setChildPassId("");
    }
    if (value.child_pass_id) {
      setChildPassId(value.child_pass_id);
      value.pass_type_id = "";
    }
  };

  useEffect(() => {
    let child_pass = [];
    if (child_pass_id) {
      child_pass = passType?.find(
        (x) => x.id === child_pass_id
      )?.child_pass_types;
    }

    setpassSubTypes(child_pass);
  }, [child_pass_id, passType]);

  useEffect(() => {
    if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${upperCase(type)} ${t("create_successfully")}`,
      });
      history.push(ROUTES.VEHICLE_PASS);
      dispatch(create_one_apply_pass_clear());
    } else if (createError) {
      showToast({
        type: "error",
        message: createError?.message,
      });
      dispatch(create_one_apply_pass_clear());
    }
  }, [createStatus, createError]);

  useEffect(() => {
    dispatch(get_pass_typeList());
    dispatch(get_designation());
    dispatch(get_Departments());
    dispatch(get_All_Entity());
  }, []);

  return (
    <div
      style={{
        overflowY: "auto",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "95%",
        }}
      >
        <Box position="sticky" flex={1} mb="10" mt="4">
          <Text fontWeight={"bold"} fontSize="md">
            Add Vehicle Pass
          </Text>
        </Box>

        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          onValuesChange={handleInputChange}
          initialValues={{
            mobile: mobile,
          }}
        >
          <Row gutter={16}>
            <Col span={7}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name="mobile"
                label="Mobile"
                rules={[
                  {
                    required: true,
                    message: "Please input your mobile number!",
                  },
                ]}
              >
                <Input
                  value={mobileNumber}
                  disabled
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={7}></Col>
            {type === "secretariat_staff" && (
              <Col span={7}>
                <Form.Item
                  name="designation_id"
                  label="Designation"
                  rules={[
                    {
                      required: true,
                      message: "Please select your designation!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Designation"
                    options={designation.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    allowClear
                  />
                </Form.Item>
              </Col>
            )}
            {(type === "secretariat_staff" || type === "hod") && (
              <Col span={7}>
                <Form.Item
                  name="department_id"
                  label="Department"
                  rules={[
                    {
                      required: true,
                      message: "Please select your department!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Department"
                    options={department.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    allowClear
                  ></Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={11}>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  { required: true, message: "Please input your address!" },
                ]}
              >
                <TextArea autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={7}>
              <Form.Item
                name="parent_pass_id"
                label="Pass"
                rules={[
                  { required: true, message: "Please select your pass!" },
                ]}
              >
                <Select placeholder="Pass" allowClear>
                  {pass_types?.map((pass) => (
                    <Select.Option key={pass?.id} value={pass?.id}>
                      {pass?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={7}>
              <Form.Item
                name="pass_type_id"
                label="Pass Type"
                rules={[
                  { required: true, message: "Please select your pass type!" },
                ]}
              >
                <Select placeholder="Pass type" allowClear>
                  {passType?.map(
                    (type) => (
                      console.log("type", type),
                      (
                        <Select.Option key={type?.id} value={type?.id}>
                          {type?.name}
                        </Select.Option>
                      )
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>

            {/* <Col span={7} offset={1}> */}
              {/* <Form.Item
                name="pass_type_id"
                label="Pass Subtype"
                rules={[
                  {
                    required: true,
                    message: "Please select your pass subtype!",
                  },
                ]}
              >
                <Select placeholder="Pass subtype" allowClear>
                  {passSubTypes?.map((subtype) => (
                    <Select.Option key={subtype?.id} value={subtype?.id}>
                      {subtype?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item> */}
            {/* </Col> */}
            <Col span={7}>
              <Form.Item
                name="vehicle_number"
                label="Vehicle Registration Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your vehicle registration number!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={7}>
              <Form.Item
                name="vehicle_make"
                label="Make & Model"
                rules={[
                  {
                    required: true,
                    message: "Please input the make and model!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={7}>
              <Form.Item
                name="ownership_detail"
                label="Ownership Details"
                rules={[
                  {
                    required: true,
                    message: "Please select ownership details!",
                  },
                ]}
              >
                <Select placeholder="Ownership">
                  <Option value="owned">Owned</Option>
                  <Option value="leased">Leased</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name="purpose_of_visit"
                label="Purpose of Visit"
                rules={[
                  {
                    required: true,
                    message: "Please input the purpose of visit!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>   
            {/* <Col span={7} offset={1}>
              <Form.Item
                name="oldPassNumber"
                label="Old Pass Number (If available)"
              >
                <Input type="text" />
              </Form.Item>
            </Col> */}
            <Col span={7}>
            {/* <Form.Item
                  name="rc_book_url"
                  label="RC Book"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e?.fileList
                  }
                >
                  <Upload
                    name="recommendationLetter"
                    action="/upload"
                    listType="text"
                    beforeUpload={beforeUpload}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item> */}

              <Form.Item
                name="rc_book_url"
                label="RC Book"
                valuePropName="fileList"
                getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
              >
                <Upload
                  name="rc_book"
                  action={action}
                  listType="text"
                  fileList={fileList}
                  maxCount={1}
                  customRequest={(e) => handleUpload(e)}
                  beforeUpload={(args) => beforeUpload(args)}
                  onChange={(e) => handleFileChanged(e)}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col span={7}>
              {type === "public" && (
              //   <Form.Item
              //   name="recommendation_letter_url"
              //   label="Recommendation Letter"
              //   valuePropName="fileList"
              //   getValueFromEvent={(e) =>
              //     Array.isArray(e) ? e : e?.fileList
              //   }
              // >
              //   <Upload
              //     name="recommendationLetter"
              //     action="/upload"
              //     listType="text"
              //     beforeUpload={beforeUpload}
              //   >
              //     <Button icon={<UploadOutlined />}>Click to Upload</Button>
              //   </Upload>
              // </Form.Item>
                <Form.Item
                  name="recommendation_letter_url"
                  label="Recommendation Letter"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e?.fileList
                  }
                >
                  <Upload
                    name="recommendationLetter"
                    action={action}
                    listType="text"
                    fileList={fileList}
                    maxCount={1}
                    customRequest={(e) => handleUpload(e)}
                    beforeUpload={(args) => beforeUpload(args)}
                    onChange={(e) => handleFileChanged(e)}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
              )}
            </Col>
          </Row>

          <HStack style={{ display: "flex", justifyContent: "flex-end" }}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item>
                  <Button
                    isLoading={createPassLoading}
                    width="100px"
                    danger
                    onClick={handleGoBack}
                    style={{ marginRight: "8px" }}
                  >
                    Back
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </HStack>
        </Form>
      </div>
    </div>
  );
};

export default PassForm;
