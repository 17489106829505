import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  HStack,
  Link,
  Center,
  VStack,
  Image,
  Text,
  TextArea,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { loginValidationSchema } from "./login_validation_schema";
import ScrollView from "@views/components/ui/scroll_view";
import Footer from "../footer/footer";
import Header from "../Header/header";
import {
  getOTPRequest,
  // refugeeLoginSelector,
  verifyOTPRequest,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [actionItem, setActionItem] = useState({ mobile: "" });
  const [counter, setCounter] = useState("");
  // const { status, verifyStatus, error, loading } =
  //   useSelector(refugeeLoginSelector);
  const onClickEvent = (values) => {
    if (!show) {
      setCounter(30);
      setActionItem({ mobile: values.mobile });
      dispatch(getOTPRequest({ mobile: values.mobile }));
    } else {
      setActionItem({ mobile: values.mobile, otp: values.otp });
      dispatch(verifyOTPRequest({ mobile: values.mobile, otp: values.otp }));
    }
  };
  const resendOTP = () => {
    setCounter(30);
    dispatch(getOTPRequest(actionItem));
  };
  useEffect(() => {
    if (status === "Success") {
      setShow(true);
    } else if (error)
      showToast({
        type: "error",
        message: error,
      });
    if (verifyStatus === "Success") {
      history.push(ROUTES.CAMP_TRANSFER_REQUEST);
    }
  }, [status, verifyStatus, error]);

  useEffect(() => {
    if (counter <= 30) {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter]);

  return (
    <ScrollView>
      <Header />
      <Box
        my="auto"
        flex="1"
        style={{
          // backgroundImage: `url(${"https://media.istockphoto.com/photos/blue-wall-background-picture-id1160707202?k=20&m=1160707202&s=612x612&w=0&h=kB17wN6CIhIZj7oPkJX4_N9FQ82OkBHSxsV6wMe5r1Q="})`,
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",

          backgroundImage: " linear-gradient(315deg, #f6ebe6 0%, #aee1f9 74%)",
        }}
      >
        <Center
          height={{
            base: "100%",
            xs: "400px",
            md: "500px",
            xl: "600px",
          }}
        >
          <Box
            width={{
              base: "100%",
              md: "390px",
              xs: "270px",
              sm: "400px",
              xl: "540px",
            }}
            _light={{
              bg: "white",
            }}
            _dark={{
              bg: "coolGray.800",
            }}
            px={2}
            my="auto"
            borderRadius={8}
            shadow={3}
          >
            <Form
              validationSchema={loginValidationSchema(t)}
              initialValues={actionItem}
              onSubmit={onClickEvent}
            >
              <VStack>
                <Box mx="auto" mt="5" mb="4">
                  <Image
                    source={{
                      uri: "http://www.nrtamils.tn.gov.in/static/images/en-tn-logo.png",
                    }}
                    alt="Alternate Text"
                    // _links={"https://www.facebook.com/corporationmadurai/"}
                    // size="md"
                    py="3"
                    width={{
                      base: "50px",
                      sm: "40px",

                      md: "80px",
                    }}
                    height={{
                      base: "40px",
                      sm: "40px",
                      xs: "50px",
                      md: "80px",
                    }}
                  />
                </Box>
                <Box mx="auto" mt="5" mb="4">
                  <Text fontSize="md" fontWeight="bold">
                    Refugee Login
                  </Text>
                </Box>
                <Box
                  mt={1}
                  // px="16%"
                  my="4"
                  mx="auto"
                  width={{
                    base: "100%",
                    md: "80%",
                  }}
                >
                  <Form.TextBox
                    field={"mobile"}
                    label={t("phone_number")}
                    fontSize={{
                      base: "xs",
                      xs: "sm",
                      md: "md",
                    }}
                    autoFocus={show ? false : true}
                  />
                </Box>
                {show ? (
                  <Box
                    mt={1}
                    my="3"
                    mx="auto"
                    width={{
                      base: "100%",
                      md: "80%",
                    }}
                  >
                    <Form.TextBox
                      field={"otp"}
                      label={t("otp")}
                      fontSize={{
                        base: "xs",
                        xs: "sm",
                        md: "md",
                      }}
                      autoFocus={show}
                    />
                  </Box>
                ) : null}

                {show ? (
                  <Box>
                    {counter > 0 ? (
                      <Box
                        style={{
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        <span>
                          {" "}
                          {t("Resend OTP in")} 00: {counter}{" "}
                        </span>
                      </Box>
                    ) : (
                      <Box alignItems="center">
                        <HStack space={1}>
                          {t("Didn't receive OTP?")}

                          <span
                            style={{
                              color: "blue",
                              cursor: "pointer",
                              fontSize: "14px",
                              fontWeight: "bold",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              resendOTP();
                            }}
                          >
                            Resend OTP
                          </span>
                        </HStack>
                      </Box>
                    )}
                  </Box>
                ) : (
                  " "
                )}
                <Box mx="auto" mt="3" mb="5">
                  <Form.Button
                    isLoading={loading}
                    width="100px"
                    formErrorMessage={t("error:error_message")}
                    _light={{
                      bg: "#0077c0",
                    }}
                    _text={{
                      fontSize: "md",
                      color: "white",
                      fontWeight: "600",
                    }}
                    _hover={{
                      _text: { color: "white" },
                      bg: "#125565",
                    }}
                  >
                    {!show ? "Send OTP" : "Login"}
                  </Form.Button>
                </Box>
              </VStack>
            </Form>
          </Box>
        </Center>
      </Box>
    </ScrollView>
  );
};
export default Login;
