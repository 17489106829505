import React, { useEffect, useState } from "react";
import { Box, Card, HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import {
  createGatePassSelector,
  create_gate_pass,
  updateGatePassSelector,
  update_gate_pass,
  getDepartmentListSelector,
  get_Departments,
  get_pass_typeList,
  getpass_typeListSelector,
  get_designation,
  getDesignationListSelector,
  create_one_gate_pass_clear,
  update_one_gate_pass_clear,
  get_GatePassList,
  get_one_gatepass,
  getOnegatepassSelector,
  getOneDriverSelector,
  get_one_driver,
} from "@services/redux";
import { Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { retrieveItem } from "@helpers/storage";

const DriverView = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();
  const { id } = useParams();
  const { item: driver } = useSelector(getOneDriverSelector);

  useEffect(() => {
    dispatch(get_one_driver({ id: id }));
  }, [id]);

  useEffect;
  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };

  const formatStatus = (status) => {
    switch (status) {
      case "active":
        return "Active";
      case "expired":
        return "Expired";
    }
  };

  const roles = JSON.parse(retrieveItem("roles"));

  return (
    <Box flex="1">
      <Box position="sticky" flex={1} mb="10" mt="4">
        <Text fontWeight={"bold"} fontSize="md">
          {"Driver Details"}
        </Text>
      </Box>
      <Card mb="5">
        <VStack space="5">
          <HStack justifyContent={"flex-end"}>
            {driver?.photo ? (
              <img src={driver?.photo} alt={"No Photo"} height="150px" />
            ) : (
              <Text bold>{"No Photo"}</Text>
            )}
          </HStack>
          <HStack>
            <Box flex={1}>
              <HStack>
                <Box flex={1}>
                  <Text bold>Name:</Text>
                </Box>
                <Box flex={1}>
                  <Text>{driver?.name}</Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box flex={1}>
                  <Text bold>Mobile Number:</Text>
                </Box>
                <Box flex={1}>
                  <Text>{driver?.mobile}</Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <HStack>
            <Box flex={1}>
              <HStack>
                <Box flex={1}>
                  <Text bold>Aadhar Number:</Text>
                </Box>
                <Box flex={1}>
                  <Text>{driver?.aadhar ? driver?.aadhar : "-"}</Text>
                </Box>
              </HStack>
            </Box>
            <Box flex={1}>
              <HStack>
                <Box flex={1}>
                  <Text bold>Address:</Text>
                </Box>
                <Box flex={1}>
                  {driver?.address?.line1 ? (
                    <Text>
                      {driver?.address?.line1}, {driver?.address?.line2},
                      {driver?.address?.pincode},
                      {driver?.address?.district?.name}
                    </Text>
                  ) : (
                    "-"
                  )}
                </Box>
              </HStack>
            </Box>
          </HStack>
        </VStack>
      </Card>
      <HStack justifyContent={"flex-end"}>
        <Button
          onClick={() => {
            history.goBack();
          }}
          type="default"
          danger
        >
          Back
        </Button>
      </HStack>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default DriverView;
