import { gql } from "@apollo/client";

export const login_query = gql`
  query loginUser($username: String, $password: String, $captcha: String!) {
    loginUser(username: $username, password: $password, captcha: $captcha) {
      data
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const fileQuery = gql`
  query getUploadDetails($type: String, $fileName: String) {
    getUploadDetails(type: $type, fileName: $fileName) {
      status
      access_token
      url
      file_upload_type
      error {
        status_code
        message
      }
    }
  }
`;
