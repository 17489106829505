import { getNotificationCustomMessage } from "@helpers/notification_custom_message";
import {
  getNotificationList,
  getNotificationListSelector,
} from "@services/redux";
import {
  Box,
  Text,
  Pressable,
  Divider,
  Image,
  HStack,
  Stack,
} from "native-base";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
// import { getNotificationCustomMessage } from "@helpers/notification_message_function";
import { Button, Pagination, Table } from "antd";
import data from "@assets/images/loader.gif";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { IoIosNotificationsOutline } from "react-icons/io";

const Notifications = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [current, setCurrent] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [notification_data, setNotificationData] = useState([]);
  const [notification_ids, setNotificationIds] = useState([]);
  const {
    items: notifications_data,
    total_items,
    loading,
  } = useSelector(getNotificationListSelector);
  // getNotificationList

  //   const { status: marked_read_status } = useDynamicSelector(
  //     "markNotificationsAsRead"
  //   );
  useEffect(() => {
    dispatch(
      getNotificationList({
        page_limit: 10,
        page_number: 1,
      })
    );
  }, []);
  // const onShowSizeChange = (current, value) => {
  //   dispatch(
  //     getNotificationList({
  //       page_limit: value,
  //       page_number: current,
  //     })
  //   );
  //   setPageLimit(value);
  // };
  const fetchData = (value, current) => {
    dispatch(
      getNotificationList({
        page_limit: current,
        page_number: value,
      })
    );
    setCurrent(value);
    setPageLimit(current);
  };
  const markNotifications = (ids) => {};

  useEffect(() => {
    if (notifications_data) {
      setNotificationData(notifications_data);
      let _is_read_false_ = notifications_data?.filter((x) => {
        return x.is_read === false;
      });
      let _ids = _is_read_false_?.map((x) => {
        return x.id;
      });
      setNotificationIds(_ids);
    }
  }, [notifications_data]);
  // useEffect(() => {
  //   if (notification_ids) {
  //     markNotifications(notification_ids);
  //   }
  // }, [notification_ids]);

  //   useEffect(() => {
  //     if (marked_read_status === "success") {
  //       // getAllNotificationsList();
  //     }
  //     dispatch(dynamicClear("markNotificationsAsRead"));
  //   }, [marked_read_status]);

  const UserImage = React.memo(function Image({ src }) {
    return (
      <img
        src={src}
        style={{
          objectFit: "cover",
          borderRadius: "50%",
        }}
        width={40}
        height={40}
      />
    );
  });

  const handleRedirectServiceRequest = (item) => {
    history.push({
      pathname: `${ROUTES.SERVICE_PAGE}/${item.service_request?.id}`,
      state: [item],
    });
  };

  if (loading)
    return (
      <Box alignSelf="center" marginTop="25%">
        <img src={data} />
      </Box>
    );

  return (
    <Box marginY={"5px"} width={"100%"}>
      {notification_data?.length > 0 ? (
        notification_data?.map((notify, index) => {
          return (
            <Box key={index}>
              <Pressable
                alignItems={"center"}
                onPress={() => {
                  handleRedirectServiceRequest(notify);
                }}
              >
                <Box width={"100%"} marginTop="5" marginBottom={"5"}>
                  <HStack alignItems={"center"}>
                    <HStack
                      space="5"
                      width={{
                        md: "20%",
                      }}
                    >
                      <Box>
                        {!notify?.is_read && (
                          <IoIosNotificationsOutline size="30" />
                        )}
                      </Box>
                      <Box backgroundColor={"red"} flex={1} mr="5">
                        <Text color="blue.700" bold>
                          {notify?.service_request?.vehicle?.vehicle_number}
                        </Text>
                      </Box>
                    </HStack>
                    <Text
                      // color={notify?.is_read ? "black" : "#712e5a"}
                      textAlign={"left"}
                      fontSize={14}
                      fontWeight={notify?.is_read === true ? "400" : "700"}
                    >
                      {getNotificationCustomMessage(t, notify)}
                    </Text>
                  </HStack>
                </Box>
                <Divider />
              </Pressable>
            </Box>
          );
        })
      ) : (
        <Box alignItems={"center"} justifyContent={"center"}>
          <Text bold>{t("No Notifications")}</Text>
        </Box>
      )}
      {notification_data?.length ? (
        <HStack justifyContent={"flex-end"} mt={"10px"}>
          <Pagination
            // onShowSizeChange={onShowSizeChange}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetchData}
            total={total_items}
          />
        </HStack>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Notifications;
