import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getOnefuelQuery } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "fuelGetOne",
  initialState,
  reducers: {
    _get_one_fuel: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },

    _get_one_fuel_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_fuel_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_fuel, _get_one_fuel_success, _get_one_fuel_failure } =
  slice.actions;

export const getOnefuelSelector = (state) => state.fuelGetOne;

export const getOnefuelReducer = slice.reducer;

export function get_one_fuel(variables) {
  return async (dispatch) => {
    dispatch(_get_one_fuel());
    try {
      const response = await QueryRequest(getOnefuelQuery, variables, dispatch);
      if (response?.data?.getFuel && !response?.data?.getFuel.error) {
        dispatch(_get_one_fuel_success(response?.data?.getFuel));
      } else if (response?.data?.getFuel?.error) {
        dispatch(_get_one_fuel_failure(response?.data?.getFuel.error));
      }
    } catch (error) {
      dispatch(_get_one_fuel_failure(error));
    }
  };
}
export async function get_one_fuel_clear(dispatch) {
  dispatch(_get_one_fuel());
}
