import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getServiceRequestReportQuery } from "./graphql";
const initialState = {
  loading: false,
  error: null,
  url: null,
};
const slice = createSlice({
  name: "getServiceRequestReport",
  initialState,
  reducers: {
    getServiceRequestReportInitialize: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    getServiceRequestReportReset: (state) => {
      state.loading = false;
      state.url = null;
      state.error = null;
    },
    getServiceRequestReportSuccess: (state, { payload }) => {
      state.loading = false;
      state.url = payload.url;
      state.error = null;
    },
    getServiceRequestReportFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  getServiceRequestReportInitialize,
  getServiceRequestReportSuccess,
  getServiceRequestReportFailure,
  getServiceRequestReportReset,
} = slice.actions;
export const getServiceRequestReportSelector = (state) =>
  state.getServiceRequestReport;

export const getServiceRequestReportReducer = slice.reducer;

export function getServiceRequestReport(variables) {
  return async (dispatch) => {
    dispatch(getServiceRequestReportInitialize());
    try {
      const response = await QueryRequest(
        getServiceRequestReportQuery,
        variables,
        dispatch
      );
      if (
        response?.data?.getServiceRequestReport &&
        !response?.data?.getServiceRequestReport.error
      ) {
        dispatch(
          getServiceRequestReportSuccess(
            response?.data?.getServiceRequestReport
          )
        );
      } else if (response?.data?.getServiceRequestReport?.error) {
        dispatch(
          getServiceRequestReportFailure(
            response?.data?.getServiceRequestReport.error
          )
        );
      }
    } catch (error) {
      dispatch(getServiceRequestReportFailure(error));
    }
  };
}

export function getServiceRequestReportClear() {
  return (dispatch) => {
    dispatch(getServiceRequestReportReset());
  };
}
