import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getOneAgencyQuery } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "agencyGetOne",
  initialState,
  reducers: {
    _get_one_agency: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },

    _get_one_agency_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_agency_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _get_one_agency, _get_one_agency_success, _get_one_agency_failure } =
  slice.actions;

export const getOneAgencySelector = (state) => state.agencyGetOne;

export const getOneAgencyReducer = slice.reducer;

export function get_one_agency(variables) {
  return async (dispatch) => {
    dispatch(_get_one_agency());
    try {
      const response = await QueryRequest(
        getOneAgencyQuery,
        variables,
        dispatch
      );
      if (response?.data?.getAgency && !response?.data?.getAgency.error) {
        dispatch(_get_one_agency_success(response?.data?.getAgency));
      } else if (response?.data?.getAgency?.error) {
        dispatch(_get_one_agency_failure(response?.data?.getAgency.error));
      }
    } catch (error) {
      dispatch(_get_one_agency_failure(error));
    }
  };
}
export async function get_one_agency_clear(dispatch) {
  dispatch(_get_one_agency());
}
