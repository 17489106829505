import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { service_request_process_mutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "serviceRequestProcess",
  initialState,
  reducers: {
    _create_service_request_process: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_service_request_process_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_service_request_process_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_service_request_process_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _create_service_request_process,
  _create_service_request_process_reset,
  _create_service_request_process_success,
  _create_service_request_process_failure,
} = slice.actions;

export const createServiceRequestProcessSelector = (state) =>
  state.serviceRequestProcess;

export const createServiceRequestProcessReducer = slice.reducer;

export function create_service_request_process(variables) {
  return async (dispatch) => {
    dispatch(_create_service_request_process());
    try {
      const response = await MutateRequest(
        service_request_process_mutation,
        variables,
        dispatch
      );
      if (
        response?.data?.processServiceRequest &&
        !response?.data?.processServiceRequest.error
      ) {
        dispatch(
          _create_service_request_process_success(
            response?.data?.processServiceRequest
          )
        );
      } else if (response?.data?.processServiceRequest?.error) {
        dispatch(
          _create_service_request_process_failure(
            response?.data?.processServiceRequest.error
          )
        );
      }
    } catch (error) {
      dispatch(_create_service_request_process_failure(error));
    }
  };
}
// export function create_one_position_clear(dispatch) {
//   return async (dispatch) => {
//     dispatch(_create_position_reset());
//   };
// }
export function create_one_service_process_clear(dispatch) {
  dispatch(_create_service_request_process_reset());
}
