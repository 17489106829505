import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { updateDriverMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "driverUpdate",
  initialState,
  reducers: {
    _update_driver: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_driver_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _update_driver_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_driver_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _update_driver,
  _update_driver_reset,
  _update_driver_success,
  _update_driver_failure,
} = slice.actions;

export const updateDriverSelector = (state) => state.driverUpdate;

export const updateDriverReducer = slice.reducer;

export function update_driver(variables) {
  return async (dispatch) => {
    dispatch(_update_driver());
    try {
      const response = await MutateRequest(
        updateDriverMutation,
        variables,
        dispatch
      );
      if (response?.data?.updateDriver && !response?.data?.updateDriver.error) {
        dispatch(_update_driver_success(response?.data?.updateDriver));
      } else if (response?.data?.updateDriver?.error) {
        dispatch(_update_driver_failure(response?.data?.updateDriver.error));
      }
    } catch (error) {
      dispatch(_update_driver_failure(error));
    }
  };
}
export async function update_one_driver_clear(dispatch) {
  dispatch(_update_driver_reset());
}
