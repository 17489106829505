import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  HStack,
  VStack,
  Text,
  FormControl,
  Modal as NativeModal,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import data from "@assets/images/loader.gif";

import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";

import { Timeline, TimelineEvent } from "react-event-timeline";
import { AiFillCar } from "react-icons/ai";

import {
  getTyre_Makers,
  create_allotment_reset,
  getAll_Agency,
  getAgencyListSelector,
  getAll_Accessories,
  getAccessoriesListSelector,
  create_Allotments,
  getCreateAllotmentsListSelector,
  getone_Vehicles,
  getOneVehiclesListSelector,
  get_Departments,
  getDesignationListSelector,
  get_designation,
  getDriverListSelector,
  get_DriverList,
  getMakerListSelector,
  getDepartmentListSelector,
  getCar_Makers,
} from "@services/redux";
import ReactSelect from "react-select";
import ActionButton from "../../components/ui/table/action_button";

import unicConvertes from "../../../helpers/date";

import Modal from "@views/components/ui/modal";

import ExportDetails from "./vehicle_return_details";
import { Button, Form as AntdForm } from "antd";
import { retrieveItem } from "@helpers/storage";

const VechilceAllotment = (props) => {
  const dispatch = useDispatch();
  const roles = JSON.parse(retrieveItem("roles"));
  const [form] = AntdForm.useForm();

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [makerChoose, setMakerChoose] = useState("");
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [currentMakerModels, setCurrentMakerModels] = useState("");
  const [model, setModel] = useState(false);
  const [returnDialogVisible, setReturnDialogVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const { item: vehicle_get_one } = useSelector(getOneVehiclesListSelector);
  const { items: maker_items } = useSelector(getMakerListSelector);
  const { items: accessories_items } = useSelector(getAccessoriesListSelector);
  const { items: designations_items } = useSelector(getDesignationListSelector);

  const { items: department_items } = useSelector(getDepartmentListSelector);

  const { createStatus, loading } = useSelector(
    getCreateAllotmentsListSelector
  );
  const { items: driver } = useSelector(getDriverListSelector);
  useEffect(() => {
    dispatch(getCar_Makers({}));
    dispatch(getTyre_Makers({}));
    dispatch(getAll_Agency({}));
    dispatch(getAll_Accessories({}));
    dispatch(get_Departments({}));
    dispatch(get_designation({}));
    dispatch(get_DriverList());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getone_Vehicles({ id: id }));
    }
  }, [id]);
  // useEffect(() => {
  //   let vehicle = {};
  //   if (vehicle_get_one?.id) {
  //     vehicle = {
  //       ...vehicle_get_one,
  //     };
  //     setActionItem(vehicle);
  //   } else {
  //     form.resetFields();
  //     setActionItem({
  //       allotment_copy: [
  //         {
  //           uid: "-1",
  //           name: "Photo",
  //           status: "done",
  //           url: "",
  //         },
  //       ],
  //     });
  //   }
  // }, [vehicle_get_one]);
  const handleBack = () => {
    history.push(ROUTES.VEHICLE_LIST);
  };

  const onValueChange = (values) => {
    if (values.vechile_make) {
      setMakerChoose(values.vechile_make);
    }
  };

  const handleReturnDialogVisibleClose = () => {
    setReturnDialogVisible(false);
  };

  React.useEffect(() => {
    if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `Allotment Updated Successfully`,
      });
      dispatch(create_allotment_reset);
      history.push(ROUTES.VEHICLE_LIST);
    } else if (createStatus === "Failure") {
      showToast({
        type: "warn",
        message: `Something Went wrong`,
      });
    }
  }, [createStatus]);

  useEffect(() => {
    let currentmakerChoose = [];

    if (makerChoose) {
      currentmakerChoose = maker_items.find(
        (x) => x.id === makerChoose
      )?.car_models;
    }

    setCurrentMakerModels(currentmakerChoose);
  }, [makerChoose]);

  const handleSubmit = (values) => {
    values.vehicle_id = id;
    // values.allotment_copy = values.allotment_copy?.[0]?.url;
    setActionItem(values);
    setManageModalVisible(true);
  };

  const handleAlot = (values) => {
    dispatch(
      create_Allotments({
        data: {
          ...actionItem,
          allotment_copy: actionItem.allotment_copy?.[0]?.url,
        },
      })
    );
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const handleReturn = (alloted_id) => {
    history.push({
      pathname: `${ROUTES.RETURN_PAGE}/${id}/${alloted_id}`,
    });
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  useEffect(() => {}, [vehicle_get_one]);

  return (
    <Fragment>
      <Box flex="1" w="100%">
        <Box top="10px" zIndex="2" bg="white">
          <Text fontSize="md" fontWeight="bold" py={4}>
            {t("Vehicle Allotment")}
          </Text>
        </Box>
        {!vehicle_get_one?.allotted_department?.name ? (
          <Fragment>
            <Box position="sticky" top="0px" zIndex="2" bg="white"></Box>
            <br />
            <Form
              onSubmit={handleSubmit}
              onValueChange={onValueChange}
              form={form}
              initialValues={actionItem}
            >
              <VStack space={10} mt={5}>
                <HStack flex={1} space={10}>
                  <Box flex={1}>
                    <Form.TextBox
                      field={"alloted_to"}
                      label={t("Allotted to")}
                    />
                  </Box>
                  <Box flex={1}>
                    <Form.Select
                      field={"department_id"}
                      label={t("Alloted Department")}
                      options={department_items}
                      labelField={"name"}
                      valueField={"id"}
                      isSearchable={true}
                      isClearable={true}
                      isMulti={false}
                      rules={[
                        {
                          required: true,
                          message: "Alloted Department  is required",
                        },
                      ]}
                    />
                  </Box>
                </HStack>
                <HStack flex={1} space={10}>
                  <Box flex={1}>
                    <Form.Select
                      field={"designation_id"}
                      label={t("form:designation_name")}
                      options={designations_items}
                      labelField={"name"}
                      valueField={"id"}
                      isSearchable={true}
                      isClearable={true}
                      isMulti={false}
                      rules={[
                        {
                          required: true,
                          message: "Designation is required",
                        },
                      ]}
                    />
                  </Box>
                  <Box flex={1}>
                    <Form.TextBox
                      field={"allotment_number"}
                      label={t("form:allotment_number")}
                    />
                  </Box>
                </HStack>
                <HStack flex={1} space={10}>
                  <Box flex={1}>
                    <Form.Date
                      field={"allotment_date_time"}
                      label={t("form:alloted_on")}
                    />
                  </Box>
                  <Box flex={1}>
                    <Form.File
                      field={"allotment_copy"}
                      label={t("form:allotment_copy")}
                    />
                  </Box>
                </HStack>
                <HStack flex={1} space={10}>
                  <Box flex={1}>
                    <Form.Select
                      field={"driver_id"}
                      label={t("form:driver_name")}
                      options={driver}
                      labelField={"name"}
                      valueField="id"
                      isClearable={true}
                    />
                  </Box>
                  <Box flex={1}>
                    <Form.TextBox
                      field={"meter_reading"}
                      label={t("form:meter_reading")}
                    />
                  </Box>
                </HStack>

                <HStack space={2} justifyContent="flex-end">
                  {roles?.[0]?.name !== "Admin" && (
                    <Form.Button
                      isLoading={false}
                      width="100px"
                      colorScheme="primary"
                      height="45px"
                      label={id ? t("update") : t("submit")}
                      formErrorMessage={t("error:error_message")}
                    >
                      {id ? t("update") : t("submit")}
                    </Form.Button>
                  )}
                  <Button
                    isLoading={false}
                    width="100px"
                    colorScheme="danger"
                    variant="outline"
                    onClick={handleBack}
                    formErrorMessage={t("error:error_message")}
                  >
                    {t("Back")}
                  </Button>
                </HStack>
              </VStack>
            </Form>
          </Fragment>
        ) : (
          ""
        )}

        {vehicle_get_one?.allotments?.length > 0 ? (
          <HStack>
            <Box flex={1} borderColor="grey">
              <Timeline>
                {vehicle_get_one?.allotments.map((item, index) => (
                  <TimelineEvent
                    createdAt={unicConvertes(item?.allotment_date_time)}
                    icon={<AiFillCar />}
                    collapsible
                    showContent={index === 0 ? true : false}
                  >
                    <VStack space={4} mt={7} flex={1}>
                      <VStack flex={1}>
                        <HStack flex={1} space={10}>
                          <HStack flex={1} space={2}>
                            {item?.department ? (
                              <Box>
                                <Text fontSize="md" fontWeight="bold">
                                  {t("Allotment GO Date :")}
                                </Text>
                              </Box>
                            ) : (
                              <Box>
                                <Text fontSize="md" fontWeight="bold">
                                  {t("Return Date :")}
                                </Text>
                              </Box>
                            )}
                            <HStack>
                              <Box>
                                <Text fontSize="md">
                                  {unicConvertes(item?.allotment_date_time)}
                                </Text>
                              </Box>
                            </HStack>
                          </HStack>
                          <HStack flex={1} space={2}>
                            <Box>
                              <Text fontSize="md" fontWeight="bold">
                                {t("Allotment GO Number :")}
                              </Text>
                            </Box>
                            <Box>
                              <Text fontSize="md">
                                {item?.allotment_number}
                              </Text>
                            </Box>
                          </HStack>

                          <HStack>
                            {vehicle_get_one?.allotted_department?.name ? (
                              <VStack>
                                <ActionButton
                                  height="50px"
                                  label={t("Return")}
                                  colorScheme="primary"
                                  variant="outline"
                                  onPress={() => {
                                    handleReturn(item?.id);
                                  }}
                                />
                              </VStack>
                            ) : (
                              ""
                            )}
                          </HStack>
                        </HStack>
                      </VStack>
                      <VStack flex={1} space={10}>
                        <HStack width={"730px"} Space={10}>
                          <HStack flex={1} space={2}>
                            <Box>
                              <Text fontSize="md" fontWeight="bold">
                                {t("Allotted To:")}
                              </Text>
                            </Box>
                            <Box>
                              <Text fontSize="md">{item?.alloted_to}</Text>
                            </Box>
                          </HStack>
                          <HStack flex={1} space={2}>
                            <Box>
                              <Text fontSize="md" fontWeight="bold">
                                {t("Allotted Department:")}
                              </Text>
                            </Box>
                            <Box>
                              <Text fontSize="md">
                                {item?.department?.name}
                              </Text>
                            </Box>
                          </HStack>
                        </HStack>
                      </VStack>
                      <VStack flex={1} space={10}>
                        <HStack width={"730px"} Space={10}>
                          <HStack flex={1} space={2}>
                            <Box>
                              <Text fontSize="md" fontWeight="bold">
                                {t("designation")}
                              </Text>
                            </Box>
                            <Box>
                              <Text fontSize="md">
                                {item?.designation?.name}
                              </Text>
                            </Box>
                          </HStack>
                          <HStack flex={1} space={2}>
                            <Box>
                              <Text fontSize="md" fontWeight="bold">
                                {t("Meter Reading:")}
                              </Text>
                            </Box>
                            <Box>
                              <Text fontSize="md">{item?.meter_reading}</Text>
                            </Box>
                          </HStack>
                        </HStack>
                      </VStack>

                      <VStack flex={1}>
                        <HStack width={"730px"}>
                          <HStack flex={1} space={0}>
                            <Box>
                              <Text fontSize="md" fontWeight="bold">
                                {t("Driver Number :")}
                              </Text>
                            </Box>
                            <Box>
                              <Text fontSize="md">{item?.driver?.mobile}</Text>
                            </Box>
                          </HStack>
                          <HStack flex={1} space={2}>
                            <Box>
                              <Text fontSize="md" fontWeight="bold">
                                {t("Driver Name :")}
                              </Text>
                            </Box>
                            <Box>
                              <Text fontSize="md">{item?.driver?.name}</Text>
                            </Box>
                          </HStack>
                        </HStack>
                      </VStack>
                    </VStack>
                  </TimelineEvent>
                ))}
              </Timeline>
            </Box>
          </HStack>
        ) : (
          ""
        )}

        <Box mt="10px" width="200px" zIndex={1}></Box>
      </Box>
      <NativeModal isOpen={manageModalVisible} onClose={handleModalClose}>
        <NativeModal.Content maxWidth="400px">
          <NativeModal.CloseButton />
          <NativeModal.Header>Create Allotment</NativeModal.Header>
          <NativeModal.Body>
            <FormControl>
              <FormControl.Label color="black">
                {`${t("Are you sure you want allot this")} ${""}${
                  vehicle_get_one.vehicle_number
                } ${t("Vehicle")} ${"?"}`}
              </FormControl.Label>
            </FormControl>
          </NativeModal.Body>
          <NativeModal.Footer>
            <HStack space={19}>
              <Button onClick={handleAlot} isLoading={loading} width="50px">
                Yes
              </Button>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onClick={handleModalClose}
              >
                Cancel
              </Button>
            </HStack>
          </NativeModal.Footer>
        </NativeModal.Content>
      </NativeModal>

      <Modal
        isOpen={returnDialogVisible}
        onClose={handleReturnDialogVisibleClose}
        header={t("Return Vehicle")}
        component={
          <ExportDetails
            vehicle_id={id}
            handleModalClose={handleReturnDialogVisibleClose}
            initialValue={actionItem}
            allotmentsLast={vehicle_get_one ? vehicle_get_one[0] : []}
          />
        }
        source={actionItem}
        group={"User"}
        size={"lg"}
      />
    </Fragment>
  );
};
export default VechilceAllotment;
