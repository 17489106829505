import React from "react";
import { Box, HStack, Center, Text, VStack, Link } from "native-base";
import vertace_logo from "@assets/images/vertace_logo.png";
import VERTACELOGO from "@assets/images/VERTACELOGO.png";
import FooterBar from "./footer_bar";
import FooterBarss from "./footerBars";
let defaultStyles = {
  height: "50px",
};

const FooterBars = (props) => {
  let height = props.height || props.h || defaultStyles.height;

  return (
    <Box h={height} w="100%" bg="#ffffff" position="relative">
      <HStack
        _light={{
          bg: "white",
        }}
        justifyContent="space-between"
        px="5"
        pb="10"
      >
        <Text color="black" font-size="lg" mt={4}>
          {new Date().getFullYear()} © Public Vehicle Management
        </Text>
        <Text color="black" font-size="lg" mt={4}>
          Powered by {""}
          <a href="https://vertace.com/" target={"_blank"}>
            <Text
              fontSize="18px"
              color="black"
              fontWeight={"bold"}
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              Vertace
            </Text>
          </a>
        </Text>
      </HStack>
    </Box>
  );
};

export default FooterBars;
