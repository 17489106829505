import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { deleteCarMakeMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "carMakeDelete",
  initialState,
  reducers: {
    _delete_carmake: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_carmake_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_carmake_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const { _delete_carmake, _delete_carmake_success, _delete_carmake_failure } =
  slice.actions;

export const deleteCarMakeSelector = (state) => state.carMakeDelete;

export const deleteCarMakeReducer = slice.reducer;

export function delete_carmake(variables) {
  return async (dispatch) => {
    dispatch(_delete_carmake());
    try {
      const response = await MutateRequest(
        deleteCarMakeMutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteCarMake &&
        !response?.data?.deleteCarMake.error
      ) {
        dispatch(_delete_carmake_success(response?.data?.deleteCarMake));
      } else if (response?.data?.deleteCarMake?.error) {
        dispatch(_delete_carmake_failure(response?.data?.deleteCarMake.error));
      }
    } catch (error) {
      dispatch(_delete_carmake_failure(error));
    }
  };
}
export function delete_one_carmake_modal(dispatch) {
  return async (dispatch) => {
    dispatch(_delete_carmake());
  };
}
