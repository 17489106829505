import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getDesignations } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "designationList",
  initialState,
  reducers: {
    _get_designation: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_designation_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_designation_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_designation, _get_designation_success, _get_designation_failure } = slice.actions;

export const getDesignationListSelector = (state) => state.designationList;

export const getDesignationListReducer = slice.reducer;

export function get_designation(variables) {
  return async (dispatch) => {
    dispatch(_get_designation());
    try {
      const response = await QueryRequest(
        getDesignations,
        variables,
        dispatch
      );
      if (response?.data?.getDesignations && !response?.data?.getDesignations.error) {
        dispatch(_get_designation_success(response?.data?.getDesignations));
      } else if (response?.data?.getDesignations?.error) {
        dispatch(_get_designation_failure(response?.data?.getDesignations.error));
      }
    } catch (error) {
      dispatch(_get_designation_failure(error));
    }
  };
}
