import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  HStack,
  VStack,
  Center,
  Text,
  Pressable,
  Card,
  Stack,
} from "native-base";
import {
  dashboardSelector,
  dashboard,
  dashboard_fuelSelector,
  dashboard_fuel,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useParams } from "react-router-dom";
import { retrieveItem, useStorageItem } from "@helpers/storage";
import data from "@assets/images/loader.gif";
import moment from "moment";
// import { VStack, HStack, Spacer } from 'react-native-stacks';

const HomeDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const roles = JSON.parse(retrieveItem("roles")) || {};
  const { name } = retrieveItem("user") || {};

  useEffect(() => {
    dispatch(dashboard());
    // dispatch(dashboard_fuel());
  }, []);

  const { item, loading } = useSelector(dashboardSelector);
  const { item: fuel, loading: fuel_loading } = useSelector(
    dashboard_fuelSelector
  );
  const handleToggle = () => {
    // history.push(ROUTES.VEHICLE_LIST);
  };
  const handleAllotments = (value) => {
    history.push(`${ROUTES.VEHICLE_LIST}/${value}`);
  };

  //   if (loading)
  //     return (
  //       <Box alignSelf="center" marginTop="25%">
  //         <img src={data} />
  //       </Box>
  //     );
  let currentDate = "";
  currentDate = new Date().toLocaleDateString();

  return (
    <Box width="100%" alignItems="center">
      {/* <Text fontSize="3xl" bold pt={4} pb={4}>
        Dashboard
      </Text> */}
      <HStack
        space={5}
        pb={2}
        flexDirection={{
          base: "column",
          md: "row",
        }}
      >
        <Card
          bgImage="linear-gradient(154deg, rgb(34 173 134) 0%, rgb(67 217 169 / 83%) 85%)"
          borderRadius={"100%"}
          height={"200px"}
          width="200px"
          style={{ justifyContent: "center" }}
        >
          <Center>
            <HStack justifyContent="space-between">
              <Text fontSize="3xl" bold color="white">
                {item?.security_audit?.testing?.total}
              </Text>
              <Text fontSize="lg" bold color="white" pt={2}>
                Total Vehicles
              </Text>
            </HStack>

            <Text fontSize="3xl" bold color="white">
              {item?.total_vehicles}
            </Text>
          </Center>
        </Card>

        <Box
          mt={{
            base: "10",
            md: "0",
          }}
          bgImage=" linear-gradient(315deg, rgb(204 131 217 / 84%) 0%, rgb(149 38 203) 74%)"
          borderRadius={"100%"}
          height={"200px"}
          width="200px"
          style={{ justifyContent: "center" }}
        >
          <Center>
            <HStack justifyContent="space-between">
              <Text fontSize="md" color="white" textAlign={"center"}>
                {item?.security_audit?.fixing?.total}
              </Text>
              <Text
                fontSize="lg"
                bold
                color="white"
                pt={2}
                textAlign={"center"}
              >
                Given For Service
              </Text>
            </HStack>
            <Text fontSize="3xl" color="white" justifyContent="center" bold>
              {item?.on_service}
            </Text>
          </Center>
        </Box>
      </HStack>

      <HStack
        space={5}
        pt={5}
        flexDirection={{
          base: "column",
          md: "row",
        }}
      >
        <Card
          bgImage=" linear-gradient(321deg, rgb(3 219 241 / 67%) 0%, rgb(63 81 181 / 92%) 74%)"
          borderRadius={"100%"}
          height={"200px"}
          width="200px"
          style={{ justifyContent: "center" }}
        >
          <Center>
            <Text fontSize="lg" bold color="white">
              Allocated
            </Text>
            <Text fontSize="3xl" bold color="white">
              {item?.allotted}
            </Text>
          </Center>
        </Card>
        <Box
          mt={{
            base: "10",
            md: "0",
          }}
          bgImage="linear-gradient(295deg, rgb(23 179 78 / 89%) 0%, rgb(73 214 15 / 86%) 74%)"
          borderRadius={"100%"}
          height={"200px"}
          width="200px"
          style={{ justifyContent: "center" }}
        >
          <Center>
            <Text fontSize="lg" color="white" bold>
              Carpool
            </Text>
            <Text fontSize="3xl" color="white" bold>
              {item?.not_allotted}
            </Text>
          </Center>
        </Box>
        <Card
          mt={{
            base: "10",
            md: "0",
          }}
          bgImage=" linear-gradient(315deg, rgb(103 58 183 / 52%) 0%, rgb(37 51 221 / 76%) 74%)"
          borderRadius={"100%"}
          height={"200px"}
          width="200px"
          style={{ justifyContent: "center" }}
        >
          <Center>
            <Text fontSize="lg" color="white" bold textAlign={"center"}>
              Condemned
            </Text>
            <Text fontSize="3xl" color="white" bold textAlign={"center"}>
              {item?.condemned}
            </Text>
          </Center>
        </Card>
      </HStack>
    </Box>
  );
};
export default HomeDashboard;
