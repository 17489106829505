import moment from "moment";
import * as XLSX from "xlsx";

export const ProjectName = "Public Vehicle Management";
export const colorScheme = {
  background_color: "#80808012",
  transparent: "transparent",
};

export const excelDownload = (items, labels, fileName) => {
  const dataWithSno = arrange_xlsx_report_data(items, labels);
  export_to_xlsx(dataWithSno, fileName);
};

export const arrange_xlsx_report_data = (dataItems, labels) => {
  const rearranged_data = [];
  for (let i = 0; i < dataItems?.length; i++) {
    let rowData = {
      ["S.No"]: i + 1,
      ...dataItems[i],
    };
    rearranged_data.push(rowData);
  }
  return rearranged_data;
};

export const export_to_xlsx = (data, fileName) => {
  const style = {
    alignment: {
      horizontal: "center",
      vertical: "center",
    },
  };

  const ws = XLSX.utils.json_to_sheet(data, {
    header: Object.keys(data[0]),
    styles: { header: [style], cell: [style] },
    autoSize: true,
  });

  const formattedDate = moment().format("DD-MM-YYYY");
  const formattedTime = moment().format("HH.mm.ss");

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");

  XLSX.writeFile(
    workbook,
    `${fileName}-${formattedDate}-${formattedTime}-hrs.xlsx`
  );
};
