import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Text, Pressable } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineCarryOut,
  AiOutlineHistory,
  AiOutlineEye,
} from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import { ROUTES } from "@views/routes/my_routes";
import { showToast } from "@helpers/toast";
import {
  getVehiclesListSelector,
  getAll_Vehicles,
  getCar_Makers,
  getMakerListSelector,
  getone_vechile_clear,
  getDepartmentListSelector,
  get_Departments,
  getUpdateVehiclesListSelector,
  update_one_vehicle_clear,
  getVehicleReportClear,
  getVehicleReportSelector,
  getVehicleReport,
  getAll_Vehicles_without_pagination,
  getVehicles_without_paginationListSelector,
  get_vehicle_clear,
  get_vehicles_without_pagination_clear,
} from "@services/redux";
import ReactSelect from "react-select";

import { FuelUsed } from "@views/components/source/constant";
import { Button, Checkbox, Drawer } from "antd";
import { retrieveItem } from "@helpers/storage";
import { colorScheme, excelDownload } from "@helpers/constants";
import { DrawerLayoutAndroid } from "react-native-web";
const VehicleList = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { status, department_id } = useParams();
  const [actionType, setActionType] = useState(null);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [actionItem, setActionItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [open_filter, setVehicleFilter] = useState(false);
  const [search_string, setSearchString] = useState("");
  const { t } = useTranslation();
  const { id } = useParams();
  const [dep, setDep] = useState([]);
  const [object, setObject] = useState(null);
  const [depsId, setDepsId] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [make, setMake] = useState([]);
  const [makerStatus, setMakerStatus] = useState([]);
  const [choosemakerId, setChoosemakerId] = useState(null);
  const [isImageUploaded, setIsImageUploaded] = useState(null);

  const [models, setModels] = useState("");
  const [showId, setShowId] = useState(null);
  const [fuelType, setFuelType] = useState("");
  const [data, setData] = useState([]);
  const roles = JSON.parse(retrieveItem("roles"));

  const [currentMakerModels, setCurrentMakerModels] = useState([]);
  const { items: department_list } = useSelector(getDepartmentListSelector);

  const {
    items: vehicle_List,
    total_items,
    loading: vechicleLoading,
    sort_column,
    sort_order,
  } = useSelector(getVehiclesListSelector);
  const { items: vehicle_items, loading: vehicle_items_loading } = useSelector(
    getVehicles_without_paginationListSelector
  );
  console.log("vehicle_items", vehicle_items);
  const { items: maker_items } = useSelector(getMakerListSelector);

  const { updateStatus } = useSelector(getUpdateVehiclesListSelector);
  const { url: vehicle_report, loading: vehicle_loading } = useSelector(
    getVehicleReportSelector
  );
  const handleAdd = () => {
    dispatch(getone_vechile_clear);
    history.push(ROUTES.VEHICLE_DETAILS);
  };

  const handleAllotment = (item) => {
    history.push({
      pathname: `${ROUTES.VEHICLE_ALLOTMENT}/${item.id}`,
    });
  };
  const handleHistory = (item) => {
    history.push({
      pathname: `${ROUTES.SERVICE_HISTORY_DETAILS}/${item.id}`,
    });
  };
  const handleEdit = (item) => {
    history.push({
      pathname: `${ROUTES.VEHICLE_TAB}/${item.id}/vehicle`,
      state: [item],
    });
  };
  const handleView = (item) => {
    history.push({
      pathname: `${ROUTES.VEHICLE_TAB_VIEW}/${item.id}`,
      state: [item],
    });
  };
  const handleModalClose = () => {
    setManageModalVisible(false);
  };
  useEffect(() => {
    dispatch(getCar_Makers({}));
    dispatch(get_Departments());
  }, []);

  useEffect(() => {
    let temp = vehicle_List.map((x, i) => ({
      ...x,
      slno: (page_number - 1) * page_limit + i + 1,
    }));
    setData(temp);
    // dispatch(get_vehicle_clear());
  }, [vehicle_List]);

  useEffect(() => {
    if (vehicle_report) {
      window.open(vehicle_report);
      dispatch(getVehicleReportClear());
    }
  }, [vehicle_report]);

  useEffect(() => {
    if (status === "true") {
      setShowId(true);
    } else if (status === "false") {
      setShowId(false);
    }
  }, [status]);
  React.useEffect(() => {
    if (updateStatus === "Success") {
      showToast({
        type: "success",
        message: `Vehicle Updated Successfully`,
      });
      setDialogVisible(false);
      dispatch(update_one_vehicle_clear);
      // dispatch(getAll_Vehicles());
    } else if (updateStatus === "Failure") {
      showToast({
        type: "warn",
        message: `Something Went wrong`,
      });
      setDialogVisible(false);
    }
  }, [updateStatus]);

  useEffect(() => {
    let temp = maker_items.map((x) => {
      return { label: x.name, value: x.id, car_models: x.car_models };
    });
    setMakerStatus(temp);
  }, [maker_items]);

  useEffect(() => {
    let currentmakerChoose = [];

    if (choosemakerId) {
      currentmakerChoose = maker_items.find(
        (x) => x.id === choosemakerId.value
      )?.car_models;
    }

    setCurrentMakerModels(currentmakerChoose);
  }, [choosemakerId]);

  useEffect(() => {
    if (currentMakerModels) {
      let temp = currentMakerModels?.map((x) => {
        return { label: x.name, value: x.id };
      });
      setMake(temp);
    }
  }, [currentMakerModels]);

  useEffect(() => {
    let temp = department_list.map((x) => {
      return { label: x.name, value: x.id };
    });
    setDep(temp);
  }, [department_list]);

  useEffect(() => {
    setObject({
      // ...object,
      department_id: depsId || department_id,
      is_allocated: showId,
      car_model_id: models,
      car_make_id: choosemakerId?.value,
      fuel_used: fuelType,
      image_upload: isImageUploaded,
      search_string: "",
    });
  }, [
    depsId,
    showId,
    models,
    choosemakerId,
    fuelType,
    status,
    isImageUploaded,
    department_id,
  ]);

  const carModalFormat = (value) => {
    return value.car_make?.name + " - " + value.name;
  };
  const carAllotmentFormat = (value) => {
    return value?.allotted_department?.name;
  };

  const allotDesignation = (value) => {
    return value?.allotments?.[0]?.designation?.name;
  };

  const vehicleDetails = (val) => {
    return (
      <Pressable
        // style={{
        //   textDecoration: "none",
        // }}
        width={"100px"}
        onPress={() => {
          roles?.[0]?.name === "Department" ||
          roles?.[0]?.name === "Public Department"
            ? handleEdit(val)
            : handleView(val);
        }}
      >
        <Text color={"blue.700"}>{val.vehicle_number}</Text>
      </Pressable>
    );
  };

  const show = [
    {
      label: "Allotted vehicles",
      value: true,
    },
    {
      label: "Carpool",
      value: false,
    },
  ];

  let actions = [
    {
      icon: <AiOutlineEdit />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("table:edit_vehicle"),
      onPress: handleEdit,
    },
  ];

  let actions_1 = [
    {
      icon: <AiOutlineEye />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("table:view_vehicle"),
      onPress: handleView,
    },
  ];
  const formatDisplay = (date) => {
    if (date && typeof date === "string") {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    } else return "-";
  };
  let columns = [
    {
      header: t("table:s_no"),
      dataIndex: "slno",
      key: "s_no",
      cursor: "pointer",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:reg_no"),
      dataIndex: "",
      key: "vehicle_number",
      cursor: "pointer",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: vehicleDetails,
    },
    // {
    //   header: t("table:reg_no"),
    //   dataIndex: "id",
    //   formatDisplay: vehicleDetails,
    //   sortable: false,
    //   key: "link",
    //   baseUrl: "/vehicle-tab/",
    //   type: "link",
    //   align: "left",
    //   flex: 1,
    // },
    {
      header: t("table:car_model"),
      dataIndex: "car_model",
      key: "model",
      sortable: false,
      align: "left",
      flex: 1,
      width: 200,
      formatDisplay: carModalFormat,
    },
    {
      header: t("table:year_of_purchase"),
      dataIndex: "date_of_delivery",
      key: "year_of_pUrchase",
      sortable: false,
      align: "left",
      flex: 1,
      width: 200,
      formatDisplay: formatDisplay,
    },
    // {
    //   header: t("table:color"),
    //   dataIndex: "colour",
    //   key: "color",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },

    {
      header: t("table:department"),
      dataIndex: "",
      key: "model",
      sortable: false,
      align: "left",
      flex: 1,
      width: 300,
      formatDisplay: status === "false" ? "" : carAllotmentFormat,
    },

    {
      header: t("table:designation"),
      dataIndex: "",
      key: "designation",
      cursor: "pointer",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: status === "false" ? "" : allotDesignation,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "left",
      type: "actions",
      width: 200,
      actions:
        roles?.[0]?.name === "Department" ||
        roles?.[0]?.name === "Public Department"
          ? actions
          : actions_1,
    },
  ];
  const handleFilterOpenModel = () => {
    setVehicleFilter(true);
  };
  let header_actions = [
    {
      height: "50px",
      label: t("table:add_vehicle"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
    // {
    //   height: "50px",
    //   label: t("table:filter"),
    //   colorScheme: "primary",
    //   variant: "outline",
    //   onPress: handleFilterOpenModel,
    // },
  ];

  const handleVehicleReport = () => {
    dispatch(
      getVehicleReport({
        department_id: depsId,
        is_allocated: showId,
        car_model_id: models,
        car_make_id: choosemakerId?.value,
        search_string: search_string,
        fuel_used: fuelType,
        image_upload: isImageUploaded,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getAll_Vehicles_without_pagination({
        department_id: depsId,
        is_allocated: status === "true" ? true : showId,
        car_model_id: models,
        car_make_id: choosemakerId?.value,
        search_string: search_string,
        fuel_used: fuelType,
        image_upload: isImageUploaded,
      })
    );
    dispatch(get_vehicles_without_pagination_clear());
  }, [
    depsId,
    showId,
    models,
    choosemakerId,
    search_string,
    fuelType,
    isImageUploaded,
  ]);

  const XLSX_download = () => {
    const labels = [
      "Vehicle Number",
      "Vehicle Class",
      "Vehicle Make",
      "Vehicle Modal",
      "Colour",
      "Fuel Used",
      "Engine Number",
      "Chassis Number",
      "Department",
      "Allotment Date",
      "Designation",
      "Driver Name",
      "Driver Number",
      "Date of purchase",
    ];

    const excel_items = vehicle_items?.map((vehicle, index) => {
      const latestAllotment = vehicle.allotments.reduce(
        (latest, current, currentIndex) => {
          if (
            !latest.allotment_date_time ||
            new Date(current.allotment_date_time) >
              new Date(latest.allotment_date_time)
          ) {
            return {
              allotment_date_time: current.allotment_date_time,
              departmentName: current.department?.name,
            };
          } else {
            return latest;
          }
        },
        { allotment_date_time: null, departmentName: null }
      );

      console.log("latestAllotment", latestAllotment);

      let vehicle_data = {
        ["Vehicle Number"]: vehicle?.vehicle_number,
        ["Class Vehicle"]: vehicle?.class_vehicle,
        ["Vehicle Make"]: vehicle?.car_model?.car_make?.name,
        ["Vehicle Modal"]: vehicle?.car_model?.name,
        ["Colour"]: vehicle?.colour,
        ["Fuel Used"]: vehicle?.fuel_used,
        ["Engine Number"]: vehicle?.engine_number,
        ["Chassis Number"]: vehicle?.chasis_number,
        ["Department"]:
          status === "false" ? " " : latestAllotment.departmentName ?? "-",
        ["Allotment Date"]: latestAllotment.allotment_date_time ?? "-",
        ["Designation"]:
          status === "false"
            ? " "
            : vehicle?.allotments[0]?.designation?.name ?? "-",
        ["Driver Name"]: vehicle?.driver_name ?? "-",
        ["Driver Number"]: vehicle?.driver_number ?? "-",
        ["Date of purchase"]: vehicle?.date_of_purchase ?? "-",
      };
      return vehicle_data;
    });
    dispatch(get_vehicle_clear());

    excelDownload(excel_items, labels, "Vehicle Report");
  };

  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <Box
          top="0px"
          zIndex="2"
          bg="white"
          backgroundColor={colorScheme.transparent}
        >
          <Text fontSize="md" fontWeight="bold" py={4}>
            {t("Vehicle List")}
          </Text>
        </Box>
        <VStack space={5}>
          <HStack space={4} zIndex={10}>
            {/* <Drawer
                placement="right"
                title="Vehicles Filter"
                open={open_filter}
              > */}
            <Box flex={1}>
              <Text>{"Show"}</Text>
              <ReactSelect
                value={show?.find((r) => r.value === showId)}
                options={show}
                onChange={(entity, Object) => {
                  if (entity) setShowId(entity.value);
                  else setShowId(null);
                }}
                isClearable={true}
              />
            </Box>
            {(roles?.[0]?.name === "Admin" ||
              roles?.[0]?.name === "Public Department") && (
              <Box flex={1}>
                <Text>{"Department"}</Text>
                <ReactSelect
                  value={dep.find((x) => x.value === department_id)}
                  // value={dep?.find((r) => r.value === depsId || department_id)}
                  options={dep}
                  onChange={(entity, Object) => {
                    if (entity) setDepsId(entity.value);
                    else setDepsId(null);
                  }}
                  isClearable={true}
                  isDisabled={department_id ? true : false}
                />
              </Box>
            )}

            <Box flex={1}>
              <Text>{"Make"}</Text>
              <ReactSelect
                value={makerStatus?.find((r) => r.value === choosemakerId)}
                isClearable={true}
                options={makerStatus}
                onChange={(entity, Object) => {
                  if (entity) setChoosemakerId(entity);
                  else setChoosemakerId(null);
                  setModels(null);
                }}
              />
            </Box>

            <Box flex={1}>
              <Text>{"Model"}</Text>
              <ReactSelect
                value={make?.find((r) => r.value === models)}
                isClearable={true}
                options={make}
                onChange={(entity, Object) => {
                  if (entity) setModels(entity.value);
                  else setModels(null);
                }}
              />
            </Box>
            <Box flex={1}>
              <Text>{"Fuel Type"}</Text>
              <ReactSelect
                value={FuelUsed?.find((r) => r.value === fuelType)}
                isClearable={true}
                options={FuelUsed}
                onChange={(entity, Object) => {
                  if (entity) setFuelType(entity.value);
                  else setFuelType(null);
                }}
              />
            </Box>
            {/* </Drawer> */}
          </HStack>
          <HStack space={4}>
            <HStack flex={1} space={2}>
              <Checkbox
                onChange={(entity, Object) => {
                  if (entity) setIsImageUploaded(entity?.target?.checked);
                  else setIsImageUploaded(null);
                }}
              />
              <Text>{"Image not uploaded Vehicles"}</Text>
            </HStack>
            {vehicle_items_loading ? (
              <Button
                // onClick={handleVehicleReport}
                onClick={XLSX_download}
                type="primary"
                style={{
                  width: "228px",
                  background: "#683fe9",
                  borderColor: "#683fe9",
                }}
                loading={vehicle_loading}
                disabled
              >
                <Text bold fontSize="15px" color="white">
                  Excel Export
                </Text>
              </Button>
            ) : (
              <Button
                // onClick={handleVehicleReport}
                onClick={XLSX_download}
                type="primary"
                style={{
                  width: "228px",
                  background: "#683fe9",
                  borderColor: "#683fe9",
                }}
                loading={vehicle_loading}
              >
                <Text bold fontSize="15px" color="white">
                  Excel Export
                </Text>
              </Button>
            )}
          </HStack>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="VehicleList"
              columns={columns}
              hasSearch
              hasPagination
              setPageLimit={setPageLimit}
              setPageNumber={setPageNumber}
              setSearchString={setSearchString}
              loading={vechicleLoading}
              data={data}
              totalItems={total_items}
              query={object}
              fetch={getAll_Vehicles}
              headerActions={
                roles?.[0]?.name === "Public Department" ||
                roles?.[0]?.name === "Department"
                  ? // ||roles?.[0]?.name === "Admin"
                    header_actions
                  : null
              }
              emptyMessage={t(
                "This vehicle is not owned by Public Motor Department"
              )}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
    </>
  );
};
export default VehicleList;
