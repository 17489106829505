import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { updateVehicle } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "vehicles_update",
  initialState,
  reducers: {
    _get_vehicle_update: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_vehicle_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _get_vehicle_update_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _get_vehicle_update_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _get_vehicle_update,
  _update_vehicle_reset,
  _get_vehicle_update_success,
  _get_vehicle_update_failure,
} = slice.actions;

export const getUpdateVehiclesListSelector = (state) => state.vehicles_update;

export const getUpdateVehiclesListReducer = slice.reducer;

export function update_Vehicles(variables) {
  return async (dispatch) => {
    dispatch(_get_vehicle_update());
    try {
      const response = await QueryRequest(updateVehicle, variables, dispatch);
      if (
        response?.data?.updateVehicle &&
        !response?.data?.updateVehicle.error
      ) {
        dispatch(_get_vehicle_update_success(response?.data?.updateVehicle));
      } else if (response?.data?.updateVehicle?.error) {
        dispatch(
          _get_vehicle_update_failure(response?.data?.updateVehicle.error)
        );
      }
    } catch (error) {
      dispatch(_get_vehicle_update_failure(error));
    }
  };
}
export async function update_one_vehicle_clear(dispatch) {
  dispatch(_update_vehicle_reset());
}
