
import { createDesignationReducer } from "./designation_create";
import { deleteDesignationReducer } from "./designation_delete";
import { getDesignationListReducer } from "./designation_list";
import { getOneDesignationReducer } from "./designation_one";
import { updatdesignationReducer } from "./designation_update";

export const designationReducers = {
  designationList: getDesignationListReducer,
  designationCreate: createDesignationReducer,
  designationDelete: deleteDesignationReducer,
  designationGetOne: getOneDesignationReducer,
  designationUpdate: updatdesignationReducer,
};

export * from "./designation_create";
export * from "./designation_delete";
export * from "./designation_list";
export * from "./designation_one";
export * from "./designation_update";
