import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { updateDepartmentMutation, updatetyreMakeMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "tyreUpdate",
  initialState,
  reducers: {
    _update_tyre: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_tyre_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _update_tyre_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_tyre_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _update_tyre,
  _update_tyre_reset,
  _update_tyre_success,
  _update_tyre_failure,
} = slice.actions;

export const updatetyreSelector = (state) => state.tyreUpdate;

export const updatetyreReducer = slice.reducer;

export function update_tyre(variables) {
  return async (dispatch) => {
    dispatch(_update_tyre());
    try {
      const response = await MutateRequest(
        updatetyreMakeMutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateTyreMake &&
        !response?.data?.updateTyreMake.error
      ) {
        dispatch(_update_tyre_success(response?.data?.updateTyreMake));
      } else if (response?.data?.updateTyreMake?.error) {
        dispatch(_update_tyre_failure(response?.data?.updateTyreMake.error));
      }
    } catch (error) {
      dispatch(_update_tyre_failure(error));
    }
  };
}
export async function update_one_tyre_clear(dispatch) {
  dispatch(_update_tyre_reset());
}
