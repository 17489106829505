import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { department_dashboard_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: {},
};

const slice = createSlice({
  name: "department_dashboard",
  initialState,
  reducers: {
    _department_dashboard: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _department_dashboard_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload.items;
      state.error = null;
    },
    _department_dashboard_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const {
  _department_dashboard,
  _department_dashboard_success,
  _department_dashboard_failure,
} = slice.actions;

export const department_dashboardSelector = (state) =>
  state.department_dashboard;

export const department_dashboardReducer = slice.reducer;

export function department_dashboard(variables) {
  return async (dispatch) => {
    dispatch(_department_dashboard());
    try {
      const response = await QueryRequest(
        department_dashboard_query,
        variables,
        dispatch
      );
      if (
        response?.data?.departmentWiseVehicleCount &&
        !response?.data?.departmentWiseVehicleCount.error
      ) {
        dispatch(
          _department_dashboard_success(
            response?.data?.departmentWiseVehicleCount
          )
        );
      } else if (response?.data?.departmentWiseVehicleCount?.error) {
        dispatch(
          _department_dashboard_failure(
            response?.data?.departmentWiseVehicleCount.error
          )
        );
      }
    } catch (error) {
      dispatch(_department_dashboard_failure(error));
    }
  };
}
