import React, { useEffect } from "react";
import {
  Box,
  HStack,
  Menu,
  VStack,
  Button,
  Pressable,
  Text,
  Hidden,
} from "native-base";
import Avatar from "react-avatar";
import { retrieveItem, useStorageItem } from "@helpers/storage";
import { setAuthorize } from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { IoIosNotificationsOutline } from "react-icons/io";
import {
  getUnReadNotificationList,
  getUnReadNotificationListSelector,
} from "@services/redux/slices/notifications/notification_unread";

let defaultStyles = {
  height: "70px",
};

const HeaderBar = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { items } = useSelector(getUnReadNotificationListSelector);

  const {
    OrganizationId: loggedInUserOrganizationId,
    LevelId: loggedInUserLevelId,
    Photo,
    name,
  } = JSON.parse(retrieveItem("user")) || {};
  // let name = JSON.parse(retrieveItem("user"));
  // let Photo = JSON.parse(retrieveItem("user"));

  const [shouldOverlapWithTrigger] = React.useState(true);
  const [position, setPosition] = React.useState("left bottom ");
  const handleLogout = () => {
    localStorage.clear();
    dispatch(setAuthorize(false));

    history.push({
      pathname: `${ROUTES.EMPTY}`,
    });
  };
  const handleChangePassword = () => {
    history.push({
      pathname: `${ROUTES.CHANGE_PASSWORD}`,
    });
  };

  useEffect(() => {
    dispatch(getUnReadNotificationList());
  }, []);
  return (
    <Box
      h={{
        base: "120",
        md: props.height || props.h || defaultStyles.height,
      }}
      w="100%"
      bg="#ffffff"
    >
      <HStack
        h="90%"
        w="100%"
        bg="#ffffff"
        style={{
          boxShadow: "0px 0px 20px rgb(229 218 255)",
        }}
      >
        <Box flex={1} justifyContent="center" pl="4">
          <Text
            bold
            fontSize={{
              base: "16px",
              md: "lg",
            }}
            fontWeight="bold"
          >
            {props.projectName}
          </Text>
        </Box>
        <Hidden only={["sm", "xs"]}>
          <Box flex={1}>
            <Box position="relative">
              <Box position="absolute" right="10px" top="10px">
                <VStack space={4} flex={1} ml={10}>
                  <HStack space="5" flex={1} justifyContent="center">
                    <Hidden only={["sm", "xs"]}>
                      <HStack>
                        <Box alignSelf={"center"}>
                          <Pressable
                            onPress={() => {
                              history.push(ROUTES.NOTIFICATIONS);
                            }}
                          >
                            <IoIosNotificationsOutline size="35" />
                          </Pressable>
                        </Box>

                        <Box marginRight="0" mt="-2" ml="-2">
                          <Text bold>{items}</Text>
                        </Box>
                      </HStack>
                    </Hidden>

                    <VStack
                      style={{
                        position: "relative",
                        top: "10px",
                        left: "-5px",
                      }}
                    >
                      <Menu
                        w="160"
                        position="absolute"
                        right="0px"
                        top="20px"
                        style={{
                          border: "1px solid #d7d7d7",
                          boxShadow: "-2px 2px #7a7a7a42",
                        }}
                        shouldOverlapWithTrigger={true}
                        placement={"left bottom"}
                        trigger={(triggerProps) => {
                          return (
                            <Pressable
                              alignSelf="center"
                              variant="solid"
                              {...triggerProps}
                            >
                              <HStack>
                                <Text
                                  bold
                                  fontSize={{
                                    base: "sm",
                                    md: "md",
                                  }}
                                  fontWeight=""
                                >
                                  Welcome, {name}!
                                </Text>
                                <Box right="10px" ml="6">
                                  <Avatar round size="25" src={Photo} />
                                </Box>
                              </HStack>
                            </Pressable>
                          );
                        }}
                      >
                        <Menu.Item onPress={handleLogout}>Logout</Menu.Item>
                      </Menu>
                    </VStack>
                  </HStack>
                </VStack>
              </Box>
            </Box>
          </Box>
        </Hidden>
        <Hidden only={["md", "lg", "xl"]}>
          <Box flex={1}>
            <Box position="relative">
              <Box position="absolute" right="10px" top="10px">
                <VStack space={4} flex={1} ml={10}>
                  <HStack justifyContent={"flex-end"}>
                    <Box alignSelf={"center"}>
                      <Pressable
                        onPress={() => {
                          history.push(ROUTES.NOTIFICATIONS);
                        }}
                      >
                        <IoIosNotificationsOutline size="35" />
                      </Pressable>
                    </Box>

                    <Box marginRight="0" mt="-2" ml="-2">
                      <Text bold>{items}</Text>
                    </Box>
                  </HStack>
                  <HStack space="5" flex={1}>
                    <VStack
                      style={{
                        position: "relative",
                        top: "10px",
                        left: "-5px",
                      }}
                    >
                      <Menu
                        w="160"
                        position="absolute"
                        right="0px"
                        top="20px"
                        style={{
                          border: "1px solid #d7d7d7",
                          boxShadow: "-2px 2px #7a7a7a42",
                        }}
                        shouldOverlapWithTrigger={true}
                        placement={"left bottom"}
                        trigger={(triggerProps) => {
                          return (
                            <Pressable
                              alignSelf="center"
                              variant="solid"
                              {...triggerProps}
                            >
                              <HStack>
                                <Text
                                  bold
                                  fontSize={{
                                    base: "sm",
                                    md: "md",
                                  }}
                                  fontWeight=""
                                >
                                  Welcome, {name}!
                                </Text>
                                <Box right="10px" ml="6">
                                  <Avatar round size="25" src={Photo} />
                                </Box>
                              </HStack>
                            </Pressable>
                          );
                        }}
                      >
                        <Menu.Item onPress={handleLogout}>Logout</Menu.Item>
                      </Menu>
                    </VStack>
                  </HStack>
                </VStack>
              </Box>
            </Box>
          </Box>
        </Hidden>
      </HStack>
    </Box>
  );
};

export default HeaderBar;
