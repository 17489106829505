import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { updateGatePassMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "gatePassUpdate",
  initialState,
  reducers: {
    _update_gate_pass: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_gate_pass_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _update_gate_pass_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_gate_pass_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _update_gate_pass,
  _update_gate_pass_reset,
  _update_gate_pass_success,
  _update_gate_pass_failure,
} = slice.actions;

export const updateGatePassSelector = (state) => state.gatePassUpdate;

export const updateGatePassReducer = slice.reducer;

export function update_gate_pass(variables) {
  return async (dispatch) => {
    dispatch(_update_gate_pass());
    try {
      const response = await MutateRequest(
        updateGatePassMutation,
        variables,
        dispatch
      );
      if (response?.data?.updatePass && !response?.data?.updatePass.error) {
        dispatch(_update_gate_pass_success(response?.data?.updatePass));
      } else if (response?.data?.updatePass?.error) {
        dispatch(_update_gate_pass_failure(response?.data?.updatePass.error));
      }
    } catch (error) {
      dispatch(_update_gate_pass_failure(error));
    }
  };
}
export function update_one_gate_pass_clear() {
  return async (dispatch) => {
    dispatch(_update_gate_pass_reset());
  };
}
