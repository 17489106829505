import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { createGatePassMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "gatePassCreate",
  initialState,
  reducers: {
    _create_gate_pass: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_gate_pass_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_gate_pass_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_gate_pass_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _create_gate_pass,
  _create_gate_pass_reset,
  _create_gate_pass_success,
  _create_gate_pass_failure,
} = slice.actions;

export const createGatePassSelector = (state) => state.gatePassCreate;

export const createGatePassReducer = slice.reducer;

export function create_gate_pass(variables) {
  return async (dispatch) => {
    dispatch(_create_gate_pass());
    try {
      const response = await MutateRequest(
        createGatePassMutation,
        variables,
        dispatch
      );
      if (response?.data?.createPass && !response?.data?.createPass.error) {
        dispatch(_create_gate_pass_success(response?.data?.createPass));
      } else if (response?.data?.createPass?.error) {
        dispatch(_create_gate_pass_failure(response?.data?.createPass.error));
      }
    } catch (error) {
      dispatch(_create_gate_pass_failure(error));
    }
  };
}
export function create_one_gate_pass_clear(dispatch) {
  return async (dispatch) => {
    dispatch(_create_gate_pass_reset());
  };
}
