import React, { useEffect, useState, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  HStack,
  VStack,
  Button,
  Text,
  Stack,
  Pressable,
  Divider,
} from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import { showToast } from "@helpers/toast";
import { ROUTES } from "@views/routes/my_routes";
import photo from "@assets/images/loader.gif";
import { AiFillCar, AiOutlineEdit } from "react-icons/ai";

import {
  get_one_service,
  getOneServiceSelector,
  createServiceRequestProcessSelector,
  create_one_service_process_clear,
  create_service_request_process,
  createServiceRequestReturnSelector,
  createServiceRequestOrderSelector,
  createServiceRequestSentToWorkshopSelector,
  service_request_order,
  create_service_request_return,
  service_request_sent_to_workshop,
  create_one_service_order_clear,
  create_one_service_sent_to_workshop_clear,
  create_one_service_return_clear,
  updateServiceRequestSelector,
} from "@services/redux";
import unicConvertes from "../../../helpers/date";
import { Card } from "antd";
import { retrieveItem } from "@helpers/storage";

const ServicePage = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id, service_id } = useParams();
  const [nextServiceDetails, setNextServiceDetails] = useState({});
  const { item: service_history } = useSelector(getOneServiceSelector);
  const {
    loading: sent_to_workshop_loading,
    error: sent_to_workshop_error,
    status: sent_to_workshop_status,
  } = useSelector(createServiceRequestSentToWorkshopSelector);
  const {
    loading: process_loading,
    status: processStatus,
    error: processError,
  } = useSelector(createServiceRequestProcessSelector);
  const {
    loading: order_loading,
    status: orderStatus,
    error: orderError,
  } = useSelector(createServiceRequestOrderSelector);
  const {
    loading: return_loading,
    status: returnStatus,
    error: returnError,
  } = useSelector(createServiceRequestReturnSelector);

  const {
    loading: updateLoading,
    status: updateStatus,
    error: updateError,
  } = useSelector(updateServiceRequestSelector);
  const history = useHistory();
  const roles = JSON.parse(retrieveItem("roles"));
  useEffect(() => {
    dispatch(get_one_service({ id: id }));
  }, []);

  const { item: getOneServicesItems, loading } = useSelector(
    getOneServiceSelector
  );

  const handleView = (item) => {
    history.push({
      pathname: `${ROUTES.SERVICE_REQUEST_DETAILS}/${id}`,
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleSubmit = () => {};

  const dateDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };

  <Box></Box>;
  let _sections = [
    {
      id: 1,
      parent_id: null,
      title: "Section 1",
      questions: [
        {
          question: "Question 1?",
          answer: "answer 1",
        },
        {
          question: "Question 2?",
          answer: "answer 2",
        },
      ],
    },
  ];
  let columns = [
    {
      header: t("table:services_to_be_done"),
      dataIndex: "checkbox",
      key: "checkbox",
      sortable: false,
      align: "left",
      flex: 1,
    },
  ];
  const [sections, setSections] = useState([
    { id: 1, title: "Section 1", parent_id: null },
  ]);

  const handleServiceRequest = () => {
    history.push({
      pathname: `${ROUTES.SERVICE_REQUEST_DETAILS}/${getOneServicesItems?.vehicle_id}/${getOneServicesItems?.id}`,
    });
  };

  const handleProcessRequest = () => {
    dispatch(create_service_request_process({ id: getOneServicesItems?.id }));
  };
  const handleOrderRequest = (value) => {
    dispatch(
      service_request_order({
        id: getOneServicesItems?.id,
        data: {
          order_url: url ? url : "234589sd",
        },
      })
    );
  };
  const handleReturnRequest = () => {
    dispatch(
      create_service_request_return({
        id: getOneServicesItems?.id,
        next_due_date: nextServiceDetails?.next_due_date,
        next_km_due: nextServiceDetails?.next_km_due,
      })
    );
  };

  const handleSentToWorkshop = () => {
    dispatch(service_request_sent_to_workshop({ id: getOneServicesItems?.id }));
  };
  const handleSubmitForm = (values) => {};

  const handleChange = (val, all) => {
    setNextServiceDetails(all);
  };
  useEffect(() => {
    setSections(_sections);
  }, []);
  useEffect(() => {
    if (processError) {
      showToast({ type: "error", message: "Something went wrong!" });
      dispatch(create_one_service_process_clear);
      history.goBack();
    } else if (processStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Service Request")} ${t(
          "Inspection started Successfully"
        )}`,
      });
      dispatch(create_one_service_process_clear);
      history.goBack();
    }
  }, [processStatus, processError]);

  useEffect(() => {
    if (orderError) {
      showToast({ type: "error", message: "Something went wrong!" });
      dispatch(create_one_service_order_clear);
      history.goBack();
    } else if (orderStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Service Request")} ${t("confirmed by department ")}`,
      });
      dispatch(create_one_service_order_clear);
      history.goBack();
    }
  }, [orderStatus, orderError]);
  useEffect(() => {
    if (sent_to_workshop_error) {
      showToast({ type: "error", message: "Something went wrong!" });
      dispatch(create_one_service_sent_to_workshop_clear);
      history.goBack();
    } else if (sent_to_workshop_status === "Success") {
      showToast({
        type: "success",
        message: `${t("Vehicle sent to workshop successfully")}`,
      });
      dispatch(create_one_service_sent_to_workshop_clear);
      history.goBack();
    }
  }, [sent_to_workshop_status, sent_to_workshop_error]);

  useEffect(() => {
    if (returnError) {
      showToast({ type: "error", message: "Something went wrong!" });
      dispatch(create_one_service_return_clear());
      history.goBack();
    } else if (returnStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Vehicle returned successfully ")}`,
      });
      dispatch(create_one_service_return_clear());
      history.goBack();
    }
  }, [returnStatus, returnError]);

  if (loading)
    return (
      <Box alignSelf="center" marginTop="25%">
        <img src={photo} />
      </Box>
    );
  let requested_json = ["Requested", "Reviewed", "Confirmed"];
  return (
    <Box flex="1" w="100%">
      <Box flex={1}>
        <Text fontSize="xl" fontWeight="bold" textAlign="center" mt="5">
          {`${t("Service Details")}`}
        </Text>

        <VStack space={4} mt={7} flex={1}>
          <>
            <HStack space="1">
              <Text fontSize="md" fontWeight="bold">
                {t("table:req_no")}
              </Text>

              <Text fontSize="md">
                {getOneServicesItems?.vehicle?.vehicle_number}
              </Text>
            </HStack>
            {getOneServicesItems?.department ? (
              <HStack>
                <Text fontSize="md" fontWeight="bold">
                  {t("Alloted To")}
                </Text>
                <Text fontSize="md">
                  {getOneServicesItems?.department?.name}
                </Text>
              </HStack>
            ) : (
              ""
            )}
          </>
        </VStack>
      </Box>
      <Box flex={1} borderColor="grey">
        <Card width="100%">
          <VStack flex={1}>
            <Stack
              flex={1}
              space={10}
              flexDirection={{
                base: "column",
                md: "row",
              }}
            >
              <HStack flex={1} space={3}>
                <Box>
                  <Text fontSize="md" fontWeight="bold">
                    {t("Service Requested Date :")}
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="md">
                    {unicConvertes(getOneServicesItems?.created_date_time)}
                  </Text>
                </Box>
              </HStack>

              <HStack flex={1} space={3}>
                <Box>
                  <Text fontSize="md" fontWeight="bold">
                    {t("Status :")}
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="md">
                    {getOneServicesItems?.entity_state?.name}
                  </Text>
                </Box>
              </HStack>
            </Stack>
            <Stack
              flexDirection={{
                base: "column",
                md: "row",
              }}
              flex={1}
              space={10}
            >
              {getOneServicesItems?.returned_date_time && (
                <HStack flex={1} space={3}>
                  <Box>
                    <Text fontSize="md" fontWeight="bold">
                      {t("Service Returned Date :")}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="md">
                      {getOneServicesItems?.returned_date_time
                        ? unicConvertes(getOneServicesItems?.returned_date_time)
                        : "-"}
                    </Text>
                  </Box>
                </HStack>
              )}
              {getOneServicesItems?.order_url && (
                <HStack flex={1} space={3}>
                  <Box>
                    <Text fontSize="md" fontWeight="bold">
                      {t("Order Copy :")}
                    </Text>
                  </Box>
                  <Box>
                    {getOneServicesItems?.order_url && (
                      <Text fontSize="md">
                        <a
                          href={getOneServicesItems?.order_url}
                          target={"_blank"}
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          Download Order Copy
                        </a>
                      </Text>
                    )}
                  </Box>
                </HStack>
              )}
            </Stack>
            {getOneServicesItems?.entity_state?.name ===
              "Service Completed" && (
              <Stack
                mt="5"
                flex={1}
                space={10}
                flexDirection={{
                  base: "column",
                  md: "row",
                }}
              >
                <Box flex={1}>
                  <HStack>
                    <Box>
                      <Text fontSize="md" fontWeight="bold">
                        {t("Service due date:  ")}
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize="md">
                        {getOneServicesItems?.next_due_date
                          ? unicConvertes(getOneServicesItems?.next_due_date)
                          : "-"}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack>
                    <Box>
                      <Text fontSize="md" fontWeight="bold">
                        {t("Service kilometres due:  ")}
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="md">
                        {getOneServicesItems?.next_km_due
                          ? getOneServicesItems?.next_km_due?.toLocaleString(
                              "en-IN"
                            )
                          : "-"}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </Stack>
            )}
            <Stack
              flex={1}
              space={10}
              flexDirection={{
                base: "column",
                md: "row",
              }}
            >
              <Box flex={1}>
                <HStack>
                  <Box>
                    <Text fontSize="md" fontWeight="bold">
                      {t("Remarks:  ")}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="md">
                      {getOneServicesItems?.remarks
                        ? getOneServicesItems?.remarks
                        : "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                {getOneServicesItems?.ta_remarks && (
                  <HStack>
                    <Box>
                      <Text fontSize="md" fontWeight="bold">
                        {t("Technician Remarks:  ")}
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="md">
                        {getOneServicesItems?.ta_remarks
                          ? getOneServicesItems?.ta_remarks
                          : "-"}
                      </Text>
                    </Box>
                  </HStack>
                )}
              </Box>
            </Stack>
            <Stack
              flex={1}
              flexDirection={{
                base: "column",
                md: "row",
              }}
            >
              <Box flex={1}>
                {getOneServicesItems?.department_remarks && (
                  <HStack>
                    <Box>
                      <Text fontSize="md" fontWeight="bold">
                        {t("Department Remarks :  ")}
                      </Text>
                    </Box>
                    <Box flex={1}>
                      <Text fontSize="md">
                        {getOneServicesItems?.department_remarks
                          ? getOneServicesItems?.department_remarks
                          : "-"}
                      </Text>
                    </Box>
                  </HStack>
                )}
              </Box>
              <Box flex={1}></Box>
            </Stack>
            <HStack
              flexDirection={{
                base: "column",
                md: "row",
              }}
            >
              <Box flex={1}>
                <VStack flex={1} space={2}>
                  <Box>
                    {getOneServicesItems?.service_logs?.map((x, i) => {
                      return (
                        <VStack>
                          <Box mt="3">
                            <Text fontSize="md" fontWeight="bold">
                              {x.created_by?.roles?.[0]?.name}
                              {"  "}
                              {requested_json?.[i]} :
                            </Text>
                          </Box>

                          <Box>
                            {x.services?.map((y) => {
                              return <Text fontSize="md">{y.name}</Text>;
                            })}
                          </Box>
                        </VStack>
                      );
                    })}
                  </Box>
                </VStack>
              </Box>
              {getOneServicesItems?.entity_state?.name ===
                "Service Completed" && (
                <Box flex={1}>
                  <VStack flex={1} space={2}>
                    <Box mt="3">
                      <Text fontSize="md" fontWeight="bold">
                        {t("Services Done : ")}
                      </Text>
                    </Box>

                    <>
                      {getOneServicesItems?.services?.map((x, index) => {
                        return (
                          <Box>
                            <Text fontSize="md">{x.name}</Text>
                          </Box>
                        );
                      })}
                    </>
                  </VStack>
                </Box>
              )}
            </HStack>
          </VStack>
        </Card>
      </Box>
      {getOneServicesItems?.entity_state?.from_state_transitions?.[0]
        ?.transition_name === "completed" &&
        roles?.[0]?.name === "Technician" && (
          <Box mt="10">
            <Form onSubmit={handleSubmitForm} onValueChange={handleChange}>
              <HStack space="10">
                <Box flex={1}>
                  <Form.Date
                    label={"Service due date"}
                    field={"next_due_date"}
                  />
                </Box>
                <Box flex={1}>
                  <Form.Number
                    label={"Service kilometres due"}
                    field={"next_km_due"}
                  />
                </Box>
              </HStack>
            </Form>
          </Box>
        )}
      <HStack space={2} justifyContent="flex-end" padding={5}>
        {getOneServicesItems?.entity_state?.from_state_transitions?.map((x) => {
          return (
            <Button
              isLoading={
                x?.transition_name === "Inspection"
                  ? process_loading
                  : x?.transition_name === "completed"
                  ? return_loading
                  : x?.transition_name === "Sent to workshop"
                  ? updateLoading
                  : x?.transition_name === "TA Confirmation"
                  ? updateLoading
                  : x?.transition_name === "Department Confirmation"
                  ? order_loading
                  : x?.transition_name === "In Service"
                  ? sent_to_workshop_loading
                  : false
              }
              size="md"
              height="45px"
              colorScheme="success"
              formErrorMessage={t("error:error_message")}
              onPress={
                x?.transition_name === "Inspection"
                  ? () => {
                      handleProcessRequest();
                    }
                  : x?.transition_name === "completed"
                  ? () => {
                      handleReturnRequest();
                    }
                  : x?.transition_name === "Send to workshop"
                  ? () => {
                      handleSubmit();
                    }
                  : x.transition_name === "TA Confirmation"
                  ? () => {
                      handleSubmit();
                    }
                  : x.transition_name === "Department Confirmation"
                  ? () => {
                      handleOrderRequest();
                    }
                  : x.transition_name === "In Service"
                  ? () => {
                      handleSentToWorkshop();
                    }
                  : () => {
                      console.log("hiii");
                    }
              }
            >
              {x?.transition_name}
            </Button>
          );
        })}
        <Button
          isLoading={false}
          // width="100px"
          size="md"
          colorScheme="warning"
          variant="outline"
          formErrorMessage={t("error:error_message")}
          onPress={handleBack}
        >
          {t("back")}
        </Button>
      </HStack>
    </Box>
  );
};
export default ServicePage;
