import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { updatedesignationMakeMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "designationUpdate",
  initialState,
  reducers: {
    _update_designation: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_designation_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _update_designation_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_designation_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _update_designation,
  _update_designation_reset,
  _update_designation_success,
  _update_designation_failure,
} = slice.actions;

export const updateDesignationSelector = (state) => state.designationUpdate;

export const updatdesignationReducer = slice.reducer;

export function update_designation(variables) {
  return async (dispatch) => {
    dispatch(_update_designation());
    try {
      const response = await MutateRequest(
        updatedesignationMakeMutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateDesignation &&
        !response?.data?.updateDesignation.error
      ) {
        dispatch(
          _update_designation_success(response?.data?.updateDesignation)
        );
      } else if (response?.data?.updateDesignation?.error) {
        dispatch(
          _update_designation_failure(response?.data?.updateDesignation.error)
        );
      }
    } catch (error) {
      dispatch(_update_designation_failure(error));
    }
  };
}
export async function update_one_designation_clear(dispatch) {
  dispatch(_update_designation_reset());
}
