import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getOneCondemnationQuery } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "condemnationGetOne",
  initialState,
  reducers: {
    _get_one_condemnation: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },

    _get_one_condemnation_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_condemnation_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const {
  _get_one_condemnation,
  _get_one_condemnation_success,
  _get_one_condemnation_failure,
} = slice.actions;

export const getOneCondemnationSelector = (state) => state.condemnationGetOne;

export const getOneCondemnationReducer = slice.reducer;

export function get_one_condemnation(variables) {
  return async (dispatch) => {
    dispatch(_get_one_condemnation());
    try {
      const response = await QueryRequest(
        getOneCondemnationQuery,
        variables,
        dispatch
      );
      if (
        response?.data?.getContamination &&
        !response?.data?.getContamination.error
      ) {
        dispatch(
          _get_one_condemnation_success(response?.data?.getContamination)
        );
      } else if (response?.data?.getContamination?.error) {
        dispatch(
          _get_one_condemnation_failure(response?.data?.getContamination.error)
        );
      }
    } catch (error) {
      dispatch(_get_one_condemnation_failure(error));
    }
  };
}
export async function get_one_condemnation_clear(dispatch) {
  dispatch(_get_one_condemnation());
}
