import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { technical_person_dashboard_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: {},
};

const slice = createSlice({
  name: "technicalPersonDashboard",
  initialState,
  reducers: {
    _technical_person_dashboard: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _technical_person_dashboard_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload.items;
      state.error = null;
    },
    _technical_person_dashboard_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const {
  _technical_person_dashboard,
  _technical_person_dashboard_success,
  _technical_person_dashboard_failure,
} = slice.actions;

export const technicalPersonDashboardSelector = (state) =>
  state.technicalPersonDashboard;

export const technicalPersonDashboardReducer = slice.reducer;

export function technical_person_dashboard(variables) {
  return async (dispatch) => {
    dispatch(_technical_person_dashboard());
    try {
      const response = await QueryRequest(
        technical_person_dashboard_query,
        variables,
        dispatch
      );
      if (
        response?.data?.serviceRequestDashboard &&
        !response?.data?.serviceRequestDashboard.error
      ) {
        dispatch(
          _technical_person_dashboard_success(
            response?.data?.serviceRequestDashboard
          )
        );
      } else if (response?.data?.serviceRequestDashboard?.error) {
        dispatch(
          _technical_person_dashboard_failure(
            response?.data?.serviceRequestDashboard.error
          )
        );
      }
    } catch (error) {
      dispatch(_technical_person_dashboard_failure(error));
    }
  };
}
