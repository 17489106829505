import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getDriverReportQuery } from "./graphql";
const initialState = {
  loading: false,
  error: null,
  url: null,
};
const slice = createSlice({
  name: "getDriverReport",
  initialState,
  reducers: {
    getDriverReportInitialize: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    getDriverReportReset: (state) => {
      state.loading = false;
      state.url = null;
      state.error = null;
    },
    getDriverReportSuccess: (state, { payload }) => {
      state.loading = false;
      state.url = payload.url;
      state.error = null;
    },
    getDriverReportFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  getDriverReportInitialize,
  getDriverReportSuccess,
  getDriverReportFailure,
  getDriverReportReset,
} = slice.actions;
export const getDriverReportSelector = (state) => state.getDriverReport;

export const getDriverReportReducer = slice.reducer;

export function getDriverReport(variables) {
  return async (dispatch) => {
    dispatch(getDriverReportInitialize());
    try {
      const response = await QueryRequest(
        getDriverReportQuery,
        variables,
        dispatch
      );
      if (
        response?.data?.getDriverReport &&
        !response?.data?.getDriverReport.error
      ) {
        dispatch(getDriverReportSuccess(response?.data?.getDriverReport));
      } else if (response?.data?.getDriverReport?.error) {
        dispatch(getDriverReportFailure(response?.data?.getDriverReport.error));
      }
    } catch (error) {
      dispatch(getDriverReportFailure(error));
    }
  };
}

export function getDriverReportClear() {
  return (dispatch) => {
    dispatch(getDriverReportReset());
  };
}
