import { createCarMakeReducer } from "./carmake_create";
import { deleteCarMakeReducer } from "./carmake_delete";
import { getOneCarMakeReducer } from "./carmake_getone";
import { updateCarMakeReducer } from "./carmake_update";
import { getMakerListReducer } from "./car_make_list";
export const getMakerListReducers = {
  makerList: getMakerListReducer,
  carmake_create: createCarMakeReducer,
  carMakeDelete: deleteCarMakeReducer,
  carmakeUpdate: updateCarMakeReducer,
  carmakeGetOne: getOneCarMakeReducer,
};
export * from "./car_make_list";
export * from "./carmake_create";
export * from "./carmake_delete";
export * from "./carmake_update";
export * from "./carmake_getone";
