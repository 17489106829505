import { gql } from "@apollo/client";

export const getAllAccessories = gql`
  query getAdditionalAccessoryMasters($page_number: Float, $page_limit: Float) {
    getAdditionalAccessoryMasters(
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const createAccessoriesMuatation = gql`
  mutation createAdditionalAccessoryMaster($data: additional_accessory_input) {
    createAdditionalAccessoryMaster(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const updateAccessoriesMuatation = gql`
  mutation updateAdditionalAccessoryMaster(
    $data: additional_accessory_input
    $id: String!
  ) {
    updateAdditionalAccessoryMaster(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const deleteAccessoriesMuatation = gql`
  mutation deleteAdditionalAccessoryMaster($id: String!) {
    deleteAdditionalAccessoryMaster(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const getOneAccessoriesQuery = gql`
  query getAdditionalAccessoryMaster($id: String) {
    getAdditionalAccessoryMaster(id: $id) {
      id
      name
    }
  }
`;
