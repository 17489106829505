export const ClassVechile = [
  {
    label: "Sedan",
    value: "Sedan",
  },
  {
    label: "Hatchback",
    value: "Hatchback",
  },
  {
    label: "Suv",
    value: "SUV",
  },
  {
    label: "MUV",
    value: "MUV",
  },
  {
    label: "Luxury",
    value: "Luxury",
  },
  {
    label: " Super Luxury",
    value: "Super_Luxury",
  },
];

export const WheelDriveType = [
  {
    label: "Front Wheel",
    value: "FrontWheel",
  },
  {
    label: "Rear Wheel",
    value: "RearWheel",
  },
  {
    label: "Four Wheel Drive",
    value: "FourWheelDrive",
  },
];
export const BatteryType = [
  {
    label: "Lead Acid Battery",
    value: "LeadAcidBattery",
  },
  {
    label: "Lithium Ion Battery",
    value: "LithiumIonBattery",
  },
  {
    label: "SLI Battery",
    value: "SLIBattery",
  },
  {
    label: "Deep Cycle Battery",
    value: "DeepCycleBattery",
  },
  {
    label: "VRLA Battery",
    value: "VRLABattery",
  },
  {
    label: "Nickel Metal Hydride Battery",
    value: "NickelMetalHydrideBattery",
  },
];

export const SeatingCapacity = [
  {
    label: "Four",
    value: "Four",
  },
  {
    label: "Five",
    value: "Five",
  },
  {
    label: "Six",
    value: "Six",
  },
  {
    label: "Seven",
    value: "Seven",
  },
];

export const FuelUsed = [
  {
    label: "Petrol",
    value: "Petrol",
  },
  {
    label: "Diesel",
    value: "Diesel",
  },
  {
    label: "CNG",
    value: "CNG",
  },
  {
    label: "LPG",
    value: "LPG",
  },
  {
    label: "Electric",
    value: "Electric",
  },
];

export const VehicleColor = [
  {
    label: "White",
    value: "White",
  },
  {
    label: "Black",
    value: "Black",
  },
  {
    label: "Silver",
    value: "Silver",
  },
  {
    label: "Grey",
    value: "Gray",
  },
  {
    label: "Red",
    value: "Red",
  },
  {
    label: "Blue",
    value: "Blue",
  },
  {
    label: "Brown",
    value: "Brown",
  },
];
export const Src = [
  {
    value: "High Court",
    label: "High Court",
  },
  {
    value: "Ministers",
    label: "Ministers",
  },
  {
    value: "Others",
    label: "Others",
  },
];
export function encryptRequest(data) {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    "4Y1oO0ElwMgib9bwVyglYxqhdpWBFpxw"
  );
  return encrypted.toString();
}
export function decryptResponse(data) {
  const bytes = CryptoJS.AES.decrypt(data, "4Y1oO0ElwMgib9bwVyglYxqhdpWBFpxw");
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}
