import React, { useEffect, useState } from "react";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { AiOutlineEye } from "react-icons/ai";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "@views/routes/my_routes";
import { useSelector } from "react-redux";
import {
  get_GatePassList,
  getGatePassListSelector,
  getPassHistoryListSelector,
} from "@services/redux";
import { Text } from "native-base";
import moment from "moment";
import { startCase } from "lodash";

const PassHistoryList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [searchString, setSearchString] = useState(null);
  const [object, setObject] = useState(null);
  const [data, setData] = useState([]);

  const { loading, items, total_items } = useSelector(getGatePassListSelector);
  console.log("items", items);

  useEffect(() => {
    let temp = items.map((x, i) => ({
      ...x,
      slno: (page_number - 1) * page_limit + i + 1,
    }));
    setData(temp);
  }, [items]);

  const handleView = (item) => {
    console.log("item", item);
    history.push({
      pathname: `${ROUTES.PASS_HISTORY_VIEW}/${item.id}`,
    });
  };

  const formatPassType = (value) => {
    console.log("value", value);
    return (
      <>
        <Text>{value?.pass_type?.parent_pass_type?.name},</Text>
        <Text>{value?.pass_type?.name}</Text>
      </>
    );
  };
  const formatDisplay = (item) => {
    console.log("DATES", item?.issued_date);
    const issued_date =
      item?.issued_date === null
        ? ""
        : moment(Number(item?.issued_date)).format("DD/MM/YYYY");
    return <Text>{issued_date ? issued_date : "-"}</Text>;
  };

  const formatValidTill = (item) => {
    const valid_till =
      item?.valid_till === null
        ? ""
        : moment(Number(item?.valid_till)).format("DD/MM/YYYY");
    return <Text>{valid_till ? valid_till : "-"}</Text>;
  };

  const formatStatus =(item)=>{
    return <Text>{startCase(item.status)}</Text>
  }
  const formatPassNumber = (item) => {
    return (
      <Text>
        {item.pass_number ? item.pass_number : "Pass Number is Not Allocated"}
      </Text>
    );
  };

  let action = [
    {
      icon: <AiOutlineEye />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("View Pass History"),
      onPress: handleView,
    },
  ];

  let columns = [
    {
      header: t("table:s_no"),
      dataIndex: "slno",
      key: "s_no",
      cursor: "pointer",
      sortable: false,
      align: "left",
      width: 150,
    },
    {
      header: t("table:pass_number"),
      dataIndex: "",
      key: "pass_number",
      sortable: false,
      align: "left",
      formatDisplay: formatPassNumber,
      width: 200,
    },
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      width: 200,
    },
    {
      header: t("table:pass_type"),
      dataIndex: "",
      key: "pass_type",
      sortable: false,
      align: "left",
      formatDisplay: formatPassType,
      width: 200,
    },
    {
      header: t("table:issued_date"),
      dataIndex: "",
      key: "issued_date",
      sortable: false,
      align: "left",
      width: 200,
      formatDisplay: formatDisplay,
    },
    {
      header: "Valid Till",
      dataIndex: "",
      key: "valid_till",
      sortable: false,
      align: "left",
      width: 200,
      formatDisplay: formatValidTill,
    },
    {
      header: t("table:vehicle_number"),
      dataIndex: "vehicle_number",
      key: "vehicle_number",
      sortable: false,
      align: "left",
      width: 200,
    },
    {
      header: t("table:vehicle_make"),
      dataIndex: "vehicle_make",
      key: "vehicle_make",
      sortable: false,
      align: "left",
      width: 200,
    },
    {
      header: t("table:status"),
      dataIndex: "",
      key: "status",
      sortable: false,
      align: "left",
      formatDisplay: formatStatus,
      width: 200,
    },

    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "left",
      type: "actions",
      width: 200,
      actions: action,
    },
  ];

  // const data = [
  //   {
  //     slno: 1,
  //     pass_number: 100001,
  //     pass_type: "Four Wheeler",
  //     issued_date: "02-08-2024",
  //     vehicle_number: 121,
  //     vehicle_make: "Innova",
  //     status: "pending",
  //   },
  // ];

  return (
    <div style={{ width: "100%" }}>
      <Table
        width="100%"
        rowKey="id"
        group="passHistory"
        loading={loading}
        setPageLimit={setPageLimit}
        setPageNumber={setPageNumber}
        setSearchString={setSearchString}
        data={data}
        columns={columns}
        hasSearch
        hasPagination
        totalItems={total_items}
        fetch={get_GatePassList}
        // onModalOpen={toggleQRModal}
        // query={object}
        // headerActions={
        //   roles?.[0]?.name === "Public Department" ||
        //   roles?.[0]?.name === "Department"
        //     ? header_actions
        //     : []
        // }
        emptyMessage={t("No Vehicle Pass History details Found")}
        headerColor={"white"}
      />
    </div>
  );
};

export default PassHistoryList;
