import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";
import { Form as AntdForm, Button } from "antd";

import {
  create_carmodel,
  getCar_Makers,
  getMakerListSelector,
  update_carmodel,
} from "@services/redux";

const CarModelDetails = (props) => {
  const dispatch = useDispatch();
  const { items: car_make } = useSelector(getMakerListSelector);
  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id, handleModalClose, initialValue } = props;
  const handleSubmit = (values) => {
    if (initialValue.id) {
      let update_id = initialValue?.id;
      delete values?.id;
      delete values?.error;
      dispatch(
        update_carmodel({
          id: update_id,
          data: {
            name: values.name,
            car_make_id: id,
          },
        })
      );
    } else {
      dispatch(
        create_carmodel({
          data: {
            name: values.name,
            car_make_id: id,
          },
        })
      );
    }
  };

  useEffect(() => {
    if (initialValue) {
      setActionItem(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    dispatch(getCar_Makers());
  }, []);
  const [form] = AntdForm.useForm();

  return (
    <Box flex="1">
      <Form
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        // onValueChange={handleInputChange}
      >
        <VStack space={10} mt={2}>
          <HStack space={10}>
            <Box flex={1}>
              <Form.TextBox
                field={"name"}
                label={t("form:name")}
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              />
            </Box>
          </HStack>

          <HStack space={2} justifyContent="flex-end">
            <Form.Button
              isLoading={false}
              width="100px"
              colorScheme="primary"
              height="45px"
              formErrorMessage={t("error:error_message")}
              label={actionItem.id ? t("update") : t("add")}
            >
              {actionItem.id ? t("update") : t("add")}
            </Form.Button>
            <Button
              isLoading={false}
              width="100px"
              colorScheme="danger"
              variant="outline"
              onClick={handleModalClose}
              formErrorMessage={t("error:error_message")}
            >
              {t("common:cancel")}
            </Button>
          </HStack>
        </VStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default CarModelDetails;
