import { gql } from "@apollo/client";

export const getCondemnations = gql`
  query getContaminations(
    $page_number: Float
    $page_limit: Float
    $search_string: String
  ) {
    getContaminations(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        vehicle_id
        go_number
        go_file
        date
        reason
        vehicle {
          id
          vehicle_number
          class_vehicle
          car_model_id
          car_model {
            id
            name
            car_make {
              id
              name
            }
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const createCondemnationMutation = gql`
  mutation createContamination($data: contamination_input) {
    createContamination(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updateCondemnationMutation = gql`
  mutation updateContamination($id: String!, $data: contamination_input) {
    updateContamination(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const getOneCondemnationQuery = gql`
  query getContamination($id: String!, $vehicle_id: String) {
    getContamination(id: $id, vehicle_id: $vehicle_id) {
      id
      vehicle_id
      go_number
      go_file
      date
      reason
      go_file
      challan
      other_document
      front_image_url
      rear_image_url
      left_image_url
      right_image_url
      dashboard_image_url
      vehicle {
        id
        vehicle_number
        class_vehicle
        car_model_id
        car_model {
          id
          name
          car_make {
            id
            name
          }
        }
      }
    }
  }
`;

export const deleteCondemnationMutation = gql`
  mutation deleteContamination($id: String!) {
    deleteContamination(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const getCondemnationsWithoutPagination = gql`
  query getContaminations(
    $page_number: Float
    $page_limit: Float
    $search_string: String
  ) {
    getContaminations(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        vehicle_id
        go_number
        go_file
        date
        reason
        vehicle {
          id
          vehicle_number
          class_vehicle
          car_model_id
          car_model {
            id
            name
            car_make {
              id
              name
            }
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
