import { gql } from "@apollo/client";

export const getAllEntity = gql`
  query getServiceRequests {
    getServiceRequests {
      items {
        id
        entity_state {
          id
          name
          from_state_transitions {
            id
            transition_name
            to_entity_state_id
          }
          entity {
            id
            name
          }
        }
      }
    }
  }
`;
