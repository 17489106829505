import { gql } from "@apollo/client";

export const apply_pass_mutation = gql`
 mutation applyPass($data: pass_input){
  applyPass(data: $data){
    id
    status
    error{
      message
    }
  }
}
`;
