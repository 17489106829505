import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Text } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import { ROUTES } from "@views/routes/my_routes";
import { useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";
import {
  getGatePassListSelector,
  get_Departments,
  get_GatePassList,
  get_pass_typeList,
  getpass_typeListSelector,
  getGatePassReport,
  getGatePassReportSelector,
  getGatePassReportClear,
  getGatePassListWithoutPaginationSelector,
  get_GatePassList_Without_Paginaton,
} from "@services/redux";
import {
  get_one_department,
  get_one_department_clear,
} from "@services/redux/slices/department/department_one";
import {
  deleteDepartmentSelector,
  delete_department,
  delete_one_department_modal,
} from "@services/redux/slices/department/department_delete";
import {
  updateDepartmentSelector,
  update_one_department_clear,
} from "@services/redux/slices/department/department_update";
import {
  createDepartmentSelector,
  create_one_department_clear,
} from "@services/redux/slices/department/department_create";
import GatePassDetails from "./gate_pass_details";
// import { pass_type } from "@utils/constant";
import ReactSelect from "react-select";
import GatePassQRModal from "./gate_pass_qr_modal";
import { FcCancel } from "react-icons/fc";
import { Button, DatePicker } from "antd";
import { retrieveItem } from "@helpers/storage";
import { excelDownload } from "@helpers/constants";
import * as XLSX from "xlsx";
import moment from "moment";

const GatePassList = () => {
  const history = useHistory();
  const roles = JSON.parse(retrieveItem("roles"));

  const dispatch = useDispatch();
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [isQRModalShowing, setIsQRModalShowing] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [searchString, setSearchString] = useState(null);
  const [object, setObject] = useState(null);
  const { t } = useTranslation();
  const { id } = useParams();
  const [type, setType] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [passList, setPassList] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [data, setData] = useState([]);
  const [passSub, setPassSub] = useState([]);
  const [passSubId, setPassSubId] = useState(null);

  const [passSubChild, setPassSubChild] = useState([]);
  const [passSubChildId, setPassSubChildId] = useState(null);

  const { loading: deleteLoading, status: deleteStatus } = useSelector(
    deleteDepartmentSelector
  );

  const { loading: updateLoading, status: updateStatus } = useSelector(
    updateDepartmentSelector
  );
  const { loading: createLoading, status: createStatus } = useSelector(
    createDepartmentSelector
  );
  const { items: pass_type } = useSelector(getpass_typeListSelector);

  const { items: gate_pass_items } = useSelector(
    getGatePassListWithoutPaginationSelector
  );

  useEffect(() => {
    dispatch(get_GatePassList_Without_Paginaton({ ...object }));
  }, [object]);

  const XLSX_download = () => {
    const labels = [
      "Pass Number",
      "Pass",
      "Pass Type",
      "Pass Sub Type",
      "Name",
      "Issued Date",
      "Department",
      "Designation",
      "Vehicle Number",
      "Vehicle Make",
      "Previous Pass Number",
    ];
    const excel_items = gate_pass_items?.map((gate_pass) => {
      const issued_date =
        gate_pass?.issued_date === null
          ? "-"
          : moment(Number(gate_pass?.issued_date)).format("DD-MM-YYYY");

      let gate_pass_data = {
        ["Pass Number"]: gate_pass?.pass_number ?? "-",
        ["Pass"]:
          gate_pass?.pass_type?.parent_pass_type?.parent_pass_type?.name ?? "-",
        ["Pass Type"]: gate_pass?.pass_type?.parent_pass_type?.name ?? "-",
        ["Pass Sub Type"]: gate_pass?.pass_type?.name ?? "-",
        ["Name"]: gate_pass?.name ?? "-",
        ["Issued Date"]: issued_date ?? "-",
        ["Department"]: gate_pass?.department?.name ?? "-",
        ["Designation"]: gate_pass?.designation?.name ?? "-",
        ["Vehicle Number"]: gate_pass?.vehicle_number ?? "-",
        ["Vehicle Make"]: gate_pass?.vehicle_make ?? "-",
        ["Previous Pass Number"]: gate_pass?.previous_pass_number ?? "-",
      };
      return gate_pass_data;
    });

    excelDownload(excel_items, labels, "Vehicle Pass Report");
    // const newWorkBook = XLSX.utils.book_new();
    // let input = gate_pass_items?.map((x) => {
    //   return {
    //     "Pass Number": x.pass_number,
    //   };
    // });

    // const newSheet = XLSX.utils.json_to_sheet(input);
    // XLSX.utils.book_append_sheet(newWorkBook, newSheet, "Sheet1");
    // XLSX.writeFile(newWorkBook, "Pass Report.xlsx");
  };

  const handleAdd = () => {
    setRerender(!rerender);
    history.push({
      pathname: `${ROUTES.GATE_PASS_DETAILS}`,
    });
    // dispatch(reset_programme_session_item());
    dispatch(get_one_department_clear);
    setActionType("add");
    setActionItem({});
    // setManageModalVisible(true);
  };

  const handleCancel = (pro) => {
    setActionType("edit");
    history.push({
      pathname: `${ROUTES.GATE_PASS_DETAILS}/${pro.id}/cancel`,
    });
  };

  const handleEdit = (pro) => {
    setRerender(!rerender);

    setActionType("edit");
    history.push({
      pathname: `${ROUTES.GATE_PASS_DETAILS}/${pro.id}`,
    });
  };
  const handleView = (pro) => {
    setActionType("edit");
    history.push({
      pathname: `${ROUTES.GATE_PASS_VIEW}/${pro.id}`,
    });
  };

  const status = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Expired",
      value: "expired",
    },
    {
      label: "Cancelled",
      value: "cancelled",
    },
    {
      label: "Submitted",
      value: "submitted",
    },
  ];
  useEffect(() => {
    dispatch(get_pass_typeList());
  }, []);

  useEffect(() => {
    setObject({
      pass_type_id: type,
      status: statusValue,
      from_date: fromDate,
      to_date: toDate,
      pass_sub_type_id: passSubId,
      pass_child_type_id: passSubChildId,
      search_string: searchString,
    });
  }, [
    type,
    statusValue,
    toDate,
    fromDate,
    passSubId,
    passSubChildId,
    searchString,
  ]);

  useEffect(() => {
    let temp = [];
    if (type) {
      temp = pass_type?.find((x) => x.id === type)?.child_pass_types;
    }
    let value = [];
    value = temp?.map((x) => {
      return {
        label: x.name,
        value: x.id,
        child_pass_types: x.child_pass_types,
      };
    });

    setPassSub(value);
  }, [type]);
  useEffect(() => {
    let temp = [];
    if (passSubId) {
      temp = passSub?.find((x) => x.value === passSubId)?.child_pass_types;
    }
    let value = [];
    value = temp?.map((x) => {
      return {
        label: x.name,
        value: x.id,
        child_pass_types: x.child_pass_types,
      };
    });
    setPassSubChild(value);
  }, [passSubId]);

  useEffect(() => {
    let temp = [];
    if (pass_type) {
      temp = pass_type?.map((x) => {
        return { label: x.name, value: x.id };
      });
    }
    setPassList(temp);
  }, [pass_type]);
  useEffect(() => {
    if (deleteStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Gate Pass")} ${t("Deleted_successfully")}`,
      });
      setDialogVisible(false);
      dispatch(get_Departments());
      handleModalClose();
      dispatch(delete_one_department_modal());
    } else if (updateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Gate Pass")} ${t("update_successfully")}`,
      });
      setManageModalVisible(false);
      dispatch(update_one_department_clear);
      dispatch(get_Departments());

      handleModalClose();
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Gate Pass")} ${t("create_successfully")}`,
      });
      setManageModalVisible(false);
      dispatch(get_Departments());
      dispatch(create_one_department_clear());
      handleModalClose();
    }
  }, [deleteStatus, updateStatus, createStatus]);

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const toggleQRModal = () => {
    setIsQRModalShowing(!isQRModalShowing);
  };
  const { loading, items, total_items } = useSelector(getGatePassListSelector);

  const formatDisplay = (item) => {
    const issued_date =
      item?.issued_date === null
        ? ""
        : moment(Number(item?.issued_date)).format("DD/MM/YYYY");
    return <Text>{issued_date ? issued_date : "-"}</Text>;
  };
  const formatValidTill = (item) => {
    const valid_till =
      item?.valid_till === null
        ? ""
        : moment(Number(item?.valid_till)).format("DD/MM/YYYY");
    console.log("valid_till", valid_till);
    return <Text>{valid_till ? valid_till : "-"}</Text>;
  };

  const { url: report, loading: report_loading } = useSelector(
    getGatePassReportSelector
  );

  useEffect(() => {
    if (report) {
      window.open(report);
      dispatch(getGatePassReportClear());
    }
  }, [report]);
  useEffect(() => {
    let temp = items.map((x, i) => ({
      ...x,
      slno: (page_number - 1) * page_limit + i + 1,
    }));
    setData(temp);
  }, [items]);
  // const handleReport = () => {
  //   dispatch(
  //     getGatePassReport({
  //       pass_type_id: type,
  //       status: statusValue,
  //       from_date: fromDate,
  //       to_date: toDate,
  //       search_string: search_string,
  //     })
  //   );
  // };

  const formatStatus = (value) => {
    switch (value) {
      case "active":
        return "Active";
      case "expired":
        return "Expired";
      case "cancelled":
        return "Cancelled";
      case "submitted":
        return "Submitted";
    }
  };

  const formatPassType = (value) => {
    return (
      <>
        <Text>{value?.pass_type?.parent_pass_type?.name},</Text>
        <Text>{value?.pass_type?.name}</Text>
      </>
    );
  };

  const formatPassNumber = (item) => {
    return (
      <Text>
        {item.pass_number ? item.pass_number : "Pass Number is Not Allocated"}
      </Text>
    );
  };

  const formatIssuedTo = (value) => {
    return (
      <>
        <Text>
          {value?.name}
          {value?.name ? "," : ""}
        </Text>

        <Text>
          {value?.designation_string || value?.designation?.name}
          {value?.designation_string || value?.designation?.name
            ? ","
            : ""}{" "}
        </Text>

        <Text>{value?.department_string || value?.department?.name}</Text>
      </>
    );
  };
  let action = [
    {
      icon: <FcCancel />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("Cancel Gate Pass"),
      onPress: handleCancel,
    },
    {
      icon:
        statusValue === "expired" || statusValue === "cancelled" ? (
          <AiOutlineEye />
        ) : (
          <AiOutlineEdit />
        ),
      iconSize: 30,
      iconColor: "primary.700",
      title:
        statusValue === "expired" || statusValue === "cancelled"
          ? t("View Gate Pass")
          : t("Edit Gate Pass"),
      onPress:
        statusValue === "expired" || statusValue === "cancelled"
          ? handleView
          : handleEdit,
    },
    // {
    //   icon: <AiOutlineEye />,
    //   iconSize: 30,
    //   iconColor: "primary.700",
    //   title: t("View Gate Pass"),
    //   onPress: handleView,
    // },
  ];

  let action_1 = [
    {
      icon: <AiOutlineEye />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("View Vehicle Pass"),
      onPress: handleView,
    },
  ];
  let columns = [
    {
      header: t("table:s_no"),
      dataIndex: "slno",
      key: "s_no",
      cursor: "pointer",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:pass_number"),
      dataIndex: "",
      key: "pass_number",
      sortable: false,
      align: "left",
      formatDisplay: formatPassNumber,
      flex: 1,
    },
    // {
    //   header: t("table:type"),
    //   dataIndex: "pass_type.name",
    //   key: "pass_type",
    //   sortable: false,
    //   align: "left",
    //   flex: 1,
    // },
    {
      header: t("table:pass_type"),
      dataIndex: "",
      key: "pass_type",
      sortable: false,
      align: "left",
      formatDisplay: formatPassType,
      width: 150,
    },
    {
      header: t("table:pass_issued_to"),
      dataIndex: "",
      key: "pass_issued_to",
      sortable: false,
      align: "left",
      width: 250,
      formatDisplay: formatIssuedTo,
    },
    // {
    //   header: t("table:issued_date"),
    //   dataIndex: "issued_date",
    //   key: "issued_date",
    //   sortable: false,
    //   align: "left",
    //   width: 100,
    //   formatDisplay: formatDisplay,
    // },
    {
      header: t("table:issued_date"),
      dataIndex: "",
      key: "issued_date",
      sortable: false,
      align: "left",
      width: 100,
      formatDisplay: formatDisplay,
    },
    {
      header: "Valid Till",
      dataIndex: "",
      key: "Valid_till",
      sortable: false,
      align: "left",
      width: 100,
      formatDisplay: formatValidTill,
    },
    {
      header: t("table:vehicle_number"),
      dataIndex: "vehicle_number",
      key: "vehicle_number",
      sortable: false,
      align: "left",
      width: 100,
    },
    {
      header: t("table:vehicle_make"),
      dataIndex: "vehicle_make",
      key: "vehicle_make",
      sortable: false,
      align: "left",
      width: 100,
    },
    {
      header: t("table:status"),
      dataIndex: "entity_state.name",
      key: "entity_state",
      sortable: false,
      align: "left",
      // formatDisplay: formatStatus,
      width: 100,
    },
    // {
    //   header: t("table:QR"),
    //   dataIndex: "id",
    //   key: "id",
    //   type: "modal",
    //   //  labelField: "name",
    //   label: "View",
    //   sortable: false,
    //   align: "center",
    //   width: 200,
    // },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "left",
      type: "actions",
      width: 200,
      actions: roles?.[0]?.name === "Public Department" ? action : action_1,
    },
  ];
  statusValue !== "expired" || statusValue !== "cancelled";
  let header_actions = [
    {
      height: "50px",
      label: t("table:add_vehicle_pass"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <Box top="0px" zIndex="3">
          <Text fontSize="md" fontWeight="bold" py={4}>
            {t("table:vehicle_pass")}
          </Text>
        </Box>
        <HStack space="5" zIndex="10" mb="3">
          <Box flex={1}>
            <Text>{"Pass"}</Text>
            <ReactSelect
              value={passList?.find((r) => r.value === type)}
              options={passList}
              onChange={(entity, Object) => {
                if (entity) setType(entity.value);
                else setType(null);
              }}
              isClearable={true}
            />
          </Box>
          <Box flex={1}>
            <Text>{"Pass Type"}</Text>
            <ReactSelect
              value={passSub?.find((r) => r.value === passSubId)}
              options={passSub}
              onChange={(entity, Object) => {
                if (entity) setPassSubId(entity.value);
                else setPassSubId(null);
              }}
              isClearable={true}
            />
          </Box>
          <Box flex={1}>
            {/* <Text>{"Pass Sub Type"}</Text>
            <ReactSelect
              value={passSubChild?.find((r) => r.value === passSubChildId)}
              options={passSubChild}
              onChange={(entity, Object) => {
                if (entity) setPassSubChildId(entity.value);
                else setPassSubChildId(null);
              }}
              isClearable={true}
            /> */}
          </Box>
        </HStack>
        <HStack space="5" zIndex={6}>
          <Box flex={1}>
            <Text>{"Status"}</Text>
            <ReactSelect
              value={status?.find((r) => r.value === type)}
              options={status}
              onChange={(entity, Object) => {
                if (entity) setStatusValue(entity.value);
                else setStatusValue(null);
              }}
              isClearable={true}
            />
          </Box>
          <Box flex={1}>
            <Text>{"From Date"}</Text>
            <DatePicker
              onChange={(value, dateString) => {
                setFromDate(moment(value).format("YYYY-MM-DDT00:00:00.000[Z]"));
              }}
              allowClear
            />
          </Box>
          <Box flex={1}>
            <Text>{"To Date"}</Text>
            <DatePicker
              onChange={(value, dateString) => {
                setToDate(moment(value).format("YYYY-MM-DDT23:59:59.999[Z]"));
              }}
              allowClear
            />
          </Box>
        </HStack>
        {/* <HStack space="5">
          <Box flex={1}>
            <RangePicker
              allowClear
              onChange={(value) => {
              }}
            />
          </Box>
          <Box flex={1}></Box>
        </HStack> */}
        <HStack mt="3" space="5">
          <Box alignSelf={"center"}>
            <Button
              onClick={XLSX_download}
              type="primary"
              style={{
                width: "228px",
                background: "#683fe9",
                borderColor: "#683fe9",
              }}
              loading={report_loading}
            >
              <Text bold fontSize="15px" color="white">
                Excel Export
              </Text>
            </Button>
          </Box>
        </HStack>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="GatePass"
              loading={loading}
              setPageLimit={setPageLimit}
              setPageNumber={setPageNumber}
              setSearchString={setSearchString}
              data={data}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={get_GatePassList}
              onModalOpen={toggleQRModal}
              query={object}
              headerActions={
                roles?.[0]?.name === "Public Department" ||
                roles?.[0]?.name === "Department"
                  ? header_actions
                  : []
              }
              emptyMessage={t("No Gate Pass details Found")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Modal
        isOpen={manageModalVisible}
        onClose={handleModalClose}
        header={
          actionType === "add"
            ? t("table:add_vehicle_pass")
            : t("table:edit_vehicle_pass")
        }
        component={
          <GatePassDetails
            id={actionItem.id}
            handleModalClose={handleModalClose}
            initialValue={actionItem}
          />
        }
        source={actionItem}
        group={"User"}
      />
      <Modal
        isOpen={isQRModalShowing}
        onClose={toggleQRModal}
        header={t("Gate Pass QR")}
        component={
          <GatePassQRModal
            toggleQRModal={toggleQRModal}
            isQRModalShowing={isQRModalShowing}
            // QRValue={QRValue}
            // programme={actionItem}
          />
        }
        source={actionItem}
        group={"User"}
        size={"container"}
      />
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("table:delete_gate_pass")}
        content={t("table:gate_pass_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            // isLoading: loading,
            formErrorMessage: t("error:error_message"),
            submit: delete_department,
          },
        ]}
      />
    </>
  );
};
export default GatePassList;
