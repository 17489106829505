import React, { useEffect, useState } from "react";
import { Box, Card, HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import {
  createGatePassSelector,
  create_gate_pass,
  updateGatePassSelector,
  update_gate_pass,
  getDepartmentListSelector,
  get_Departments,
  get_pass_typeList,
  getpass_typeListSelector,
  get_designation,
  getDesignationListSelector,
  create_one_gate_pass_clear,
  update_one_gate_pass_clear,
  get_GatePassList,
  get_one_gatepass,
  getOnegatepassSelector,
  getOneDriverSelector,
  get_one_driver,
} from "@services/redux";
import { Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { retrieveItem } from "@helpers/storage";

const OfficerDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();
  const { id } = useParams();
  const { item: driver } = useSelector(getOneDriverSelector);

  useEffect(() => {
    dispatch(get_one_driver({ id: id }));
  }, [id]);

  useEffect;
  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };

  const formatStatus = (status) => {
    switch (status) {
      case "active":
        return "Active";
      case "expired":
        return "Expired";
    }
  };

  const roles = JSON.parse(retrieveItem("roles"));

  const items = [
    {
      name: "ram",
      designation: "Additional Director",
      application_no: "726542",
      address: [
        {
          line1: "test1",
          line2: "tested",
          pincode: "600008",
          district: "chennai",
        },
      ],
      pass: "two wheeler pass",
      pass_type: "two wheeler",
      pass_subtype: "Parking",
      vehicle_registration_number: "TN62",
      make_model: "bike",
      ownership_details: "sivakumar",
      purpose_of_visit: "shopping",
      old_pass_number: 1712,
      recommendation_letter: "-",
    },
  ];


  return (
    <Box flex="1">
      <Box position="sticky" flex={1} mb="10" mt="4">
        <Text fontWeight={"bold"} fontSize="md">
          {t("officers_details")}
        </Text>
      </Box>
      <Card mb="5">
        <VStack space="5">
          {items.map((item) => {
            return (
              <>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box flex={1}>
                        <Text bold>{t("name")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{item?.name}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box flex={1}>
                        <Text bold>{t("designation")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{item?.designation}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box flex={1}>
                        <Text bold>{t("application_no")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {item?.application_no ? item?.application_no : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box flex={1}>
                        <Text bold>{t("address")}:</Text>
                      </Box>
                      <Box flex={1}>
                        {item?.address?.line1 ? (
                          <Text>
                            {item?.address?.line1}, {item?.address?.line2},
                            {item?.address?.pincode},{item?.address?.district}
                          </Text>
                        ) : (
                          "-"
                        )}
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box flex={1}>
                        <Text bold>{t("pass")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{item?.pass}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box flex={1}>
                        <Text bold>{t("pass_type")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{item?.pass_type}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box flex={1}>
                        <Text bold>{t("pass_subtype")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{item?.pass_subtype}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box flex={1}>
                        <Text bold>{t("vehicle_registration_number")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{item?.pass_subtype}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box flex={1}>
                        <Text bold>{t("make_model")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{item?.make_model}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box flex={1}>
                        <Text bold>{t("ownership_details")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{item?.ownership_details}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box flex={1}>
                        <Text bold>{t("purpose_of_visit")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{item?.purpose_of_visit}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box flex={1}>
                        <Text bold>{t("old_pass_number")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{item?.old_pass_number}</Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box flex={1}>
                        <Text bold>{t("recommendation_letter")}:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{item?.recommendation_letter}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                  </Box>
                </HStack>
              </>
            );
          })}
        </VStack>
      </Card>
      <HStack justifyContent={"flex-end"}>
        <Button
          onClick={() => {
            history.goBack();
          }}
          type="default"
          danger
        >
          Back
        </Button>
      </HStack>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default OfficerDetails;
