import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  HStack,
  VStack,
  Center,
  Text,
  Pressable,
  SimpleGrid,
  Image,
} from "native-base";
import {
  dashboardSelector,
  dashboard,
  department_dashboard,
  department_dashboardSelector,
  technical_person_dashboard,
  technicalPersonDashboardSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useParams } from "react-router-dom";
import { retrieveItem, useStorageItem } from "@helpers/storage";
import data from "@assets/images/loader.gif";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Vechicle from "@assets/icons/vehicle.png";
import Contract from "@assets/icons/contract.png";
import Regular from "@assets/icons/regular.png";
import Political from "@assets/icons/political.png";
import { Button } from "antd";
import { IoMdArrowRoundBack } from "react-icons/io";

const AllocatedDriversDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const roles = JSON.parse(retrieveItem("roles"));
  const { t } = useTranslation();
  const { name } = retrieveItem("user");
  const { id } = useParams();

  console.log("id", id);

  useEffect(() => {
    dispatch(dashboard());
    dispatch(department_dashboard());
    dispatch(technical_person_dashboard());
  }, []);

  const { item, loading } = useSelector(dashboardSelector);
  const { item: department } = useSelector(department_dashboardSelector);
  const { item: technical_person_dashboard_list } = useSelector(
    technicalPersonDashboardSelector
  );

  const handleAllotments = (value) => {
    history.push(`${ROUTES.VEHICLE_LIST}/${value}`);
  };

  const handleDepartmentRedirect = (value, department_id) => {
    history.push(`${ROUTES.VEHICLE_LIST}/${value}/${department_id}`);
  };

  const handleGoBack = () => {
    history.push(ROUTES.ALLOCATED_DRIVER_DASHBOARD);
  };

  if (loading)
    return (
      <Box alignSelf="center" marginTop="25%">
        <img src={data} />
      </Box>
    );
  let currentDate = "";
  currentDate = new Date().toLocaleDateString();

  let allocated_chief_minister_count = 0;
  allocated_chief_minister_count =
    department?.length &&
    department?.find(
      (x) => x.department_id === "f842edb1-a105-4560-ba43-1cd626fc70e8"
    )?.vehicle_count;

  let allocated_minister_count = 0;
  allocated_minister_count =
    department?.length &&
    department?.find(
      (x) => x.department_id === "6bdf3a7f-9396-4bb5-8f41-49c792be6b59"
    )?.vehicle_count;
  let allocated_public_department_count = 0;
  allocated_public_department_count =
    department?.length &&
    department?.find(
      (x) => x.department_id === "ab60c9c2-3d4f-4cb0-a8c0-a5ca93a8c458"
    )?.vehicle_count;

  let allocated_state_guest_count = 0;
  allocated_state_guest_count =
    department?.length &&
    department?.find(
      (x) => x.department_id === "89e9a04d-54bd-475f-a985-6a4675615d3d"
    )?.vehicle_count;

  let allocated_other_department =
    item?.allotted -
    (Number(allocated_chief_minister_count) +
      Number(allocated_minister_count) +
      Number(allocated_public_department_count) +
      Number(allocated_state_guest_count));

  const handle_redirect = (type) => {
    history.push(`${ROUTES.DRIVER_LIST}/${type}/${id}`);
  };

  const handleRegular = () => {
    handle_redirect("type-regular");
  };

  const handlePolitical = () => {
    handle_redirect("type-political");
  };

  const handleTexco = () => {
    handle_redirect("type-TEXCO");
  };

  const handleContract = () => {
    handle_redirect("type-contract");
  };
  const public_department = () => {
    handleDepartmentRedirect(
      "department",
      "ab60c9c2-3d4f-4cb0-a8c0-a5ca93a8c458"
    );
  };
  const handleChiefMinister = () => {
    handleDepartmentRedirect(
      "department",
      "f842edb1-a105-4560-ba43-1cd626fc70e8"
    );
  };
  const handleMinister = () => {
    handleDepartmentRedirect(
      "department",
      "6bdf3a7f-9396-4bb5-8f41-49c792be6b59"
    );
  };
  const handleGuestHouse = () => {
    handleDepartmentRedirect(
      "department",
      "89e9a04d-54bd-475f-a985-6a4675615d3d"
    );
  };
  let dashboards = [];

  let title = "";
  if (
    roles?.[0]?.name === "Admin" ||
    roles?.[0]?.name === "Public Department"
  ) {
    title = "";
    dashboards.push(
      {
        name: t("regular"),
        value: 21 || 0,
        color: "#82aebf",
        sub_color: "#088395",
        icon: (
          <Image
            source={Regular}
            size={"50px"}
            style={{ tintColor: "#088395" }}
          />
        ),
        onPress: handleRegular,
      },
      {
        name: t("political"),
        value: 12 || 0,
        color: "#E2BBE9",
        sub_color: "#9B86BD",
        icon: (
          <Image
            source={Political}
            size={"50px"}
            style={{ tintColor: "#9B86BD" }}
          />
        ),
        onPress: handlePolitical,
      },
      {
        name: t("texco"),
        value: 40 || 0,
        color: "#83B4FF",
        sub_color: "#5A72A0",
        icon: (
          <Image
            source={Vechicle}
            size={"50px"}
            style={{ tintColor: "#5A72A0" }}
          />
        ),
        onPress: handleTexco,
      },
      {
        name: t("contract"),
        value: 10 || 0,
        color: "#EE6983",
        sub_color: "#850E35",
        icon: (
          <Image
            source={Contract}
            size={"50px"}
            style={{ tintColor: "#850E35" }}
          />
        ),
        onPress: handleContract,
      }
    );
  }
  return (
    <Box>
      <Box alignItems={"start"}>
        <Button onClick={handleGoBack}>
          <IoMdArrowRoundBack size={"20px"} />
        </Button>
      </Box>
      <Box width="100%" alignItems="center">
        <Box alignItems={"center"} justifyContent={"center"} height={"100%"}>
          <Text fontSize="3xl" bold pb={4} color={"black"}>
            {title}
          </Text>
          <SimpleGrid
            columns={{
              base: 1,
              xs: 1,
              md: 3,
              sm: 2,
              lg: 4,
              xl: 4,
              "2xl": 5,
            }}
            spacingY={"27px"}
            spacingX={21}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {dashboards?.map((option) => {
              return (
                <Box
                  size="100px"
                  backgroundColor={option.color}
                  style={{
                    cursor: "pointer",
                    width: "220px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <Pressable onPress={option.onPress}>
                    <VStack
                      justifyContent={"space-between"}
                      alignItems={"space-around"}
                    >
                      <HStack
                        space={"10px"}
                        justifyContent={"space-around"}
                        // padding={"10px"}
                      >
                        <Box h={"80px"} justifyContent={"center"}>
                          {option.icon}
                        </Box>
                        <Box h={"80px"} justifyContent={"center"}>
                          <Text
                            color={"white"}
                            fontSize={"26px"}
                            // fontStyle={"italic"}
                            fontWeight={600}
                          >
                            {option?.value}
                          </Text>
                        </Box>
                      </HStack>
                      <Box
                        backgroundColor={option?.sub_color}
                        justifyContent={"center"}
                        width={"100%"}
                        height={"30px"}
                        style={{
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <Text
                          textAlign={"center"}
                          alignItems={"center"}
                          color={"white"}
                          fontSize={"14px"}
                          fontWeight={500}
                        >
                          {option?.name}
                        </Text>
                      </Box>
                    </VStack>
                  </Pressable>
                </Box>
              );
            })}
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
};
export default AllocatedDriversDashboard;
