import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getCondemnations, getCondemnationsWithoutPagination } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "condemnationListWithoutPagination",
  initialState,
  reducers: {
    _get_condemnation_without_pagination: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_condemnation_without_pagination_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_condemnation_without_pagination_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_condemnation_without_pagination,
  _get_condemnation_without_pagination_success,
  _get_condemnation_without_pagination_failure,
} = slice.actions;

export const getCondemnationListWithoutPaginationSelector = (state) =>
  state.condemnationListWithoutPagination;

export const getCondemnationListReducerWithoutPagination = slice.reducer;

export function get_CondemnationListWithoutPagination(variables) {
  console.log("variableszzzz", variables);
  return async (dispatch) => {
    dispatch(_get_condemnation_without_pagination());
    try {
      // let data = {
      //   items: [
      //     {
      //       reason: "Unserviceable",
      //       go_date: "10/12/2022",
      //       go_number: "G.O. 123/13",
      //     },
      //   ],
      // };
      // dispatch(_get_condemnation_without_pagination_success(data));
      const response = await QueryRequest(
        getCondemnations,
        variables,
        dispatch
      );
      if (
        response?.data?.getContaminations &&
        !response?.data?.getContaminations.error
      ) {
        dispatch(
          _get_condemnation_without_pagination_success(
            response?.data?.getContaminations
          )
        );
      } else if (response?.data?.getContaminations?.error) {
        dispatch(
          _get_condemnation_without_pagination_failure(
            response?.data?.getContaminations.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_condemnation_without_pagination_failure(error));
    }
  };
}
