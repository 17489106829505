import { gql } from "@apollo/client";

export const getDistricts = gql`
  query getDistricts($page_number: Float, $page_limit: Float) {
    getDistricts(page_number: $page_number, page_limit: $page_limit) {
      items {
        id
        name
        error {
          status_code
          message
        }
      }
    }
  }
`;
