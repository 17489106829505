import { gql } from "@apollo/client";

export const getDepartments = gql`
  query getDepartments(
    $page_number: Float
    $page_limit: Float
    $search_string: String
  ) {
    getDepartments(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
    ) {
      items {
        id
        name
        code
      }
      pagination {
        page_limit
        total_count
        page_number
      }
    }
  }
`;

export const createDepartmentMutation = gql`
  mutation createDepartment($data: department_input) {
    createDepartment(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updateDepartmentMutation = gql`
  mutation updateDepartment($id: String!, $data: department_input) {
    updateDepartment(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const getOneDepartmentQuery = gql`
  query getDepartment($id: String!) {
    getDepartment(id: $id) {
      id
      name
      code
    }
  }
`;

export const deleteDepartmentMutation = gql`
  mutation deleteDepartment($id: String!) {
    deleteDepartment(id: $id) {
      id
      status
      error {
        message
      }
    }
  }
`;
