import { createfuelReducer } from "./fuel_create";
import { deletefuelReducer } from "./fuel_delete";
import { getfuelListReducer } from "./fuel_list";
import { getfuelListWithOutPaginationReducer } from "./fuel_list_without_pagination";
import { getOnefuelReducer } from "./fuel_one";
import { updatefuelReducer } from "./fuel_update";
export const getFuelListReducers = {
  fuelList: getfuelListReducer,
  fuelCreate: createfuelReducer,
  fuelGetOne: getOnefuelReducer,
  fuelUpdate: updatefuelReducer,
  fuelDelete: deletefuelReducer,
  fuelListWithoutPagination:getfuelListWithOutPaginationReducer
};
export * from "./fuel_list";
export * from "./fuel_create";
export * from "./fuel_delete";
export * from "./fuel_one";
export * from "./fuel_update";
export* from "./fuel_list_without_pagination"
