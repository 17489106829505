import React, { useEffect, useState } from "react";
import { Box, Card, HStack, Text, VStack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/antd_form";

import {
  createGatePassSelector,
  create_gate_pass,
  updateGatePassSelector,
  update_gate_pass,
  getDepartmentListSelector,
  get_Departments,
  get_pass_typeList,
  getpass_typeListSelector,
  get_designation,
  getDesignationListSelector,
  create_one_gate_pass_clear,
  update_one_gate_pass_clear,
  get_GatePassList,
  get_one_gatepass,
  getOnegatepassSelector,
  cancel_gate_pass,
  cancelGatePassSelector,
  cancel_one_gate_pass_clear,
} from "@services/redux";
import { Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";
import { Form as AntdForm } from "antd";
import moment from "moment";
import { retrieveItem } from "@helpers/storage";
import { colorScheme } from "@helpers/constants";

const GatePassDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = AntdForm.useForm();

  const [actionItem, setActionItem] = useState({});
  const [transitShow, setTransitShow] = useState(false);
  const [passId, setPassId] = useState("");
  const [visitors, setVisitors] = useState();
  const [visitorsId, setVisitorsId] = useState("");

  const [passType, setpassType] = useState([]);
  const [passSubTypes, setpassSubTypes] = useState([]);
  const [child_pass_id, setChildPassId] = useState();
  const [designationList, setDesignationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentId, setDepartmentId] = useState(false);
  const [designationId, setDesignationId] = useState(false);

  const { t } = useTranslation();
  const { id, param_type } = useParams();
  
  const {
    loading: createLoading,
    error: createError,
    status: createStatus,
  } = useSelector(createGatePassSelector);
  const {
    loading: updateLoading,
    error: updateError,
    status: updateStatus,
  } = useSelector(updateGatePassSelector);
  const {
    loading: cancelLoading,
    error: cancelError,
    status: cancelStatus,
  } = useSelector(cancelGatePassSelector);
  const { item: pass } = useSelector(getOnegatepassSelector);
  const { items: department } = useSelector(getDepartmentListSelector);
  const { items: pass_types } = useSelector(getpass_typeListSelector);
  const { items: designation } = useSelector(getDesignationListSelector);
  const handleSubmit = (values) => {
    delete values.parent_pass_id;
    delete values.child_pass_id;
    delete values.pass_type;
    delete values.status;
    values.department_id === "others" && delete values.department_id;
    values.designation_id === "others" && delete values.designation_id;

    values.rc_book_url = values.rc_book_url?.[0]?.url;
    if (param_type && pass?.id) {
      let update_id = values?.id;
      delete values?.error;
      dispatch(
        cancel_gate_pass({
          id: pass?.id,
          data: values,
        })
      );
    } else if (pass?.id) {
      let update_id = values?.id;
      delete values?.error;
      dispatch(
        update_gate_pass({
          id: pass?.id,
          data: values,
        })
      );
    } else {
      dispatch(
        create_gate_pass({
          data: values,
        })
      );
    }
  };

  useEffect(() => {
    if (designation) {
      setDesignationList([
        ...designation,
        {
          id: "others",
          name: "Others",
        },
      ]);
    }
  }, [designation]);

  useEffect(() => {
    if (department) {
      setDepartmentList([
        ...department,
        {
          id: "others",
          name: "Others",
        },
      ]);
    }
  }, [department]);

  useEffect(() => {
    if (createError) {
      showToast({
        type: "error",
        message: createError,
      });

      dispatch(create_one_gate_pass_clear());
    } else if (createError === "Failure") {
      showToast({
        type: "error",
        message: `${t("Something went wring")} `,
      });

      dispatch(create_one_gate_pass_clear());
    } else if (updateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Gate Pass")} ${t("update_successfully")}`,
      });
      history.goBack();
      dispatch(update_one_gate_pass_clear());
      dispatch(get_GatePassList());
    } else if (cancelStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Gate Pass")} ${t("cancelled successfully")}`,
      });
      history.goBack();
      dispatch(cancel_one_gate_pass_clear());
      dispatch(get_GatePassList());
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Gate Pass")} ${t("create_successfully")}`,
      });
      dispatch(create_one_gate_pass_clear());
      dispatch(get_GatePassList());
      history.goBack();
    } else if (updateError === "Failure") {
      showToast({
        type: "error",
        message: `${t("Something went wring")} `,
      });

      dispatch(update_one_gate_pass_clear());
    }
  }, [updateStatus, createStatus, cancelStatus, createError, updateError]);
  useEffect(() => {
    dispatch(get_one_gatepass({ id: id }));
  }, [id]);
  useEffect(() => {
    if (pass?.id) {
      setChildPassId(pass?.pass_type?.parent_pass_type?.id);
      setPassId(pass?.pass_type?.parent_pass_type?.parent_pass_type?.id);
      pass?.designation_string && setDesignationId(true);
      pass?.department_string && setDepartmentId(true);
      setActionItem({
        ...pass,
        rc_book_url: pass?.rc_book_url
          ? [
              {
                uid: "-1",
                name: "Photo",
                status: "done",
                url: pass?.rc_book_url,
              },
            ]
          : [],
        parent_pass_id: pass?.pass_type?.parent_pass_type?.parent_pass_type?.id,
        child_pass_id: pass?.pass_type?.parent_pass_type?.id,
        pass_type_id: pass?.pass_type_id,
        designation_id: pass?.designation_string
          ? "others"
          : pass?.designation_id,
        department_id: pass?.department_string ? "others" : pass?.department_id,
        issued_date: moment(parseInt(pass?.issued_date, 10)),
        previous_issued_date:
          pass?.previous_issued_date &&
          moment(parseInt(pass?.previous_issued_date, 10)),
      });
    } else {
      form.resetFields();
      setActionItem({});
    }
  }, [pass]);
  useEffect(() => {
    dispatch(get_Departments());
    dispatch(get_pass_typeList());
    dispatch(get_designation());
  }, []);
  useEffect(() => {
    let sub_types = [];
    if (passId) {
      sub_types = pass_types?.find((x) => x.id === passId)?.child_pass_types;
    }

    setpassType(sub_types);
  }, [passId]);

  useEffect(() => {
    let pass1 = pass_types.find(
      (element) => element?.id === passId
    )?.child_pass_types;
    let pass2 = pass1?.find(
      (element) => element.id === child_pass_id
    )?.child_pass_types;
    let pass3 = pass2?.find((element) => element.id === visitorsId);
    setVisitors(pass3);
  }, [visitorsId, passId, child_pass_id]);
  useEffect(() => {
    let child_pass = [];
    if (child_pass_id || pass?.pass_type?.parent_pass_type?.id) {
      child_pass = passType?.find(
        (x) => x.id === child_pass_id || pass?.pass_type?.parent_pass_type?.id
      )?.child_pass_types;
    }
    setpassSubTypes(child_pass);
  }, [child_pass_id, pass, id, passType]);

  useEffect;
  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };

  const formatStatus = (status) => {
    switch (status) {
      case "active":
        return "Active";
      case "expired":
        return "Expired";
    }
  };
  const handleInputChange = (val, value) => {
    if (value.parent_pass_id) {
      setPassId(value.parent_pass_id);
      setChildPassId("");
    }
    if (value.child_pass_id) {
      setChildPassId(value.child_pass_id);
      value.pass_type_id = "";
      setTransitShow(true);
    } else setTransitShow(false);
    if (value.pass_type_id) {
      setVisitorsId(value.pass_type_id);
    }
    if (value.department_id === "others") {
      setDepartmentId(true);
    } else setDepartmentId(false);
    if (value.designation_id === "others") {
      setDesignationId(true);
    } else setDesignationId(false);
  };
  const roles = JSON.parse(retrieveItem("roles"));
  return (
    <Box flex="1">
      <Box
        position="sticky"
        flex={1}
        mb="10"
        mt="4"
        backgroundColor={colorScheme.transparent}
      >
        <Text fontWeight={"bold"} fontSize="md">
          {id ? "Edit Vehicle Pass" : "Add Vehicle Pass"}
        </Text>
      </Box>
      <Form
        form={form}
        initialValues={actionItem}
        onSubmit={handleSubmit}
        onValueChange={handleInputChange}
        // validationSchema={getGatePassValidationSchema(t)}
      >
        {param_type ? (
          <Box>
            <Card mb="5">
              <HStack justifyContent={"flex-end"} space="4">
                <Box>
                  <Text bold>Status:</Text>
                </Box>
                <Box>
                  <Text>{formatStatus(pass?.status)}</Text>
                </Box>
              </HStack>
              <HStack>
                <Box flex={1}>
                  <HStack>
                    <Box flex={1}>
                      <Text bold>Pass Number:</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>{pass?.pass_number}</Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack>
                    <Box flex={1}>
                      <Text bold>Pass Type:</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>{pass?.pass_type?.name}</Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
              <HStack>
                <Box flex={1}>
                  <HStack>
                    <Box flex={1}>
                      <Text bold>Name:</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>{pass?.name}</Text>
                    </Box>
                  </HStack>
                </Box>
                <Box flex={1}>
                  <HStack>
                    <Box flex={1}>
                      <Text bold>Pass Issued Date:</Text>
                    </Box>
                    <Box flex={1}>
                      <Text>{formatDisplay(pass?.issued_date)}</Text>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
            </Card>
            <HStack space={5}>
              <Box flex={1}>
                <Form.TextArea
                  field={"remarks"}
                  label={t("form:reason")}
                  rules={[
                    {
                      required: false,
                      message: "Remarks is required",
                    },
                  ]}
                />
              </Box>
            </HStack>
          </Box>
        ) : (
          <VStack space={4}>
            <HStack space={5}>
              <Box flex={1}>
                <Form.Select
                  field={"parent_pass_id"}
                  label={t("form:pass")}
                  options={pass_types}
                  labelField={"name"}
                  valueField={"id"}
                  isClearable={true}
                  rules={[
                    {
                      required: true,
                      message: "Pass is required",
                    },
                  ]}
                />
              </Box>
              <Box flex={1}>
                <Form.Select
                  field={"child_pass_id"}
                  label={t("form:pass_type")}
                  options={passType}
                  labelField={"name"}
                  valueField={"id"}
                  isClearable={true}
                  rules={[
                    {
                      required: true,
                      message: "Pass Type is required",
                    },
                  ]}
                />
              </Box>
              <Box flex={1}>
                <Form.Select
                  field={"pass_type_id"}
                  label={t("form:pass_sub_type")}
                  options={passSubTypes}
                  labelField={"name"}
                  valueField={"id"}
                  isClearable={true}
                  rules={[
                    {
                      required: true,
                      message: "Pass Sub Type is required",
                    },
                  ]}
                />
              </Box>
            </HStack>
            <HStack space={5}>
              <Box flex={1}>
                <Form.TextBox
                  field={"pass_number"}
                  label={t("form:pass_number")}
                  rules={[
                    {
                      required: true,
                      message: "Pass Number is required",
                    },
                  ]}
                />
              </Box>
              <Box flex={1}>
                <Form.TextBox
                  field={"previous_pass_number"}
                  label={t("form:previous_pass_number")}
                />
              </Box>
              <Box flex={1}>
                <Form.Date
                  field={"previous_issued_date"}
                  label={t("form:previous_issued_date")}
                />
              </Box>
            </HStack>
            <HStack space={5}>
              <Box flex={1}>
                <Form.TextBox
                  field={"name"}
                  label={t("Name")}
                  rules={[
                    {
                      required: false,
                      message: "HOD and Secretariat Staff is required",
                    },
                  ]}
                />
              </Box>

              <Box flex={1}>
                <Form.TextBox
                  field={"vehicle_number"}
                  label={t("form:vechicle_number")}
                  rules={[
                    {
                      required: false,
                      message: "Vehicle Number is required",
                    },
                  ]}
                />
              </Box>
            </HStack>
            <HStack space={5}>
              {visitors?.name !== "Visitors" && (
                <>
                  <Box flex={1}>
                    <Form.Select
                      field={"designation_id"}
                      label={t("form:designation")}
                      options={designationList}
                      labelField="name"
                      valueField="id"
                      isClearable={true}
                    />
                  </Box>
                  <Box flex={1}>
                    <Form.Select
                      field={"department_id"}
                      label={t("form:department")}
                      options={departmentList}
                      labelField={"name"}
                      valueField={"id"}
                    />
                  </Box>
                </>
              )}
            </HStack>

            <HStack space={5}>
              {designationId && (
                <Box width="48.5%">
                  <Form.TextBox
                    field={"designation_string"}
                    label={t("Designation")}
                  />
                </Box>
              )}
              {departmentId && (
                <Box width="48.5%">
                  <Form.TextBox
                    field={"department_string"}
                    label={t("Department")}
                  />
                </Box>
              )}

              {/* {(!departmentId || !designationId) && <Box flex={1}></Box>} */}
            </HStack>
            <HStack space={5}>
              <Box flex={1}>
                <Form.TextBox
                  field={"vehicle_make"}
                  label={t("form:vechicle_make")}
                  rules={[
                    {
                      required: false,
                      message: "Vehicle Make is required",
                    },
                  ]}
                />
              </Box>
              <Box flex={1}>
                <Form.Date
                  field={"issued_date"}
                  label={t("form:issued_date")}
                  maxDate={new Date()}
                  rules={[
                    {
                      required: true,
                      message: "Issued Date is required",
                    },
                  ]}
                />
              </Box>
            </HStack>

            {visitors?.name === "Visitors" && (
              <HStack space={5}>
                <Box flex={1}>
                  <Form.TextBox
                    field={"referred_by"}
                    label={t("form:referred_by")}
                    rules={[
                      {
                        required: false,
                        message: "Vehicle Make is required",
                      },
                    ]}
                  />
                </Box>
                <Box flex={1}>
                  <Form.TextBox field={"address"} label={t("form:address")} />
                </Box>
              </HStack>
            )}
            {visitors?.name === "Visitors" && (
              <HStack space={5}>
                <Box>
                  <Form.File field={"rc_book_url"} label={t("form:rc_book")} />
                </Box>
                <Box flex={1}></Box>
              </HStack>
            )}
          </VStack>
        )}
        <HStack space={2} justifyContent="flex-end" mt="5">
          {roles?.[0]?.name === "Admin" ? (
            ""
          ) : (
            <Form.Button
              isLoading={id ? updateLoading : createLoading}
              width="100px"
              BoxorScheme="primary"
              height="45px"
              formErrorMessage={t("error:error_message")}
              label={param_type ? t("Cancel Pass") : t("Submit")}
            >
              {param_type ? t("Cancel Pass") : t("add")}
            </Form.Button>
          )}
          <Button
            isLoading={false}
            width="100px"
            danger
            onClick={() => {
              history.goBack();
            }}
            formErrorMessage={t("error:error_message")}
          >
            {t("common:Back")}
          </Button>
        </HStack>
      </Form>
      <Box mt="10px" width="200px" zIndex={1}></Box>
    </Box>
  );
};
export default GatePassDetails;
