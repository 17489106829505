import { gql } from "@apollo/client";

export const getfuels = gql`
  query getFuels(
    $page_number: Float
    $page_limit: Float
    $vehicle_id: String
    $fuel_refilled_start_date: String
    $fuel_refilled_end_date: String
    $search_string: String
  ) {
    getFuels(
      page_number: $page_number
      page_limit: $page_limit
      vehicle_id: $vehicle_id
      fuel_refilled_start_date: $fuel_refilled_start_date
      fuel_refilled_end_date: $fuel_refilled_end_date
      search_string: $search_string
    ) {
      items {
        id
        running_number
        vehicle_id
        driver_id
        meter_reading
        quantity
        amount
        fuel_refilled_date
        fuel_type
        vehicle {
          id
          allotted_department {
            name
          }
          vehicle_number
          car_model {
            car_make {
              id
              name
            }
          }
        }
        driver {
          id
          name
          mobile
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const createfuelMutation = gql`
  mutation createFuel($data: fuel_input) {
    createFuel(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updatefuelMutation = gql`
  mutation updateFuel($id: String!, $data: fuel_input) {
    updateFuel(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const getOnefuelQuery = gql`
  query getFuel($id: String!) {
    getFuel(id: $id) {
      id
      vehicle_id
      meter_reading
      quantity
      fuel_type
      officer_name
      driver_id
      fuel_refilled_date
      vehicle {
        id
        vehicle_number
        car_model {
          name
          car_make {
            name
          }
        }
      }
      driver {
        name
        mobile
      }
    }
  }
`;

export const deletefuelMutation = gql`
  mutation deleteFuel($id: String!) {
    deleteFuel(id: $id) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;
export const getfuelswithoutPagination = gql`
  query getFuels(
    $page_number: Float
    $page_limit: Float
    $vehicle_id: String
    $fuel_refilled_start_date: String
    $fuel_refilled_end_date: String
    $search_string: String
  ) {
    getFuels(
      page_number: $page_number
      page_limit: $page_limit
      vehicle_id: $vehicle_id
      fuel_refilled_start_date: $fuel_refilled_start_date
      fuel_refilled_end_date: $fuel_refilled_end_date
      search_string: $search_string
    ) {
      items {
        id
        vehicle_id
        driver_id
        meter_reading
        quantity
        amount
        fuel_refilled_date
        officer_name
        vehicle {
          id
          vehicle_number
          car_model {
            car_make {
              id
              name
            }
          }
        }
        driver {
          id
          name
          mobile
        }
      }
    }
  }
`;
