import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Text } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Dialog from "@views/components/ui/dialog";
import Form from "@views/components/ui/antd_form";
import { showToast } from "@helpers/toast";
import { ROUTES } from "@views/routes/my_routes";
import data from "@assets/images/loader.gif";
import { useHistory } from "react-router-dom";
import { Src } from "@views/components/source/constant";
import {
  ClassVechile,
  VehicleColor,
  FuelUsed,
  BatteryType,
  SeatingCapacity,
  WheelDriveType,
} from "@views/components/source/constant";
import {
  getMakerListSelector,
  getCar_Makers,
  getTyre_Makers,
  getTyreMakerListSelector,
  getAll_Agency,
  getAgencyListSelector,
  getAll_Accessories,
  getAccessoriesListSelector,
  update_Vehicles,
  create_one_createvehicle_clear,
  getone_Vehicles,
  getOneVehiclesListSelector,
  getone_vechile_clear,
  delete_Vehicles,
  getDeleteVehiclesListSelector,
  delete_vechile_reset,
  getAll_Vehicles,
  getUpdateVehiclesListSelector,
  update_one_vehicle_clear,
  create_createvehicle,
  createVehicleSelector,
  check_vehicles,
  checkVehicleSelector,
  check_vehicle_clear,
} from "@services/redux";
import ReactSelect from "react-select";
import { getVehicleValidationSchema } from "./vehicleValidation";
import { validate } from "uuid";
import fileUpload from "@utils/file_upload";
import moment from "moment";
import { Button, Form as AntdForm, DatePicker } from "antd";
import { colorScheme } from "@helpers/constants";

const VehicleDetails = (props) => {
  const dispatch = useDispatch();
  const [form] = AntdForm.useForm();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);

  const [dialogVisible, setDialogVisible] = useState(false);
  const [showField, setShowField] = useState(false);

  const [makerChoose, setMakerChoose] = useState("");
  const [accessoriesList, setAccessoriesList] = useState([]);

  const [currentMakerModels, setCurrentMakerModels] = useState([]);
  const [vehicle_number, setVehicleNumber] = useState();
  const [vehicle_model_year, setVehicleModelYear] = useState();
  const {
    loading: update_loading,
    error: update_error,
    status: update_status,
  } = useSelector(getUpdateVehiclesListSelector);

  const { error: check_error, status: check_status } =
    useSelector(checkVehicleSelector);
  const handleBack = () => {
    dispatch(getone_vechile_clear);
    history.push(ROUTES.VEHICLE_LIST);
  };
  const handleDelete = (item) => {
    setActionItem({ id });

    setDialogVisible(true);
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const [vehicleSource, setVehicleSource] = useState("");
  const { items: maker_items } = useSelector(getMakerListSelector);
  const { items: tyremaker_items } = useSelector(getTyreMakerListSelector);
  const { items: agency_items } = useSelector(getAgencyListSelector);
  const { items: accessories_items } = useSelector(getAccessoriesListSelector);

  const {
    status: createStatus,
    loading: create_loading,
    error: createError,
  } = useSelector(createVehicleSelector);
  const { item: vehicle_get_one, loading } = useSelector(
    getOneVehiclesListSelector
  );

  const { status: deleteStatus } = useSelector(getDeleteVehiclesListSelector);

  const onValueChange = (values, all) => {
    if (values.vehicle_number) {
      setVehicleNumber(values.vehicle_number);
    }
    if (values.vechile_make) {
      setMakerChoose(values.vechile_make);
    }

    if (values.source) {
      setVehicleSource(values.source);
    }
    if (all.additional_accessories?.some((x) => x === "others")) {
      setShowField(true);
    } else setShowField(false);
  };

  useEffect(() => {
    dispatch(getCar_Makers({}));
    dispatch(getTyre_Makers({}));
    dispatch(getAll_Agency({}));
    dispatch(getAll_Accessories({}));
  }, []);
  useEffect(() => {
    if (vehicle_number && vehicle_number?.length === 13) {
      dispatch(check_vehicles({ vehicle_number: vehicle_number }));
    }
  }, [vehicle_number]);
  useEffect(() => {
    setAccessoriesList([
      ...accessories_items,
      { id: "others", name: `Others` },
    ]);
  }, [accessories_items]);
  useEffect(() => {
    if (id) dispatch(getone_Vehicles({ id: id }));
  }, [id]);

  useEffect(() => {
    if (deleteStatus === "Success") {
      showToast({
        type: "success",
        message: `Vehicle Deleted Successfully`,
      });
      setDialogVisible(false);
      dispatch(delete_vechile_reset);

      history.push(ROUTES.VEHICLE_LIST);
    } else if (deleteStatus === "Failure") {
      showToast({
        type: "warn",
        message: `Something Went wrong`,
      });
      setDialogVisible(false);
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `Vehicle Created Successfully`,
      });

      history.push(ROUTES.VEHICLE_LIST);
      dispatch(create_one_createvehicle_clear());
    } else if (createError === "Failure") {
      showToast({
        type: "error",
        message: `Something Went wrong`,
      });
      dispatch(create_one_createvehicle_clear());
    } else if (createStatus === "Failure") {
      showToast({
        type: "error",
        message: `Something Went wrong`,
      });
      dispatch(create_one_createvehicle_clear());
    } else if (update_status === "Success") {
      showToast({
        type: "success",
        message: `Vehicle Updated Successfully`,
      });

      history.push(ROUTES.VEHICLE_LIST);
      dispatch(update_one_vehicle_clear);
    } else if (update_status === "Failure") {
      showToast({
        type: "warn",
        message: `Something Went wrong`,
      });
      dispatch(update_one_vehicle_clear);
    }
  }, [createStatus, update_error, update_status]);

  useEffect(() => {
    if (check_error) {
      showToast({
        type: "warn",
        message: check_error,
      });
      dispatch(check_vehicle_clear());
    }
  }, [check_error]);

  useEffect(() => {
    let currentmakerChoose = [];

    if (makerChoose) {
      currentmakerChoose = maker_items.find(
        (x) => x.id === makerChoose
      )?.car_models;
    }

    setCurrentMakerModels(currentmakerChoose);
  }, [makerChoose]);

  const handleSubmit = (values) => {
    values.vehicle_model_year = vehicle_model_year;
    values.fuel_tank_capacity = parseInt(values.fuel_tank_capacity);
    values.vehicle_price = parseInt(values.vehicle_price);
    delete values.vechile_make;
    // delete values.source;
    values.front_image_url = values.front_image_url?.[0]?.url;
    values.rear_image_url = values.rear_image_url?.[0]?.url;
    values.left_image_url = values.left_image_url?.[0]?.url;
    values.right_image_url = values.right_image_url?.[0]?.url;
    values.dashboard_image_url = values.dashboard_image_url?.[0]?.url;
    values.go_copy = values.go_copy?.[0]?.url;
    values.rc_book = values.rc_book?.[0]?.url;
    values.additional_accessories = values.additional_accessories?.filter(
      (x) => x !== "others"
    );

    if (vehicle_get_one.id) {
      let update_id = vehicle_get_one?.id;
      delete values?.id;
      delete values?.error;
      // delete values?.source;
      delete values?.agency;
      delete values?.car_model;
      delete values?.tyre_make;
      delete values?.services;
      delete values?.current_service;
      delete values?.allotments;
      dispatch(
        update_Vehicles({
          id: update_id,
          data: values,
          car_model_id: values.id,
        })
      );
    } else {
      dispatch(
        create_createvehicle({
          data: values,
        })
      );
    }
  };

  useEffect(() => {
    let vehicle = {};
    if (vehicle_get_one?.id) {
      setShowField(vehicle_get_one?.other_accessory);
      setVehicleSource(vehicle_get_one?.source);
      vehicle = {
        ...vehicle_get_one,
        additional_accessories: vehicle_get_one?.additional_accessories?.map(
          (x) => {
            return x.additional_accessory_id;
          }
        ),
        date_of_delivery:
          vehicle_get_one.date_of_delivery &&
          moment(parseInt(vehicle_get_one.date_of_delivery, 10)),
        go_date:
          vehicle_get_one.go_date &&
          moment(parseInt(vehicle_get_one.go_date, 10)),

        vechile_make: vehicle_get_one?.car_model?.car_make?.id,
        front_image_url: vehicle_get_one?.front_image_url
          ? [
              {
                uid: "-1",
                name: vehicle_get_one?.front_image_url ? "Front image" : "",
                status: "done",
                url: vehicle_get_one?.front_image_url,
              },
            ]
          : [],
        rear_image_url: vehicle_get_one?.rear_image_url
          ? [
              {
                uid: "-1",
                name: vehicle_get_one?.rear_image_url ? "Rear image" : "",
                status: "done",
                url: vehicle_get_one?.rear_image_url,
              },
            ]
          : [],
        left_image_url: vehicle_get_one?.left_image_url
          ? [
              {
                uid: "-1",
                name: vehicle_get_one?.left_image_url ? "Left image" : "",
                status: "done",
                url: vehicle_get_one?.left_image_url,
              },
            ]
          : [],
        right_image_url: vehicle_get_one?.right_image_url
          ? [
              {
                uid: "-1",
                name: vehicle_get_one?.right_image_url ? "Right image" : "",
                status: "done",
                url: vehicle_get_one?.right_image_url,
              },
            ]
          : [],
        dashboard_image_url: vehicle_get_one?.dashboard_image_url
          ? [
              {
                uid: "-1",
                name: vehicle_get_one?.dashboard_image_url
                  ? "Dashboard image"
                  : "",
                status: "done",
                url: vehicle_get_one?.dashboard_image_url,
              },
            ]
          : [],
        go_copy: vehicle_get_one?.go_copy
          ? [
              {
                uid: "-1",
                name: vehicle_get_one?.go_copy ? "GO Copy" : "",
                status: "done",
                url: vehicle_get_one?.go_copy,
              },
            ]
          : [],
        rc_book: vehicle_get_one?.rc_book
          ? [
              {
                uid: "-1",
                name: vehicle_get_one?.rc_book ? "RC Book" : "",
                status: "done",
                url: vehicle_get_one?.rc_book,
              },
            ]
          : [],
      };
      setMakerChoose(vehicle_get_one?.car_model?.car_make?.id);
      setActionItem(vehicle);
    } else {
      form.resetFields();
      // setActionItem({
      //   date_of_delivery: moment(),
      // });
    }
  }, [vehicle_get_one]);

  const vehicle_no_regex = /^[A-Z]{2}\s[0-9]{2}\s[A-Z]{1,2}\s[0-9]{4}$/;
  return (
    <>
      <Box flex="1" w="100%">
        <Box
          top="10px"
          zIndex="2"
          bg="white"
          backgroundColor={colorScheme.transparent}
        >
          <Text fontSize="md" fontWeight="bold" py={4}>
            {id ? t("update_vechicle") : t("add_vechicle")}
          </Text>
        </Box>

        <br />
        <Form
          form={form}
          // validationSchema={getVehicleValidationSchema(t)}
          initialValues={actionItem}
          onSubmit={handleSubmit}
          onValueChange={onValueChange}
        >
          <VStack space={5} mt={5}>
            <HStack flex={1} space={5}>
              <Box flex={1}>
                <Form.File
                  field={`front_image_url`}
                  label={t("Front")}
                  shape="square"
                  rules={[
                    {
                      required: false,
                      message: "RC Book is required",
                    },
                  ]}
                  // fallbackUrl={front}
                />
                {vehicle_get_one?.front_image_url && (
                  <img
                    src={vehicle_get_one?.front_image_url}
                    alt=""
                    width="65px"
                    // height={"25px"}
                  />
                )}
              </Box>
              <Box flex={1}>
                <Form.File
                  field={`rear_image_url`}
                  label={t("Rear")}
                  shape="square"
                  rules={[
                    {
                      required: false,
                      message: "RC Book is required",
                    },
                  ]}
                  // fallbackUrl={back}
                />
                {vehicle_get_one?.rear_image_url && (
                  <img
                    src={vehicle_get_one?.rear_image_url}
                    alt=""
                    width="65px"
                  />
                )}
              </Box>
              <Box flex={1}>
                <Form.File
                  field={`left_image_url`}
                  label={t("Left")}
                  shape="square"
                  rules={[
                    {
                      required: false,
                      message: "RC Book is required",
                    },
                  ]}
                  // fallbackUrl={left}
                />
                {vehicle_get_one?.left_image_url && (
                  <img
                    src={vehicle_get_one?.left_image_url}
                    alt=""
                    width="65px"
                  />
                )}
              </Box>
              <Box flex={1}>
                <Form.File
                  field={`right_image_url`}
                  label={t("Right")}
                  shape="square"
                  rules={[
                    {
                      required: false,
                      message: "RC Book is required",
                    },
                  ]}
                  // fallbackUrl={right}
                />
                {vehicle_get_one?.right_image_url && (
                  <img
                    src={vehicle_get_one?.right_image_url}
                    alt=""
                    width="65px"
                  />
                )}
              </Box>
              <Box flex={1}>
                <Form.File
                  field={`dashboard_image_url`}
                  label={t("Dashboard")}
                  shape="square"
                  rules={[
                    {
                      required: false,
                      message: "RC Book is required",
                    },
                  ]}
                  // fallbackUrl={dash}W
                />
                {vehicle_get_one?.dashboard_image_url && (
                  <img
                    src={vehicle_get_one?.dashboard_image_url}
                    alt=""
                    width="65px"
                  />
                )}
              </Box>
            </HStack>

            <HStack flex={1} space={5}>
              <Box flex={1}>
                <Form.TextBox
                  field={"vehicle_number"}
                  label={t("form:vehicle_number")}
                  upperCaseOnly={true}
                  rules={[
                    {
                      required: true,
                      message: "Vehicle Number is required",
                    },
                    {
                      pattern: vehicle_no_regex,
                      message: "Vehicle Number is invalid",
                    },
                  ]}
                />
              </Box>
              <Box flex={1}>
                <Form.Select
                  field={"class_vehicle"}
                  label={t("form:class_vehicle")}
                  options={ClassVechile}
                  labelField={"label"}
                  valueField={"value"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                  rules={[
                    {
                      required: true,
                      message: "Class Vehicle is required",
                    },
                  ]}
                />
              </Box>

              <Box flex={1}>
                <Form.Select
                  field={"vechile_make"}
                  label={t("form:vechile_make")}
                  options={maker_items}
                  labelField={"name"}
                  valueField={"id"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                  rules={[
                    {
                      required: true,
                      message: "vehicle Make is required",
                    },
                  ]}
                />
              </Box>

              <Box flex={1}>
                <Form.Select
                  field={"car_model_id"}
                  label={t("form:vechile_model")}
                  options={currentMakerModels}
                  labelField={"name"}
                  valueField={"id"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                  rules={[
                    {
                      required: true,
                      message: "Car Modal is required",
                    },
                  ]}
                />
              </Box>
            </HStack>
            <HStack flex={1} space={5}>
              <Box flex={1}>
                <Form.Select
                  field={"colour"}
                  label={t("form:colour")}
                  options={VehicleColor}
                  labelField={"label"}
                  valueField={"value"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                />
              </Box>

              <Box flex={1}>
                <Form.Select
                  field={"fuel_used"}
                  label={t("form:fuel_used")}
                  options={FuelUsed}
                  labelField={"label"}
                  valueField={"value"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                  rules={[
                    {
                      required: false,
                      message: "Fuel Used is required",
                    },
                  ]}
                />
              </Box>

              <Box flex={1}>
                <Form.TextBox
                  field={"engine_number"}
                  label={t("form:engine_number")}
                  rules={[
                    {
                      required: false,
                      message: "Engine Number is required",
                    },
                  ]}
                />
              </Box>
              <Box flex={1}>
                <Form.TextBox
                  field={"chasis_number"}
                  label={t("form:chasis_number")}
                  rules={[
                    {
                      required: false,
                      message: "Chassis Number is required",
                    },
                  ]}
                />
              </Box>
            </HStack>

            <HStack flex={4} space={5}>
              <Box flex={1}>
                <Form.Select
                  field={"battery_type"}
                  label={t("form:battery_type")}
                  options={BatteryType}
                  labelField={"label"}
                  valueField={"value"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                />
              </Box>
              <Box flex={1}>
                <Form.TextBox
                  field={"battery_number"}
                  label={t("form:battery_number")}
                  rules={[
                    {
                      required: false,
                      message: "Battery Number is required",
                    },
                  ]}
                />
              </Box>

              <Box flex={1}>
                <Form.Number
                  field={"fuel_tank_capacity"}
                  label={t("form:fuel_tank_capacity")}
                  rules={[
                    {
                      required: false,
                      message: "Fuel tank capacity is required",
                    },
                  ]}
                />
              </Box>
              <Box flex={1}>
                <Form.Select
                  field={"seating_capacity"}
                  label={t("form:seating_capacity")}
                  options={SeatingCapacity}
                  labelField={"label"}
                  valueField={"value"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                  rules={[
                    {
                      required: false,
                      message: "Seating Capacity is required",
                    },
                  ]}
                />
              </Box>
            </HStack>
            <HStack space={5}>
              <Box flex={1}>
                <Form.Select
                  field={"tyre_make_id"}
                  label={t("form:tyre_maker_front")}
                  options={tyremaker_items}
                  labelField={"name"}
                  valueField={"id"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                />
              </Box>
              <Box flex={1}>
                <Form.Select
                  field={"front_right_tyre_make_id"}
                  label={t("form:tyre_maker_right")}
                  options={tyremaker_items}
                  labelField={"name"}
                  valueField={"id"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                />
              </Box>
              <Box flex={1}>
                <Form.Select
                  field={"rear_left_tyre_make_id"}
                  label={t("form:tyre_maker_left")}
                  options={tyremaker_items}
                  labelField={"name"}
                  valueField={"id"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                />
              </Box>
              <Box flex={1}>
                <Form.Select
                  field={"rear_right_tyre_make_id"}
                  label={t("form:tyre_maker_back")}
                  options={tyremaker_items}
                  labelField={"name"}
                  valueField={"id"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                />
              </Box>
            </HStack>
            <HStack flex={1} space={5}>
              <Box flex={1}>
                <Form.Select
                  field={"spare_tyre_make_id"}
                  label={t("form:tyre_maker_spare_wheel")}
                  options={tyremaker_items}
                  labelField={"name"}
                  valueField={"id"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                />
              </Box>
              <Box flex={1}>
                <Form.TextBox
                  field={"tyre_number"}
                  label={t("form:tyre_number_front")}
                />
              </Box>
              <Box flex={1}>
                <Form.TextBox
                  field={"front_right_tyre_number"}
                  label={t("form:tyre_number_right")}
                />
              </Box>
              <Box flex={1}>
                <Form.TextBox
                  field={"rear_left_tyre_number"}
                  label={t("form:tyre_number_left")}
                />
              </Box>
            </HStack>
            <HStack space={5}>
              <Box flex={1}>
                <Form.TextBox
                  field={"rear_right_tyre_number"}
                  label={t("form:tyre_number_back")}
                />
              </Box>
              <Box flex={1}>
                <Form.TextBox
                  field={"spare_tyre_number"}
                  label={t("form:tyre_number_spare_wheel")}
                />
              </Box>
              <Box flex={1}>
                <Form.Select
                  field={"wheel_drive"}
                  label={t("form:wheel_drive")}
                  options={WheelDriveType}
                  labelField={"label"}
                  valueField={"value"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                />
              </Box>
              <Box flex={1}>
                <Form.Number
                  field={"vehicle_price"}
                  label={t("form:vehicle_price")}
                />
              </Box>
            </HStack>
            <HStack flex={1} space={5} justifyContent={"center"}>
              <Box flex={1}>
                <label>Vehicle Modal Year</label>
                <DatePicker
                  style={{ marginTop: "8px" }}
                  picker="year"
                  onChange={(e, year) => {
                    setVehicleModelYear(year);
                  }}
                />
              </Box>
              <Box flex={1}>
                <Form.TextBox
                  field={"go_number"}
                  label={t("form:go_number")}
                  rules={[
                    {
                      required: false,
                      message: "GO Number is required",
                    },
                  ]}
                />
              </Box>
              <Box flex={1}>
                <Form.Date
                  field={"go_date"}
                  label={t("form:go_date")}
                  rules={[
                    {
                      required: false,
                      message: "GO Date is required",
                    },
                  ]}
                />
              </Box>

              <Box flex={1}>
                <Form.Date
                  field={"date_of_delivery"}
                  label={t("form:date_of_purchase")}
                  format={"YYYY/MM/DD"}
                  rules={[
                    {
                      required: false,
                      message: "Date of delivery is required",
                    },
                  ]}
                />
              </Box>
            </HStack>
            <HStack flex={1} space={5}>
              <Box flex={1}>
                <Form.Select
                  field={"source"}
                  label={t("form:received_from")}
                  options={Src}
                  labelField={"label"}
                  valueField={"value"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={false}
                  // onChnage={onChanges}
                />
              </Box>
              {vehicleSource === "Others" ? (
                <Box flex={1}>
                  <Form.Select
                    field={"agency_id"}
                    label={t("form:agency_id")}
                    options={agency_items}
                    labelField={"name"}
                    valueField={"id"}
                    isSearchable={true}
                    isClearable={true}
                    isMulti={false}
                  />
                </Box>
              ) : (
                ""
              )}
              <Box flex={1}>
                <Form.Select
                  field={"additional_accessories"}
                  label={t("form:additional_accessories")}
                  options={accessoriesList}
                  labelField={"name"}
                  valueField={"id"}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={true}
                />
              </Box>
            </HStack>

            <HStack flex={1} space={5}>
              {showField && (
                <Box flex={1}>
                  <Form.TextBox
                    field={"other_accessory"}
                    label={t("Other Accessory")}
                  />
                </Box>
              )}

              {showField && <Box flex={1}></Box>}
            </HStack>

            <HStack space={5}>
              <Box flex={1}>
                <Form.File
                  label={t("GO Copy")}
                  field={"go_copy"}
                  rules={[
                    {
                      required: false,
                      message: "GO Copy is required",
                    },
                  ]}
                />
              </Box>

              <Box flex={1}>
                <Form.File
                  label={t("RC Book")}
                  field={"rc_book"}
                  rules={[
                    {
                      required: false,
                      message: "RC Book is required",
                    },
                  ]}
                />
              </Box>
            </HStack>
            <HStack space={2} justifyContent="flex-end">
              <Form.Button
                isLoading={id ? update_loading : create_loading}
                width="100px"
                colorScheme="success"
                height="45px"
                formErrorMessage={t("error:error_message")}
                label={id ? t("update") : t("submit")}
              >
                {id ? t("update") : t("submit")}
              </Form.Button>
              {id ? (
                <Button
                  isLoading={false}
                  width="100px"
                  colorScheme="danger"
                  onClick={() => handleDelete()}
                  formErrorMessage={t("error:error_message")}
                >
                  {t("Delete")}
                </Button>
              ) : null}

              <Button
                isLoading={false}
                width="100px"
                colorScheme="danger"
                variant="outline"
                onClick={handleBack}
                formErrorMessage={t("error:error_message")}
              >
                {t("Back")}
              </Button>
            </HStack>
          </VStack>
        </Form>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("table:delete_vechile")}
        content={t("table:delete_vechile_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "danger",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            // variant: "outline",
            // isLoading: loading,
            formErrorMessage: t("error:error_message"),
            submit: delete_Vehicles,
          },
        ]}
        z
      />
    </>
  );
};
export default VehicleDetails;
