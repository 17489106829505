import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { dashboard_fuel_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: {},
};

const slice = createSlice({
  name: "dashboard_fuel",
  initialState,
  reducers: {
    _dashboard_fuel: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    _dashboard_fuel_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _dashboard_fuel_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const { _dashboard_fuel, _dashboard_fuel_success, _dashboard_fuel_failure } =
  slice.actions;

export const dashboard_fuelSelector = (state) => state.dashboard_fuel;

export const dashboard_fuelReducer = slice.reducer;

export function dashboard_fuel(variables) {
  return async (dispatch) => {
    dispatch(_dashboard_fuel());
    try {
      const response = await QueryRequest(
        dashboard_fuel_query,
        variables,
        dispatch
      );
      if (
        response?.data?.fuelDashboardDetails &&
        !response?.data?.fuelDashboardDetails.error
      ) {
        dispatch(_dashboard_fuel_success(response?.data?.fuelDashboardDetails));
      } else if (response?.data?.fuelDashboardDetails?.error) {
        dispatch(
          _dashboard_fuel_failure(response?.data?.fuelDashboardDetails.error)
        );
      }
    } catch (error) {
      dispatch(_dashboard_fuel_failure(error));
    }
  };
}
