import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getAllEntity } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "entityList",
  initialState,
  reducers: {
    _get_entity: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_entity_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_entity_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_entity, _get_entity_success, _get_entity_failure } = slice.actions;

export const getEntityListSelector = (state) => state.entityList;

export const getEntityListReducer = slice.reducer;

export function get_all_entity(variables) {
  return async (dispatch) => {
    dispatch(_get_entity());
    try {
      const response = await QueryRequest(getAllEntity, variables, dispatch);
      if (
        response?.data?.getServiceRequests &&
        !response?.data?.getServiceRequests.error
      ) {
        dispatch(_get_entity_success(response?.data?.getServiceRequests));
      } else if (response?.data?.getServiceRequests?.error) {
        dispatch(_get_entity_failure(response?.data?.getServiceRequests.error));
      }
    } catch (error) {
      dispatch(_get_entity_failure(error));
    }
  };
}
