import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { updateCondemnationMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "condemnationUpdate",
  initialState,
  reducers: {
    _update_condemnation: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_condemnation_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _update_condemnation_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_condemnation_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _update_condemnation,
  _update_condemnation_reset,
  _update_condemnation_success,
  _update_condemnation_failure,
} = slice.actions;

export const updateCondemnationSelector = (state) => state.condemnationUpdate;

export const updateCondemnationReducer = slice.reducer;

export function update_condemnation(variables) {
  return async (dispatch) => {
    dispatch(_update_condemnation());
    try {
      const response = await MutateRequest(
        updateCondemnationMutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateContamination &&
        !response?.data?.updateContamination.error
      ) {
        dispatch(
          _update_condemnation_success(response?.data?.updateContamination)
        );
      } else if (response?.data?.updateContamination?.error) {
        dispatch(
          _update_condemnation_failure(
            response?.data?.updateContamination.error
          )
        );
      }
    } catch (error) {
      dispatch(_update_condemnation_failure(error));
    }
  };
}
export function update_one_condemnation_clear() {
  return async (dispatch) => {
    dispatch(_update_condemnation_reset());
  };
}
