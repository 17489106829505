import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getAllVehicles } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "vehicles_List",
  initialState,
  reducers: {
    _get_vehicles: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_vehicles_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_vehicles_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
    _get_vehicles_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
  },
});

const { _get_vehicles, _get_vehicles_success, _get_vehicles_failure, _get_vehicles_reset } =
  slice.actions;

export const getVehiclesListSelector = (state) => state.vehicles_List;

export const getVehiclesListReducer = slice.reducer;

export function getAll_Vehicles(variables) {
  return async (dispatch) => {
    dispatch(_get_vehicles());
    try {
      const response = await QueryRequest(getAllVehicles, variables, dispatch);
      if (response?.data?.getVehicles && !response?.data?.getVehicles.error) {
        dispatch(_get_vehicles_success(response?.data?.getVehicles));
      } else if (response?.data?.getVehicles?.error) {
        dispatch(_get_vehicles_failure(response?.data?.getVehicles.error));
      }
    } catch (error) {
      dispatch(_get_vehicles_failure(error));
    }
  };
}

export function get_vehicle_clear(dispatch) {
  return async (dispatch) => {
    dispatch(_get_vehicles_reset());
  };
}