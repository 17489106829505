import { colorScheme } from "@helpers/constants";
import ScrollView from "@views/components/ui/scroll_view/scroll_view";
import Footer from "@views/pages/footer/footer";
import Header from "@views/pages/Header/header";
import React from "react";
// import "./home_screen.css"

const HomeLayout = ({ children }) => {
  return (
    <ScrollView>
      <Header />
      <div
        className="home-style"
        style={{ backgroundColor: colorScheme.background_color }}
      >
        {children}
      </div>
      <Footer />
    </ScrollView>
  );
};
export default HomeLayout;
