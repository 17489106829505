import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import TamilNaduLogo from "@assets/images/tamilnadu_logo-removebg.png";
import PassLogo from "@assets/images/SECRETARIAT--TRANSIT CAR PASS--2023--KIND-II-Red.jpg";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import moment from "moment";

const GeneratePass = (props) => {
  const { pass_item } = props;
  const { t } = useTranslation();

  const valid_till = moment(Number(pass_item?.valid_till)).format("DD/MM/YYYY");
  return (
    <Document>
      <Page size="A4" orientation="portrait" style={styles.page}>
        <View style={styles.headerSpace}>
          <View style={styles.headAgreement}>
            <Image style={styles.image} src={TamilNaduLogo} />
            <View style={styles.pass_content}>
              <View>
                <Text style={styles.head_one}>VP NO.</Text>
              </View>
              <View>
                <Text style={styles.text_details}>
                  {pass_item?.pass_number}
                </Text>
              </View>
            </View>
          </View>
          <Image style={styles.image1} src={PassLogo} />
          <View style={styles.description_pass}>
            <View style={styles.pass_content_centered}>
              <Text style={styles.text_style}>DESIGNATION{":"}</Text>
              <Text style={styles.description}>
                {pass_item?.designation?.name
                  ? pass_item?.designation?.name
                  : "-"}
              </Text>
            </View>
            <View style={styles.pass_content_centered}>
              <Text style={styles.text_style}>DEPARTMENT{":"}</Text>
              <Text style={styles.description}>
                {pass_item?.department?.name
                  ? pass_item?.department?.name
                  : "-"}
              </Text>
            </View>
            <View style={styles.pass_content_centered}>
              <Text style={styles.text_style}>VEHICLE NO{":"}</Text>
              <Text style={styles.description}>
                {pass_item?.vehicle_number ? pass_item?.vehicle_number : "-"}
              </Text>
            </View>
            <View style={styles.pass_content_centered}>
              <Text style={styles.text_style}>VEHICLE MAKE{":"}</Text>
              <Text style={styles.description}>
                {pass_item?.vehicle_make ? pass_item?.vehicle_make : "-"}
              </Text>
            </View>
            <View style={styles.pass_content_centered}>
              <Text style={styles.text_style}>VALIDITY UPTO{":"}</Text>
              <Text style={styles.description}>
                {valid_till ? valid_till : "-"}
              </Text>
            </View>
          </View>
          <View style={styles.qr_right}>
            <QRCode value={"abc"} size="50px"></QRCode>
          </View>
          <View style={styles.content_left}>
            <View>
              <Text style={styles.font_style}>
                Dy.Secy / Under Secy to Govt
              </Text>
            </View>
            <View>
              <Text style={styles.font_style}>Public (MV) Department</Text>
            </View>
            <View>
              <Text style={styles.font_style}>Secretariat, Chennai-9</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  headAgreement: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    marginTop: "120px",
  },
  image: {
    width: "80px",
    height: "90px",
    alignItems: "center",
  },
  image1: {
    width: "100%",
    zIndex: "-1",
    alignItems: "center",
    position: "absolute",
  },
  headerSpace: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  head_one: {
    fontSize: "12px",
  },
  text_details: {
    fontSize: "12px",
    textAlign: "center",
    fontWeight: "light",
    textDecoration: "underline",
  },
  pass_content: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    width: "100%",
    marginBottom: "6px",
  },
  pass_content_centered: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center", // Center the content horizontally
    width: "100%",
    gap: "10px",
    marginBottom: "6px",
  },
  content_left: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: "100%",
    gap: "5px",
    marginLeft: "200px",
  },
  qr_right: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    marginLeft: "100px",
  },
  text_style: {
    alignItems: "center",
    fontSize: "12px",
    textAlign: "left",
    fontWeight: "bold",
    marginBottom: "5px",
  },
  font_style: {
    fontSize: "10px",
    fontWeight: "bold",
    textAlign: "center",
    fontWeight: "bold",
  },
  description: {
    // width:"50%",
    fontSize: "12px",
    textAlign: "center",
    fontWeight: "light",
    textDecoration: "underline",
  },
  description_pass: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
});

export default GeneratePass;
