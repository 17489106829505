import CryptoJS from "crypto-js";

export const encryptData = (data, salt) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();

export const decryptData = (ciphertext, salt) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, salt);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    return null;
  }
};

export function decryptResponse(data) {
  const bytes = CryptoJS.AES.decrypt(data, "4Y1oO0ElwMgib9bwVyglYxqhdpWBFpxw");
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}
export function encryptRequest(data) {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    "4Y1oO0ElwMgib9bwVyglYxqhdpWBFpxw"
  );
  return encrypted.toString();
}
