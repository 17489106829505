import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, HStack, VStack, Text, Stack } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { useTranslation } from "react-i18next";
import Form from "@views/components/ui/form";
import { showToast } from "@helpers/toast";
import { ROUTES } from "@views/routes/my_routes";
import data from "@assets/images/loader.gif";
import { AiFillCar } from "react-icons/ai";

import {
  getone_Vehicles,
  getOneVehiclesListSelector,
  getVehiclesListSelector,
  getAll_Vehicles,
} from "@services/redux";
import { Button, Card } from "antd";
import { retrieveItem } from "@helpers/storage";
const ServiceHistory = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [lang, setLang] = useState([]);

  const history = useHistory();
  const roles = JSON.parse(retrieveItem("roles"));

  const handleView = (item) => {
    history.push({
      pathname: `${ROUTES.SERVICE_REQUEST_DETAILS}/${id}`,
    });
  };

  useEffect(() => {
    if (id) dispatch(getone_Vehicles({ id: id }));
  }, []);

  const { item: getOne_items, loading } = useSelector(
    getOneVehiclesListSelector
  );

  const handleBack = () => {
    history.push(ROUTES.VEHICLE_LIST);
  };

  const dateDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };

  if (loading)
    return (
      <Box alignSelf="center" marginTop="25%">
        <img src={data} />
      </Box>
    );

  return (
    <Box flex="1" w="100%" overflowX={"hidden"}>
      <Box borderColor="grey" m="4">
        <Box mb="4">
          <Text bold fontSize="18px">
            Service History
          </Text>
        </Box>

        {getOne_items?.services?.length ? (
          <>
            {getOne_items?.services?.map((item, i) => {
              return (
                <Card padding={"10"} mt="6">
                  <VStack flex={1} space={5} mb="6">
                    <HStack flex={1} space={10}>
                      <HStack flex={1} space={2}>
                        <Box>
                          <Text fontSize="md" fontWeight="bold">
                            {t("Created Date :")}
                          </Text>
                        </Box>
                        <Box>
                          <Text fontSize="md">
                            {dateDisplay(item.created_date_time)}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack flex={1} space={2}>
                        <Box>
                          <Text fontSize="md" fontWeight="bold">
                            {t("Return Date :")}
                          </Text>
                        </Box>
                        <Box>
                          <Text fontSize="md">
                            {item?.returned_date_time
                              ? dateDisplay(item?.returned_date_time)
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack flex={1} space={2}>
                        <Box>
                          <Text fontSize="md" fontWeight="bold">
                            {t("Department :")}
                          </Text>
                        </Box>
                        <Box>
                          <Text fontSize="md">
                            {getOne_items.allotted_department?.name
                              ? getOne_items.allotted_department?.name
                              : "-"}
                          </Text>
                        </Box>
                      </HStack>
                    </HStack>
                    <VStack flex={1}>
                      <Box>
                        <Text fontSize="md" fontWeight="bold">
                          {t("Services done")}
                        </Text>
                      </Box>

                      {item?.services?.map((item1, index) => {
                        return (
                          <Box>
                            <Text> {item1.name} </Text>
                          </Box>
                        );
                      })}
                    </VStack>
                  </VStack>
                </Card>
              );
            })}
          </>
        ) : (
          <Text bold textAlign={"center"}>
            {"No services available for this vehicle."}
          </Text>
        )}

        <HStack justifyContent={"flex-end"} mt="6">
          <Box>
            <Button
              isLoading={false}
              width="100px"
              colorScheme="danger"
              variant="outline"
              onClick={handleBack}
              formErrorMessage={t("error:error_message")}
            >
              {t("Back")}
            </Button>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};
export default ServiceHistory;
