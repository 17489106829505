import { v4 as uuidv4 } from "uuid";
import { gql } from "@apollo/client";
import { createClient } from "@supabase/supabase-js";
import { QueryRequest } from "@services/apollo/api_service";

const SUPABASE_URL = "https://bnxrptuidsjuvnrroxgz.supabase.co";
const SUPABASE_ANON_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJueHJwdHVpZHNqdXZucnJveGd6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDgyODkzNTYsImV4cCI6MTk2Mzg2NTM1Nn0.s_P8d5PoEt65-6kBOjlos5upqONvEM39cisoRioDb0M";

export default async function fileUpload(file, storageName) {}
async function getToken() {
  let { data } = await QueryRequest(fileQuery);
  return data;
}

const fileQuery = gql`
  query getUploadToken($type: String, $fileName: String) {
    getUploadToken(type: $type, fileName: $fileName) {
      status
      accessToken
      error {
        statusCode
        message
      }
    }
  }
`;
