import { createCarModelReducer } from "./carmodel_create";
import { deleteCarModelReducer } from "./carmodel_delete";
import { getOneCarModelReducer } from "./carmodel_getone";
import { updateCarModelReducer } from "./carmodel_update";
import { getModelListReducer } from "./car_model_list";
export const getModelListReducers = {
  modelList: getModelListReducer,
  carmodel_create: createCarModelReducer,
  carmodelDelete: deleteCarModelReducer,
  carmodelGetOne: getOneCarModelReducer,
  carmodelUpdate: updateCarModelReducer,
};
export * from "./car_model_list";
export * from "./carmodel_create";
export * from "./carmodel_delete";
export * from "./carmodel_getone";
export * from "./carmodel_update";
