import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getVehicleReportQuery } from "./graphql";
const initialState = {
  loading: false,
  error: null,
  url: null,
};
const slice = createSlice({
  name: "getVehicleReport",
  initialState,
  reducers: {
    getVehicleReportInitialize: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },
    getVehicleReportReset: (state) => {
      state.loading = false;
      state.url = null;
      state.error = null;
    },
    getVehicleReportSuccess: (state, { payload }) => {
      state.loading = false;
      state.url = payload.url;
      state.error = null;
    },
    getVehicleReportFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.item = null;
    },
  },
});

const {
  getVehicleReportInitialize,
  getVehicleReportSuccess,
  getVehicleReportFailure,
  getVehicleReportReset,
} = slice.actions;
export const getVehicleReportSelector = (state) => state.getVehicleReport;

export const getVehicleReportReducer = slice.reducer;

export function getVehicleReport(variables) {
  return async (dispatch) => {
    dispatch(getVehicleReportInitialize());
    try {
      const response = await QueryRequest(
        getVehicleReportQuery,
        variables,
        dispatch
      );
      if (
        response?.data?.getVehicleReport &&
        !response?.data?.getVehicleReport.error
      ) {
        dispatch(getVehicleReportSuccess(response?.data?.getVehicleReport));
      } else if (response?.data?.getVehicleReport?.error) {
        dispatch(
          getVehicleReportFailure(response?.data?.getVehicleReport.error)
        );
      }
    } catch (error) {
      dispatch(getVehicleReportFailure(error));
    }
  };
}

export function getVehicleReportClear() {
  return (dispatch) => {
    dispatch(getVehicleReportReset());
  };
}
