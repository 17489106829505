import React from "react";
import { Modal as NativebaseModal } from "native-base";
import { useWindowSize } from "@helpers/dimension";

const Modal = (props) => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const Child = () => {
    const element = React.cloneElement(props.component, {
      close: props.onClose,
    });
    return <>{element}</>;
  };

  const styles = {
    top: {
      marginBottom: "auto",
      marginTop: 20,
    },
    bottom: {
      marginBottom: 20,
      marginTop: "auto",
    },
    left: {
      marginLeft: 0,
      marginRight: "auto",
    },
    right: {
      marginLeft: "auto",
      marginRight: 0,
    },
    center: {},
  };

  return (
    <>
      {props.isOpen && (
        <NativebaseModal
          isOpen={props.isOpen}
          onClose={props.onClose}
          size={props.size || props.width || "xl"}
        >
          <NativebaseModal.Content
            maxH={windowHeight}
            {...styles[props.placement || "top"]}
          >
            <NativebaseModal.CloseButton />
            {props.header && (
              <NativebaseModal.Header>{props.header}</NativebaseModal.Header>
            )}
            <NativebaseModal.Body>
              <Child w="100%" />
            </NativebaseModal.Body>
          </NativebaseModal.Content>
        </NativebaseModal>
      )}
    </>
  );
};

export default Modal;
