import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getPassEntityStateTransitions } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "getPassEntityState",
  initialState,
  reducers: {
    _get_PassEntityState: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },

    _get_PassEntityState_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_PassEntityState_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const {
  _get_PassEntityState,
  _get_PassEntityState_success,
  _get_PassEntityState_failure,
} = slice.actions;

export const getPassEntityStateSelector = (state) => state.getPassEntityState;
export const getPassEntityStateReducer = slice.reducer;

export function get_pass_entity_state(variables) {
  return async (dispatch) => {
    dispatch(_get_PassEntityState());
    try {
      const response = await QueryRequest(
        getPassEntityStateTransitions,
        variables,
        dispatch
      );
      if (
        response?.data?.getPassEntityStateTransitions &&
        !response?.data?.getPassEntityStateTransitions.error
      ) {
        dispatch(
          _get_PassEntityState_success(
            response?.data?.getPassEntityStateTransitions
          )
        );
      } else if (response?.data?.getPassEntityStateTransitions?.error) {
        dispatch(
          _get_PassEntityState_failure(
            response?.data?.getPassEntityStateTransitions.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_PassEntityState_failure(error));
    }
  };
}

