import { gql } from "@apollo/client";

export const getAllServicesHistory = gql`
  query getServiceRequests(
    $department_id: String
    $search_string: String
    $status_id: String
    $from_requested_date_time: String
    $to_requested_date_time: String
    $from_returned_date_time: String
    $to_returned_date_time: String
    $page_limit: Float
    $page_number: Float
  ) {
    getServiceRequests(
      department_id: $department_id
      search_string: $search_string

      status_id: $status_id
      from_requested_date_time: $from_requested_date_time
      to_requested_date_time: $to_requested_date_time
      from_returned_date_time: $from_returned_date_time
      to_returned_date_time: $to_returned_date_time
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        vehicle_id
        department_id
        created_date_time
        returned_date_time
        sent_to_workshop_date_time
        next_km_due
        next_due_date
        entity_state {
          id
          name
          from_state_transitions {
            id
            transition_name
          }
        }
        department {
          id
          name
        }
        vehicle {
          id
          vehicle_number
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const getOneServiceHistory = gql`
  query getServiceRequest($id: String) {
    getServiceRequest(id: $id) {
      id
      other_service
      remarks
      order_url
      next_km_due
      next_due_date
      created_date_time
      returned_date_time
      ta_remarks
      department_remarks
      service_logs {
        services {
          name
        }
        created_by {
          name
          roles {
            name
          }
        }
      }
      vehicle_id
      vehicle {
        allotments {
          department {
            name
          }
        }
      }
      vehicle {
        vehicle_number
      }
      entity_state {
        id
        name
        from_state_transitions {
          id
          transition_name
          from_entity_state_id
          from_entity_state {
            name
          }
          to_entity_state_id
          to_entity_state {
            id
            name
          }
        }
        to_state_transitions {
          id
          transition_name
          from_entity_state_id
          from_entity_state {
            id
            name
          }
          to_entity_state_id
          to_entity_state {
            id
            name
          }
        }
      }
      department {
        id
        name
      }
      services {
        id
        name
      }
    }
  }
`;

export const getAllServiceQuestionQuery = gql`
  query getServices($page_number: Float, $page_limit: Float) {
    getServices(page_number: $page_number, page_limit: $page_limit) {
      items {
        id
        name
        description
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const createServiceRequestMutation = gql`
  mutation createServiceRequest($data: service_request_input) {
    createServiceRequest(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updateServiceRequestMutation = gql`
  mutation updateServiceRequest($id: String!, $data: service_request_input) {
    updateServiceRequest(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const getServiceRequestQuery = gql`
  query getServiceRequest($id: String) {
    getServiceRequest(id: $id) {
      id
      next_km_due
      next_due_date
      services {
        id
        name
        description
        service_requests {
          id
        }
      }
    }
  }
`;

export const getOneServiceRequestHistoryQuery = gql`
  query getServiceRequest {
    getServiceRequest {
      id
      vehicle_id
      department_id
      vehicle {
        allotments {
          department {
            name
          }
        }
      }
      other_service
      remarks
      vehicle {
        id
        vehicle_number
        car_model {
          id
          name
          car_make {
            id
            name
          }
        }
      }
    }
  }
`;

export const getDeleteServiceRequestMutation = gql`
  mutation deleteServiceRequest($id: String!) {
    deleteServiceRequest(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const service_request_process_mutation = gql`
  mutation processServiceRequest($id: String!) {
    processServiceRequest(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const service_request_return_mutation = gql`
  mutation serviceRequestReturned(
    $id: String!
    $next_due_date: String
    $next_km_due: Float
  ) {
    serviceRequestReturned(
      id: $id
      next_due_date: $next_due_date
      next_km_due: $next_km_due
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const service_request_order_mutation = gql`
  mutation uploadOrderForServiceRequest(
    $id: String!
    $data: order_upload_request_input
  ) {
    uploadOrderForServiceRequest(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const service_request_sent_to_workshop_mutation = gql`
  mutation sentToWorkshopServiceRequest($id: String!) {
    sentToWorkshopServiceRequest(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_service_request_without_pagination = gql`
  query getServiceRequests($department_id: String, $status_id: String) {
    getServiceRequests(department_id: $department_id, status_id: $status_id) {
      items {
        id
        vehicle_id
        department_id
        created_date_time
        returned_date_time
        sent_to_workshop_date_time
        services {
          id
          name
        }
        entity_state {
          id
          name
          from_state_transitions {
            id
            transition_name
          }
        }
        department {
          id
          name
        }
        vehicle {
          id
          vehicle_number
        }
      }
    }
  }
`;
