import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { deleteCarModelMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "carmodelDelete",
  initialState,
  reducers: {
    _delete_carmodel: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_carmodel_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_carmodel_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const { _delete_carmodel, _delete_carmodel_success, _delete_carmodel_failure } =
  slice.actions;

export const deleteCarModelSelector = (state) => state.carmodelDelete;

export const deleteCarModelReducer = slice.reducer;

export function delete_carmodel(variables) {
  return async (dispatch) => {
    dispatch(_delete_carmodel());
    try {
      const response = await MutateRequest(
        deleteCarModelMutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteCarModel &&
        !response?.data?.deleteCarModel.error
      ) {
        dispatch(_delete_carmodel_success(response?.data?.deleteCarModel));
      } else if (response?.data?.deleteCarModel?.error) {
        dispatch(
          _delete_carmodel_failure(response?.data?.deleteCarModel.error)
        );
      }
    } catch (error) {
      dispatch(_delete_carmodel_failure(error));
    }
  };
}
export function delete_one_carmodel_modal(dispatch) {
  return async (dispatch) => {
    dispatch(_delete_carmodel());
  };
}
