import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";

import { retrieveItem, useStorageItem } from "@helpers/storage";
import { notification } from "antd";
import { showToast } from "@helpers/toast";

//import { logoutRequest } from "../../redux";

const BASE_URL = "https://pvm-sit.vertace.org/api";
// const BASE_URL = "https://publicmvms.tn.gov.in/api";
// const BASE_URL="http://192.168.1.47:5018/dev/graphql";

// const BASE_URL = "http://localhost:5018/dev/graphql";
// process.env.REACT_APP_BASE_URL;

const httpLink = createHttpLink({
  uri: BASE_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const token = retrieveItem("token");
  console.log("token",token);
  
  return {
    headers: {
      ...headers,
      token: token ? token : "",
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const openNotification = (placement) => {
  notification.info({
    message: `Notification ${placement}`,
    description:
      "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
    placement,
  });
};

const handleLogout = () => {
  localStorage.clear();

  window.location.replace("/");
};
export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});

export async function QueryRequest(query, variables, dispatch) {
  let response = await client.query({
    query,
    variables,
  });
  let isUnauthorized = JSON.stringify(response).indexOf("Not authorized") > -1;
  if (isUnauthorized && dispatch) {
    showToast({ type: "error", message: "Not Authorized" });
    // handleLogout();
    //  dispatch(logoutRequest("invalid"));
    return null;
  }
  return response;
}
export async function MutateRequest(mutation, variables, dispatch) {
  let response = null;
  try {
    response = await client.mutate({
      mutation,
      variables,
    });
    let isUnauthorized =
      JSON.stringify(response).indexOf("Not authorized") > -1;
    if (isUnauthorized && dispatch) {
      showToast({ type: "error", message: "Not Authorized" });
      // handleLogout();
      //  dispatch(logoutRequest("invalid"));
      return null;
    }
    return response;
  } catch (e) {
    if (dispatch) {
      //  dispatch(logoutRequest("invalid"));
    }
    throw new Error(e);
  }
}
