import { gql } from "@apollo/client";

export const getCarModels = gql`
  query getCarModels($car_make_id: String) {
    getCarModels(car_make_id: $car_make_id) {
      items {
        id
        name
        car_make_id
        car_make {
          id
          name
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const createCarModelMutation = gql`
  mutation createCarModel($data: car_model_input) {
    createCarModel(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updateCarModelMutation = gql`
  mutation updateCarModel($data: car_model_input, $id: String!) {
    updateCarModel(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const deleteCarModelMutation = gql`
  mutation deleteCarModel($id: String!) {
    deleteCarModel(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const getOneCarModelQuery = gql`
  query getCarModel($id: String) {
    getCarModel(id: $id) {
      id
      name
      car_make_id
      car_make {
        id
        name
      }
    }
  }
`;
