import { createDriverReducer } from "./driver_create";
import { deleteDriverReducer } from "./driver_delete";
import { getDriverHistoryReducer } from "./driver_history";
import { getDriverListReducer } from "./driver_list";
import { getdriverListWithOutPaginationReducer } from "./driver_list_without_pagination";
import { getOneDriverReducer } from "./driver_one";
import { updateDriverReducer } from "./driver_update";
export const getDriverListReducers = {
  driverList: getDriverListReducer,
  driverCreate: createDriverReducer,
  driverGetOne: getOneDriverReducer,
  driverUpdate: updateDriverReducer,
  driverDelete: deleteDriverReducer,
  driverHistoryList: getDriverHistoryReducer,
  driverListWithoutPagination: getdriverListWithOutPaginationReducer,
};
export * from "./driver_list";
export * from "./driver_create";
export * from "./driver_delete";
export * from "./driver_one";
export * from "./driver_update";
export * from "./driver_history";
export * from "./driver_list_without_pagination";
