import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getdesignationMakeQuery } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: null,
};

const slice = createSlice({
  name: "designationGetOne",
  initialState,
  reducers: {
    _get_one_designation: (state) => {
      state.loading = true;
      state.item = null;
      state.error = null;
    },

    _get_one_designation_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _get_one_designation_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = null;
    },
  },
});

const {
  _get_one_designation,
  _get_one_designation_success,
  _get_one_designation_failure,
} = slice.actions;

export const getOneDesignationSelector = (state) => state.designationGetOne;

export const getOneDesignationReducer = slice.reducer;

export function get_one_designation(variables) {
  return async (dispatch) => {
    dispatch(_get_one_designation());
    try {
      const response = await QueryRequest(
        getdesignationMakeQuery,
        variables,
        dispatch
      );
      if (
        response?.data?.getDesignation &&
        !response?.data?.getDesignation.error
      ) {
        dispatch(_get_one_designation_success(response?.data?.getDesignation));
      } else if (response?.data?.getDesignation?.error) {
        dispatch(
          _get_one_designation_failure(response?.data?.getDesignation.error)
        );
      }
    } catch (error) {
      dispatch(_get_one_designation_failure(error));
    }
  };
}
export async function get_one_designation_clear(dispatch) {
  dispatch(_get_one_designation());
}
// export default tyre_one
