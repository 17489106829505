import { gql } from "@apollo/client";

export const getGatePassReportQuery = gql`
  query getGatePassReport(
    $status: String
    $pass_type_id: String
    $from_date: String
    $search_string: String
    $to_date: String
  ) {
    getGatePassReport(
      status: $status
      pass_type_id: $pass_type_id
      to_date: $to_date
      search_string: $search_string
      from_date: $from_date
    ) {
      url
      error {
        message
      }
    }
  }
`;

export const getVehicleReportQuery = gql`
  query getVehicleReport(
    $department_id: String
    $car_model_id: String
    $car_make_id: String
    $is_allocated: Boolean
    $fuel_used: String
    $search_string: String
    $image_upload: Boolean
  ) {
    getVehicleReport(
      department_id: $department_id
      car_make_id: $car_make_id
      car_model_id: $car_model_id
      fuel_used: $fuel_used
      search_string: $search_string
      is_allocated: $is_allocated
      image_upload: $image_upload
    ) {
      url
      error {
        message
      }
    }
  }
`;

export const getServiceRequestReportQuery = gql`
  query getServiceRequestReport(
    $department_id: String
    $status_id: String
    $from_requested_date_time: String
    $search_string: String
    $to_requested_date_time: String
    $from_returned_date_time: String
    $to_returned_date_time: String
  ) {
    getServiceRequestReport(
      department_id: $department_id
      status_id: $status_id
      search_string: $search_string
      from_requested_date_time: $from_requested_date_time
      to_requested_date_time: $to_requested_date_time
      from_returned_date_time: $from_returned_date_time
      to_returned_date_time: $to_returned_date_time
    ) {
      url
      error {
        message
      }
    }
  }
`;

export const getFuelReportQuery = gql`
  query getFuelReport(
    $vehicle_id: String
    $fuel_refilled_start_date: String
    $fuel_refilled_end_date: String
    $search_string: String
  ) {
    getFuelReport(
      vehicle_id: $vehicle_id
      fuel_refilled_start_date: $fuel_refilled_start_date
      search_string: $search_string
      fuel_refilled_end_date: $fuel_refilled_end_date
    ) {
      url
      error {
        message
      }
    }
  }
`;

export const getCondemnationReportQuery = gql`
  query getCondemnationReport($search_string: String) {
    getCondemnationReport(search_string: $search_string) {
      url
      error {
        message
      }
    }
  }
`;

export const getDriverReportQuery = gql`
  query getDriverReport {
    getDriverReport {
      url
      error {
        message
      }
    }
  }
`;
