import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { deleteVehicle } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "vehicles_delete",
  initialState,
  reducers: {
    vehicle_delete: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
      state.status = null;
    },
    vehicle_delete_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.status = null;
    },
    vehicle_delete_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.status = payload.status;
    },
    vehicle_delete_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  vehicle_delete,
  vehicle_delete_success,
  vehicle_delete_failure,
  vehicle_delete_reset,
} = slice.actions;

export const getDeleteVehiclesListSelector = (state) => state.vehicles_delete;
export const getDeleteVehiclesListReducer = slice.reducer;

export function delete_Vehicles(variables) {
  return async (dispatch) => {
    dispatch(vehicle_delete());
    try {
      const response = await QueryRequest(deleteVehicle, variables, dispatch);
      if (
        response?.data?.deleteVehicle &&
        !response?.data?.deleteVehicle.error
      ) {
        dispatch(vehicle_delete_success(response?.data?.deleteVehicle));
      } else if (response?.data?.deleteVehicle?.error) {
        dispatch(vehicle_delete_failure(response?.data?.deleteVehicle.error));
      }
    } catch (error) {
      dispatch(vehicle_delete_failure(error));
    }
  };
}

export async function delete_vechile_reset(dispatch) {
  dispatch(vehicle_delete_reset());
}
