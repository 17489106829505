import { gql } from "@apollo/client";

export const getAllVehicles = gql`
  query getVehicles(
    $page_number: Float
    $page_limit: Float
    $department_id: String
    $car_model_id: String
    $car_make_id: String
    $search_string: String
    $is_allocated: Boolean
    $fuel_used: String
    $image_upload: Boolean
  ) {
    getVehicles(
      page_number: $page_number
      page_limit: $page_limit
      department_id: $department_id
      car_make_id: $car_make_id
      car_model_id: $car_model_id
      search_string: $search_string
      is_allocated: $is_allocated
      fuel_used: $fuel_used
      image_upload: $image_upload
    ) {
      items {
        id
        vehicle_number
        class_vehicle
        car_model_id
        engine_number
        chasis_number
        colour
        fuel_used
        battery_type
        battery_number
        fuel_tank_capacity
        seating_capacity
        tyre_make_id
        tyre_number
        wheel_drive
        agency_id
        allotted_department {
          id
          name
        }
        department {
          id
          name
        }
        date_of_delivery
        vehicle_price
        go_number
        car_model {
          id
          name
          car_make {
            id
            name
          }
        }

        agency {
          id
          name
          address
        }

        additional_accessories {
          id
          vehicle_id
          additional_accessory_id
          additional_accessory {
            id
            name
          }
        }
        services {
          id
          created_date_time
          returned_date_time
          vehicle_id
          services {
            id
            name
          }
          department_id
          department {
            id
            name
            code
          }
        }
        current_service {
          id
          vehicle_id
          created_date_time
          returned_date_time
          services {
            id
            name
          }
        }
        allotments {
          id
          designation {
            id
            name
          }
          driver_name
          driver_mobile
          department {
            id
            name
          }
          allotment_date_time
          department_id
          vehicle_id
          allotment_number
          meter_reading
          additional_accessory_allotments {
            allotment_id
            additional_accessory_id
            added_date_time
            remarks
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const service_request_vehicles_query = gql`
  query getVehicles {
    getVehicles {
      items {
        id
        vehicle_number
        car_model {
          id
          name
          car_make {
            id
            name
          }
        }
        current_service {
          id
          vehicle_id
          created_date_time
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const createVehicleMutation = gql`
  mutation createVehicle($data: vehicle_input) {
    createVehicle(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updateVehicle = gql`
  mutation updateVehicle($data: vehicle_input, $id: String!) {
    updateVehicle(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const getOneVehicle = gql`
  query getVehicle($id: String!) {
    getVehicle(id: $id) {
      id
      vehicle_number
      class_vehicle
      car_model_id
      engine_number
      chasis_number
      colour
      source
      allotted_department {
        id
        name
      }
      go_date
      fuel_used
      battery_type
      battery_number
      fuel_tank_capacity
      seating_capacity
      tyre_make_id
      tyre_number
      wheel_drive
      agency_id
      date_of_delivery
      front_image_url
      left_image_url
      right_image_url
      rear_image_url
      front_right_tyre_make_id
      front_right_tyre_number
      rear_left_tyre_make_id
      rear_left_tyre_number
      rear_right_tyre_make_id
      rear_right_tyre_number
      spare_tyre_make_id
      spare_tyre_number
      go_copy
      rc_book
      other_accessory
      dashboard_image_url
      vehicle_price
      go_number
      car_model {
        id
        name
        car_make {
          id
          name
        }
      }

      agency {
        id
        name
        address
      }
      services {
        id
        services {
          id
          name
        }
        created_date_time
        returned_date_time
        vehicle_id
        department_id
        department {
          id
          name
          code
        }
      }
      current_service {
        id
        vehicle_id
        created_date_time
        returned_date_time
        department {
          id
          name
        }
        services {
          id
          name
        }
      }
      additional_accessories {
        additional_accessory_id
        additional_accessory {
          id
          name
        }
      }

      allotments {
        id
        driver {
          id
          name
          mobile
        }
        department {
          id
          name
        }
        designation {
          id
          name
        }
        allotment_date_time
        department_id
        vehicle_id
        allotment_number
        meter_reading
        alloted_to
        additional_accessory_allotments {
          allotment_id
          additional_accessory_id
          additional_accessory {
            id
            name
          }
          added_date_time
          remarks
        }
      }
    }
  }
`;

export const deleteVehicle = gql`
  mutation deleteVehicle($id: String!) {
    deleteVehicle(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const getAllVehiclesWithOutpagination = gql`
  query getVehicles(
    $department_id: String
    $car_model_id: String
    $car_make_id: String
    $search_string: String
    $is_allocated: Boolean
    $fuel_used: String
  ) {
    getVehicles(
      department_id: $department_id
      car_make_id: $car_make_id
      car_model_id: $car_model_id
      search_string: $search_string
      fuel_used: $fuel_used
      is_allocated: $is_allocated
    ) {
      items {
        id
        vehicle_number
        class_vehicle
        car_model_id
        engine_number
        chasis_number
        colour
        fuel_used
        battery_type
        battery_number
        fuel_tank_capacity
        seating_capacity
        tyre_make_id
        tyre_number
        wheel_drive
        agency_id
        date_of_delivery
        vehicle_price
        go_number
        car_model {
          id
          name
          car_make {
            id
            name
          }
        }

        agency {
          id
          name
          address
        }

        additional_accessories {
          id
          vehicle_id
          additional_accessory_id
          additional_accessory {
            id
            name
          }
        }
        services {
          id
          created_date_time
          returned_date_time
          vehicle_id
          services {
            id
            name
          }
          department_id
          department {
            id
            name
            code
          }
        }
        current_service {
          id
          vehicle_id
          created_date_time
          returned_date_time
          services {
            id
            name
          }
        }
        allotments {
          id
          designation {
            id
            name
          }

          department {
            id
            name
          }
          allotment_date_time
          department_id
          vehicle_id
          allotment_number
          meter_reading
          additional_accessory_allotments {
            allotment_id
            additional_accessory_id
            added_date_time
            remarks
          }
        }
      }
    }
  }
`;

export const checkVehicleQuery = gql`
  query checkVehicle($vehicle_number: String!) {
    checkVehicle(vehicle_number: $vehicle_number) {
      id
      vehicle_number
      error {
        status_code
        message
      }
    }
  }
`;
