import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getAllAccessories } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "accessories_List",
  initialState,
  reducers: {
    _get_accessories: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_accessories_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_accessories_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_accessories, _get_accessories_success, _get_accessories_failure } = slice.actions;

export const getAccessoriesListSelector = (state) => state.accessories_List;

export const getAccessoriesListReducer = slice.reducer;

export function getAll_Accessories(variables) {
  return async (dispatch) => {
    dispatch(_get_accessories());
    try {
      const response = await QueryRequest(
        getAllAccessories,
        variables,
        dispatch
      );
      if (response?.data?.getAdditionalAccessoryMasters && !response?.data?.getAdditionalAccessoryMasters.error) {
        dispatch(_get_accessories_success(response?.data?.getAdditionalAccessoryMasters));
      } else if (response?.data?.getAdditionalAccessoryMasters?.error) {
        dispatch(_get_accessories_failure(response?.data?.getAdditionalAccessoryMasters.error));
      }
    } catch (error) {
      dispatch(_get_accessories_failure(error));
    }
  };
}
