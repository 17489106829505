import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  HStack,
  Input as NativeBaseInput,
  Text,
  TextArea,
  Tooltip,
  IconButton,
  Center,
  useTheme,
} from "native-base";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";
import lodash from "lodash";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ReactSelect from "react-select";
import FloatingLabel from "../floating_label";

let defaultStyles = {
  height: "40px",
  labelColor: "#6e6e6e",
  labelBGColor: "#fff",
  iconColor: "#ff0000",
  iconSize: 20,
  boxBorderRadius: 6,
  fontSize: "14px",
};

const Input = (props) => {
  const { control } = useFormContext();
  let focusTimeout = null;
  let labelRef = useRef("");
  let inputRef = useRef("");
  const { colors } = useTheme();
  const [options, setOptions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [showPass, setShowPass] = useState(null);

  useEffect(() => {
    let _options = [];
    if (
      props.type === "select" &&
      props.options &&
      props.labelField &&
      props.valueField
    ) {
      for (let i = 0; i < props.options.length; i++) {
        let option = props.options[i];
        var newObj = {};
        newObj["value"] = lodash.get(option, props.valueField || "id", null);
        newObj["label"] = lodash.get(option, props.labelField || "name", null);
        _options.push(newObj);
      }
    }
    setOptions(_options);
  }, [props.options]);

  useEffect(() => {
    if (props.type === "intl-phone") {
      try {
        if (isFocused) {
          labelRef.current.handleFocus();
        } else {
          labelRef.current.handleBlur();
        }
      } catch (e) {}
    }
    if (isFocused) {
      try {
        inputRef.current.focus();
        inputRef.current.selectionStart = inputRef.current.value.length;
        inputRef.current.selectionEnd = inputRef.current.value.length;
      } catch (e) {}
    }
  }, [isFocused]);

  useEffect(() => {
    if (props.type === "password") {
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.selectionEnd = inputRef.current.value.length;
    }
  }, [showPass]);

  const ButtonIcon = () => {
    if (props.button) {
      const element = React.cloneElement(props.button.icon, {
        color: props.button.iconColor || defaultStyles.iconColor,
        size: props.button.iconSize || defaultStyles.iconSize,
      });
      return <>{element}</>;
    }
    return <></>;
  };

  let height = props.height || props.h || defaultStyles.height;
  let labelColor = props.labelColor || defaultStyles.labelColor;
  let labelBGColor = props.labelBGColor || defaultStyles.labelBGColor;
  let boxBorderRadius = props.boxBorderRadius || defaultStyles.boxBorderRadius;
  let fontSize = props.fontSize || defaultStyles.fontSize;

  let rawHeight = parseInt(height.replace("px", ""));
  let floatingBlurTop = `${rawHeight / 2 - 7}px`;
  let floatingFocusTop = `${-9}px`;
  let floatingLeft = props.hideLeftbar ? `${7}px` : `${22}px`;

  let passwordEyeTop = `${rawHeight / 2 - 18}px`;

  if (props.type === "intl-phone") {
    document.documentElement.style.setProperty(
      "--phone-input-height",
      `${rawHeight - 9}px`
    );
    document.documentElement.style.setProperty(
      "--phone-input-font-size",
      fontSize
    );
  } else if (props.type === "select") {
    document.documentElement.style.setProperty(
      "--react-select-height",
      `${rawHeight - 2}px`
    );
  }
  let boxColor = "inputBorder.300";

  let inputBaseDisplayProps = {
    isRequired: false,
    _hover: { bg: labelBGColor },
    fontSize: fontSize,
    fontWeight: "medium",
    height: height,
    borderColor: "transparent",
    _focus: {
      borderColor: "transparent",
    },
    autoComplete: "chrome-off",
    borderRightRadius: "6",
    borderLeftRadius: !props.hideLeftbar ? "0" : "6",
  };

  let inputBaseEventProps = {
    onFocus: () => {
      clearTimeout(focusTimeout);
      setIsFocused(true);
      try {
        labelRef.current.handleFocus();
      } catch (e) {}
    },
    onBlur: () => {
      focusTimeout = setTimeout(() => {
        setIsFocused(false);
        try {
          labelRef.current.handleBlur();
        } catch (e) {}
      }, 60);
    },
  };
  const validationSchema = yup.object({
    firstName: yup.string().required("Required"),
    //lastName: yup.string().email().required("Required"),
    district_id: yup.string().required("Required"),
    area: yup.object().when("district_id", {
      is: (district_id) => district_id,
      then: yup.object().required(),
    }),
  });

  return (
    <Box minHeight={height} {...props} flex={1}>
      <Controller
        control={control}
        name={`${props.field}`}
        render={({
          field: { onChange, value, name, ref },
          fieldState: { error: _error },
          fieldState,
        }) => {
          const isRequired =
            validationSchema.fields[props.field]?.exclusiveTests?.required ||
            false;
          let isValid = _error?.message === undefined;
          let error = _error?.message;
          let hasValue = value !== null && value !== undefined && value !== "";

          let selected = null;
          if (props.type === "select") {
            if (props.isMulti && value) {
              selected = options.filter((x) => value.indexOf(x.value) > -1);
            } else if (!props.isMulti) {
              selected = options.find((x) => x.value === value);
            }
          }

          if (!isValid && error) {
            boxColor = "error.400";
          } else if (isFocused) {
            boxColor = "primary.400";
          }
          return (
            <>
              <FloatingLabel
                {...props}
                hasValue={hasValue}
                isFocused={isFocused}
                height={height}
                blurTop={floatingBlurTop}
                focusTop={floatingFocusTop}
                left={floatingLeft}
                fontSize={fontSize}
                ref={labelRef}
                labelColor={labelColor}
                labelBGColor={labelBGColor}
              />
              <HStack space={0} justifyContent="flex-end">
                {!props.hideLeftbar && (
                  <Box
                    width="15px"
                    minHeight={height}
                    bg={boxColor}
                    borderLeftRadius={boxBorderRadius}
                    borderWidth="1px"
                    borderColor={boxColor}
                    style={{
                      transition: "background .5s ease",
                      WebkitTransition: "background .5s ease",
                      MozTransition: "background .5s ease",
                    }}
                  ></Box>
                )}
                <Box
                  flex={1}
                  minHeight={height}
                  bg="#ffffff"
                  borderRightRadius="6"
                  borderLeftRadius={props.hideLeftbar ? "6" : "0"}
                  borderWidth="1px"
                  borderLeftWidth={!props.hideLeftbar ? "0px" : "1px"}
                  borderColor={isFocused ? "primary.400" : boxColor}
                  style={{
                    transition: "border .5s ease",
                    WebkitTransition: "border .5s ease",
                    MozTransition: "border .5s ease",
                  }}
                >
                  <Tooltip
                    label={error}
                    isDisabled={!isValid && error ? false : true}
                    display={!isValid && error ? "block" : "none"}
                    //hasArrow={!isValid && error ? true : false}
                    bg="error.400"
                    _text={{
                      color: "#fff",
                    }}
                  >
                    <Box>
                      {props.type === "text" && (
                        <NativeBaseInput
                          {...props}
                          {...inputBaseDisplayProps}
                          {...inputBaseEventProps}
                          ref={inputRef}
                          value={`${value ? value : ""}`}
                          onChangeText={(txt) => {
                            onChange(txt);
                            onChange(txt);
                          }}
                        />
                      )}
                      {props.type === "number" && (
                        <NativeBaseInput
                          {...props}
                          {...inputBaseDisplayProps}
                          {...inputBaseEventProps}
                          ref={inputRef}
                          value={`${value ? value : ""}`}
                          type={"tel"}
                          onKeyPress={(e) => {
                            if (
                              !/[0-9]/.test(e.key) &&
                              e.key.toString() !== "Backspace" &&
                              e.key.toString() !== "ArrowLeft" &&
                              e.key.toString() !== "ArrowRight" &&
                              e.key.toString() !== "Delete"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            onChange(
                              e.target.value !== ""
                                ? parseInt(e.target.value)
                                : null
                            );
                            onChange(
                              e.target.value !== ""
                                ? parseInt(e.target.value)
                                : null
                            );
                          }}
                        />
                      )}
                      {props.type === "textarea" && (
                        <TextArea
                          {...props}
                          {...inputBaseDisplayProps}
                          {...inputBaseEventProps}
                          ref={inputRef}
                          value={`${value ? value : ""}`}
                          onChangeText={(txt) => {
                            onChange(txt);
                            onChange(txt);
                          }}
                        />
                      )}
                      {props.type === "password" && (
                        <NativeBaseInput
                          {...props}
                          {...inputBaseDisplayProps}
                          {...inputBaseEventProps}
                          value={`${value ? value : ""}`}
                          ref={inputRef}
                          type={showPass ? "text" : "password"}
                          onChangeText={(txt) => {
                            onChange(txt);
                            onChange(txt);
                          }}
                        />
                      )}
                      {props.type === "intl-phone" && (
                        <PhoneInput
                          {...inputBaseEventProps}
                          style={{ marginTop: "2px", marginLeft: "8px" }}
                          value={`${value ? value : ""}`}
                          ref={inputRef}
                          onChange={(txt) => {
                            onChange(txt);
                            onChange(txt);
                          }}
                        />
                      )}

                      {props.type === "phone" && (
                        <NativeBaseInput
                          {...props}
                          {...inputBaseDisplayProps}
                          {...inputBaseEventProps}
                          ref={inputRef}
                          value={`${value ? value : ""}`}
                          type={"tel"}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            onChange(e.target.value);
                            onChange(e.target.value);
                          }}
                        />
                      )}
                      {props.type === "select" && (
                        <ReactSelect
                          {...props}
                          {...inputBaseEventProps}
                          className="basic-single"
                          classNamePrefix="select"
                          placeholder=""
                          ref={inputRef}
                          menuPortalTarget={document.body}
                          //  menuIsOpen={isFocused}
                          value={selected}
                          options={options}
                          onChange={(entity, Object) => {
                            if (props.isMulti) {
                              let selectedValues = entity
                                ? entity.map((x) => x.value)
                                : [];
                              onChange(selectedValues);
                              onChange(selectedValues);
                            } else {
                              let selectedValue = entity ? entity.value : null;
                              onChange(selectedValue);
                              onChange(selectedValue);
                              inputRef.current.blur();
                            }
                          }}
                          onFocus={() => {
                            try {
                              setIsFocused(true);
                              labelRef.current.handleFocus();
                            } catch (e) {}
                          }}
                          onBlur={() => {
                            try {
                              setIsFocused(false);
                              labelRef.current.handleBlur();
                            } catch (e) {}
                          }}
                        />
                      )}
                    </Box>
                  </Tooltip>
                  {props.type === "password" && value && (
                    <IconButton
                      position="absolute"
                      right="0px"
                      top={passwordEyeTop}
                      variant="unstyled"
                      icon={
                        !showPass ? (
                          <AiFillEyeInvisible
                            size={20}
                            color={colors["primary"]["700"]}
                          />
                        ) : (
                          <AiFillEye
                            size={20}
                            color={colors["primary"]["700"]}
                          />
                        )
                      }
                      onPress={() => {
                        setShowPass(showPass ? false : true);
                        inputRef.current.focus();
                      }}
                    />
                  )}
                </Box>
                {props.button && (
                  <Center width="50px" minHeight={height}>
                    <IconButton
                      variant="unstyled"
                      icon={<ButtonIcon />}
                      onPress={() => {
                        props.button.onPress();
                      }}
                    />
                  </Center>
                )}
              </HStack>
            </>
          );
        }}
      />
    </Box>
  );
};

export default Input;
