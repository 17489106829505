import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_meter_reading_reached_vehicles } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "meterReadingReachedVehiclesList",
  initialState,
  reducers: {
    _get_meter_reading_reached_vehicles: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_meter_reading_reached_vehicles_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_meter_reading_reached_vehicles_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_meter_reading_reached_vehicles,
  _get_meter_reading_reached_vehicles_success,
  _get_meter_reading_reached_vehicles_failure,
} = slice.actions;

export const get_meter_reading_reached_vehicles_list_selector = (state) =>
  state.meterReadingReachedVehiclesList;

export const getMeterReadingReachedVehiclesListReducer = slice.reducer;

export function get_meter_reading_reached_vehicles_list(variables) {
  return async (dispatch) => {
    dispatch(_get_meter_reading_reached_vehicles());
    try {
      const response = await QueryRequest(
        get_meter_reading_reached_vehicles,
        variables,
        dispatch
      );
      if (
        response?.data?.getCondemnationReadyVehicles &&
        !response?.data?.getCondemnationReadyVehicles.error
      ) {
        dispatch(
          _get_meter_reading_reached_vehicles_success(
            response?.data?.getCondemnationReadyVehicles
          )
        );
      } else if (response?.data?.getCondemnationReadyVehicles?.error) {
        dispatch(
          _get_meter_reading_reached_vehicles_failure(
            response?.data?.getCondemnationReadyVehicles.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_meter_reading_reached_vehicles_failure(error));
    }
  };
}
