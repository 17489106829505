import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { createAgencyMutation, createDepartmentMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "agency_create",
  initialState,
  reducers: {
    _create_agency: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _create_agency_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _create_agency_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _create_agency_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _create_agency,
  _create_agency_reset,
  _create_agency_success,
  _create_agency_failure,
} = slice.actions;

export const createAgencySelector = (state) => state.agency_create;

export const createAgencyReducer = slice.reducer;

export function create_agency(variables) {
  return async (dispatch) => {
    dispatch(_create_agency());
    try {
      const response = await MutateRequest(
        createAgencyMutation,
        variables,
        dispatch
      );
      if (response?.data?.createAgency && !response?.data?.createAgency.error) {
        dispatch(_create_agency_success(response?.data?.createAgency));
      } else if (response?.data?.createAgency?.error) {
        dispatch(_create_agency_failure(response?.data?.createAgency.error));
      }
    } catch (error) {
      dispatch(_create_agency_failure(error));
    }
  };
}
export function create_one_agency_clear(dispatch) {
  return async (dispatch) => {
    dispatch(_create_agency_reset());
  };
}
