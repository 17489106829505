import { useTranslation } from "react-i18next";
import { Box, Button, HStack, Text, VStack } from "native-base";
// import logo from "@assets/images/logo.png";
import QRCode from "react-qr-code";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const GatePassQRModal = ({ toggleQRModal, QRValue, programme }) => {
  const { t } = useTranslation();
  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("");
      pdf.addImage(imgData, "JPEG", 15, 10, 180, 0);
      pdf.save(`${QRValue?.session_title}.pdf`);
    });
  };

  return (
    <Box width="500px">
      <div id="divToPrint">
        <VStack space={4} justifyContent="center" alignItems={"center"} my={4}>
          <Box>
            {/* <img
              src={logo}
              alt=""
              width="100"
              className="auth-logo-dark m-auto"
            /> */}
          </Box>

          <Box>
            <QRCode value={"QRValue?.qr_code"} />
          </Box>
        </VStack>
      </div>
      <HStack space={2} justifyContent="flex-end">
        <Button onPress={printDocument} colorScheme="primary" variant="outline">
          {t("common:Download")}
        </Button>
        <Button onPress={toggleQRModal} colorScheme="danger" variant="outline">
          {t("common:cancel")}
        </Button>
      </HStack>
    </Box>
  );
};
export default GatePassQRModal;
