import React, { useEffect, useState } from "react";
import { Box, Button, VStack } from "native-base";
import { ROUTES } from "@views/routes/my_routes";
import SideBar from "@views/components/ui/side_bar";
import SideBarHeader from "@views/components/modules/sidebar/sidebar_header";
import SideBarFooter from "@views/components/modules/sidebar/sidebar_footer";
import { FaGem, FaHeart, FaUserNurse } from "react-icons/fa";
import {
  MdOutlineDashboard,
  MdOutlineMiscellaneousServices,
  MdCarRental,
  MdOutlineDeviceHub,
  MdKeyboardHide,
  MdAssignmentInd,
  MdOutlineLocalCarWash,
} from "react-icons/md";
import { TiSpannerOutline } from "react-icons/ti";
import HeaderBar from "@views/components/ui/header_bar";
import FooterBar from "@views/components/ui/footer_bar";
import ScrollView from "@views/components/ui/scroll_view";
import { retrieveItem, useStorageItem } from "@helpers/storage";
import { AiOutlineCar, AiOutlineIdcard } from "react-icons/ai";
import { DiHtml5DeviceAccess, DiMagento } from "react-icons/di";
import { GiTyre } from "react-icons/gi";
import { RiOilLine, RiQuestionAnswerLine } from "react-icons/ri";
import { GiNightSleep } from "react-icons/gi";
import { GrUserWorker } from "react-icons/gr";
import { Menu } from "antd";
import { useHistory } from "react-router-dom";
import { IoPeopleOutline } from "react-icons/io5";
const Layout1 = ({ children }) => {
  const [role, setRole] = useState(false);
  const roles = JSON.parse(retrieveItem("roles"));
  console.log("roles", roles);
  const history = useHistory();
  const {
    OrganizationId: loggedInUserOrganizationId,
    LevelId: loggedInUserLevelId,
    Photo,
    name,
  } = useStorageItem("user");
  // useEffect(() => {
  //   roles?.map((x) => {
  //     if (x.name === "Admin") {
  //       setRole("admin");
  //     }
  //     if (x.name === "CourseDirector") {
  //       setRole("course_director");
  //     }
  //     if (x.name === "Faculty") {
  //       setRole("faculty");
  //     }
  //     if (x.name === "DataEntryOperator") {
  //       setRole("deo");
  //     }
  //     if (x.name === "RTC") {
  //       setRole("RTC");
  //     }
  //     if (x.name === "Hostel Admin") {
  //       setRole("Hostel Admin");
  //     }
  //   });
  // }, [roles]);
  // window.scrollTo(0, 0)
  // lodash.startCase(camelCase(currentage))
  // document.title = currentage + " | AASC"

  let menu = [];
  if (roles?.[0]?.name === "Admin") {
    menu.push(
      {
        title: "Dashboard",
        icon: <MdOutlineDashboard />,
        url: ROUTES.DASHBOARD,
      },
      {
        title: "Vehicle",
        icon: <MdCarRental />,
        url: ROUTES.VEHICLE_LIST,
      },
      {
        title: "Driver",
        icon: <FaUserNurse color="#ffffff" />,
        url: ROUTES.DRIVER_LIST,
      },
      {
        title: "Condemnation",
        icon: <GiNightSleep />,
        url: ROUTES.CONDEMNATION_LIST,
      },
      {
        title: "Vehicle Pass",
        icon: <AiOutlineIdcard />,
        url: ROUTES.GATE_PASS_LIST,
      },
      {
        title: "Fuel",
        icon: <RiOilLine />,
        url: ROUTES.FUEL_LIST,
      },
      {
        title: "Service Request",
        icon: <TiSpannerOutline />,
        url: ROUTES.SERVICE_REQUEST_LIST,
      },
      {
        title: "Dormant",
        icon: <GiNightSleep />,
        url: ROUTES.SERVICE_NOT_GIVEN_VEHICLES,
      },
      {
        title: "Ready for Condemnation",
        icon: <MdOutlineMiscellaneousServices />,
        url: ROUTES.REACHED_METER_READING,
      },
      {
        title: "Officers",
        icon: <IoPeopleOutline />,
        url: ROUTES.OFFICERS,
      },

      {
        title: "Master Data",
        icon: <MdKeyboardHide />,
        subMenu: [
          {
            title: "Department",
            icon: <MdOutlineDeviceHub />,
            url: ROUTES.DEPARTMENT_LIST,
          },
          {
            title: "Car Make",
            icon: <AiOutlineCar />,
            url: ROUTES.CAR_MAKE_LIST,
          },
          {
            title: "Service Question",
            icon: <RiQuestionAnswerLine />,
            url: ROUTES.SERVICE_LIST,
          },
          // {
          //   title: "Car Model",
          //   icon: <IoLogoModelS />,
          //   url: ROUTES.CAR_MODEL_LIST,
          // },
          {
            title: "Agency",
            icon: <DiMagento />,
            url: ROUTES.AGENCY_LIST,
          },
          {
            title: "Accessories",
            icon: <DiHtml5DeviceAccess />,
            url: ROUTES.ACCESSORIES_LIST,
          },

          {
            title: "Tyre",
            icon: <GiTyre />,
            url: ROUTES.TYRE_LIST,
          },
          // {
          //   title: "Battery",
          //   icon: <MdOutlineDeviceHub />,
          //   url: ROUTES.BATTERY_LIST,
          // },
          {
            title: "Designation",
            icon: <MdAssignmentInd />,
            url: ROUTES.DESIGNATION_LIST,
          },
        ],
      }
    );
  } else if (roles?.[0]?.name === "Department") {
    menu.push(
      {
        title: "Dashboard",
        icon: <MdOutlineDashboard />,
        url: ROUTES.DASHBOARD,
      },
      {
        title: "Vehicle",
        icon: <MdCarRental />,
        url: ROUTES.VEHICLE_LIST,
      },

      {
        title: "Service Request",
        icon: <TiSpannerOutline />,
        url: ROUTES.SERVICE_REQUEST_LIST,
      },
      {
        title: "Fuel",
        icon: <RiOilLine />,
        url: ROUTES.FUEL_LIST,
      }
    );
  } else if (roles?.[0]?.name === "Public Department") {
    menu.push(
      {
        title: "Dashboard",
        icon: <MdOutlineDashboard />,
        url: ROUTES.DASHBOARD,
      },
      {
        title: "Vehicle",
        icon: <MdCarRental />,
        url: ROUTES.VEHICLE_LIST,
      },
      {
        title: "Driver",
        icon: <FaUserNurse color="#ffffff" />,
        url: ROUTES.DRIVER_LIST,
      },
      {
        title: "Condemnation",
        icon: <GiNightSleep />,
        url: ROUTES.CONDEMNATION_LIST,
      },
      // {
      //   title: "Vehicle Pass",
      //   icon: <AiOutlineIdcard />,
      //   url: ROUTES.GATE_PASS_LIST,
      // },
      {
        title: "Fuel",
        icon: <RiOilLine />,
        url: ROUTES.FUEL_LIST,
      },
      {
        title: "Service Request",
        icon: <TiSpannerOutline />,
        url: ROUTES.SERVICE_REQUEST_LIST,
      },
      {
        title: "Dormant",
        icon: <GiNightSleep />,
        url: ROUTES.SERVICE_NOT_GIVEN_VEHICLES,
      },
      {
        title: "Ready for Condemnation",
        icon: <MdOutlineMiscellaneousServices />,
        url: ROUTES.REACHED_METER_READING,
      }
    );
  } else if (roles?.[0]?.name === "Technician") {
    menu.push(
      {
        key: "Dashboard",
        label: "Dashboard",
        icon: <MdOutlineDashboard />,
        url: ROUTES.DASHBOARD,
      },
      {
        key: "Service Request",
        label: "Service Request",
        icon: <TiSpannerOutline />,
        url: ROUTES.SERVICE_REQUEST_LIST,
      }
    );
  } else if (roles?.[0]?.name === "Secretary") {
    menu.push(
      {
        title: "Dashboard",
        icon: <MdOutlineDashboard />,
        url: ROUTES.DASHBOARD,
      },
      {
        title: "Vehicle",
        icon: <MdCarRental />,
        url: ROUTES.VEHICLE_LIST,
      },
      {
        title: "Service Request",
        icon: <TiSpannerOutline />,
        url: ROUTES.SERVICE_REQUEST_LIST,
      },
      {
        title: "Vehicle Pass",
        icon: <AiOutlineIdcard />,
        url: ROUTES.GATE_PASS_LIST,
      },
      {
        title: "Condemnation",
        icon: <GiNightSleep />,
        url: ROUTES.CONDEMNATION_LIST,
      },
      {
        title: "Fuel",
        icon: <RiOilLine />,
        url: ROUTES.FUEL_LIST,
      },
      {
        title: "Driver",
        icon: <FaUserNurse color="#ffffff" />,
        url: ROUTES.DRIVER_LIST,
      }
    );
  } else if (
    roles?.[0]?.name === "ASO" ||
    roles?.[0]?.name === "SO" ||
    roles?.[0]?.name === "Deputy Secretary" ||
    roles?.[0]?.name === "Under Secretary"
  ) {
    menu.push(
      {
        title: "Dashboard",
        icon: <MdOutlineDashboard />,
        url: ROUTES.DASHBOARD,
      },
      {
        title: "Vehicle Pass",
        icon: <AiOutlineIdcard />,
        url: ROUTES.GATE_PASS_LIST,
      }
    );
  }
  const onClick = ({ item }) => {
    if (item?.props?.url) {
      history.push(item?.props?.url);
    }
  };
  return (
    <Box flexDirection="row" flexGrow="1">
      {roles?.[0]?.name !== "Technician" && (
        <SideBar
          image={"https://pbs.twimg.com/media/DxDDzzCVAAAlHS6.jpg"}
          iconShape="round"
          iconBgColor={"none"}
          iconColor={"#ffffff"}
          iconSize={25}
          iconSelectedColor={"#330000"}
          titleColor={"#adadad"}
          titleActiveColor={"#ffffff"}
          headerHeight={"100px"}
          footerHeight="50px"
          menu={menu}
          headerComponent={<SideBarHeader />}
          footerComponent={<SideBarFooter />}
          projectName="Public Vehicle Management"
        />
      )}
      <VStack flex={1}>
        <HeaderBar projectName={"Public Vehicle Management"} />
        {roles?.[0]?.name === "Technician" && (
          <Menu items={menu} onClick={onClick} mode="horizontal" />
        )}

        <ScrollView flex={1}>
          <Box flex={1} ml="10px" mr="10px" px="30px">
            {children}
          </Box>
        </ScrollView>
        <FooterBar />
      </VStack>
    </Box>
  );
};
export default Layout1;
