import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { updateAllotmentMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "allotmentUpdate",
  initialState,
  reducers: {
    _update_allotment: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _update_allotment_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.error = null;
    },
    _update_allotment_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _update_allotment_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
  },
});

const {
  _update_allotment,
  _update_allotment_reset,
  _update_allotment_success,
  _update_allotment_failure,
} = slice.actions;

export const updateAllotmentSelector = (state) => state.allotmentUpdate;

export const updateAllotmentReducer = slice.reducer;

export function update_allotment(variables) {
  return async (dispatch) => {
    dispatch(_update_allotment());
    try {
      const response = await MutateRequest(
        updateAllotmentMutation,
        variables,
        dispatch
      );
      if (
        response?.data?.updateAllotment &&
        !response?.data?.updateAllotment.error
      ) {
        dispatch(_update_allotment_success(response?.data?.updateAllotment));
      } else if (response?.data?.updateAllotment?.error) {
        dispatch(
          _update_allotment_failure(response?.data?.updateAllotment.error)
        );
      }
    } catch (error) {
      dispatch(_update_allotment_failure(error));
    }
  };
}
export async function update_one_allotment_clear(dispatch) {
  dispatch(_update_allotment_reset());
}
