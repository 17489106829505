import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getAllAgency } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "agency_List",
  initialState,
  reducers: {
    _get_agency: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_agency_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_agency_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_agency, _get_agency_success, _get_agency_failure } = slice.actions;

export const getAgencyListSelector = (state) => state.agency_List;

export const getAgencyListReducer = slice.reducer;

export function getAll_Agency(variables) {
  return async (dispatch) => {
    dispatch(_get_agency());
    try {
      const response = await QueryRequest(
        getAllAgency,
        variables,
        dispatch
      );
      if (response?.data?.getAgencies && !response?.data?.getAgencies.error) {
        dispatch(_get_agency_success(response?.data?.getAgencies));
      } else if (response?.data?.getAgencies?.error) {
        dispatch(_get_agency_failure(response?.data?.getAgencies.error));
      }
    } catch (error) {
      dispatch(_get_agency_failure(error));
    }
  };
}
