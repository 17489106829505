import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  HStack,
  VStack,
  Center,
  Text,
  Pressable,
  SimpleGrid,
  Image,
} from "native-base";
import {
  dashboardSelector,
  dashboard,
  dashboard_fuelSelector,
  dashboard_fuel,
  department_dashboard,
  department_dashboardSelector,
  technical_person_dashboard,
  technicalPersonDashboardSelector,
  driver_dashboard,
  driver_dashboard_count_Selector,
  pass_year_list_Selector,
  pass_year_list,
  pass_dashboard_Selector,
  pass_dashboard,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory, useParams } from "react-router-dom";
import { retrieveItem, useStorageItem } from "@helpers/storage";
import data from "@assets/images/loader.gif";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Fuel from "@assets/icons/fuel.png";
import Carpool from "@assets/icons/carpool.png";
import Vechicle from "@assets/icons/vehicle.png";
import Cars from "@assets/icons/cars.png";
import GuestHouse from "@assets/icons/country-house.png";
import AllotVehicle from "@assets/icons/allocted Car.png";
import BlockCar from "@assets/icons/block_car.png";
import OtherDepartment from "@assets/icons/garage.png";
import Chief from "@assets/icons/man.png";
import Minister from "@assets/icons/businessman.png";
import WorkShop from "@assets/icons/workshop.png";
import Inspection from "@assets/icons/inspection.png";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import TotalDriver from "@assets/icons/group (3).png";
import AllocateDriver from "@assets/icons/add-friend (2).png";
import NotAllocate from "@assets/icons/remove-user.png";
import Retried from "@assets/icons/driver.png";
import { Select } from "antd";
import TotalPass from "@assets/icons/id-card.png";
import TwoWheeler from "@assets/icons/bycicle.png";
import FourWheeler from "@assets/icons/car.png";
import FourTransit from "@assets/icons/gear.png";

// import { VStack, HStack, Spacer } from 'react-native-stacks';

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const roles = JSON.parse(retrieveItem("roles"));
  const { t } = useTranslation();
  const { name } = retrieveItem("user");
  const [fromDate, setFromDate] = useState("");
  const { Option } = Select;

  const { item, loading } = useSelector(dashboardSelector);
  const { item: department } = useSelector(department_dashboardSelector);
  const { item: fuel, loading: fuel_loading } = useSelector(
    dashboard_fuelSelector
  );
  const { item: technical_person_dashboard_list } = useSelector(
    technicalPersonDashboardSelector
  );
  const { item: driver_count } = useSelector(driver_dashboard_count_Selector);
  const { item: pass_year } = useSelector(pass_year_list_Selector);
  const { item: pass_count } = useSelector(pass_dashboard_Selector);
  const [selectedYears, setSelectedYears] = useState(String(moment().year()));

  const options = Array.isArray(pass_year?.years) ? pass_year.years : [];

  useEffect(() => {
    dispatch(dashboard());
    dispatch(dashboard_fuel());
    dispatch(department_dashboard());
    dispatch(technical_person_dashboard());
    dispatch(driver_dashboard());
    dispatch(pass_year_list());
  }, []);

  useEffect(() => {
    dispatch(pass_dashboard({ year: selectedYears }));
  }, [selectedYears]);

  const handleToggle = () => {
    history.push(ROUTES.SERVICE_REQUEST_LIST);
  };
  const handleAllotments = (value) => {
    history.push(`${ROUTES.VEHICLE_LIST}/${value}`);
  };
  const handleVehicles = () => {
    history.push(`${ROUTES.VEHICLE_LIST}`);
  };
  const handleDepartmentRedirect = (value, department_id) => {
    history.push(`${ROUTES.VEHICLE_LIST}/${value}/${department_id}`);
  };
  const handleFuelPage = () => {
    history.push(ROUTES.FUEL_LIST);
  };

  const handleTotalDrivers = () => {
    history.push(ROUTES.DRIVER_LIST);
  };

  const handleRetried = (type) => {
    history.push(`${ROUTES.DRIVER_LIST}/${type}`);
  };

  const handleRetriedDrivers = () => {
    handleRetried("status-retried");
  };

  if (loading)
    return (
      <Box alignSelf="center" marginTop="25%">
        <img src={data} />
      </Box>
    );
  let currentDate = moment().format("DD-MM-YYYY");

  let allocated_chief_minister_count = 0;
  allocated_chief_minister_count =
    department?.length &&
    department?.find(
      (x) => x.department_id === "f842edb1-a105-4560-ba43-1cd626fc70e8"
    )?.vehicle_count;

  let allocated_minister_count = 0;
  allocated_minister_count =
    department?.length &&
    department?.find(
      (x) => x.department_id === "6bdf3a7f-9396-4bb5-8f41-49c792be6b59"
    )?.vehicle_count;
  let allocated_public_department_count = 0;
  allocated_public_department_count =
    department?.length &&
    department?.find(
      (x) => x.department_id === "ab60c9c2-3d4f-4cb0-a8c0-a5ca93a8c458"
    )?.vehicle_count;

  let allocated_state_guest_count = 0;
  allocated_state_guest_count =
    department?.length &&
    department?.find(
      (x) => x.department_id === "89e9a04d-54bd-475f-a985-6a4675615d3d"
    )?.vehicle_count;

  let allocated_other_department =
    item?.allotted -
    (Number(allocated_chief_minister_count) +
      Number(allocated_minister_count) +
      Number(allocated_public_department_count) +
      Number(allocated_state_guest_count));

  const handleCondemned = () => {
    history.push(ROUTES.CONDEMNATION_LIST);
  };
  const handleCarpool = () => {
    handleAllotments(false);
  };
  const handleAllotted = () => {
    history.push(ROUTES.ALLOCATED_DASHBOARD);
    // handleAllotments(true);
  };
  const handleDriverAllotted = () => {
    history.push(ROUTES.ALLOCATED_DRIVER_DASHBOARD);
    // handleAllotments(true);
  };
  const public_department = () => {
    handleDepartmentRedirect(
      "department",
      "ab60c9c2-3d4f-4cb0-a8c0-a5ca93a8c458"
    );
  };
  const handleChiefMinister = () => {
    handleDepartmentRedirect(
      "department",
      "f842edb1-a105-4560-ba43-1cd626fc70e8"
    );
  };
  const handleMinister = () => {
    handleDepartmentRedirect(
      "department",
      "6bdf3a7f-9396-4bb5-8f41-49c792be6b59"
    );
  };
  const handleGuestHouse = () => {
    handleDepartmentRedirect(
      "department",
      "89e9a04d-54bd-475f-a985-6a4675615d3d"
    );
  };
  let dashboards = [];
  let drivers = [];
  let passes = [];
  let fuel_details = [];
  let service_request = [];
  let condemnation = [];

  let title = "";
  let title2 = "";
  let title3 = "";
  let title4 = "";
  let title5 = "";
  let title6 = "";

  if (
    roles?.[0]?.name === "Admin" ||
    roles?.[0]?.name === "Public Department"
  ) {
    title = "Vehicle";
    title2 = "Drivers";
    title3 = "Passes";
    title4 = "Fuel";
    title5 = "Service Request";
    title6 = "Condemnation";

    dashboards.push(
      {
        name: "Total Vehicles",
        value: item?.total_vehicles || 0,
        color: "#a272bd",
        sub_color: "#8c54ab",
        icon: (
          <Image source={Cars} size={"50px"} style={{ tintColor: "#7f4a9d" }} />
        ),
        onPress: handleVehicles,
      },
      {
        name: t("allocated_other_departments"),
        value: item?.allotted || 0,
        onPress: handleAllotted,
        color: "#0c7297",
        sub_color: "#105d78",
        icon: (
          <Image
            source={AllotVehicle}
            size={"50px"}
            style={{ tintColor: "#105d78" }}
          />
        ),
      },
      {
        name: t("carpool"),
        value: item?.not_allotted || 0,
        color: "#777fd8",
        sub_color: "#5960a5",
        icon: (
          <Image
            source={Carpool}
            size={"50px"}
            style={{ tintColor: "#5960a5" }}
          />
        ),
        //
        onPress: handleCarpool,
      }
      // {
      //   name: "Other Departments",
      //   value: allocated_other_department || 0,
      //   color: "#C88EA7",
      //   sub_color: "#a57f8f",
      //   icon: (
      //     <Image
      //       source={OtherDepartment}
      //       size={"50px"}
      //       style={{ tintColor: "#a57f8f" }}
      //     />
      //   ),
      // },
      // {
      //   name: t("chief_minister_office"),
      //   value: allocated_chief_minister_count || 0,
      //   color: "#82aebf",
      //   sub_color: "#658997",
      //   icon: (
      //     <Image
      //       source={Chief}
      //       size={"50px"}
      //       style={{ tintColor: "#658997" }}
      //     />
      //   ),
      //   onPress: handleChiefMinister,
      // },
      // {
      //   name: t("Hon'ble Minister"),
      //   value: allocated_minister_count || 0,
      //   color: "#FFD24C",
      //   sub_color: "#d1ad3f",
      //   icon: (
      //     <Image
      //       source={Minister}
      //       size={"50px"}
      //       style={{ tintColor: "#d1ad3f" }}
      //     />
      //   ),
      //   onPress: handleMinister,
      // },
      // {
      //   name: t("public_department"),
      //   value: allocated_public_department_count || 0,
      //   color: "#AD8B73",
      //   sub_color: "#8c6f5b",
      //   icon: (
      //     <Image
      //       source={Vechicle}
      //       size={"50px"}
      //       style={{ tintColor: "#8c6f5b" }}
      //     />
      //   ),
      //   onPress: public_department,
      // },
      // {
      //   name: t("state_guest_house"),
      //   value: allocated_state_guest_count || 0,
      //   color: "#069A8E",
      //   sub_color: "#068278",
      //   icon: (
      //     <Image
      //       source={GuestHouse}
      //       size={"50px"}
      //       style={{ tintColor: "#068278" }}
      //     />
      //   ),
      //   onPress: handleGuestHouse,
      // }
    );
    drivers.push(
      {
        name: t("total_drivers"),
        value: driver_count?.total_active_drivers || 0,
        color: "#DD5746",
        sub_color: "#8B322C",
        icon: (
          <Image
            source={TotalDriver}
            size={"50px"}
            style={{ tintColor: "#8B322C" }}
          />
        ),
        onPress: handleTotalDrivers,
      },
      {
        name: t("allocated_drivers"),
        value: driver_count?.alloted_drivers || 0,
        onPress: handleDriverAllotted,
        color: "#8DECB4",
        sub_color: "#41B06E",
        icon: (
          <Image
            source={AllocateDriver}
            size={"50px"}
            style={{ tintColor: "#41B06E" }}
          />
        ),
      },
      {
        name: "Not Allocated",
        value: driver_count?.not_alloted_drivers || 0,
        color: "#7A3E3E",
        sub_color: "#482121",
        icon: (
          <Image
            source={NotAllocate}
            size={"50px"}
            style={{ tintColor: "#482121" }}
          />
        ),
        //
        // onPress: handleCarpool,
      },
      {
        name: "Retired Drivers",
        value: driver_count?.retired_drivers || 0,
        color: "#C3AED6",
        sub_color: "#8675A9",
        icon: (
          <Image
            source={Retried}
            size={"50px"}
            style={{ tintColor: "#8675A9" }}
          />
        ),
        //
        onPress: handleRetriedDrivers,
      }
    ),
      passes.push(
        {
          name: "Total Passes",
          value: pass_count?.total_pass_count || 0,
          color: "#37B7C3",
          sub_color: "#088395",
          icon: (
            <Image
              source={TotalPass}
              size={"50px"}
              style={{ tintColor: "#088395" }}
            />
          ),
          // onPress: handleVehicles,
        },
        {
          name: "Two Wheeler Parking Passes",
          value: pass_count?.two_wheeler_parking_pass || 0,
          // onPress: handleDriverAllotted,
          color: "#B47B84",
          sub_color: "#944E63",
          icon: (
            <Image
              source={TwoWheeler}
              size={"50px"}
              style={{ tintColor: "#944E63" }}
            />
          ),
        },
        {
          name: "Four Wheeler Parking Passes",
          value: pass_count?.four_wheeler_parking_pass || 0,
          color: "#4B70F5",
          sub_color: "#4C3BCF",
          icon: (
            <Image
              source={FourWheeler}
              size={"50px"}
              style={{ tintColor: "#4C3BCF" }}
            />
          ),
          //
          // onPress: handleCarpool,
        },
        {
          name: "Four Wheeler Transit Passes",
          value: pass_count?.four_wheeler_transit_pass || 0,
          color: "#F9D689",
          sub_color: "#E0A75E",
          icon: (
            <Image
              source={FourTransit}
              size={"50px"}
              style={{ tintColor: "#E0A75E" }}
            />
          ),
          //
          // onPress: handleCarpool,
        }
      ),
      fuel_details.push({
        name: `This month as on ${currentDate}`,
        value: `${fuel?.this_month_quantity?.toLocaleString("en-IN") || 0} `,
        color: "#54BAB9",
        sub_color: "#429796",
        icon: (
          <Image source={Fuel} size={"50px"} style={{ tintColor: "#429796" }} />
        ),
        onPress: handleFuelPage,
      });
    service_request.push({
      name: "Given For Service",
      value: item?.department_on_service || 0,
      color: "#FF6464",
      sub_color: "#af5252",
      icon: (
        <Image
          source={WorkShop}
          size={"50px"}
          style={{ tintColor: "#af5252" }}
        />
        // <IoSettingsOutline color="#af5252" size={45} />
      ),
      onPress: handleToggle,
    });
    condemnation.push({
      name: "Condemned",
      value: item?.condemned || 0,
      color: "#E49393",
      sub_color: "#af7070",
      icon: (
        <Image
          source={BlockCar}
          size={"50px"}
          style={{ tintColor: "#af7070" }}
        />
      ),
      onPress: handleCondemned,
    });
  } else if (roles?.[0]?.name === "Technician") {
    title = "Vehicles";
    dashboards.push(
      {
        name: technical_person_dashboard_list?.[0]?.name,
        value: technical_person_dashboard_list?.[0]?.count || 0,
        // onPress: handleAllotments(true),
        color: "#0c7297",
        sub_color: "#105d78",
        icon: <MdOutlineMiscellaneousServices color="#105d78" size={50} />,
      },
      {
        name: technical_person_dashboard_list?.[1]?.name,
        value: technical_person_dashboard_list?.[1]?.count || 0,
        color: "#C88EA7",
        sub_color: "#ab8495",
        icon: (
          <Image
            source={Inspection}
            size={"50px"}
            style={{ tintColor: "#ab8495" }}
          />
        ),
      }
    );
  }
  return (
    <Box width="100%">
      <Box alignItems={"center"} justifyContent={"center"} height={"100%"}>
        <Text fontSize="3xl" bold pb={4} color={"black"}>
          {title}
        </Text>
        <SimpleGrid
          columns={{
            base: 1,
            xs: 1,
            md: 3,
            sm: 2,
            lg: 4,
            xl: 4,
            "2xl": 5,
          }}
          spacingY={"27px"}
          spacingX={21}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {dashboards?.map((option) => {
            return (
              <Box
                size="100px"
                backgroundColor={option.color}
                style={{
                  cursor: "pointer",
                  width: "220px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Pressable onPress={option.onPress}>
                  <VStack
                    justifyContent={"space-between"}
                    alignItems={"space-around"}
                  >
                    <HStack
                      space={"10px"}
                      justifyContent={"space-around"}
                      // padding={"10px"}
                    >
                      <Box h={"80px"} justifyContent={"center"}>
                        {option.icon}
                      </Box>
                      <Box h={"80px"} justifyContent={"center"}>
                        <Text
                          color={"white"}
                          fontSize={"26px"}
                          // fontStyle={"italic"}
                          fontWeight={600}
                        >
                          {option?.value}
                        </Text>
                      </Box>
                    </HStack>
                    <Box
                      backgroundColor={option?.sub_color}
                      justifyContent={"center"}
                      width={"100%"}
                      height={"30px"}
                      style={{
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <Text
                        textAlign={"center"}
                        alignItems={"center"}
                        color={"white"}
                        fontSize={"14px"}
                        fontWeight={500}
                      >
                        {option?.name}
                      </Text>
                    </Box>
                  </VStack>
                </Pressable>
              </Box>
            );
          })}
        </SimpleGrid>
      </Box>
      <Box
        alignItems={"center"}
        justifyContent={"center"}
        height={"100%"}
        mt={20}
      >
        <Text fontSize="3xl" bold pb={4} color={"black"}>
          {title2}
        </Text>
        <SimpleGrid
          columns={{
            base: 1,
            xs: 1,
            md: 3,
            sm: 2,
            lg: 4,
            xl: 4,
            "2xl": 5,
          }}
          spacingY={"27px"}
          spacingX={21}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {drivers?.map((option) => {
            return (
              <Box
                size="100px"
                backgroundColor={option.color}
                style={{
                  cursor: "pointer",
                  width: "220px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Pressable onPress={option.onPress}>
                  <VStack
                    justifyContent={"space-between"}
                    alignItems={"space-around"}
                  >
                    <HStack
                      space={"10px"}
                      justifyContent={"space-around"}
                      // padding={"10px"}
                    >
                      <Box h={"80px"} justifyContent={"center"}>
                        {option.icon}
                      </Box>
                      <Box h={"80px"} justifyContent={"center"}>
                        <Text
                          color={"white"}
                          fontSize={"26px"}
                          // fontStyle={"italic"}
                          fontWeight={600}
                        >
                          {option?.value}
                        </Text>
                      </Box>
                    </HStack>
                    <Box
                      backgroundColor={option?.sub_color}
                      justifyContent={"center"}
                      width={"100%"}
                      height={"30px"}
                      style={{
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <Text
                        textAlign={"center"}
                        alignItems={"center"}
                        color={"white"}
                        fontSize={"14px"}
                        fontWeight={500}
                      >
                        {option?.name}
                      </Text>
                    </Box>
                  </VStack>
                </Pressable>
              </Box>
            );
          })}
        </SimpleGrid>
      </Box>
      <Box alignItems={"center"} mt={20}>
        <Text fontSize="3xl" bold color={"black"}>
          {title3}
        </Text>
      </Box>
      <Box my="10px" alignItems={"start"}>
        <Select
          mode="sigle"
          placeholder="Select years"
          value={selectedYears}
          allowClear
          onChange={(value) => setSelectedYears(value)}
        >
          {options.map((year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
        </Select>
      </Box>
      <Box alignItems={"center"} justifyContent={"center"} height={"100%"}>
        <SimpleGrid
          columns={{
            base: 1,
            xs: 1,
            md: 3,
            sm: 2,
            lg: 4,
            xl: 4,
            "2xl": 5,
          }}
          spacingY={"27px"}
          spacingX={21}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {passes?.map((option) => {
            return (
              <Box
                size="100px"
                backgroundColor={option.color}
                style={{
                  cursor: "pointer",
                  width: "220px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Pressable onPress={option.onPress}>
                  <VStack
                    justifyContent={"space-between"}
                    alignItems={"space-around"}
                  >
                    <HStack
                      space={"10px"}
                      justifyContent={"space-around"}
                      // padding={"10px"}
                    >
                      <Box h={"80px"} justifyContent={"center"}>
                        {option.icon}
                      </Box>
                      <Box h={"80px"} justifyContent={"center"}>
                        <Text
                          color={"white"}
                          fontSize={"26px"}
                          // fontStyle={"italic"}
                          fontWeight={600}
                        >
                          {option?.value}
                        </Text>
                      </Box>
                    </HStack>
                    <Box
                      backgroundColor={option?.sub_color}
                      justifyContent={"center"}
                      width={"100%"}
                      height={"30px"}
                      style={{
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <Text
                        textAlign={"center"}
                        alignItems={"center"}
                        color={"white"}
                        fontSize={"14px"}
                        fontWeight={500}
                      >
                        {option?.name}
                      </Text>
                    </Box>
                  </VStack>
                </Pressable>
              </Box>
            );
          })}
        </SimpleGrid>
      </Box>
      <HStack
        width={"100%"}
        alignItems={"center"}
        justifyContent={"space-evenly"}
      >
        <Box alignItems={"center"} justifyContent={"center"} mt={20}>
          <Text fontSize="3xl" bold pb={4} color={"black"}>
            {title4}
          </Text>
          <SimpleGrid
            columns={{
              base: 1,
              xs: 1,
              md: 3,
              sm: 2,
              lg: 4,
              xl: 4,
              "2xl": 5,
            }}
            spacingY={"27px"}
            spacingX={21}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {fuel_details?.map((option) => {
              return (
                <Box
                  size="100px"
                  backgroundColor={option.color}
                  style={{
                    cursor: "pointer",
                    width: "220px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <Pressable onPress={option.onPress}>
                    <VStack
                      justifyContent={"space-between"}
                      alignItems={"space-around"}
                    >
                      <HStack
                        space={"10px"}
                        justifyContent={"space-around"}
                        // padding={"10px"}
                      >
                        <Box h={"80px"} justifyContent={"center"}>
                          {option.icon}
                        </Box>
                        <Box h={"80px"} justifyContent={"center"}>
                          <HStack textAlign={"center"} alignItems={"center"}>
                            <Text
                              color={"white"}
                              fontSize={"26px"}
                              // fontStyle={"italic"}
                              fontWeight={600}
                            >
                              {option?.value}
                            </Text>
                            <Text color={"white"}>{"(in Liters)"}</Text>
                          </HStack>
                        </Box>
                      </HStack>
                      <Box
                        backgroundColor={option?.sub_color}
                        justifyContent={"center"}
                        width={"100%"}
                        height={"30px"}
                        style={{
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <Text
                          textAlign={"center"}
                          alignItems={"center"}
                          color={"white"}
                          fontSize={"14px"}
                          fontWeight={500}
                        >
                          {option?.name}
                        </Text>
                      </Box>
                    </VStack>
                  </Pressable>
                </Box>
              );
            })}
          </SimpleGrid>
        </Box>
        <Box alignItems={"center"} justifyContent={"center"} mt={20}>
          <Text fontSize="3xl" bold pb={4} color={"black"}>
            {title5}
          </Text>
          <SimpleGrid
            columns={{
              base: 1,
              xs: 1,
              md: 3,
              sm: 2,
              lg: 4,
              xl: 4,
              "2xl": 5,
            }}
            spacingY={"27px"}
            spacingX={21}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {service_request?.map((option) => {
              return (
                <Box
                  size="100px"
                  backgroundColor={option.color}
                  style={{
                    cursor: "pointer",
                    width: "220px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <Pressable onPress={option.onPress}>
                    <VStack
                      justifyContent={"space-between"}
                      alignItems={"space-around"}
                    >
                      <HStack
                        space={"10px"}
                        justifyContent={"space-around"}
                        // padding={"10px"}
                      >
                        <Box h={"80px"} justifyContent={"center"}>
                          {option.icon}
                        </Box>
                        <Box h={"80px"} justifyContent={"center"}>
                          <HStack textAlign={"center"} alignItems={"center"}>
                            <Text
                              color={"white"}
                              fontSize={"26px"}
                              // fontStyle={"italic"}
                              fontWeight={600}
                            >
                              {option?.value}
                            </Text>
                            <Text color={"white"}></Text>
                          </HStack>
                        </Box>
                      </HStack>
                      <Box
                        backgroundColor={option?.sub_color}
                        justifyContent={"center"}
                        width={"100%"}
                        height={"30px"}
                        style={{
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <Text
                          textAlign={"center"}
                          alignItems={"center"}
                          color={"white"}
                          fontSize={"14px"}
                          fontWeight={500}
                        >
                          {option?.name}
                        </Text>
                      </Box>
                    </VStack>
                  </Pressable>
                </Box>
              );
            })}
          </SimpleGrid>
        </Box>
        <Box alignItems={"center"} justifyContent={"center"} mt={20}>
          <Text fontSize="3xl" bold pb={4} color={"black"}>
            {title6}
          </Text>
          <SimpleGrid
            columns={{
              base: 1,
              xs: 1,
              md: 3,
              sm: 2,
              lg: 4,
              xl: 4,
              "2xl": 5,
            }}
            spacingY={"27px"}
            spacingX={21}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {condemnation?.map((option) => {
              return (
                <Box
                  size="100px"
                  backgroundColor={option.color}
                  style={{
                    cursor: "pointer",
                    width: "220px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <Pressable onPress={option.onPress}>
                    <VStack
                      justifyContent={"space-between"}
                      alignItems={"space-around"}
                    >
                      <HStack
                        space={"10px"}
                        justifyContent={"space-around"}
                        // padding={"10px"}
                      >
                        <Box h={"80px"} justifyContent={"center"}>
                          {option.icon}
                        </Box>
                        <Box h={"80px"} justifyContent={"center"}>
                          <HStack textAlign={"center"} alignItems={"center"}>
                            <Text
                              color={"white"}
                              fontSize={"26px"}
                              // fontStyle={"italic"}
                              fontWeight={600}
                            >
                              {option?.value}
                            </Text>
                            <Text color={"white"}></Text>
                          </HStack>
                        </Box>
                      </HStack>
                      <Box
                        backgroundColor={option?.sub_color}
                        justifyContent={"center"}
                        width={"100%"}
                        height={"30px"}
                        style={{
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <Text
                          textAlign={"center"}
                          alignItems={"center"}
                          color={"white"}
                          fontSize={"14px"}
                          fontWeight={500}
                        >
                          {option?.name}
                        </Text>
                      </Box>
                    </VStack>
                  </Pressable>
                </Box>
              );
            })}
          </SimpleGrid>
        </Box>
      </HStack>
    </Box>
  );
};
export default Dashboard;
