import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getTyreMakers } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "tyreMakerList",
  initialState,
  reducers: {
    _get_tyre_maker: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_tyre_maker_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_tyre_maker_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const { _get_tyre_maker, _get_tyre_maker_success, _get_tyre_maker_failure } = slice.actions;

export const getTyreMakerListSelector = (state) => state.tyreMakerList;

export const getTyreMakerListReducer = slice.reducer;

export function getTyre_Makers(variables) {
  return async (dispatch) => {
    dispatch(_get_tyre_maker());
    try {
      const response = await QueryRequest(
        getTyreMakers,
        variables,
        dispatch
      );
      if (response?.data?.getTyreMakes && !response?.data?.getTyreMakes.error) {
        dispatch(_get_tyre_maker_success(response?.data?.getTyreMakes));
      } else if (response?.data?.getTyreMakes?.error) {
        dispatch(_get_tyre_maker_failure(response?.data?.getTyreMakes.error));
      }
    } catch (error) {
      dispatch(_get_tyre_maker_failure(error));
    }
  };
}
