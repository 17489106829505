import { gql } from "@apollo/client";

export const get_captcha_query = gql`
  query getCaptcha {
    getCaptcha {
      captcha
      error {
        status_code
        message
      }
    }
  }
`;
