import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getPassSubTypesQuery } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "passSubList",
  initialState,
  reducers: {
    _get_pass_sub_type: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_pass_sub_type_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_pass_sub_type_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_pass_sub_type,
  _get_pass_sub_type_success,
  _get_pass_sub_type_failure,
} = slice.actions;

export const getPassSubListSelector = (state) => state.passSubList;

export const getPassSubListReducer = slice.reducer;

export function getPassSubList(variables) {
  return async (dispatch) => {
    dispatch(_get_pass_sub_type());
    try {
      // let data = {
      //   items: [
      //     {
      //       pass_number: "001/22",
      //       type: "Transit Pass",
      //       pass_sub_type: "Official Pass",
      //       pass_issued_to: "Saran",
      //       issued_date: "12/01/2023",
      //     },
      //   ],
      // };
      // dispatch(_get_pass_sub_type_success(data));
      const response = await QueryRequest(
        getPassSubTypesQuery,
        variables,
        dispatch
      );
      if (
        response?.data?.getPassSubTypes &&
        !response?.data?.getPassSubTypes.error
      ) {
        dispatch(_get_pass_sub_type_success(response?.data?.getPassSubTypes));
      } else if (response?.data?.getPassSubTypes?.error) {
        dispatch(
          _get_pass_sub_type_failure(response?.data?.getPassSubTypes.error)
        );
      }
    } catch (error) {
      dispatch(_get_pass_sub_type_failure(error));
    }
  };
}
