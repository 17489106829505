import { createAgencyReducer } from "./agency_create";
import { deleteAgencyReducer } from "./agency_delete";
import { getOneAgencyReducer } from "./agency_getone";
import { getAgencyListReducer } from "./agency_list";
import { updateAgencyReducer } from "./agency_update";
export const getAgencyListReducers = {
  agency_List: getAgencyListReducer,
  agencyUpdate: updateAgencyReducer,
  agency_create: createAgencyReducer,
  agencyDelete: deleteAgencyReducer,
  agencyGetOne: getOneAgencyReducer,
};
export * from "./agency_list";
export * from "./agency_update";
export * from "./agency_create";
export * from "./agency_delete";
export * from "./agency_getone";
