import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Text } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Modal from "@views/components/ui/modal";
import { useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";

import {
  createAgencySelector,
  create_one_agency_clear,
  deleteAgencySelector,
  delete_agency,
  delete_one_carmake_modal,
  getAgencyListSelector,
  getAll_Agency,
  get_one_agency,
  get_one_agency_clear,
  updateAgencySelector,
  update_one_agency_clear,
} from "@services/redux";

import AgencyDetails from "./agency_details";
const AgencyList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState(null);
  const [actionItem, setActionItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { loading: deleteLoading, status: deleteStatus } =
    useSelector(deleteAgencySelector);
  const { status: updateStatus } = useSelector(updateAgencySelector);
  const { status: createStatus } = useSelector(createAgencySelector);

  const handleAdd = () => {
    dispatch(get_one_agency_clear);
    setActionType("add");
    setActionItem({});
    setManageModalVisible(true);
  };

  const handleEdit = (pro) => {
    setActionType("edit");
    dispatch(get_one_agency({ id: pro.id }));
    setActionItem({
      ...pro,

      id: pro.id,
    });
    setManageModalVisible(true);
  };

  useEffect(() => {
    if (deleteStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Agency")} ${t("Deleted_successfully")}`,
      });
      setDialogVisible(false);
      dispatch(getAll_Agency());
      handleModalClose();
      dispatch(delete_one_carmake_modal());
    } else if (updateStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Agency")} ${t("update_successfully")}`,
      });
      setManageModalVisible(false);
      dispatch(update_one_agency_clear);
      dispatch(getAll_Agency());

      handleModalClose();
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Agency")} ${t("create_successfully")}`,
      });
      setManageModalVisible(false);
      dispatch(getAll_Agency());
      dispatch(create_one_agency_clear());
      handleModalClose();
    }
  }, [deleteStatus, updateStatus, createStatus]);

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const { loading, items, total_items } = useSelector(getAgencyListSelector);

  let columns = [
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:address"),
      dataIndex: "address",
      key: "addrress",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "left",
      type: "actions",
      width: 200,
      actions: [
        {
          icon: <AiOutlineEdit />,
          iconSize: 30,
          iconColor: "primary.700",
          title: t("table:edit_agency"),
          onPress: handleEdit,
        },
        {
          icon: <AiOutlineDelete />,
          iconSize: 30,
          iconColor: "red.700",
          title: t("table:delete_agency"),
          onPress: handleDelete,
        },
      ],
    },
  ];

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_agency"),
      colorScheme: "primary",
      variant: "outline",
      onPress: handleAdd,
    },
  ];

  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <Text fontSize="md" fontWeight="bold" py={4}>
          {t("table:agency_name")}
        </Text>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="ProgrammeSessions"
              loading={loading}
              data={items}
              columns={columns}
              hasSearch
              hasPagination
              totalItems={total_items}
              fetch={getAll_Agency}
              query={null}
              headerActions={header_actions}
              emptyMessage={t("table:empty_agency")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
      <Modal
        isOpen={manageModalVisible}
        onClose={handleModalClose}
        header={actionType === "add" ? t("Add Agency") : t("Edit Agency")}
        component={
          <AgencyDetails
            handleModalClose={handleModalClose}
            initialValue={actionItem}
          />
        }
        source={actionItem}
        group={"User"}
        size={"lg"}
      />
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("table:delete_agency")}
        content={t("table:delete_agency_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            // isLoading: loading,
            formErrorMessage: t("error:error_message"),
            submit: delete_agency,
          },
        ]}
      />
    </>
  );
};
export default AgencyList;
