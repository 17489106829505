import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { getOneVehicle } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  item: {},
};

const slice = createSlice({
  name: "vehicles_getone",
  initialState,
  reducers: {
    _getOne_vehicle: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _getOne_vehicle_reset: (state) => {
      state.loading = false;
      state.id = null;
      state.status = null;
      state.item = {};
    },
    _getOne_vehicle_success: (state, { payload }) => {
      state.loading = false;
      state.item = payload;
      state.error = null;
    },
    _getOne_vehicle_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.item = {};
    },
  },
});

const {
  _getOne_vehicle,
  _getOne_vehicle_reset,
  _getOne_vehicle_success,
  _getOne_vehicle_failure,
} = slice.actions;

export const getOneVehiclesListSelector = (state) => state.vehicles_getone;

export const getOneVehiclesListReducer = slice.reducer;

export function getone_Vehicles(variables) {
  return async (dispatch) => {
    dispatch(_getOne_vehicle());
    try {
      const response = await QueryRequest(getOneVehicle, variables, dispatch);
      if (response?.data?.getVehicle && !response?.data?.getVehicle.error) {
        dispatch(_getOne_vehicle_success(response?.data?.getVehicle));
      } else if (response?.data?.getVehicle?.error) {
        dispatch(_getOne_vehicle_failure(response?.data?.getVehicle.error));
      }
    } catch (error) {
      dispatch(_getOne_vehicle_failure(error));
    }
  };
}

export async function getone_vechile_clear(dispatch) {
  dispatch(_getOne_vehicle_reset());
}
