import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack, Text } from "native-base";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import data from "@assets/images/loader.gif";

import {
  getCar_Makers,
  getTyre_Makers,
  getAll_Agency,
  getAll_Accessories,
  getone_Vehicles,
  getOneVehiclesListSelector,
  getone_vechile_clear,
  createVehicleSelector,
} from "@services/redux";
import moment from "moment";
import { Button, Card } from "antd";

const VehicleDetailsView = (props) => {
  const dispatch = useDispatch();

  const [actionItem, setActionItem] = useState({});
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();

  const formatDisplay = (date) => {
    if (typeof date === "string") {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    } else return "-";
  };

  useEffect(() => {
    dispatch(getCar_Makers({}));
    dispatch(getTyre_Makers({}));
    dispatch(getAll_Agency({}));
    dispatch(getAll_Accessories({}));
  }, []);

  useEffect(() => {
    if (id) dispatch(getone_Vehicles({ id: id }));
  }, [id]);

  const handleBack = () => {
    dispatch(getone_vechile_clear);
    history.push(ROUTES.VEHICLE_LIST);
  };

  const { item: vehicle_get_one, loading } = useSelector(
    getOneVehiclesListSelector
  );

  useEffect(() => {
    let vehicle = {};
    if (vehicle_get_one?.id) {
      vehicle = {
        ...vehicle_get_one,
        additional_accessories: vehicle_get_one?.additional_accessories?.map(
          (x) => {
            return x.additional_accessory_id;
          }
        ),
        date_of_delivery: moment(
          parseInt(vehicle_get_one.date_of_delivery, 10)
        ),
        go_date: moment(parseInt(vehicle_get_one.go_date, 10)),

        vechile_make: vehicle_get_one?.car_model?.car_make?.id,
        front_image_url: [
          {
            uid: "-1",
            name: vehicle_get_one?.front_image_url ? "Front image" : "",
            status: "done",
            url: vehicle_get_one?.front_image_url,
          },
        ],
        rear_image_url: [
          {
            uid: "-1",
            name: vehicle_get_one?.rear_image_url ? "Rear image" : "",
            status: "done",
            url: vehicle_get_one?.rear_image_url,
          },
        ],
        left_image_url: [
          {
            uid: "-1",
            name: vehicle_get_one?.left_image_url ? "Left image" : "",
            status: "done",
            url: vehicle_get_one?.left_image_url,
          },
        ],
        right_image_url: [
          {
            uid: "-1",
            name: vehicle_get_one?.right_image_url ? "Right image" : "",
            status: "done",
            url: vehicle_get_one?.right_image_url,
          },
        ],
        dashboard_image_url: [
          {
            uid: "-1",
            name: vehicle_get_one?.dashboard_image_url ? "Dashboard image" : "",
            status: "done",
            url: vehicle_get_one?.dashboard_image_url,
          },
        ],
        go_copy: [
          {
            uid: "-1",
            name: vehicle_get_one?.go_copy ? "GO Copy" : "",
            status: "done",
            url: vehicle_get_one?.go_copy,
          },
        ],
        rc_book: [
          {
            uid: "-1",
            name: vehicle_get_one?.rc_book ? "RC Book" : "",
            status: "done",
            url: vehicle_get_one?.rc_book,
          },
        ],
      };
      setActionItem(vehicle);
    }
  }, [vehicle_get_one]);

  if (loading)
    return (
      <Box alignSelf="center" marginTop="25%">
        <img src={data} />
      </Box>
    );

  return (
    <>
      <Box flex="1" w="100%">
        <Box top="10px" zIndex="2" bg="white">
          <Text fontSize="md" fontWeight="bold" py={4}>
            {"Vehicle Details"}
          </Text>
        </Box>
        <HStack space={"10"} mt="10" mb="10">
          <Box flex={1}>
            <Text>{"Front Image"}</Text>
            {vehicle_get_one?.front_image_url && (
              <img
                src={vehicle_get_one?.front_image_url}
                alt=""
                width="65px"
                // height={"25px"}
              />
            )}
          </Box>
          <Box flex={1}>
            <Text>{"Rear Image"}</Text>
            {vehicle_get_one?.rear_image_url && (
              <img
                src={vehicle_get_one?.rear_image_url}
                alt=""
                width="65px"
                // height={"25px"}
              />
            )}
          </Box>
          <Box flex={1}>
            <Text>{"Left Image"}</Text>
            {vehicle_get_one?.left_image_url && (
              <img
                src={vehicle_get_one?.left_image_url}
                alt=""
                width="65px"
                // height={"25px"}
              />
            )}
          </Box>{" "}
          <Box flex={1}>
            <Text>{"Right Image"}</Text>
            {vehicle_get_one?.right_image_url && (
              <img
                src={vehicle_get_one?.right_image_url}
                alt=""
                width="65px"
                // height={"25px"}
              />
            )}
          </Box>
          <Box flex={1}>
            <Text>{"Dashboard Image"}</Text>
            {vehicle_get_one?.dashboard_image_url && (
              <img
                src={vehicle_get_one?.dashboard_image_url}
                alt=""
                width="65px"
                // height={"25px"}
              />
            )}
          </Box>
        </HStack>
        <Card mb="5">
          <VStack space="5">
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>Vehicle Number:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{vehicle_get_one?.vehicle_number}</Text>
                  </Box>
                </HStack>
              </Box>

              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>Class Vehicle:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>
                      {vehicle_get_one?.class_vehicle
                        ? vehicle_get_one?.class_vehicle
                        : "-"}
                    </Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>Vehicle Make:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{vehicle_get_one?.car_model?.car_make?.name}</Text>
                  </Box>
                </HStack>
              </Box>
              <Box flex={1}>
                <HStack>
                  <Box width="35%">
                    <Text bold>Vehicle Model:</Text>
                  </Box>
                  <Box flex={1}>
                    <Text>{vehicle_get_one?.car_model?.name}</Text>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Colour:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{vehicle_get_one?.colour}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Fuel Type:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {vehicle_get_one?.fuel_used
                            ? vehicle_get_one?.fuel_used
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Engine Number:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>{vehicle_get_one?.engine_number}</Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Chassis Number:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {vehicle_get_one?.chasis_number
                            ? vehicle_get_one?.chasis_number
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Battery Type:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {vehicle_get_one?.battery_type
                            ? vehicle_get_one?.battery_type
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Battery Number:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {vehicle_get_one?.battery_number
                            ? vehicle_get_one?.battery_number
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Fuel Tank Capacity:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {vehicle_get_one?.fuel_tank_capacity
                            ? vehicle_get_one?.fuel_tank_capacity
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Seating Capacity:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {vehicle_get_one?.seating_capacity
                            ? vehicle_get_one?.seating_capacity
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Tyre Make:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {vehicle_get_one?.tyre_make?.name
                            ? vehicle_get_one?.tyre_make?.name
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Tyre Number:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {vehicle_get_one?.tyre_number
                            ? vehicle_get_one?.tyre_number
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Wheel Drive:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {vehicle_get_one?.wheel_drive
                            ? vehicle_get_one?.wheel_drive
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Go Number:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {vehicle_get_one?.go_number
                            ? vehicle_get_one?.go_number
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>GO Date:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {vehicle_get_one?.go_date
                            ? formatDisplay(vehicle_get_one?.go_date)
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Vehicle Price:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {vehicle_get_one?.vehicle_price
                            ? vehicle_get_one?.vehicle_price.toLocaleString(
                                "en-IN"
                              )
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Date Of Delivery:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {vehicle_get_one?.date_of_delivery
                            ? formatDisplay(vehicle_get_one?.date_of_delivery)
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Received From:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {vehicle_get_one?.source
                            ? vehicle_get_one?.source
                            : "-"}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>Additional Accessories:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          {vehicle_get_one?.additional_accessories?.map(
                            (x, i) => {
                              return (
                                <>
                                  {(i ? "," : "") + x.additional_accessory.name}
                                </>
                              );
                            }
                          )}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}></Box>
                </HStack>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1}>
                <HStack>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>GO Copy:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          <a
                            href={vehicle_get_one?.go_copy}
                            target={"_blank"}
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            GO Copy
                          </a>
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack>
                      <Box width="35%">
                        <Text bold>RC Book:</Text>
                      </Box>
                      <Box flex={1}>
                        <Text>
                          <a
                            href={vehicle_get_one?.rc_book}
                            target={"_blank"}
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            RC Book
                          </a>
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>
          </VStack>
        </Card>

        <HStack justifyContent={"flex-end"} mt="8">
          <Box>
            <Button
              isLoading={false}
              width="100px"
              colorScheme="danger"
              variant="outline"
              onClick={handleBack}
              formErrorMessage={t("error:error_message")}
            >
              {t("Back")}
            </Button>
          </Box>
        </HStack>
      </Box>
    </>
  );
};
export default VehicleDetailsView;
