import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Text } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import ScrollView from "@views/components/ui/scroll_view";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { CgFileRemove } from "react-icons/cg";
import Modal from "@views/components/ui/modal";
import { ROUTES } from "@views/routes/my_routes";
import { useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";

import {
  getDriverListSelector,
  getDriverReport,
  getDriverReportClear,
  getDriverReportSelector,
  get_DriverList,
  update_driver,
} from "@services/redux";
import { getOneDriverSelector, get_one_driver_clear } from "@services/redux";
import {
  deleteDriverSelector,
  delete_driver,
  delete_one_driver_modal,
} from "@services/redux";
import { updateDriverSelector, update_one_driver_clear } from "@services/redux";
import { createDriverSelector, create_one_driver_clear } from "@services/redux";
import { Button, Form, Select } from "antd";
import { BiHistory } from "react-icons/bi";
import { retrieveItem } from "@helpers/storage";
import { startCase } from "lodash";
import {
  get_driver_without_pagination,
  getdriverListWithOutPaginationSelector,
} from "@services/redux/slices/drivers/driver_list_without_pagination";
import { excelDownload } from "@helpers/constants";
const OfficerList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [object, set_object] = useState({ status: "active" });
  const [actionItem, setActionItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [retriedVisible, setRetriedVisible] = useState(false);
  const [searchString, setSearchString] = useState(null);
  console.log("searchString", searchString);
  const [form_data, set_form_data] = useState(null);
  console.log("object", object);

  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useSelector(deleteDriverSelector);
  const {
    loading: update_loading,
    status: update_status,
    error: update_error,
  } = useSelector(updateDriverSelector);
  const { loading, items, total_items } = useSelector(getDriverListSelector);
  const { items: driver_details } = useSelector(
    getdriverListWithOutPaginationSelector
  );
  console.log("driver_details", driver_details);

  const handleAdd = () => {
    history.push({
      pathname: `${ROUTES.DRIVER_DETAILS}`,
    });
    dispatch(get_one_driver_clear);
    setActionItem({});
    setManageModalVisible(true);
  };

  const handleEdit = (pro) => {
    history.push({
      pathname: `${ROUTES.DRIVER_DETAILS}/${pro.id}`,
    });
    dispatch(getOneDriverSelector({ id: pro.id }));
    setActionItem({
      ...pro,

      id: pro.id,
    });
    setManageModalVisible(true);
  };
  const handleView = (item) => {
    history.push({
      pathname: `${ROUTES.OFFICERS_DETAILS}/${item.id}`,
    });
  };
  //   const handleHistory = (item) => {
  //     history.push({
  //       pathname: `${ROUTES.DRIVER_HISTORY}/${item.id}`,
  //     });
  //   };
  useEffect(() => {
    if (deleteStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("Driver")} ${t("Deleted_successfully")}`,
      });
      setDialogVisible(false);
      dispatch(get_DriverList());

      dispatch(delete_one_driver_modal());
    } else if (deleteError === "Failure") {
      showToast({
        type: "success",
        message: `${t("Driver")} ${t("Deleted_successfully")}`,
      });
      setDialogVisible(false);
      dispatch(get_DriverList());

      dispatch(delete_one_driver_modal());
    }
  }, [deleteStatus, deleteError]);

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
    setRetriedVisible(false);
  };
  const handle_retried_driver = () => {
    dispatch(
      update_driver({ id: actionItem?.id, data: { status: "retried" } })
    );
  };

  const { url: report, loading: report_loading } = useSelector(
    getDriverReportSelector
  );
  useEffect(() => {
    if (report) {
      window.open(report);
      dispatch(getDriverReportClear());
    }
  }, [report]);

  const roles = JSON.parse(retrieveItem("roles"));
  let rejected_status = "";
  const handle_reject = (get_one) => {
    setActionItem(get_one);
    setRetriedVisible(true);
    console.log("get_one", get_one);
    rejected_status = "success";
  };

  useEffect(() => {
    if (update_status === "Success") {
      showToast({
        type: "success",
        message: `${t("Driver")} ${t("retried successfully")}`,
      });
      setRetriedVisible(false);
      dispatch(update_one_driver_clear);
      dispatch(
        get_DriverList({ type: "active", page_number: 1, page_limit: 10 })
      );
    } else if (update_error) {
      showToast({
        type: "error",
        message: `Something want wrong`,
      });
      dispatch(update_one_driver_clear());
    }
  }, [update_status, update_error]);

  //   let actions = [
  //     {
  //       icon: <AiOutlineEdit />,
  //       iconSize: 30,
  //       iconColor: "primary.700",
  //       title: t("table:edit_driver"),
  //       onPress: handleEdit,
  //     },
  //     {
  //       icon: <BiHistory />,
  //       iconSize: 30,
  //       iconColor: "primary.700",
  //       title: t("table:history"),
  //       onPress: handleHistory,
  //     },
  //     {
  //       icon: <AiOutlineDelete />,
  //       iconSize: 30,
  //       iconColor: "red.700",
  //       title: t("table:delete_driver"),
  //       onPress: handleDelete,
  //     },
  //   ];
  let action_public_department = [
    {
      icon: <AiOutlineEye />,
      iconSize: 30,
      iconColor: "primary.700",
      title: t("View Driver Details"),
      onPress: handleView,
    },
    // {
    //   icon: <BiHistory />,
    //   iconSize: 30,
    //   iconColor: "primary.700",
    //   title: t("table:history"),
    //   onPress: handleHistory,
    // },
  ];

  //   if (object.status === "active")
  //     actions.push({
  //       icon: <CgFileRemove />,
  //       iconSize: 30,
  //       iconColor: "red.700",
  //       title: t("table:retried_driver"),
  //       onPress: handle_reject,
  //     });

  const officer_items = [
    {
      application_no: "726542",
      name: "ram",
      designation: "teacher",
      pass_category: "Four Wheeler",
      pass_type: "Parking",
    },
    {
      application_no: "724282",
      name: "siva",
      designation: "Typist",
      pass_category: "Two Wheeler",
      pass_type: "Parking",
    },
  ];

  let columns = [
    {
      header: t("table:application_no"),
      dataIndex: "application_no",
      key: "application_no",
      sortable: false,
      align: "left",
      width: 220,
    },
    {
      header: t("table:name"),
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:designation"),
      dataIndex: "designation",
      key: "designation",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:pass_category"),
      dataIndex: "pass_category",
      key: "pass_category",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:pass_type"),
      dataIndex: "pass_type",
      key: "pass_type",
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "left",
      type: "actions",
      width: 150,
      actions: action_public_department,
    },
  ];

  //   let header_actions = [
  //     {
  //       height: "50px",
  //       label: t("table:add_driver"),
  //       colorScheme: "primary",
  //       variant: "outline",
  //       onPress: handleAdd,
  //     },
  //   ];

  //   useEffect(() => {
  //     set_object({
  //       status: form_data?.status,
  //       type: form_data?.type,
  //       search_string: searchString,
  //     });
  //   }, [form_data, searchString]);
  //   useEffect(() => {
  //     dispatch(
  //       get_driver_without_pagination({
  //         ...form_data,
  //         search_string: searchString,
  //       })
  //     );
  //   }, [form_data, searchString]);

  return (
    <>
      <Box width="100%" mt="10px" marginBottom="20px">
        <Box>
          <Text fontSize="md" fontWeight="bold" py={4}>
            {t("officers_list")}
          </Text>
        </Box>
        <VStack space={5}>
          <Wrapper>
            <Table
              width="100%"
              rowKey="id"
              group="OfficerList"
              loading={loading}
              data={officer_items}
              columns={columns}
              hasSearch
              hasPagination
              setSearchString={setSearchString}
              totalItems={total_items}
              //   fetch={get_DriverList}
              query={object}
              //   headerActions={
              //     roles?.[0]?.name === "Admin" ? header_actions : null
              //   }
              emptyMessage={t("No officers details Found")}
              headerColor={"white"}
            />
          </Wrapper>
        </VStack>
      </Box>
    </>
  );
};
export default OfficerList;
